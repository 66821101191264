import { useState, useEffect } from "react";
import FacilityDetails from './FacilityDetails';
import FacilityInformation from './FacilityInformation';
import FacilityInfoDetails from './FacilityInfoDetails';
import "./CreateHfrId.css";
import axios from 'axios';
import { config } from 'process';
import { CircularProgress } from '@mui/material';
import OTPInput from 'react-otp-input';
import Configg from '../Config/config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const CreateHfrId = () => {
  const navigate = useNavigate();
  const [view, setView] = useState('details');
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [visible, setVisible] = useState({
    Otpscreen: false,
    facilityid: false,
  });
  const [facilityId, setFacilityId] = useState("");
  const [facilityDetails, setFacilityDetails] = useState({});


  // sessionstorage get items
  const Token = sessionStorage.getItem("phrotpTransactionId");
  const facilityname = sessionStorage.getItem("facilityName");
  const ownershipcode = sessionStorage.getItem("ownershipcode");
  const ownershipsubcode = sessionStorage.getItem("ownershipsubtype");
  const countryname = sessionStorage.getItem("country");
  const statecode = sessionStorage.getItem("statecode");
  const address = sessionStorage.getItem("address");
  const districtcode = sessionStorage.getItem("districtcode");
  // const selectedmedicinecode = JSON.parse(sessionStorage.getItem("selectedMedicineCodes") || "[]")[0] || "";
  const pincode = sessionStorage.getItem("pincode");
  const subDistrictCode = sessionStorage.getItem("subdistrictcode");
  const FacilityCode = sessionStorage.getItem("facilitycode");
  const subFacilityCode = sessionStorage.getItem("facilitysubtypecode");
  const operationalcode = sessionStorage.getItem("facilityStatus");

  const facilityformdetails = sessionStorage.getItem("facilityFormData");

  const [variables, setVariables] = useState({
    otp: "",
    alertTitle: "",
    alertMessage: "",
    showAlert: false,
  });

  useEffect(() => {
    const data = {
      facilityname: sessionStorage.getItem("facilityName"),
      ownershipcode: sessionStorage.getItem("ownershipcode"),
      ownershipsubcode: sessionStorage.getItem("ownershipsubtype"),
      countryname: sessionStorage.getItem("country"),
      statecode: sessionStorage.getItem("statecode"),
      address: sessionStorage.getItem("address"),
      districtcode: sessionStorage.getItem("districtcode"),
      pincode: sessionStorage.getItem("pincode"),
      subDistrictCode: sessionStorage.getItem("subdistrictcode"),
      FacilityCode: sessionStorage.getItem("facilitycode"),
      subFacilityCode: sessionStorage.getItem("facilitysubtypecode"),
      operationalcode: sessionStorage.getItem("facilityStatus"),
      facilityformdetails: sessionStorage.getItem("facilityFormData"),
      selectedmedicinecodes: sessionStorage.getItem("selectedMedicineCodes"),

    };


    setFacilityDetails(data);
  });

  const handleContinue = () => {
    let missingFields = [];
    const {
      facilityname,
      countryname,
      statecode,
      districtcode,
      subDistrictCode,
      address,
      pincode,
    } = facilityDetails;

    if (!facilityname) missingFields.push("Facility Name");
    console.log("facilityname", facilityDetails);
    if (!countryname) missingFields.push("Country Name");
    if (!statecode) missingFields.push("State");
    if (!districtcode) missingFields.push("District");
    if (!subDistrictCode) missingFields.push("Sub-District");
    if (!address) missingFields.push("Address");
    if (!pincode) missingFields.push("Pincode");
    console.log("pincode", pincode)

    if (missingFields.length > 0) {
      toast.error(`Please fill the following mandatory fields: ${missingFields.join(', ')}`);
      setShowLoading(false);
      return;
    }
    if (view === 'details') {
      setView('information');
    } else if (view === 'information') {
      setView('infoDetails');
    }
  };

  const handleContinue1 = () => {
    let missingFields = [];
    const { ownershipcode, FacilityCode, operationalcode, subFacilityCode, ownershipsubcode, selectedmedicinecodes } = facilityDetails;
    console.log("ownership", ownershipcode, ownershipsubcode)

    if (!ownershipcode) missingFields.push("Facility ownership");
    if (!FacilityCode) missingFields.push("Facility Code");
    if (!operationalcode) missingFields.push("operationalcode");
    if (!subFacilityCode) missingFields.push("Sub Facility");
    if (!ownershipsubcode) missingFields.push("Owneraship subtype");
    if (!selectedmedicinecodes) missingFields.push("Medicine");

    if (missingFields.length > 0) {
      toast.error(`Please fill the following mandatory fields: ${missingFields.join(', ')}`);
      setShowLoading(false);
      return;
    }

    getSessionTokenAPI(); // Call the API if validation passes
  };


  const getSessionTokenAPI = async () => {
    setShowLoading(true);
    await axios.post(`${Configg.abhaBaseUrl}getphrsessiontoken`)
      .then((response) => {
        console.log("phr session token", response);
        if (response.status === 422) {
          // console.log("response:", response);
          setShowLoading(false);
        } else if (response.status === 200) {
          sessionStorage.setItem("hpraccessToken", response.data.accessToken);
          generateOtp(response.data.accessToken);
          setShowLoading(false);
        }
      })
      .catch(async (error) => {
        console.log(error);
        setShowLoading(false);
        setSnackbarMessage(
          "An error occurred.Please try again after sometime"
        );
        setShowSnackbar(true);
      });
  };

  const generateOtp = async (accessToken) => {
    setShowLoading(true);
    console.log("access token", accessToken);
    const hprid = sessionStorage.getItem("hpraddress");
    try {
      const dataBody = {
        "idType": "hpr_id",
        "domainName": "@hpr.abdm",
        "authMethod": "AADHAAR_OTP",
        "hprId": hprid
      };
      console.log("data body", dataBody);
      const response = await axios.post(
        `${Configg.abhaBaseUrl}api/generate-otp`,
        dataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": accessToken,
          },
        }
      );

      if (response.status === 422) {
        setVisible((prevState) => ({
          ...prevState,
          Otpscreen: false,
        }));

        setShowLoading(false)
      } else if (response.status === 200) {
        console.log("response success", response);
        sessionStorage.setItem("hpraddressTransactionId", response.data.txnId);
        setVisible((prevState) => ({
          ...prevState,
          Otpscreen: true,
        }));
        setShowLoading(false);
      } else {
        setVisible((prevState) => ({
          ...prevState,
          Otpscreen: false,
        }));
        setShowLoading(false)
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    } catch (err) {
      if (err.response) {
        console.log("Error Response:", err.response.data);
      } else {
        console.log("Request Error:", err.message);
      }
    }
  };

  const verifyOtp = async () => {
    setShowLoading(true);
    const txnId = sessionStorage.getItem("hpraddressTransactionId");
    const accessToken = sessionStorage.getItem("hpraccessToken");
    try {
      const dataBody = {
        "otp": variables.otp,
        "txnId": txnId,
      };
      console.log("data body", dataBody);
      const response = await axios.post(
        `${Configg.abhaBaseUrl}api/verify-otp`,
        dataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": accessToken,
          },
        }
      );

      console.log("response of otp", response);

      if (response.status === 422) {
        setVisible((prevState) => ({
          ...prevState,
          Otpscreen: false,
        }));
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "Error",
          alertMessage: "Invalid OTP",
        }));
        setShowLoading(false)
      } else if (response.status === 200) {
        console.log("otp response success", response);
        sessionStorage.setItem("phrotpTransactionId", response.data.token);
        basicinfo(response.data.token);
        setShowLoading(true);
        // setVisible((prevState) => ({
        //   ...prevState,
        //   Otpscreen: true,
        // }));
      } else {
        setVisible((prevState) => ({
          ...prevState,
          Otpscreen: false,
        }));
        setShowLoading(false)
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    } catch (err) {
      if (err.response) {
        console.log("Error Response:", err.response.data);
        console.log("Request Error:", err.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: err.response.data.message || "Error",
          alertMessage: `${err.response.data.error} or Invalid OTP` || "Invalid OTP",
        }));
      } else {
        console.log("Request Error:", err.message);
      }
      setShowSnackbar(true);
      setShowLoading(false);
    }
  };

  const basicinfo = async (hprid) => {
    setShowLoading(true);
    const selectedmedicinecodes = sessionStorage.getItem("selectedMedicineCodes");
    const cleanedmedicinecode = selectedmedicinecodes
      ? JSON.parse(selectedmedicinecodes.replace(/\\/g, "").replace(/^"|"$/g, ""))
      : [];
    //  try
    // Collect missing fields
    let missingFields = [];
    if (!facilityname) missingFields.push("Facility Name");
    if (!ownershipcode) missingFields.push("Ownership Code");
    if (!ownershipsubcode) missingFields.push("Ownership Sub Code");
    if (!countryname) missingFields.push("Country Name");
    if (!statecode) missingFields.push("State Code");
    if (!districtcode) missingFields.push("District Code");
    if (!subDistrictCode) missingFields.push("Sub-District Code");
    if (!address) missingFields.push("Address");
    if (!pincode) missingFields.push("Pincode");
    if (!selectedmedicinecodes) missingFields.push("Selected Medicine Code");
    if (!FacilityCode) missingFields.push("Facility Code");
    if (!subFacilityCode) missingFields.push("Sub Facility Code");
    if (!operationalcode) missingFields.push("Operational Code");

    if (missingFields.length > 0) {
      toast.error(`Please fill the following mandatory fields: ${missingFields.join(', ')}`);
      setShowLoading(false);
      return;
    }



    try {
      const dataBody = {
        "trackingId": "",
        "facilityName": facilityname,
        "ownershipCode": ownershipcode,
        "ownershipSubTypeCode": ownershipsubcode,
        "ownershipSubTypeCode2": "MOHF",
        "country": countryname,
        "stateLGDCode": statecode,
        "districtLGDCode": districtcode,
        "subDistrictLGDCode": subDistrictCode,
        "facilityRegion": "",
        "villageCityTownLGDCode": "",
        "addressLine1": address,
        "addressLine2": "",
        "pincode": pincode,
        "latitude": "21.4691505",
        "longitude": "78.5291706",
        "facilityContactNumber": "",
        "facilityEmailId": "",
        "facilityLandlineNumber": "",
        "facilityStdCode": "",
        "websiteLink": "",
        "systemOfMedicineCode": cleanedmedicinecode.join(','),
        "facilityTypeCode": FacilityCode,
        "facilitySubType": subFacilityCode,
        "specialityTypeCode": "",
        "typeOfServiceCode": "DAY",
        "facilityOperationalStatus": operationalcode,
        "facilityBoardName": "",
        "facilityBoardValue": "",
        "facilityBuildingName": "",
        "facilityBuildingValue": "",
        "addressProofType": "",
        "addressProofAttachmentName": "",
        "addressProofAttachmentValue": 4,
        "workingDays": "Tue",
        "openingHours": operationalcode === "F" ? "1:00 AM - 2:00 pm" : null,
        "hpridAuth": hprid,
      };
      console.log("data body of hfr basic info", dataBody);
      const response = await axios.post(
        `${Configg.abhaBaseUrl}v3/hfrBasicInfo`,
        dataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Configg.token
          },
        }
      );

      console.log("response ", response);

      if (response.status === 422 || response.status === 400) {
        setVisible((prevState) => ({
          ...prevState,
          Otpscreen: false,
        }));
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "Error!!",
          alertMessage: "Invalid auth token.",
        }));
        setShowLoading(false)
      } else if (response.status === 200) {
        console.log("basic info response success", response);
        sessionStorage.setItem("hprtrackingid", response.data.data.trackingId);
        console.log("tracking id", response.data.data.trackingId);
        setView('infoDetails');
        setVisible({ ...visible, Otpscreen: false });

        setShowLoading(false);
      } else {
        setVisible((prevState) => ({
          ...prevState,
          Otpscreen: false,
        }));
        setShowLoading(false)
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "Error",
          alertMessage: "Invalid Authentication",
        }));
      }
    } catch (err) {
      if (err.response) {
        console.log("Error Response:", err.response.status, err.response.data);
        console.log("Request Error:", err.message);
        setShowLoading(false)
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: err?.response?.data?.errorStatus?.length >= 2
            ? `${err.response.data.errorStatus[0]?.field} and ${err.response.data.errorStatus[1]?.field}`
            : err?.response?.data?.errorStatus?.[0]?.field || "Error!!",
          alertMessage: err?.response?.data?.errorStatus?.length >= 2
            ? `${err.response.data.errorStatus[0]?.message} and ${err.response.data.errorStatus[1]?.message}`
            : err?.response?.data?.errorStatus?.[0]?.message || "Please try again",

          // alertTitle: err.response.data?.errorStatus?.[0]?.field || "Error!!",
          // alertMessage: err.response.data?.errorStatus?.[0]?.message || err.response.data?.message || "An error occurred",
        }));

      } else {
        console.log("Request Error:", err.message);
      }
    }
  };

  const handlePrevious = () => {
    if (view === 'information') {
      setView('details');
    } else if (view === 'infoDetails') {
      setView('information');
    }
  };

  const handleChange = (otp) => {
    setVariables((prevState) => ({
      ...prevState,
      otp: otp,
    }));
  };

  const handleCancel = () => {
    navigate("/patients");
  };

  const handleCancel1 = () => {
    setView('information');
    setVisible({ ...visible, Otpscreen: false });
  }


  // for hfr additional info
  const hfradditionalinfo = async () => {
    setShowLoading(true);
    const trackingid = sessionStorage.getItem("hprtrackingid");
    console.log("tracking id", trackingid)
    try {
      const dataBody = {
        "trackingId": trackingid,
        "hasPharmacy": "N",
        "hasBloodBank": "N",
        "hasCathLab": "N",
        "hasDiagnosticLab": "N",
        "hasImagingCenter": "N",
        "servicesByImagingCenterService": "",
        "servicesByImagingCenterCount": 0,
        "nhrrId": "",
        "nin": "",
        "abpmjayId": "",
        "rohiniId": "",
        "echsId": "",
        "cghsId": "",
        "ceaRegistration": "",
        "stateInsuranceSchemeId": ""
      };
      console.log("data body", dataBody);
      const response = await axios.post(
        `${Configg.abhaBaseUrl}v3/hfrAdditionalInfo`,
        dataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Configg.token,
          },
        }
      );

      console.log("response of additional info", response);

      if (response.status === 422) {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
        setShowLoading(false)
      } else if (response.status === 200) {
        console.log("hfradditional response success", response);
        await Createhfrdetailinfo();
        setShowLoading(false);
      } else {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
        setShowLoading(false);
      }
    } catch (err) {
      if (err.response) {
        console.log("Error Response:", err.response.data);
      } else {
        console.log("Request Error:", err.message);
      }
      setShowLoading(false);
    }
  };

  // for creating hfr details
  const Createhfrdetailinfo = async () => {
    setShowLoading(true);
    const selectedmedicinecode = sessionStorage.getItem("selectedMedicineCodes");
    const cleanedmedicinecode = selectedmedicinecode
      ? JSON.parse(selectedmedicinecode.replace(/\\/g, "").replace(/^"|"$/g, ""))
      : [];
    const trackingid = sessionStorage.getItem("hprtrackingid");
    // const combinedSelectedServices = sessionStorage.getItem("selectedservice");
    const combinedSelectedServices = sessionStorage.getItem("selectedservice");
    const cleanedServices = combinedSelectedServices
      ? JSON.parse(combinedSelectedServices.replace(/\\/g, "").replace(/^"|"$/g, ""))
      : [];

    console.log("combinedSelectedServices", cleanedServices);

    const bedsWithVent = sessionStorage.getItem("bedsWithVent");
    const hduWithVent = sessionStorage.getItem("hduWithVent");
    const bedsWithOxy = sessionStorage.getItem("bedsWithOxy");
    const bedsWithoutOxy = sessionStorage.getItem("bedsWithoutOxy");
    const bedsWithoutVent = sessionStorage.getItem("bedsWithoutVent");
    const hduWithoutVent = sessionStorage.getItem("hduWithoutVent");
    const totalvent = sessionStorage.getItem("totalvent");
    const totalbeds = sessionStorage.getItem("totalbeds");
    const parsedServices = JSON.parse(combinedSelectedServices);

    const selectedArray = parsedServices.MEDICINE;

    console.log("tracking id", trackingid, combinedSelectedServices)
    try {
      const dataBody = {
        "trackingId": trackingid,
        "systemOfMedicineCode": cleanedmedicinecode.join(','),
        "isSpecializationAvalaible": "Y",
        "specialities": cleanedServices,
        "countHDUBedsWithFunctionalVentilators": hduWithoutVent,
        "countIPDBedsWithoutOxygen": bedsWithOxy,
        "countIPDBedsWithOxygen": bedsWithoutOxy,
        "countICUBedsWithVentilators": bedsWithoutVent,
        "countICUBedsWithoutVentilators": bedsWithVent,
        "countHDUBedsWithVentilators": hduWithVent,
        "countHDUBedsWithoutVentilators": hduWithoutVent,
        "totalNumberOfVentilators": totalvent,
        "countDayCareBedsWithoutOxygen": "",
        "countDayCareBedsWithOxygen": "",
        "countDentalChairs": 0,
        "totalNumberOfBeds": totalbeds,
        "isJanAushadhiKendra": "",
        "janAushadhiKendraId": "",
        "drugLicenseNumber": "",
        "pharmacyGstinNumber": "",
        "pharmacistRegistrationNumber": "",
        "isFacilityRegisteredInERaktkosh": "",
        "eRaktoshId": "",
        "bloodBankLicenseNumber": "",
        "bloodStorageCenters": "",
        "storageCentersCount": 0,
        "bloodCollectedPerAnnum": "",
        "bloodRequiredPerAnnum": "",
        "service": "",
        "count": 0
      };
      console.log("data body", dataBody);
      const response = await axios.post(
        `${Configg.abhaBaseUrl}v3/hfrDetailInfo`,
        dataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Configg.token,
          },
        }
      );

      if (response.status === 422) {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
        setShowLoading(false)
      } else if (response.status === 200) {
        console.log("Create hfr response success", response);
        await Submithfrdetails();
        setShowLoading(false);
      } else {
        setVisible((prevState) => ({
          ...prevState,
          Otpscreen: false,
        }));
        setShowLoading(false)
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    } catch (err) {
      if (err.response) {
        console.log("Error Response:", err.response.data);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: err?.response?.data?.errorStatus?.length >= 2
            ? `${err.response.data.errorStatus[0]?.field} and ${err.response.data.errorStatus[1]?.field}`
            : err?.response?.data?.errorStatus?.[0]?.field || "Error!!",
          alertMessage: err?.response?.data?.errorStatus?.length >= 2
            ? `${err.response.data.errorStatus[0]?.message} and ${err.response.data.errorStatus[1]?.message}`
            : err?.response?.data?.errorStatus?.[0]?.message || "",
        }));

      } else {
        console.log("Request Error:", err.message);
      }
      setShowLoading(false);
    }
  };

  // For submitting hfrdetails
  const Submithfrdetails = async () => {
    setShowLoading(true);
    const trackingid = sessionStorage.getItem("hprtrackingid");
    const hpridAuthToken = sessionStorage.getItem("phrotpTransactionId");
    try {
      const dataBody = {
        "trackingId": trackingid,
        "sourceOfInformation": "",
        "sourceUniqueID": "",
        "hpridAuth": hpridAuthToken
      };
      console.log("data body", dataBody);
      const response = await axios.post(
        `${Configg.abhaBaseUrl}v3/SubmitHfrDetails`,
        dataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Configg.token,
          },
        }
      );
      console.log("Submit hfr response", response);

      if (response.status === 422) {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
        setShowLoading(false)
      } else if (response.status === 200 || response.status === 201) {
        const facilityId = response.data.data.facilityId;
        sessionStorage.setItem("hprtrackingid", facilityId);
        setFacilityId(facilityId);
        // for dialogue box
        setVisible((prevState) => ({
          ...prevState,
          facilityid: true,
        }));
        setShowLoading(false);
      } else {
        setShowLoading(false)
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    } catch (err) {
      if (err.response) {
        console.log("Error Response:", err.response.data);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: err?.response?.data?.errorStatus?.length >= 2
            ? `${err.response.data.errorStatus[0]?.field} and ${err.response.data.errorStatus[1]?.field}`
            : err?.response?.data?.errorStatus?.[0]?.field || "Error!!",
          alertMessage: err?.response?.data?.errorStatus?.length >= 2
            ? `${err.response.data.errorStatus[0]?.message} and ${err.response.data.errorStatus[1]?.message}`
            : err?.response?.data?.errorStatus?.[0]?.message || "Please try again.",
        }));
      } else {
        console.log("Request Error:", err.message);
      }
    }
  };

  const handleok = () => {
    sessionStorage.removeItem("facilityName");
    sessionStorage.removeItem("ownershipcode");
    sessionStorage.removeItem("ownershipsubtype");
    sessionStorage.removeItem("country");
    sessionStorage.removeItem("statecode");
    sessionStorage.removeItem("address");
    sessionStorage.removeItem("districtcode");
    sessionStorage.removeItem("pincode");
    sessionStorage.removeItem("subdistrictcode");
    sessionStorage.removeItem("facilitycode");
    sessionStorage.removeItem("facilitysubtypecode");
    sessionStorage.removeItem("facilityStatus");
    sessionStorage.removeItem("selectedMedicineCodes");
    sessionStorage.removeItem("selectedservice");
    setVisible((prevState) => ({
      ...prevState,
      facilityid: false, // Hide the dialog
    }));
    navigate("/patients");
  };

  const handleAlertClose = () => {
    setVariables((prevState) => ({
      ...prevState,
      showAlert: false,
    }));
  };

  const ShowAlert = ({ title, message, onClose }) => {
    return (
      <div className="popup">
        <div className="popup-inner">
          <p
            style={{ fontSize: "24px", fontWeight: "600", fontFamily: "bold" }}
          >
            {title}
          </p>
          <p>{message}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2rem",
              padding: "4%",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="ContinueBtn"
              onClick={
                onClose
              }
              style={{
                background: "#52447d",
                width: "20%",
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div >
    );
  };

  return (
    <div style={{ width: "100%", margin: "1.5%" }}>
      {!visible.Otpscreen && !visible.facilityid && (
        <>
          {view === 'details' && <FacilityDetails />}
          {view === 'information' && <FacilityInformation />}
          {view === 'infoDetails' && <FacilityInfoDetails />}

          <div className='hfridbutton'>
            {view === 'details' && (
              <>
                <button className='createHfridbuttons' style={{ width: "20%" }} onClick={handleCancel}>Cancel</button>
                <button className='createHfridbuttons' style={{ width: "20%" }} onClick={handleContinue}>Continue</button>
              </>
            )}
            {view === 'information' && (
              <>
                <button className='createHfridbuttons' style={{ width: "20%" }} onClick={handlePrevious}>Previous</button>
                <button className='createHfridbuttons' style={{ width: "20%" }} onClick={handleContinue1}>
                  {showLoading && (
                    <CircularProgress size={24} color="inherit" />
                  )}
                  Continue</button>
                {/* <button className='createHfridbuttons' onClick={handleContinue}>Continue</button> */}
              </>
            )}
            {view === 'infoDetails' && (
              <button className='createHfridbuttons' style={{ width: "28%" }} onClick={hfradditionalinfo}>
                {showLoading && (
                  <CircularProgress size={24} color="inherit" />
                )}
                Submit</button>
            )}

          </div>
        </>
      )}
      {/* 'otp screen */}

      {variables.showAlert && (
        <ShowAlert
          title={variables.alertTitle}
          message={variables.alertMessage}
          onClose={handleAlertClose}
        />
      )}

      {visible.Otpscreen && !visible.facilityid && (
        <>
          <div
            className="leftContainer"
            style={{ width: "50%", gap: "1rem", justifyContent: "center" }}
          >
            <div className="abhaContainer" style={{ padding: "30px" }}>
              <div style={{ gap: "1rem", justifyContent: "center" }}>
                <h2 style={{ textAlign: "center" }}>
                  Verification code
                </h2>
                <h3 style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}>
                  We have sent the code verification to your mobile number
                </h3>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <OTPInput
                    value={variables.otp}
                    onChange={handleChange}
                    numInputs={6}
                    renderSeparator={<span>{""}</span>}
                    renderInput={(props) => (
                      <input {...props} type="tel" />
                    )}
                    skipDefaultStyles={true}
                    inputStyle="otp-input"
                    inputType="number"
                  />
                </div>

                {/* <div
            onClick={handleResend1}
            style={{
              marginLeft: "60%",
              color: "orange",
              cursor: disabled ? "not-allowed" : "pointer",
              opacity: disabled ? 0.6 : 1,
            }}
          >
            Resend OTP? {disabled && `(${timer}s)`}
          </div> */}

                <div className="Adharandmobile" style={{ marginTop: "40px" }}>
                  <button
                    className={"editButton2"}
                    style={{
                      marginRight: "10px",
                      width: "30%",
                      margin: "0 auto",
                      height: "40px",
                      color: "white",
                    }}
                    onClick={handleCancel1}
                  >
                    Cancel
                  </button>
                  <button
                    className={"editButton2"}
                    style={{
                      marginRight: "10px",
                      width: "30%",
                      margin: "0 auto",
                      height: "40px",
                      color: "white",
                    }}
                    onClick={verifyOtp}
                  // onclick={verifyOtp1}
                  >
                    {showLoading && (
                      <CircularProgress size={24} color="inherit" />
                    )}
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {visible.facilityid && (
        <>
          <div
            // className="leftContainer"
            style={{
              width: "70%",
              gap: "2rem",
              justifyContent: "center",
              position: "relative",
              padding: "20px",
              border: "1px solid #d5e2f4",
              borderRadius: "8px",
              backgroundColor: "#fff",
              marginTop: "25%",
              marginLeft: "20%"
            }}
          >
            <h2 style={{ textAlign: "center", marginBottom: "7%" }}>
              Health Facility Registry Service
            </h2>
            <div style={{ textAlign: "center", fontSize: "18px" }}>
              Facility ID: <span style={{ fontWeight: "bold" }}>{facilityId}</span>
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              Facility created successfully. Please save the facility Id returned.
            </div>
            <button
              className="createHfridbuttons"
              style={{
                width: "20%",
                marginLeft: "70%",
                marginTop: "10%",
              }}
              onClick={handleok}
            >
              Ok
            </button>
          </div>
        </>

      )}

    </div>
  );
};

export default CreateHfrId;
