import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { publicEncrypt, constants } from "crypto-browserify";
import "./ABHA.css";
import { Buffer } from "buffer";
import Config from "../../../Config/config";
import Axios from "axios";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";


const AbhaMobileValidation = () => {
  const navigate = useNavigate();

  const [variables, setVariables] = useState({
    abhaAddress: "",
    // mobileNumber: "",
    mobileNumber: sessionStorage.getItem("aadharmobilenumber") || "",
    showAlert: false,
    alertTitle: "",
    alertMessage: "",
    otp: "",
    mobileOtp: false,
    isEditable: true,
    suggestions: "",
    createabhaaddress: "",
    abhaaddressnumber: [],
    suggestedAbhaAddress: [],
  });
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState(30);
  const [disabled, setDisabled] = useState(true);


  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");


  const PATIENT_ID = sessionStorage.getItem("PATIENT_ID");

  const ShowAlert = ({ title, message, onClose }) => {
    return (
      <div className="popup">
        <div className="popup-inner">
          <p
            style={{ fontSize: "24px", fontWeight: "600", fontFamily: "bold" }}
          >
            {title}
          </p>
          <p>{message}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2rem",
              padding: "4%",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="ContinueBtn"
              onClick={
                variables.type === "registerSuccessfull"
                  ? uploadAbhaData
                  : onClose
              }
              style={{
                background: "#52447d",
                width: "20%",
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    );
  };

  // useEffect(() => {
  //   isexist();
  // }, [variables.abhaAddress]);

  const getPublickey = async () => {
    Axios.get(`${Config.abhaBaseUrl}publicKey/${UserID}`, {
      headers: {
        authorization: `Bearer ${token}`,
        "x-caller": await encrypted,
      },
    })
      .then((response) => {
        // console.log("response :", response);
        if (response.status == 200) {
          // console.log("PUBLICKEY :", response.data);
          sessionStorage.setItem("ABDMPublicKey", response.data);
          return response;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSessionTokenAPI = async () => {
    if (variables.abhaAddress.trim() !== "") {
      setShowLoading(true);
      const dataBody = {
        healthId: variables.abhaAddress, // + '@' + 'abdm',
        USER_ID: UserID,
      };

      console.log("dataBody", dataBody);
      await Axios.post(`${Config.abhaBaseUrl}getSessionToken`, dataBody, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
        },
      })
        .then(async (response) => {
          console.log("res in sessiontoken", response);
          sessionStorage.setItem("accessToken", response.data.accessToken);
          sessionStorage.setItem("abhaAddress", dataBody.healthId);

          if (response.status === 422) {
            await generateMobileOTP(variables.mobileNumber);
            console.log("Otp getting response.status === 422 ");
          } else if (
            response.data.status === "ACTIVE" ||
            response.data.status === 200
          ) {
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: "Sorry!!!",
              alertMessage:
                "This name is already taken as ABHA Adrress. Please try with new address.",
            }));
          } else {
            let details = response.data.details[0];
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: response.data.code,
              alertMessage:
                details && details.length > 0
                  ? details.message
                  : response.data.message,
            }));
            setShowLoading(false);
          }
        })
        .catch(async (error) => {
          console.log("error", error);
          sessionStorage.setItem("abhaAddress", dataBody.healthId);
          var status = await generateMobileOTP();
          console.log("Otp is coming through catch block");
          return status;
        });
    } else {
      setSnackbarMessage("Please enter a both the values.");
      setShowSnackbar(true);
    }
  };

  // api call before v3 version
  // const generateMobileOTP = async (mobile) => {
  //   await getPublickey();
  //   try {
  //     const accessToken = await sessionStorage.getItem("accessToken");
  //     const transactionId = await sessionStorage.getItem("transactionId");
  //     const dataBody = {
  //       USER_ID: UserID,
  //       accessToken: accessToken,
  //       txnId: transactionId,
  //       mobile: variables.mobileNumber,
  //     };
  //     const response = await Axios.post(
  //       `${Config.abhaBaseUrl}generateMobileOTP`,
  //       dataBody,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //           "x-caller": encrypted,
  //         },
  //       }
  //     );

  //     // console.log("response in generateMobileOtp", response, "status", response.status);

  //     if (response.status === 422) {
  //       let details = response.data.details[0];
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: response.data.code,
  //         alertMessage:
  //           details && details.length > 0
  //             ? details.message
  //             : response.data.message,
  //       }));
  //       setShowLoading(false);
  //     } else if (response.status === 200) {
  //       sessionStorage.setItem("transactionId", response.data.txnId);
  //       sessionStorage.setItem("abhaAddress", variables.abhaAddress);
  //       setShowLoading(false);
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         mobileOtp: true,
  //       }));
  //     } else {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setShowLoading(false);
  // };


  // ABHA v3 API call

  const generateMobileOTP = async () => {
    if (!variables.mobileNumber || variables.mobileNumber.length != 10) {
      toast.error("Please enter a valid mobile number");
      return;
    }
    setShowLoading(true);
    setVariables((prevState) => ({ ...prevState, isEditable: false }));

    try {
      const transactionId = await sessionStorage.getItem("authTransactionId");
      console.log("transactionid", transactionId);
      const dataBody = {
        txnId: transactionId,
        mobile: variables.mobileNumber,
      };
      const response = await Axios.post(
        `${Config.abhaBaseUrl}v3/updateMobile`,
        dataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": `${Config.token}`,
          },
        }
      );

      console.log("response in generateMobileOtp", response, "status", response.status);

      if (response.status === 422) {
        let details = response.data.details[0];
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            details && details.length > 0
              ? details.message
              : response.data.message,
          isEditable: true,
        }));
        setShowLoading(false);
      } else if (response.status === 200) {
        sessionStorage.setItem("mobiletransactionId", response.data.data.txnId);
        sessionStorage.setItem("abhaAddress", variables.abhaAddress);
        setShowLoading(false);
        setVariables((prevState) => ({
          ...prevState,
          mobileOtp: true,
          isEditable: true,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
          isEditable: true,
        }));
        setShowLoading(false);
      }
    } catch (error) {
      console.log(error);
      setVariables((prevState) => ({ ...prevState, isEditable: true }));
    }
    setShowLoading(false);
  };

  // before v3 api call
  // const VerifyMobileOtp = async () => {
  //   setShowLoading(true);
  //   try {
  //     const accessToken = await sessionStorage.getItem("accessToken");
  //     const txnId = await sessionStorage.getItem("authTransactionId");
  //     const otpDataBody = {
  //       txnId: txnId || "",
  //       otp: variables.otp,
  //       USER_ID: UserID,
  //       token: accessToken,
  //     };

  //     // console.log("otp databody", otpDataBody)

  //     const response = await Axios.post(
  //       `${Config.abhaBaseUrl}verifyMobileOtp`,
  //       otpDataBody,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //           "x-caller": encrypted,
  //         },
  //       }
  //     );

  //     //console.log("response in verifyMobileOtp", response, "status", response.status);

  //     if (response.status === 422) {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: response.data.code,
  //         alertMessage:
  //           response.data.details.length > 0
  //             ? response.data.details[0].message
  //             : response.data.message,
  //       }));
  //     } else if (response.status === 200) {
  //       sessionStorage.setItem("transactionId", response.data.txnId);
  //       await createHealthID(accessToken);
  //     } else {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //     }
  //     setShowLoading(false);
  //   } catch (error) {
  //     if (error.response && error.response.status === 422) {
  //       let details = error.response.data.details[0];
  //       console.log(details.message, "===", error.response.data.message);
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: error.response.data.code,
  //         alertMessage:
  //           details && details.message
  //             ? details.message
  //             : error.response.data.message,
  //       }));
  //     } else {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //     }
  //   }
  // };

  // abha v3 api call

  const VerifyMobileOtp = async () => {
    setShowLoading(true);
    try {
      const txnId = await sessionStorage.getItem("mobiletransactionId");
      const accessToken = await sessionStorage.getItem("accessToken");
      console.log("transiid in abhamobilevalidation", txnId);
      const otpDataBody = {
        txnId: txnId,
        otp: variables.otp,
      };

      // console.log("otp databody", otpDataBody)

      const response = await Axios.post(
        `${Config.abhaBaseUrl}v3/verifyUpdateMobile`,
        otpDataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token,
          },
        }
      );

      console.log("response in verifyMobileOtp", response, "status", response.status);

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            response.data.details.length > 0
              ? response.data.details[0].message
              : response.data.message,
        }));
        setShowLoading(true);
      } else if (response.status === 200) {
        if (response.data.data.authResult == "Failed") {
          setVariables((prevState) => ({
            ...prevState,
            showAlert: true,
            alertTitle: response.data.data.authResult,
            alertMessage:
              response.data.data.message,
          }));
          setShowLoading(true);
        } else {
          sessionStorage.setItem("transactionId", response.data.data.txnId);
          sessionStorage.setItem("abhaacoountnumber", response.data.data.accounts[0].ABHANumber);

          // setVariables((prevState) => ({
          //   ...prevState,
          //   // abhaaddressnumber: [response.data.data.accounts[0].ABHANumber],
          //   abhaaddressnumber: [response.data.data.accounts[0].ABHANumber],
          // }));
          Phraddresses();
          setShowLoading(false);
          await abhasuggestions();
        }
        // setVariables((prevState) => ({
        //   ...prevState,
        //   suggestions: true,
        // }));
        // await createHealthID();
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
        setShowLoading(false);
      }
      setShowLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
        setShowLoading(false);
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
        setShowLoading(false);
      }
    }
  };

  const isexist = async () => {
    setShowLoading(true);
    console.log("kjbbvj");
    try {
      const DataBody = {
        abhaAddress: variables?.abhaAddress,
      };

      // console.log("otp databody", otpDataBody)

      const response = await Axios.post(
        `${Config.abhav3BaseUrl}isExists`,
        DataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token,
          },
        }
      );

      console.log("response in verifyMobileOtp", response, "status", response.status);

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            response.data.details.length > 0
              ? response.data.details[0].message
              : response.data.message,
        }));
      } else if (response.status === 200) {
        setMessage("ABHA address is available");
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
      setShowLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
    }
  };

  const Phraddresses = async () => {
    const hie = sessionStorage.getItem("phraddress");

    if (hie) {
      const phrAddressArray = hie.split(","); // Convert comma-separated string into an array
      setVariables((prevState) => ({
        ...prevState,
        abhaaddressnumber: phrAddressArray, // Store the array in state
      }));
    } else {
      console.log("No phraddress found");
    }

  };

  const abhasuggestions = async () => {
    setShowLoading(true);
    try {
      const txnId = await sessionStorage.getItem("transactionId");
      console.log("transiid in suggestion", txnId);
      const otpDataBody = {
        txnId: txnId,
      };

      // console.log("otp databody", otpDataBody)

      const response = await Axios.post(
        `${Config.abhaBaseUrl}v3/getSuggestions`,
        otpDataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token,
          },
        }
      );

      console.log("response in verifyMobileOtp", response, "status", response.status);

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            response.data.details.length > 0
              ? response.data.details[0].message
              : response.data.message,
        }));
      } else if (response.status === 200) {
        const abhaAddresses = response.data.data.abhaAddressList || [];
        sessionStorage.setItem("transactionIdsuggestions", response.data.data.txnId);
        sessionStorage.setItem("abhaddresseslist", JSON.stringify(abhaAddresses));
        setShowLoading(false);
        setVariables((prevState) => ({
          ...prevState,
          suggestions: true,
          suggestedAbhaAddress: abhaAddresses,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
      setShowLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
    }
  };

  // api call before v3
  // const createHealthID = async (accessToken) => {
  //   // var accessToken = await sessionStorage.getItem('accessToken') ?? '';
  //   var transactionId = (await sessionStorage.getItem("transactionId")) ?? "";
  //   var abhaAddress = (await sessionStorage.getItem("abhaAddress")) ?? "";

  //   const payload = {
  //     healthId: abhaAddress,
  //     txnId: transactionId,
  //     accessToken: accessToken,
  //     USER_ID: UserID,
  //   };
  //   console.log(payload, "createHealthID payload");
  //   await Axios.post(`${Config.abhaBaseUrl}createHealthID`, payload, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       authorization: `Bearer ${token}`,
  //       "x-caller": encrypted,
  //     },
  //   })
  //     .then(async (response) => {
  //       console.log("response in createHealthID", response);
  //       if (response.status === 422) {
  //         let details = response.data.details[0];
  //         setVariables((prevState) => ({
  //           ...prevState,
  //           showAlert: true,
  //           alertTitle: response.data.code,
  //           alertMessage:
  //             details && details.length > 0
  //               ? details.message
  //               : response.data.message,
  //         }));
  //         setShowLoading(false);
  //       } else if (response.status === 200) {
  //         sessionStorage.setItem("loginData", response.data["token"]);
  //         await readLoginData(response.data);
  //       } else {
  //         setVariables((prevState) => ({
  //           ...prevState,
  //           showAlert: true,
  //           alertTitle: "ABHA Server down!!!",
  //           alertMessage:
  //             "The ABHA server is down at this time. Please try after sometime.",
  //         }));
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err in createid", err);
  //     });
  // };

  // M1 V3 API CALL
  const createHealthID = async () => {
    // var accessToken = await sessionStorage.getItem('accessToken') ?? '';
    var transactionId = (await sessionStorage.getItem("transactionIdsuggestions")) ?? "";
    var abhaAddress = (await sessionStorage.getItem("abhaAddress")) ?? "";
    console.log(transactionId, "transactionId");
    console.log(abhaAddress, "abhaAddress");
    const payload = {
      abhaAddress: variables.abhaAddress,
      txnId: transactionId,
    };
    console.log(payload, "createHealthID payload");
    await Axios.post(`${Config.abhaBaseUrl}v3/createHealthID`, payload, {
      headers: {
        "Content-Type": "application/json",
        "x-client-token": Config.token,
      },
    })
      .then(async (response) => {
        console.log("response in createHealthID", response);
        if (response.status === 422) {
          let details = response.data.details[0];
          setVariables((prevState) => ({
            ...prevState,
            showAlert: true,
            alertTitle: response.data.code,
            alertMessage:
              details && details.length > 0
                ? details.message
                : response.data.message,
          }));
          setShowLoading(false);
        } else if (response.status === 200) {
          sessionStorage.setItem("newabhaaddress", payload.abhaAddress);
          // sessionStorage.setItem("loginData", response.data["token"]);
          // await readLoginData(response.data);
          handleNavigateToDemographics("Create ABHA");
        } else {
          setVariables((prevState) => ({
            ...prevState,
            showAlert: true,
            alertTitle: "ABHA Server down!!!",
            alertMessage:
              "The ABHA server is down at this time. Please try after sometime.",
          }));
        }
      })
      .catch((err) => {
        console.log("err in createid", err);
      });
  };

  const readLoginData = async (loginData) => {
    var loginData = loginData ? loginData : sessionStorage.getItem("loginData");
    //console.log("login data", loginData)

    var abhaCard = await getPNGCard(loginData.token);

    // console.log("abhacard", abhaCard)

    await getUserDetails(loginData.token);

    setVariables((prevState) => ({
      ...prevState,
      showAlert: true,
      alertTitle: "Registration Successful!",
      alertMessage:
        "Thank you for integrating with ABHA.Please setup your profile.",
      type: "registerSuccessfull",
    }));
    setShowLoading(false);
  };

  const getPNGCard = async (token) => {
    var accessToken = (await sessionStorage.getItem("accessToken")) ?? "";
    var token = token ? token : sessionStorage.getItem("loginData") ?? "";

    const payload = {
      accessToken: accessToken,
      token: token,
      USER_ID: UserID,
    };

    const response = await Axios.post(
      `${Config.abhaBaseUrl}getPNGCard`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
        },
      },
      { responseType: "arraybuffer" }
    );

    //console.log("response in getPNGCard", response);

    if (response.status === 422) {
      let details = response.data.details[0];
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: response.data.code,
        alertMessage:
          details && details.length > 0
            ? details.message
            : response.data.message,
      }));
      setShowLoading(false);
    } else if (response.status === 200) {
      let base64ImageString = Buffer.from(response.data, "utf8").toString(
        "base64"
      );
      return base64ImageString;
    } else {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "ABHA Server down!!!",
        alertMessage:
          "The ABHA server is down at this time. Please try after sometime.",
      }));
    }
  };

  const getUserDetails = async (token) => {
    var accessToken = (await sessionStorage.getItem("accessToken")) ?? "";
    var token = token ?? "";

    const payload = {
      accessToken: accessToken,
      token: token,
      USER_ID: UserID,
    };

    const response = await Axios.post(
      `${Config.abhaBaseUrl}getUserDetails`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
          responseType: "arraybuffer",
        },
      }
    );

    //console.log("response in getUserDetails", response);

    if (response.status === 422) {
      let details = response.data.details[0];
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: response.data.code,
        alertMessage:
          details && details.length > 0
            ? details.message
            : response.data.message,
      }));
      setShowLoading(false);
    } else if (response.status === 200) {
      sessionStorage.setItem("profileData", JSON.stringify(response.data));
    } else {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "ABHA Server down!!!",
        alertMessage:
          "The ABHA server is down at this time. Please try after sometime.",
      }));
    }
  };

  const uploadAbhaData = async () => {
    let abhaProfileData = await sessionStorage.getItem("profileData");
    const abhaData = JSON.parse(abhaProfileData);

    const body = {
      USER_ID: UserID,
      PATIENT_ID: PATIENT_ID,
      ABHA_NUMBER: abhaData?.healthIdNumber,
      ABHA_ADDRESS: abhaData?.healthId,
    };

    //console.log(body,"me/ABHA_DATA Call data ....")
    const response = await Axios.post(`${Config.apiURL}/me/ABHA_DATA`, body, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
        "x-caller": encrypted,
      },
    });

    //console.log("response in uplaod abha data", response)
    if (response.status === 201) {
      await handleNavigateToDemographics();
    } else if (response.status === 404) {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "Sorry!!!",
        alertMessage: "Data is not submitted Please submit again",
      }));
    } else {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "Error!!!",
        alertMessage:
          "An unknown error occurred.Please try again after sometime.",
      }));
    }
  };

  const handleNavigateToDemographics = (source) => {
    // navigate("/abha/demographics");
    navigate(`/abha/demographics?source=${source}`);
  };

  const handleInputChange = (e, ctrlName) => {
    const { value } = e.target;

    setVariables({
      ...variables,
      [ctrlName]: value,
    });
  };

  const handleAlertClose = () => {
    setVariables((prevState) => ({
      ...prevState,
      showAlert: false,
    }));
  };

  const handlemobileotpCancel = () => {
    setVariables((prevState) => ({
      ...prevState,
      mobileOtp: false,
      isEditable: true,
      otp: ""
    }));
  };

  const handleChange = (otp) => {
    setVariables((prevState) => ({
      ...prevState,
      otp: otp,
    }));
  };

  const handleCancel = () => {
    navigate("/patients");
  };

  const handleaddressCancel = () => {
    setVariables((prevState) => ({
      ...prevState,
      createabhaaddress: false,
    }));
  };

  const handlecreateabhaaddress = () => {
    setVariables((prevState) => ({
      ...prevState,
      createabhaaddress: true,
    }));
  }

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setDisabled(false);
    }
  }, [timer]);

  const handleResend = () => {
    if (!disabled) {
      setDisabled(true);
      setTimer(30);
      generateMobileOTP();
    }
  };

  return (
    <div className="abhaContainer" style={{ width: "50%" }}>
      <div className="abhaContainerLeft">

        {!variables.mobileOtp && !variables.suggestions && !variables.createabhaaddress && (
          <>
            <div>
              <p className="abhaTtile"> ABHA Services</p>
              <p className="content" style={{ marginTop: "20px" }}>
                Your Aadhaar verified Successfully
              </p>
              <p style={{ fontSize: "18px", marginLeft: "20px", marginTop: "20px" }}>
                Next Step
              </p>
              <div style={{ display: "flex", flexDirection: "column", gap: "1rem", marginTop: "20px", alignItems: "center" }}>
                <p style={{ fontSize: "18px", marginLeft: "20px", fontWeight: "bold", }}>
                  Your Communication number
                </p>
                <p style={{ fontSize: "16px", marginLeft: "20px", }}>
                  This mobile number is linked to an Aadhaar number.
                </p>
                <div style={{ display: "flex", justifyContent: "center", width: "80%" }}>
                  <p style={{ fontSize: "16px", textAlign: "center", fontWeight: "bold", }}>
                    This mobile number is set as your communication number for ABHA services.
                  </p>
                </div>

                <input
                  style={{
                    display: "block",
                    width: "70%",
                    alignItems: "center",
                    color: "black",
                    cursor: variables.isEditable ? "text" : "not-allowed",
                  }}
                  className="abhanoInfoInput"
                  type="text"
                  placeholder="Mobile Number"
                  // value={variables.mobileNumber}
                  value={variables.mobileNumber}
                  onChange={(e) => handleInputChange(e, "mobileNumber")}
                  disabled={!variables.isEditable}
                />
                <p style={{ fontSize: "16px", color: "#939393" }}>
                  All future communications will be sent to this number.
                </p>
              </div>
            </div>

            <>
              <div className="Adharandmobile">
                <div
                  className={"editButton2"}
                  style={{
                    width: "30%",
                    margin: "0 auto",
                    height: "40px",
                  }}
                  onClick={() => handleCancel()}
                >
                  <p className="OtpBtn">Cancel</p>
                </div>

                <div
                  className={"editButton2"}
                  style={{
                    width: "30%",
                    margin: "0 auto",
                    height: "40px",
                    color: "white"
                  }}
                  onClick={generateMobileOTP}
                >
                  {showLoading && (
                    <CircularProgress size={24} color="inherit" />
                  )}
                  <p className="OtpBtn">Continue</p>
                </div>
              </div>
            </>
          </>
        )}

        {variables.showAlert && (
          <ShowAlert
            title={variables.alertTitle}
            message={variables.alertMessage}
            onClose={handleAlertClose}
          />
        )}

        {variables.mobileOtp && !variables.suggestions && !variables.createabhaaddress && (
          <>
            {/* <h2 style={{ textAlign: "center" }}>ABHA OTP REQUEST</h2> */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <div style={{ flex: 1, textAlign: "center" }}>
                <h2>ABHA OTP REQUEST</h2>
              </div>
              <CloseIcon
                style={{ cursor: "pointer", fontSize: "24px", marginRight: "10px" }}
                onClick={handlemobileotpCancel}
              />
            </div>
            <h3 style={{ textAlign: "center" }}>
              Verify OTP to access your ABHA Account
            </h3>
            {variables.mobileNumber && (
              <p>
                Enter the code sent to Mobile number which is linked with your
                Aadhaar
                <h4
                  style={{
                    color: "blue",
                    display: "inline",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  {variables.mobileNumber}
                </h4>
              </p>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <OtpInput
                value={variables.otp}
                onChange={handleChange}
                numInputs={6}
                renderSeparator={<span>{""}</span>}
                renderInput={(props) => <input {...props} type="tel" />}
                skipDefaultStyles={true}
                inputStyle="otp-input"
                inputType="number"
              />
            </div>

            <div
              onClick={handleResend}
              style={{
                marginLeft: "60%",
                color: "orange",
                cursor: disabled ? "not-allowed" : "pointer",
                opacity: disabled ? 0.6 : 1,
              }}
            >
              Resend OTP? {disabled && `(${timer}s)`}
            </div>

            <div className="Adharandmobile">
              <button
                className={"editButton2"}
                style={{
                  marginRight: "10px",
                  width: "30%",
                  margin: "0 auto",
                  height: "40px",
                  color: "white",
                }}
                onClick={VerifyMobileOtp}
              >
                {showLoading && <CircularProgress size={24} color="inherit" />}
                Verify Otp
              </button>
            </div>
          </>
        )}


        {/* {!variables.createabhaaddress && variables.suggestions && variables.abhaaddressnumber.map((abha, index) => {
          const cleanedAbha = abha.replace(/-/g, '');
          return (
            <div key={index} style={{ display: "flex", flexDirection: "column", gap: "2rem", textAlign: "center" }}>
              <h2>Select an ABHA address</h2>
              <div style={{ marginLeft: "10%" }}>
                <label style={{ display: "flex", gap: "8px" }}>
                  <input
                    type="radio"
                    name="abhaAddress"
                    value={cleanedAbha}
                    checked={variables.abhaAddress === cleanedAbha}
                    onChange={(e) => setVariables({ ...variables, abhaAddress: e.target.value })}
                  />
                  {cleanedAbha}
                </label>
              </div>

              <h4
                style={{ color: "#52447d", cursor: "pointer" }}
                onClick={handlecreateabhaaddress}
              >
                Want to create a new ABHA address?
              </h4>


              <div className="Adharandmobile">
                <div
                  className={"editButton2"}
                  style={{
                    width: "30%",
                    margin: "0 auto",
                    height: "40px",
                  }}
                  onClick={() => handleCancel()}
                >
                  <p className="OtpBtn">Cancel</p>
                </div>

                <div
                  className={"editButton2"}
                  style={{
                    width: "30%",
                    margin: "0 auto",
                    height: "40px",
                    opacity: variables.abhaAddress ? 1 : 0.5,
                    pointerEvents: variables.abhaAddress ? "auto" : "none",
                  }}
                  // onClick={handleNavigateToDemographics("Create ABHA")}
                  onClick={() => {
                    console.log("variables.abhaAddress", variables.abhaAddress);
                    if (variables.abhaAddress) {
                      sessionStorage.setItem("newabhaaddress", variables.abhaAddress);
                      sessionStorage.setItem("abhaAddress", variables.abhaAddress);
                      handleNavigateToDemographics("Create ABHA");
                    }
                  }
                    //  handleNavigateToDemographics("Create ABHA")
                  }
                >
                  {showLoading && <CircularProgress size={24} color="inherit" />}
                  <p className="OtpBtn">Continue</p>
                </div>
              </div>
            </div>
          );
        })} */}

        {!variables.createabhaaddress && variables.suggestions && (
          <div style={{ display: "flex", flexDirection: "column", gap: "2rem", textAlign: "center" }}>
            <h2>Select an ABHA address</h2>

            {/* Scrollable Container */}
            <div style={{ marginLeft: "10%", marginBottom: "10px", height: "25vh", overflowY: "auto", border: "1px solid #ccc", padding: "15px", borderRadius: "9px" }}>
              {variables.abhaaddressnumber.map((abha, index) => {
                const cleanedAbha = abha.replace(/-/g, '');
                return (
                  <div style={{ marginBottom: "10px" }}>
                    <label key={index} style={{ display: "flex", gap: "10px" }}>
                      <input
                        type="radio"
                        name="abhaAddress"
                        value={cleanedAbha}
                        checked={variables.abhaAddress === cleanedAbha}
                        onChange={(e) => setVariables({ ...variables, abhaAddress: e.target.value })}
                      />
                      {cleanedAbha}
                    </label>
                  </div>
                );
              })}
            </div>

            <h4 style={{ color: "#52447d", cursor: "pointer" }} onClick={handlecreateabhaaddress}>
              Want to create a new ABHA address?
            </h4>

            <div className="Adharandmobile">
              <div
                className={"editButton2"}
                style={{ width: "30%", margin: "0 auto", height: "40px" }}
                onClick={() => handleCancel()}
              >
                <p className="OtpBtn">Cancel</p>
              </div>

              <div
                className={"editButton2"}
                style={{
                  width: "30%",
                  margin: "0 auto",
                  height: "40px",
                  opacity: variables.abhaAddress ? 1 : 0.5,
                  pointerEvents: variables.abhaAddress ? "auto" : "none",
                }}
                onClick={() => {
                  console.log("variables.abhaAddress", variables.abhaAddress);
                  if (variables.abhaAddress) {
                    sessionStorage.setItem("newabhaaddress", variables.abhaAddress);
                    sessionStorage.setItem("abhaAddress", variables.abhaAddress);
                    handleNavigateToDemographics("Create ABHA");
                  }
                }}
              >
                {showLoading && <CircularProgress size={24} color="inherit" />}
                <p className="OtpBtn">Continue</p>
              </div>
            </div>
          </div>
        )}

        {/* want to create abha */}

        {variables.createabhaaddress && (
          <>
            <div>
              <div style={{ flex: 1 }}>
                <div style={{ textAlign: "center", padding: 15 }}>
                  <h1 style={{ fontSize: 30, fontWeight: "bold", color: "#52447d" }}>
                    ABHA Services
                  </h1>
                </div>

                <div style={{ textAlign: "center", padding: "20px 0" }}>
                  <p
                    style={{ color: "grey", fontSize: 20, fontWeight: "bold" }}
                  >
                    Create ABHA Address to continue.
                  </p>
                </div>

                <div style={{ marginLeft: "40px", marginBottom: "10px", fontWeight: "400" }}>
                  Abha address
                </div>

                <div style={{ padding: "0 10px" }}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "90%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="text"
                        className="AbhaSetupInputs"
                        value={variables?.abhaAddress}
                        onChange={(e) => handleInputChange(e, "abhaAddress")}
                        placeholder="Create your ABHA Address"
                        style={{
                          padding: 16,
                          width: "100%",
                          borderRadius: 10,
                          // border: "none",
                          backgroundColor: "white",
                        }}
                      />
                      <span>@abdm</span>
                    </div>
                  </div>
                </div>

                <div style={{ color: "green", fontWeight: "bold", paddingLeft: "35px" }}>{message}</div>

                <div style={{ padding: "20px 35px" }}>
                  <div style={{ fontSize: "14px", paddingBottom: "20px" }}>
                    You can use these ABHA addresses:
                    <br />
                    <span
                      style={{
                        fontSize: 14,
                        color: "green",
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      {/* {variables.suggestedAbhaAddress.map((address, index) => (
                        <div key={index}>{address}</div>
                      ))} */}
                      {variables.suggestedAbhaAddress.join(", ")}
                    </span>
                  </div>

                  <p
                    style={{
                      fontSize: 10,
                      color: "grey",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Conditions for setting ABHA Address:
                  </p>
                  <p
                    style={{ fontSize: 10, color: "grey", fontStyle: "italic" }}
                  >
                    Example123@abdm,
                  </p>
                  <p
                    style={{ fontSize: 10, color: "grey", fontStyle: "italic" }}
                  >
                    4-32 characters alphabets and numbers only,
                  </p>
                  <p
                    style={{ fontSize: 10, color: "grey", fontStyle: "italic" }}
                  >
                    it should not start with a number or dot(.),
                  </p>
                  {/* <p
                    style={{ fontSize: 10,paddingTop:"10px"}}
                  >
                    hi
                  </p> */}
                </div>

                <div
                  style={{
                    marginTop: 30,
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <button
                    onClick={handleaddressCancel}
                    style={{
                      padding: "12px",
                      backgroundColor: "#52447d",
                      borderRadius: 10,
                      color: "white",
                      fontWeight: "bold",
                      border: "none",
                      width: "20%"
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    // onClick={createAbhaAddress}
                    onClick={createHealthID}
                    style={{
                      padding: "12px",
                      backgroundColor: "#52447d",
                      borderRadius: 10,
                      color: "white",
                      fontWeight: "bold",
                      border: "none",
                      width: "20%"
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

      </div>
    </div >
  );
};

export default AbhaMobileValidation;
