import React, { useState, useEffect } from "react";
import Navbar from "../components/modules/Navbar";
import TopNavBar from "../components/modules/TopNavBar";
import PatientsInformation from "./patientsInformation";
import "./layout.css";

function Layout({ ComponentName, type }) {
  const [navbarMapulation, setnavbarMapulation] = useState();
  const [state, setstate] = useState(true);
  const action = sessionStorage.getItem("action");
  const navbarMapulationvalue = (value) => {
    setnavbarMapulation(!value);
  };
  const MainContent = () => {
    return ComponentName;
  };
  const setSelectedRowsendData = (data) => {};

  const setvisiblecontent = (data) => {
    setstate(data);
  };
  return (
    <div className="Patient-MainContainer">
      {/* {console.log(action,"actionactionaction")} */}
      {action === "register" ? (
        <></>
      ) : (
        <Navbar navbarMapulationvalue={navbarMapulationvalue} />
      )}
      <div
        className="layoutRight"
        style={{ width: navbarMapulation ? "94.5%" : "84.5%" }}
      >
        <TopNavBar />
        <div className="layoutRight_1">
          <div className="layoutRight_1_1">
            {!type && (
              <PatientsInformation setvisiblecontent={setvisiblecontent} />
            )}

            <div className="layoutRight_1_child_2">
              <MainContent setSelectedRowsendData={setSelectedRowsendData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;



// import React, { useState, useEffect, useRef } from "react";
// import Navbar from "../components/modules/Navbar";
// import TopNavBar from "../components/modules/TopNavBar";
// import PatientsInformation from "./patientsInformation";
// import "./layout.css";

// function Layout({ ComponentName, type }) {
//   const navbarMapulation = useRef(false);  // Use useRef instead of useState for navbarMapulation
//   const [state, setState] = useState(true);
//   const action = sessionStorage.getItem("action");

//   // Update the navbarMapulation value without re-rendering
//   const navbarMapulationvalue = (value) => {
//     navbarMapulation.current = !navbarMapulation.current; // Flip the current value
//   };

//   const MainContent = () => {
//     return ComponentName;
//   };

//   const setSelectedRowsendData = (data) => {};

//   const setVisibleContent = (data) => {
//     setState(data);
//   };

//   return (
//     <div className="Patient-MainContainer">
//       {/* {console.log(action, "actionactionaction")} */}
//       {action === "register" ? (
//         <></>
//       ) : (
//         <Navbar navbarMapulationvalue={navbarMapulationvalue} />
//       )}
//       <div
//         className="layoutRight"
//         style={{ width: navbarMapulation.current ? "94.5%" : "84.5%" }} // Use navbarMapulation.current for conditional styling
//       >
//         <TopNavBar />
//         <div className="layoutRight_1">
//           <div className="layoutRight_1_1">
//             {!type && (
//               <PatientsInformation setVisibleContent={setVisibleContent} />
//             )}

//             <div className="layoutRight_1_child_2">
//               <MainContent setSelectedRowsendData={setSelectedRowsendData} />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Layout;
