// import React, { useEffect, useState } from "react";
// import Navbar from "../Navbar";
// import { useNavigate } from "react-router-dom";
// import BackArrow from "../../../images/Vector 34.svg";
// import "echarts/lib/component/tooltip";
// import { publicEncrypt, constants } from "crypto-browserify";
// import { Buffer } from "buffer";
// import Config from "../../../Config/config";
// import Axios from "axios";
// import Pdf from '../../../assets/pdf1.png';
// import axios from "axios";
// import { useFilePicker } from 'use-file-picker';
// import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
// import FileUploadSharpIcon from '@mui/icons-material/FileUploadSharp';
// import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';
// import FilePresentSharpIcon from '@mui/icons-material/FilePresentSharp';
// import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
// import './Document.css';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const Document = () => {
//   const [patientRecords, setPatientRecords] = useState([]);
//   const [selectedFile, setSelectedFile] = useState([]);
//   const [showIcons, setShowIcons] = useState(false);
//   const [selectedIconType, setSelectedIconType] = useState("");

//   const { openFilePicker, filesContent, loading, errors, plainFiles, clear } = useFilePicker({
//     multiple: true,
//     readAs: 'DataURL',
//     accept: ['.json', '.pdf', '.png', '.doc'],
//     onFilesSelected: (files) => {
//       console.log("files", files.plainFiles)
//       setSelectedFile(files.plainFiles)
//     }
//   })
//   const navigate = useNavigate();

//   useEffect(() => {
//     console.log("Sekeed", selectedFile)
//     if (selectedFile.length > 0) {
//       uploadFiles();
//       setSelectedFile([]);
//     }
//   }, [selectedFile]);

//   const UserID = sessionStorage.getItem("RegUserId")
//     ? JSON.parse(sessionStorage.getItem("RegUserId"))
//     : sessionStorage.getItem("UserID");

//   const publicKey = sessionStorage.getItem("RegpublicKey")
//     ? sessionStorage.getItem("RegpublicKey")
//     : JSON.parse(sessionStorage.getItem("publicKey"));
//   const token = sessionStorage.getItem("Regtoken")
//     ? sessionStorage.getItem("Regtoken")
//     : JSON.parse(sessionStorage.getItem("token"));

//   const padding = constants.RSA_PKCS1_PADDING;
//   const data = Buffer.from(UserID.toString(), "utf-8");
//   const encrypted = publicEncrypt(
//     { key: publicKey, padding: padding },
//     data
//   ).toString("base64");

//   const patient_id = sessionStorage.getItem("PATIENT_ID");

//   const patientDetails = JSON.parse(
//     sessionStorage.getItem("selectedPatientDetails")
//   );

//   useEffect(() => {

//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     const UserID = JSON.parse(sessionStorage.getItem("UserID"));
//     const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
//     let data = JSON.stringify({
//       userId: UserID,
//       id: PATIENT_ID,
//     });
//     let config = {
//       method: "GET",
//       maxBodyLength: Infinity,
//       url: `${Config.apiURL}${Config.routes.getPatientRecordsAPI}?userId=${UserID}&id=${patient_id}`,
//       headers: {
//         authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//         "x-caller": await encrypted,
//       },
//       data: data,
//     };

//     try {
//       const response = await Axios.request(config);
//       // console.log("response in records", response);
//       setPatientRecords(response.data.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleBackClick = () => {
//     navigate("/patients");
//   };

//   const handleIconClick = () => {
//     setShowIcons(prevState => !prevState);
//   };

//   const handleFilePickerClick = async (fileType) => {
//     // try {
//     openFilePicker();
//     setShowIcons(false);
//     setSelectedIconType(fileType);
//     // const selectedFiles = plainFiles;

//     // if (selectedFiles && selectedFiles.length > 0) {
//     //   console.log("Selected files:", selectedFiles);
//     //   // setShowIcons(false);
//     //   // setSelectedIconType(fileType);
//     //   // Process the selected files
//     //   setSelectedFile(selectedFiles);

//     //   uploadFiles(selectedFiles, fileType);
//     // } else {
//     //   console.log("No files selected.");
//     // }
//     // } catch (error) {
//     //   console.error("Error while handling file picker:", error);
//     // }
//   };

//   // Function to handle duplicates
//   const duplicate = (i) => {
//     console.log("I", i)
//     if (i === 0) {
//       return '';
//     } else {
//       return `(${i})`;
//     }
//   };

//   console.log("picked file", selectedFile)

//   async function uploadFiles() {
//     try {
//       const pickedFiles = selectedFile
//       const fileType = selectedIconType
//       // console.log("picked file",pickedFiles)
//       const formData = new FormData();
//       const now = new Date();
//       const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1)
//         .toString()
//         .padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;

//       for (let i = 0; i < pickedFiles.length; i++) {
//         let file = pickedFiles[i];
//         console.log('file:', file);
//         console.log('Original path:', file.name);

//         const extension = file.name.substring(file.name.lastIndexOf('.'));
//         const newPath = `${fileType}_${formattedDate}${duplicate(i)}${extension}`;
//         console.log('NewPath:', newPath);
//         // file.name = newPath
//         // Append each file to formData
//         formData.append('files', file);
//       }

//       const payload = {
//         USER_ID: UserID,
//         PATIENT_ID: patient_id
//       }

//       // Append other data as well
//       formData.append('data', JSON.stringify(payload));

//       // Prepare Axios request
//       const options = {
//         method: "POST",
//         url: `${Config.base_url}upload_files`,
//         headers: {
//           'Authorization': `Bearer ${token}`,
//           "Content-Type": "multipart/form-data",
//           "x-caller": await encrypted,
//         },
//         data: formData,
//       };

//       const response = await Axios(options);
//       console.log(response.data);
//       if (response.status === 201) {
//         await fetchData();
//         toast.success('File Uploaded Successfully!')
//       }
//       else if (response.status === 404) {
//         toast.warning("Sorry", "File is not uploaded Please upload again")
//       }
//       else {
//         toast.error("An unknown error occurred.Please try again after sometime")
//       }

//     } catch (error) {
//       console.error(error);
//     }
//   }

//   const openPdf = (PdfData) => {
//     console.log("PdfData", PdfData)
//     const filePath = `${Config.apiURL}/${PdfData.RECORD_PATH}${PdfData.RECORD_FILE}`
//     window.open(filePath, '_blank')
//   }

//   return (
//     <>
//       <Navbar />
//       <div>
//         <div className="entireDocumentPage" >
//           <div className="documentScreen" >
//             {/* <DocumentContainer /> */}
//             <div className="pdfGeneratorScreen" >
//               <div className="patientDocument" >
//                 <div className="backButton" onClick={handleBackClick} >
//                   <img id="image" src={BackArrow} />
//                 </div>
//                 <button className="review">
//                   <h3>
//                     {patientDetails && patientDetails.FIRST_NAME
//                       ? patientDetails.FIRST_NAME.toUpperCase()
//                       : ""}
//                     'S Documents
//                   </h3>
//                 </button>
//               </div>
//               <div>
//                 <ul>
//                   {patientRecords.map((pdf, index) => (
//                     <li key={index}>
//                       <div style={{
//                         display: 'flex',
//                         backgroundColor: 'white',
//                         margin: '1% 6%',
//                         height: '4vw',
//                         width: '50vw',
//                         borderRadius: '8px',
//                         paddingLeft: '2%',
//                         paddingTop: '1%'
//                       }}
//                         onClick={() => openPdf(pdf)}
//                       >
//                         <div><img src={Pdf} /></div>
//                         <div style={{ marginLeft: '1%' }}
//                         // onClick={() => openPdf(pdf)}
//                         >
//                           <div style={{ color: '#52447D' }}>
//                             {pdf.RECORD_FILE}
//                           </div>
//                           <p style={{ margin: '1%', fontSize: '80%' }}>Record#: {pdf.RECORD_ID}</p>
//                         </div>
//                       </div>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//               <div >
//                 <div className="uploadPdfs">
//                   {showIcons && (
//                     <div className="uploadPdfsSubIcon" >
//                       <div className="icon" >
//                         <p className="filePicker">Other Document</p>
//                         <div>
//                           <button onClick={() => handleFilePickerClick('Other_Documents')} className="iconButton" >
//                             <FilePresentSharpIcon />
//                           </button>
//                         </div>
//                       </div>
//                       <div className="icon" >
//                         <p className="filePicker">Radiology</p>
//                         <div>
//                           <button onClick={() => handleFilePickerClick('Radiology')} className="iconButton">
//                             <LocalHospitalRoundedIcon />
//                           </button>
//                         </div>
//                       </div>
//                       <div className="icon" >

//                         <p className="filePicker">Lab Report</p>

//                         <div>
//                           <button onClick={() => handleFilePickerClick('Lab_Report')} className="iconButton" >
//                             <UploadFileOutlinedIcon />
//                           </button>
//                         </div>
//                       </div>
//                       <div className="icon" >
//                         {/* <div className="iconText" > */}
//                         <p className="filePicker">Prescription</p>
//                         {/* </div> */}
//                         <div>
//                           <button onClick={() => handleFilePickerClick('Prescription')} className="iconButton" >
//                             <NoteAddRoundedIcon />
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   )}
//                   <div className="icon1">
//                     <div className="iconText1">
//                       <p>Upload Records / Documents</p>
//                     </div>
//                     <div>
//                       <button onClick={handleIconClick} className="iconButton1" >
//                         <FileUploadSharpIcon />
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//                 <ToastContainer />
//               </div >
//             </div>

//           </div>
//         </div>
//       </div>

//     </>
//   );
// };

// export default Document;

import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
// import BackArrow from "../../../images/Vector 34.svg";
import BackArrow from "../../../images/leftArrow.svg";
import "echarts/lib/component/tooltip";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import Config from "../../../Config/config";
import Axios from "axios";
import Pdf from "../../../assets/pdf1.png";
import axios from "axios";
import { useFilePicker } from "use-file-picker";
import LocalHospitalRoundedIcon from "@mui/icons-material/LocalHospitalRounded";
import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddRounded";
import FilePresentSharpIcon from "@mui/icons-material/FilePresentSharp";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import "./Document.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
const Document = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [patientRecords, setPatientRecords] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [showIcons, setShowIcons] = useState();
  const [selectedIconType, setSelectedIconType] = useState("");
  const [fileOpen, setFileOpen] = useState(null);
  const { openFilePicker, filesContent, loading, errors, plainFiles, clear } =
    useFilePicker({
      multiple: true,
      readAs: "DataURL",
      accept: [".json", ".pdf", ".png", ".doc"],
      onFilesSelected: (files) => {
        //console.log("files", files.plainFiles);
        setSelectedFile(files.plainFiles);
      },
    });
  const navigate = useNavigate();

  useEffect(() => {
    //console.log("Sekeed", selectedFile);
    if (selectedFile?.length > 0) {
      uploadFiles();
      setSelectedFile([]);
    }
  }, [selectedFile]);

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const patient_id = sessionStorage.getItem("PATIENT_ID");

  const patientDetails = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const UserID = JSON.parse(sessionStorage.getItem("UserID"));
    const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
    let data = JSON.stringify({
      userId: UserID,
      id: PATIENT_ID,
    });
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: `${Config.apiURL}${Config.routes.getPatientRecordsAPI}?userId=${UserID}&id=${patient_id}`,
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-caller": await encrypted,
      },
      data: data,
    };

    try {
      const response = await Axios.request(config);
      console.log("response in records", response);
      setPatientRecords(response.data.data);
      openPdf(response.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (setFileOpen) {
    }
  }, [setFileOpen]);
  const handleBackClick = () => {
    navigate("/patients");
  };

  const handleIconClick = (event) => {
    setShowIcons(showIcons ? null : event.currentTarget);
  };

  function checkUrl(url) {
    if (url.endsWith(".pdf") || url.endsWith(".png")) {
      console.log("The URL ends with .pdf or .png");
    } else {
      console.log("The URL does not end with .pdf or .png");
    }
  }

  const handleFilePickerClick = async (fileType) => {
    // try {
    openFilePicker();
    setShowIcons(false);
    setSelectedIconType(fileType);
    // const selectedFiles = plainFiles;

    // if (selectedFiles && selectedFiles.length > 0) {
    //   console.log("Selected files:", selectedFiles);
    //   // setShowIcons(false);
    //   // setSelectedIconType(fileType);
    //   // Process the selected files
    //   setSelectedFile(selectedFiles);

    //   uploadFiles(selectedFiles, fileType);
    // } else {
    //   console.log("No files selected.");
    // }
    // } catch (error) {
    //   console.error("Error while handling file picker:", error);
    // }
  };

  // Function to handle duplicates
  const duplicate = (i) => {
    //console.log("I", i);
    if (i === 0) {
      return "";
    } else {
      return `(${i})`;
    }
  };

  console.log("picked file", selectedFile);

  async function uploadFiles() {
    try {
      const pickedFiles = selectedFile;
      const fileType = selectedIconType;
      // console.log("picked file",pickedFiles)
      const formData = new FormData();
      const now = new Date();
      const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;

      // for (let i = 0; i < pickedFiles?.length; i++) {
      //   let file = pickedFiles[i];
      //      const extension = file.name.substring(file.name.lastIndexOf("."));
      //   const newPath = `${fileType}_${formattedDate}${duplicate(
      //     i
      //   )}${extension}`;
      //    formData.append("files", file);
      // }

      for (let i = 0; i < pickedFiles?.length; i++) {
        let file = pickedFiles[i];

        const extension = file.name.substring(file.name.lastIndexOf("."));
        const newFileName = `${fileType}_${formattedDate}${duplicate(i)}${extension}`;

        const renamedFile = new File([file], newFileName, { type: file.type });

        formData.append("files", renamedFile);
      }

      const payload = {
        USER_ID: UserID,
        PATIENT_ID: patient_id,
      };

      // Append other data as well
      formData.append("data", JSON.stringify(payload));

      // Prepare Axios request
      const options = {
        method: "POST",
        url: `${Config.base_url}upload_files`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "x-caller": await encrypted,
        },
        data: formData,
      };

      const response = await Axios(options);
      //console.log(response.data);
      if (response.status === 201) {
        await fetchData();
        toast.success("File Uploaded Successfully!");
      } else if (response.status === 404) {
        toast.warning("Sorry", "File is not uploaded Please upload again");
      } else {
        toast.error(
          "An unknown error occurred.Please try again after sometime"
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  const openPdf = (PdfData) => {
    //console.log("PdfData", PdfData);
    const filePath = `${Config.apiURL}/${PdfData.RECORD_PATH}${PdfData.RECORD_FILE}`;
    //window.open(filePath, '_blank')
    //console.log("Opening PDF at:", filePath);
    setFileOpen(filePath);
  };

  const PdfData = {
    RECORD_PATH: "path/to/pdf/",
    RECORD_FILE: "example.pdf",
  };

  const open = Boolean(showIcons);
  const id = open ? "simple-popup" : undefined;

  function checkUrlAndRender(url) {
    // console.log()
    //     if (url.endsWith(".pdf")) {
    //       console.log("The URL ends with .pdf . Rendering component.");
    //       return "pdf";
    //     } else {
    //       console.log(
    //         "The URL does not end with .pdf or .png. Rendering not possible."
    //       );
    //       return false; // Indicate that the URL is not valid for rendering
    //     }
    const fileName = url.split("/").pop(); // Extract the file name
    const fileType = fileName.split(".").pop(); // Extract the file extension
    console.log(fileType, "fileType");
    return fileType;
  }

  // Component rendering
  function PdfRenderer({ fileOpen }) {
    // Check if the URL is valid for rendering
    const isValidUrl = checkUrlAndRender(fileOpen);

    return (
      <div>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js" />

        {isValidUrl ? (
          <div className="articles-hyper-pdf">
            {console.log(fileOpen, isValidUrl)}
            <Viewer
              fileUrl={`${fileOpen}`}
              plugins={[defaultLayoutPluginInstance]}
            />
          </div>
        ) : (
          <p>The file is not a valid PDF or PNG, and cannot be rendered.</p>
        )}
      </div>
    );
  }

  return (
    <div className="docMain">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js" />
      {/* <Navbar /> */}
      <div>
        <div className="patientDocument">
          <div className="backButton" onClick={handleBackClick}>
            <img id="image" src={BackArrow} />
          </div>
          <button className="review">
            <h3>
              {patientDetails && patientDetails.FIRST_NAME
                ? patientDetails.FIRST_NAME.toUpperCase()
                : ""}
              'S Documents
            </h3>
          </button>
        </div>
        <div className="entireDocumentPage">
          <div className="documentScreen">
            {/* Uploaded records */}
            <div className="uploadPdfs">
              <div className="icon1">
                {/* <div style={{ margin: "2%" }}>
                  <button
                    onClick={handleIconClick}
                    className="iconButton1"
                    type="button"
                  >
                    <span className="iconButtonContent">
                      <FileUploadSharpIcon />
                      <span style={{ fontWeight: "bold" }}>Upload Records</span>
                    </span>
                  </button>
                </div> */}

                {/* <div className="iconText1">
                  <p>Upload Records / Documents</p>
                </div> */}
                {/* <div>
                  <div
                  // id={id}
                  // open={open}
                  // anchor={showIcons}
                  // className="popupicons"
                  > */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  }}
                >
                  <div className="uploadfileicon">
                    <button
                      onClick={() => handleFilePickerClick("Other_Documents")}
                      className="iconButton"
                    >
                      <FilePresentSharpIcon />
                    </button>

                    <p className="filePicker">Other Documents</p>
                  </div>
                  <div className="uploadfileicon">
                    <button
                      onClick={() => handleFilePickerClick("Radiology")}
                      className="iconButton"
                    >
                      <LocalHospitalRoundedIcon />
                    </button>
                    <p className="filePicker">Radiology</p>
                  </div>
                  <div className="uploadfileicon">
                    <button
                      onClick={() => handleFilePickerClick("Lab_Report")}
                      className="iconButton"
                    >
                      <UploadFileOutlinedIcon />
                    </button>
                    <p className="filePicker">Lab Report</p>
                  </div>
                  <div className="uploadfileicon">
                    <button
                      onClick={() => handleFilePickerClick("Prescription")}
                      className="iconButton"
                    >
                      <NoteAddRoundedIcon />
                    </button>
                    <p className="filePicker">Prescription</p>
                  </div>
                </div>
                {/* </div>
                </div> */}
              </div>
            </div>
            {/* <DocumentContainer /> */}
            <div className="pdfGeneratorScreen">
              <div>
                <ul>
                  {patientRecords.map((pdf, index) => (
                    <li key={index}>
                      <div className="generatepdf" onClick={() => openPdf(pdf)}>
                        <div>
                          <img src={Pdf} />
                        </div>
                        <div style={{ marginLeft: "1%" }}>
                          <div style={{ color: "#52447D", fontSize: "90%" }}>
                            {pdf.RECORD_FILE}
                          </div>
                          <p style={{ margin: "1%", fontSize: "65%" }}>
                            Record#: {pdf.RECORD_ID}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {fileOpen && (
            <div className="pdfview">
              {/* 
              {fileOpen && (
                <iframe
                  src={fileOpen}
                  style={{ width: "100%", height: "100%" }}
                  title="Embedded PDF"
                ></iframe>
              )}

              */}

              {/* <iframe src="https://www.google.com/" height="200" width="300" title="Iframe Example"></iframe> */}
              {/*  <div>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js" />

                <div className="articles-hyper-pdf">
                {console.log(fileOpen, checkUrl(fileOpen))}
               
              
                  <Viewer
                    
                    fileUrl={`${fileOpen}`}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </div> 

              </div>*/}

              {checkUrlAndRender(fileOpen) === "pdf" && (
                <div className="articles-hyper-pdf">
                  {console.log(checkUrlAndRender(fileOpen), "rewedrfvtgbyhnu")}
                  <Viewer
                    fileUrl={`${fileOpen}`}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </div>
              )}
              {checkUrlAndRender(fileOpen) === "jpg" && (
                <div className="articles-hyper-image">
                  <img crossorigin="anonymous" src={fileOpen} />
                </div>
              )}
              {checkUrlAndRender(fileOpen) === "png" && (
                <div className="articles-hyper-image">
                  {console.log(fileOpen)}
                  <img
                    crossorigin="anonymous"
                    src={fileOpen}
                    alt="Image description"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Document;
