import React, { useState, useEffect } from "react";
import { Await, useHistory } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Box,
  TextField,
} from "@mui/material";
import { publicEncrypt, constants } from "crypto-browserify";
import "./ABHA.css";
import { Buffer } from "buffer";
import Config from "../../../Config/config";
import Axios from "axios";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import JSEncrypt from "jsencrypt";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

const AbhaAddressSetUp = ({ activeSectionInput, onActiveSectionChange }) => {
  const navigate = useNavigate();

  const [variables, setVariables] = useState({
    abhaAddress: "",
    mobileNumber: "",
    showAlert: false,
    alertTitle: "",
    alertMessage: "",
    otp: "",
    mobileOtp: false,
    listOfAbhaAddress: [], //list of abha addresses we
    suggestedAbhaAddress: "",
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    gender: "",
    pinCode: "",
    state: "",
    district: "",
    statecode: "",
    districtcode: "",
    yearOfBirth: "",
    monthOfBirth: "",
    dateOfBirth: "",
  });

  const [formData1, setFormData1] = useState({
    fullName: "",
    email: "",
    gender: "",
    district: "",
    address: "",
    state: "",
    pincode: "",
    birthDate: {
      day: "",
      month: "",
      year: "",
    },
  });

  const [listValue, setListValue] = useState(null);
  const [activeSection, setActiveSection] = useState(activeSectionInput);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [pinCodeDetails, setPinCodeDetails] = useState("");
  // const [district, setDistrict] = useState("");
  // const [state, setStateValue] = useState("");
  const [country, setCountry] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedState, setSelectedState] = useState(""); // Selected state code
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  // const UserData = sessionStorage.getItem("UserData")
  //   ? JSON.parse(sessionStorage.getItem("UserData"))
  //   : sessionStorage.getItem("UserData");

  // console.log("UserData", UserData);

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");
  const [timer, setTimer] = useState(30);
  const [disabled, setDisabled] = useState(true);

  const PATIENT_ID = sessionStorage.getItem("PATIENT_ID");

  const genderItems = [
    { display: "Select Gender", value: "" },
    { display: "Male", value: "M" },
    { display: "Female", value: "F" },
    { display: "Others", value: "O" },
  ];

  const ShowAlert = ({ title, message, onClose }) => {
    return (
      <div className="popup">
        <div className="popup-inner">
          <p
            style={{ fontSize: "24px", fontWeight: "600", fontFamily: "bold" }}
          >
            {title}
          </p>
          <p>{message}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2rem",
              padding: "4%",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="ContinueBtn"
              onClick={onClose}
              style={{
                background: "#52447d",
                width: "20%",
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData1({
      ...formData1,
      [name]: value,
    });
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setFormData1({
      ...formData1,
      birthDate: {
        ...formData1.birthDate,
        [name]: value,
      },
    });
  };

  // useEffect(() => {
  //   // getPublickey();
  //   getDataFromPinCode();
  // });

  useEffect(() => {
    if (formData.pinCode && formData.pinCode.length === 6) {
      getDataFromPinCode();
    }
  }, [formData.pinCode]);

  const getPublickey = async () => {
    // console.log("UserID",UserID)
    Axios.get(`${Config.abhaBaseUrl}publicKey/${UserID}`, {
      headers: {
        authorization: `Bearer ${token}`,
        "x-caller": await encrypted,
      },
    })
      .then((response) => {
        // console.log("response :", response);
        if (response.status === 200) {
          // console.log("PUBLICKEY :", response.data);
          sessionStorage.setItem("ABDMPublicKey", response.data);
          return response;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Encryption of otp using JSEncrypt
  const otpencrytion = async (otp) => {
    var data = otp;
    var key = sessionStorage.getItem("PHRPublicKey");
    var jsencrypt = new JSEncrypt();
    jsencrypt.setPublicKey(key);
    var encrypt = jsencrypt.encrypt(data);
    //console.log("encrypted otp :", encrypt);
    return encrypt;
  };

  const generatePHRMobileOTP = async () => {
    try {
      const mobilenum = sessionStorage.getItem('mobile');
      console.log("mobile num", mobilenum);
      if (mobilenum && mobilenum !== "") {
        setShowLoading(true);
        const dataBody = {
          "mobile": mobilenum,
        };

        sessionStorage.setItem("mobile", variables.phrMobile);

        const response = await Axios.post(
          `${Config.abhav3BaseUrl}generateMobileOtp`,
          dataBody,
          {
            headers: {
              "x-client-token": Config.token,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          console.log("success resending otp");
        } else {
          throw new Error("Unexpected response from the server.");
        }
      } else {
        setSnackbarMessage("Please enter a valid mobile number");
        setShowSnackbar(true);
      }
    } catch (error) {
      console.error("Error in generatePHRMobileOTP:", error);

      if (error.response?.status === 422 || error.response?.status === 400) {
        const details = error.response.data.details?.[0];
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code || "Error",
          alertMessage: details?.message || error.response.data.message,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage: "Please try again later.",
        }));
      }
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setDisabled(false);
    }
  }, [timer]);

  const handleResend = () => {
    if (!disabled) {
      setDisabled(true);
      setTimer(30);
      generatePHRMobileOTP();
    }
  };

  // working 
  // const validatePHROTP = async () => {
  //   setShowLoading(true);
  //   try {
  //     const phraccessToken = await sessionStorage.getItem("phraccessToken");
  //     const transactionId = await sessionStorage.getItem("transactionIdPhr");
  //     let encryptedOTP = await otpencrytion(variables.otp);

  //     const otpDataBody = {
  //       otp: encryptedOTP,
  //       USER_ID: UserID,
  //       phraccessToken: phraccessToken,
  //       transactionId: transactionId,
  //     };

  //     //console.log("otp databody", otpDataBody);

  //     const response = await Axios.post(
  //       `${Config.abhaBaseUrl}validatePHROTP`,
  //       otpDataBody,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //           "x-caller": encrypted,
  //         },
  //       }
  //     );

  //     //console.log("response in verifyMobileOtp", response);

  //     if (response.status === 422 || response.status === 400) {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: response.data.code,
  //         alertMessage:
  //           response.data.details.length > 0
  //             ? response.data.details[0].message
  //             : response.data.message,
  //       }));
  //     } else if (response.status === 200) {
  //       sessionStorage.setItem("loginResponse", response.data);
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         listOfAbhaAddress: response.data.mappedPhrAddress,
  //       }));
  //       setActiveSection("selectAbha");
  //       onActiveSectionChange("selectAbha");
  //     } else {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //     }
  //     setShowLoading(false);
  //   } catch (error) {
  //     if (
  //       (error.response && error.response.status === 422) ||
  //       (error.response && error.response.status === 400)
  //     ) {
  //       let details = error.response.data.details[0];
  //       console.log(details.message, "===", error.response.data.message);
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: error.response.data.code,
  //         alertMessage:
  //           details && details.message
  //             ? details.message
  //             : error.response.data.message,
  //       }));
  //     } else {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //     }
  //     setShowLoading(false);
  //   }
  // };

  // V3 call
  const validatePHROTP = async () => {
    setShowLoading(true);
    try {
      // const phraccessToken = await sessionStorage.getItem("phraccessToken");
      const transactionId = await sessionStorage.getItem("transactionIdPhr");
      // let encryptedOTP = await otpencrytion(variables.otp);

      console.log("otp", variables.otp);
      console.log("ID", transactionId);

      const otpDataBody = {
        "otp": `${variables.otp}`,
        // USER_ID: UserID,
        // phraccessToken: phraccessToken,
        "txnId": `${transactionId}`,
      };


      const response = await Axios.post(
        `${Config.abhav3BaseUrl}VerifyMobileOtp`,
        otpDataBody,
        {
          headers: {
            "x-client-token": Config.token,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("response in verifyMobileOtp", response);

      if (response.status === 422 || response.status === 400) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.data.code,
          alertMessage:
            response.data.details.length > 0
              ? response.data.details[0].message
              : response.data.message,
        }));
        setShowLoading(false);
      } else if (response.status === 200) {
        if (response.data.data.authResult == "failed") {
          setVariables((prevState) => ({
            ...prevState,
            showAlert: true,
            alertTitle: "Failed!!",
            alertMessage: "Entered OTP is incorrect. Kindly re-enter valid OTP",
          }));
        } else {
          sessionStorage.setItem("loginResponse", response.data);
          setVariables((prevState) => ({
            ...prevState,
            listOfAbhaAddress: response.data.mappedPhrAddress,
          }));
          setActiveSection("createNew");
          onActiveSectionChange("createNew");
        }
        setShowLoading(false);
        // setActiveSection("selectAbha");
        // onActiveSectionChange("selectAbha");
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
      setShowLoading(false);
    } catch (error) {
      if (
        (error.response && error.response.status === 422) ||
        (error.response && error.response.status === 400)
      ) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
        setShowLoading(false);
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
      setShowLoading(false);
    }
  };

  // Encryption of mobile number  using JSEncrypt
  const mobileEncryption = async (mobile) => {
    var data = mobile;
    var key = sessionStorage.getItem("PHRPublicKey");
    var jsencrypt = new JSEncrypt();
    jsencrypt.setPublicKey(key);
    var encrypt = jsencrypt.encrypt(data);
    //console.log("encrypted mobile :", encrypt);
    return encrypt;
  };

  // v1 version api call
  // const detailsToAbhaAddress = async () => {
  //   try {
  //     //console.log("formdata===", formData);
  //     const phraccessToken = await sessionStorage.getItem("phraccessToken");
  //     var token = (await sessionStorage.getItem("transactionIdPhr")) ?? "";
  //     var userMobile = (await sessionStorage.getItem("mobile")) ?? "";
  //     var encryptedmobile = await mobileEncryption(userMobile);

  //     const payload = {
  //       token: token,
  //       USER_ID: UserID,
  //       mobile: encryptedmobile,
  //       gender: formData.gender,
  //       firstName: formData.firstName,
  //       pinCode: formData.pinCode,
  //       transactionId: token,
  //       yearOfBirth: formData.yearOfBirth,
  //       dayOfBirth: formData.dateOfBirth,
  //       monthOfBirth: formData.monthOfBirth,
  //       phraccessToken: phraccessToken,
  //     };
  //     console.log("paylod user details", payload);
  //     const response = await Axios.post(
  //       `${Config.abhaBaseUrl}detailsToAbhaAddress`,
  //       payload,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //           "x-caller": encrypted,
  //           responseType: "arraybuffer",
  //         },
  //       }
  //     );

  //     //console.log("response in detailsToAbhaAddress", response);
  //     if (response.status === 422) {
  //       let details = response.data.details[0];
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: response.data.code,
  //         alertMessage:
  //           details && details.length > 0
  //             ? details.message
  //             : response.data.message,
  //       }));
  //       setShowLoading(false);
  //     } else if (response.status === 200) {
  //       await getAbhaSuggestions();
  //     } else {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //     }
  //   } catch (error) {
  //     console.log("Errrr", error);
  //     if (
  //       (error.response && error.response.status === 422) ||
  //       (error.response && error.response.status === 400)
  //     ) {
  //       let details = error.response.data.details?.[0];
  //       console.log(details.message, "===", error.response.data.message);
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: error.response.data.code,
  //         alertMessage:
  //           details && details?.message
  //             ? details?.message
  //             : error.response.data.message,
  //       }));
  //     } else {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //     }
  //   }
  // };

  // v3 api call
  const detailsToAbhaAddress = async () => {

    const missingFields = [];

    if (variables.abhaAddress === "") missingFields.push("Abha address");
    if (variables.password === "") missingFields.push("Password");

    if (missingFields.length > 0) {
      // If any fields are missing, show an alert
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "Missing Information",
        alertMessage: `Please fill in the following fields:\n${missingFields.join(", ")}`,
      }));
      return;
    }

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (variables.password.length < 8 || !specialCharRegex.test(variables.password)) {
      toast.error("Password must contain at least one special character and be longer than 8 characters.");
      return;
    }

    try {
      //console.log("formdata===", formData);
      const phraccessToken = await sessionStorage.getItem("phraccessToken");
      var token = (await sessionStorage.getItem("transactionIdPhr")) ?? "";
      var userMobile = (await sessionStorage.getItem("mobile")) ?? "";
      var encryptedmobile = await mobileEncryption(userMobile);

      const payload = {
        // token: token,
        // USER_ID: UserID,
        "mobile": `${userMobile}`,
        "gender": `${formData.gender}`,
        "firstName": `${formData.lastName}`,
        "middleName": `${formData.lastName}`,
        "lastName": `${formData.firstName}`,
        "email": `${formData.email}`,
        "address": `${formData.address}`,
        "stateName": `${formData.state}`,
        "stateCode": `${formData.statecode}`,
        "districtName": `${formData.district}`,
        "districtCode": `${formData.districtcode}`,
        "pinCode": `${formData.pinCode}`,
        "txnId": `${token}`,
        "yearOfBirth": `${formData.yearOfBirth}`,
        "dayOfBirth": `${formData.dateOfBirth}`,
        "monthOfBirth": `${formData.monthOfBirth}`,
        "abhaAddress": `${variables.abhaAddress}@abdm`,
        "password": `${variables.password}`,
        // phraccessToken: phraccessToken,
      };
      console.log("paylod user details", payload);
      const response = await Axios.post(
        `${Config.abhav3BaseUrl}enrollAbhaAddress`,
        payload,
        {
          headers: {
            "x-client-token": Config.token,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("response status of abha", response.status);
      console.log("hie in formdata 2", response);

      //console.log("response in detailsToAbhaAddress", response);
      if (response.status === 422) {
        let details = response.data.details[0];
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            details && details.length > 0
              ? details.message
              : response.data.message,
        }));
        setShowLoading(false);
      } else if (response.status === 200) {
        console.log("hie in formdata 2", response);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "Success",
          alertMessage: "Your ABHA address has been created successfully!",
          showDoneButton: true,
        }));
        sessionStorage.setItem("profileData", JSON.stringify(response.data.data.phrDetails));
        sessionStorage.setItem("newabhaaddress", variables.abhaAddress);
        setTimeout(() => {
          handleNavigate();
        }, 3000);
        // sessionStorage.setItem("abhaAddressList", response.data.data.phrDetails.abhaAddress);
        // setActiveSection("register");
        // onActiveSectionChange("register");
        // await getAbhaSuggestions();
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
    } catch (error) {
      console.log("Errrr", error);
      if (
        (error.response && error.response.status === 422) ||
        (error.response && error.response.status === 400)
      ) {
        // console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data[0].code,
          alertMessage: error.response.data[0].message,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
    }
  };

  // V1 version api call
  // const getAbhaSuggestions = async () => {
  //   try {
  //     const phraccessToken = await sessionStorage.getItem("phraccessToken");
  //     var transactionId =
  //       (await sessionStorage.getItem("transactionIdPhr")) ?? "";

  //     const payload = {
  //       transactionId: transactionId,
  //       USER_ID: UserID,
  //       phraccessToken: phraccessToken,
  //     };

  //     //console.log("paylod in getAbhaSuggestions", payload);
  //     const response = await Axios.post(
  //       `${Config.abhaBaseUrl}getAbhaSuggestions`,
  //       payload,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //           "x-caller": encrypted,
  //           responseType: "arraybuffer",
  //         },
  //       }
  //     );

  //     //console.log("response in getAbhaSuggestions", response);
  //     if (response.status === 422) {
  //       let details = response.data.details[0];
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: response.data.code,
  //         alertMessage:
  //           details && details.length > 0
  //             ? details.message
  //             : response.data.message,
  //       }));
  //       setShowLoading(false);
  //     } else if (response.status === 200) {
  //       sessionStorage.setItem("abhaAddressList", response.data);

  //       const suggestions = response.data.join(", ");
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         suggestedAbhaAddress: suggestions,
  //       }));

  //       setActiveSection("createAbhaAddress");
  //       onActiveSectionChange("createAbhaAddress");
  //     } else {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //     }
  //   } catch (error) {
  //     if (
  //       (error.response && error.response.status === 422) ||
  //       (error.response && error.response.status === 400)
  //     ) {
  //       let details = error.response.data.details[0];
  //       console.log(details.message, "===", error.response.data.message);
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: error.response.data.code,
  //         alertMessage:
  //           details && details.message
  //             ? details.message
  //             : error.response.data.message,
  //       }));
  //     } else {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //     }
  //   }
  // };

  // V3 API CALL 

  // v3 call working
  // const getAbhaSuggestions = async () => {
  //   if (    formData.firstName.trim() !== "" &&
  //   formData.lastName.trim() !== "" &&
  //   formData.dateOfBirth.trim() !== "" &&
  //   formData.monthOfBirth.trim() !== "" &&
  //   formData.yearOfBirth.trim() !== "" &&
  //   formData.email.trim() !== "") {
  //     try {
  //       const phraccessToken = await sessionStorage.getItem("phraccessToken");
  //       var transactionId =
  //         (await sessionStorage.getItem("transactionIdPhr")) ?? "";

  //       const payload = {
  //         "txnId": `${transactionId}`,
  //         // USER_ID: UserID,
  //         // phraccessToken: phraccessToken,
  //         "firstName": formData.firstName,
  //         "lastName": formData.lastName,
  //         "dayOfBirth": formData.dateOfBirth,
  //         "monthOfBirth": formData.monthOfBirth,
  //         "yearOfBirth": formData.yearOfBirth,
  //         "email": formData.email
  //       };

  //       //console.log("paylod in getAbhaSuggestions", payload);
  //       const response = await Axios.post(
  //         `${Config.abhav3BaseUrl}getSuggestionsList`,
  //         payload,
  //         {
  //           headers: {
  //             "x-client-token": Config.token,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       //console.log("response in getAbhaSuggestions", response);
  //       if (response.status === 200) {
  //         console.log("response status-----", response.status);
  //         console.log("Full response object:", response);
  //         console.log("Full response AAdhar:", response.data.data.abhaAddressList);
  //         sessionStorage.setItem("abhaAddressList", response.data.data.abhaAddressList);
  //         const suggestions = response.data.data.abhaAddressList.join(", ");
  //         setVariables((prevState) => ({
  //           ...prevState,
  //           suggestedAbhaAddress: suggestions,
  //         }));

  //         // setActiveSection("createAbhaAddress");
  //         // onActiveSectionChange("createAbhaAddress");createAbhaAddress
  //         setActiveSection("createAbhaAddress");
  //         console.log("comming here");
  //         onActiveSectionChange("createAbhaAddress");
  //       }
  //       else if (response.status === 422) {
  //         let details = response.data.details[0];
  //         setVariables((prevState) => ({
  //           ...prevState,
  //           showAlert: true,
  //           alertTitle: response.data.code,
  //           alertMessage:
  //             details && details.length > 0
  //               ? details.message
  //               : response.data.message,
  //         }));
  //         setShowLoading(false);
  //       } else {
  //         console.log("hieee");
  //         setVariables((prevState) => ({
  //           ...prevState,
  //           showAlert: true,
  //           alertTitle: "ABHA Server down!!!",
  //           alertMessage:
  //             "The ABHA server is down at this time. Please try after sometime.",
  //         }));
  //       }
  //     } catch (error) {
  //       console.log("hieee");
  //       if (
  //         (error.response && error.response.status === 422) ||
  //         (error.response && error.response.status === 400)
  //       ) {
  //         let details = error.response.data.details[0];
  //         console.log(details.message, "===", error.response.data.message);
  //         setVariables((prevState) => ({
  //           ...prevState,
  //           showAlert: true,
  //           alertTitle: error.response.data.code,
  //           alertMessage:
  //             details && details.message
  //               ? details.message
  //               : error.response.data.message,
  //         }));
  //       } else {
  //         setVariables((prevState) => ({
  //           ...prevState,
  //           showAlert: true,
  //           alertTitle: "ABHA Server down!!!",
  //           alertMessage:
  //             "The ABHA server is down at this time. Please try after sometime.",
  //         }));
  //       }
  //     }
  //   }
  //   else {
  //     console.log("All required fields must be filled before making the request.");
  //     setVariables((prevState) => ({
  //       ...prevState,
  //       showAlert: true,
  //       alertTitle: "Missing Information",
  //       alertMessage: "Please fill in all the required fields before proceeding.",
  //     }));
  //   }
  // };

  // v3 call with validation

  const getAbhaSuggestions = async () => {
    const missingFields = [];

    if (formData.firstName === "") missingFields.push("First Name");
    if (formData.lastName === "") missingFields.push("Last Name");
    if (formData.dateOfBirth === "") missingFields.push("Date of Birth");
    if (formData.monthOfBirth === "") missingFields.push("Month of Birth");
    if (formData.yearOfBirth === "") missingFields.push("Year of Birth");
    if (formData.email === "") missingFields.push("Email");
    if (formData.gender === "") missingFields.push("Gender");
    if (formData.districtcode === "") missingFields.push("District Code");
    if (formData.pinCode === "") missingFields.push("Pin Code");

    if (missingFields.length > 0) {
      // If any fields are missing, show an alert
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "Missing Information",
        alertMessage: `Please fill in the following fields:\n${missingFields.join(", ")}`,
      }));
      return;
    }
    try {
      const phraccessToken = await sessionStorage.getItem("phraccessToken");
      var transactionId = (await sessionStorage.getItem("transactionIdPhr")) ?? "";

      const payload = {
        txnId: transactionId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        dayOfBirth: formData.dateOfBirth,
        monthOfBirth: formData.monthOfBirth,
        yearOfBirth: formData.yearOfBirth,
        email: formData.email,
      };

      const response = await Axios.post(
        `${Config.abhav3BaseUrl}getSuggestionsList`,
        payload,
        {
          headers: {
            "x-client-token": Config.token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("ABHA Address List:", response.data.data.abhaAddressList);
        sessionStorage.setItem("abhaAddressList", response.data.data.abhaAddressList);

        const suggestions = response.data.data.abhaAddressList.join(", ");
        setVariables((prevState) => ({
          ...prevState,
          suggestedAbhaAddress: suggestions,
        }));

        setActiveSection("createAbhaAddress");
        onActiveSectionChange("createAbhaAddress");
      } else {
        handleServerError(response);
      }
    } catch (error) {
      console.log("error of that response", error);
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: error.response.data[0].code || "Error",
        alertMessage: error.response.data[0].message || "Please enter valid details",
      }));
    }
  };

  // Function to handle server errors
  const handleServerError = (response) => {
    if (response.status === 422) {
      let details = response.data.details[0];
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: response.data.code,
        alertMessage: details?.message || response.data.message,
      }));
    } else {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "ABHA Server down!!!",
        alertMessage: "The ABHA server is down at this time. Please try after sometime.",
      }));
    }
  };

  // Function to handle request errors
  const handleRequestError = (error) => {
    if (error.response && (error.response.status === 422 || error.response.status === 400)) {
      let details = error.response.data.details[0];
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: error.response.data.code,
        alertMessage: details?.message || error.response.data.message,
      }));
    } else {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "ABHA Server down!!!",
        alertMessage: "The ABHA server is down at this time. Please try after sometime.",
      }));
    }
  };


  // password encryption using JSEncrypt


  const passwordEncryption = async (password) => {
    var data = password;
    var key = sessionStorage.getItem("PHRPublicKey");
    var jsencrypt = new JSEncrypt();
    jsencrypt.setPublicKey(key);
    var encrypt = jsencrypt.encrypt(data);
    //console.log("encrypted mobile :", encrypt);
    return encrypt;
  };

  // const createAbhaAddress = async () => {
  //   try {
  //     const phraccessToken = await sessionStorage.getItem("phraccessToken");
  //     var transactionId =
  //       (await sessionStorage.getItem("transactionIdPhr")) ?? "";
  //     const encryptedPassword = await passwordEncryption(variables.password);
  //     let phrAddress = variables.abhaAddress;

  //     const payload = {
  //       transactionId: transactionId,
  //       USER_ID: UserID,
  //       phraccessToken: phraccessToken,
  //       encryptedPassword: encryptedPassword,
  //       phrAddress: phrAddress,
  //     };

  //     console.log("paylod in createAbhaAddress", payload);
  //     const response = await Axios.post(
  //       `${Config.abhaBaseUrl}createPhrAddress`,
  //       payload,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //           "x-caller": encrypted,
  //         },
  //       }
  //     );

  //     //console.log("response in createAbhaAddress", response);
  //     if (response.status === 422) {
  //       let details = response.data.details[0];
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: response.data.code,
  //         alertMessage:
  //           details && details.length > 0
  //             ? details.message
  //             : response.data.message,
  //       }));
  //       setShowLoading(false);
  //     } else if (response.status === 200) {
  //       sessionStorage.setItem("abhaAddressList", response.data);
  //       setActiveSection("register");
  //       onActiveSectionChange("register");
  //     } else {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //     }
  //   } catch (error) {
  //     if (
  //       (error.response && error.response.status === 422) ||
  //       (error.response && error.response.status === 400)
  //     ) {
  //       let details = error.response.data.details?.[0];
  //       // console.log(details.message, "===", error.response.data.message);
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: error.response.data.code,
  //         alertMessage:
  //           details && details.message
  //             ? details.message
  //             : error.response.data.message,
  //       }));
  //     } else {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(formData1);

    const phraccessToken = await sessionStorage.getItem("phraccessToken");
    var token = (await sessionStorage.getItem("transactionIdPhr")) ?? "";
    var userMobile = (await sessionStorage.getItem("mobile")) ?? "";
    var encryptedmobile = await mobileEncryption(userMobile);

    const payload = {
      token: token,
      USER_ID: UserID,
      mobile: encryptedmobile,
      transactionId: token,
      phraccessToken: phraccessToken,
      address: formData1.address,
      dayOfBirth: formData1.birthDate.day,
      districtCode: formData1.district,
      email: formData1.email,
      firstName: formData1.firstName,
      gender: formData1.gender,
      lastName: formData1.lastName,
      middleName: formData1.middleName,
      monthOfBirth: formData1.birthDate.month,
      pinCode: formData1.pincode,
      stateCode: formData1.state,
      yearOfBirth: formData1.birthDate.year,
    };
    //console.log("paylod user details", payload);
    const response = await Axios.post(
      `${Config.abhaBaseUrl}detailsToAbhaAddress`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
          responseType: "arraybuffer",
        },
      }
    );

    //console.log("response in detailsToAbhaAddress", response);
    if (response.status === 422) {
      let details = response.data.details[0];
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: response.data.code,
        alertMessage:
          details && details.length > 0
            ? details.message
            : response.data.message,
      }));
      setShowLoading(false);
    } else if (response.status === 200) {
      await getAbhaSuggestions();
    } else {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "ABHA Server down!!!",
        alertMessage:
          "The ABHA server is down at this time. Please try after sometime.",
      }));
    }
  };

  const handleNavigateToDemographics = () => {
    navigate("/abha/demographics");
  };
  const handleNavigatePatients = () => {
    navigate("/patients");
  };
  const handleCreateNewAddress = () => {
    setActiveSection("createNew");
    onActiveSectionChange("createNew");
  };

  const handleNavigationtomobilenumber = () => {

  };

  // Naviagtion to patient registration page
  const handleNavigate = () => {
    navigate('/patients/add-patients?action=abhatoaddpatient')
  };


  const handleInputChange = async (e, ctrlName) => {
    const { value } = e.target;

    if (
      ctrlName === "pinCode" ||
      ctrlName === "yearOfBirth" ||
      ctrlName === "monthOfBirth" ||
      ctrlName === "dateOfBirth"
    ) {
      // Check if the input is a number
      if (/^\d*$/.test(value)) {
        setFormData({
          ...formData,
          [ctrlName]: value,
        });
      }
    } else if (ctrlName === "firstName") {
      // Check if the input is only alphabets and spaces
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setFormData({
          ...formData,
          [ctrlName]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [ctrlName]: value,
      });
    }
    // console.log(ctrlName, value.length, 'ikndfknac changes....')
    // if (ctrlName === "pinCode") {
    //   await getDataFromPinCode();
    // }
  };

  const getDataFromPinCode = async () => {
    console.log("hie");
    const url = `https://api.postalpincode.in/pincode/${formData.pinCode}`;
    if (formData.pinCode && formData.pinCode.length === 6) {
      try {
        const response = await Axios.get(url);
        if (response.status === 200) {
          const jsonResponse = response.data[0];
          console.log("hie inside");

          if (jsonResponse.Status === "Error") {
            setPinCodeDetails("Pin code is not valid.");
          } else {
            const postOfficeArray = jsonResponse.PostOffice;
            if (postOfficeArray && postOfficeArray.length > 0) {
              const obj = postOfficeArray[0];
              setDistricts(obj.District);
              setStates(obj.State);
              setCountry(obj.Country);

              setPinCodeDetails(
                `Details of pin code are:\nDistrict: ${obj.District}\nState: ${obj.State}\nCountry: ${obj.Country}`
              );

              console.log("Pin Code API Response:", obj);

              // Fetch state list and find the matching state
              getStateList(obj.State);
            }
          }
        } else {
          setPinCodeDetails("Failed to fetch data. Please try again.");
        }
      } catch (error) {
        setPinCodeDetails("Error occurred. Please try again.");
        console.error(error);
      }
    }
  };

  // const getStateList = async (selectedState) => {
  //   console.log("Selected State Name:", selectedState);
  //   try {
  //     const response = await Axios.post(`${Config.abhaBaseUrl}v3/getHfrStates`, {}, {
  //       headers: {
  //         "x-client-token": Config.token,
  //         "content-Type": "application/json",
  //         "x-caller": await encrypted,
  //       },
  //     });

  //     console.log("State API Full Response:", response.data);
  //     console.log("State API Full Response states:", Array.isArray(response.data));

  //     if (response.status === 200 && Array.isArray(response.data)) {
  //       setStates(response.data);
  //     } else {
  //       console.error("Error: Expected an array but got:", response.data);
  //       setStates([]); // Prevent crash
  //     }
  //   } catch (error) {
  //     console.error("Error fetching states:", error);
  //     setStates([]); // Prevent crashes
  //   }
  // };

  const getStateList = async (selectedState) => {
    console.log("Selected State Name:", selectedState);
    try {
      const response = await Axios.post(`${Config.abhaBaseUrl}v3/getHfrStates`, {}, {
        headers: {
          "x-client-token": Config.token,
          "content-Type": "application/json",
          "x-caller": await encrypted,
        },
      });

      console.log("State API Full Response:", response.data);

      if (response.status === 200 && Array.isArray(response.data)) {
        setStates(response.data);

        // Find the state matching selectedState
        const matchedState = response.data.find(state => state.name.toLowerCase() === selectedState.toLowerCase());

        if (matchedState) {
          setFormData(prevFormData => ({
            ...prevFormData,
            state: matchedState.name,
            statecode: matchedState.code,
          }));
          setStateCode(matchedState.code);

          getDistrictList(matchedState.code);
        }
      } else {
        console.error("Error: Expected an array but got:", response.data);
        setStates([]); // Prevent crash
      }
    } catch (error) {
      console.error("Error fetching states:", error);
      setStates([]); // Prevent crashes
    }
  };

  const handleDistrictChange = (e) => {
    setFormData({
      ...formData,
      district: e.target.options[e.target.selectedIndex].text,
      districtcode: e.target.value,
    });
  };


  const getDistrictList = async (stateCode) => {
    try {
      const response = await Axios.post(
        `${Config.abhaBaseUrl}v3/getHfrDistricts/${stateCode}`,
        {},
        {
          headers: {
            "content-type": "application/json",
            "x-client-token": Config.token,
            "x-caller": await encrypted,
          },
        }
      );

      console.log("response data of districts", response.data);

      if (response.status === 200 && Array.isArray(response.data)) {
        setDistricts(response.data);
      } else {
        console.error("Failed to fetch districts");
      }
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };

  const handleInputChange1 = (e, ctrlName) => {
    const { value } = e.target;
    setVariables({
      ...variables,
      [ctrlName]: value,
    });
  };

  const handleInputChangepass = (e, ctrlName) => {
    const { value } = e.target;

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (ctrlName === "password") {
      if (value.length <= 8 || !specialCharRegex.test(value)) {
        toast.error("Password must contain at least one special character and be longer than 8 characters.");
      }
    }

    setVariables({
      ...variables,
      [ctrlName]: value,
    });
  };

  const handleAlertClose = () => {
    setVariables((prevState) => ({
      ...prevState,
      showAlert: false,
    }));
  };

  const handleChange = (otp) => {
    setVariables((prevState) => ({
      ...prevState,
      otp: otp,
    }));
  };

  const handleCancel = () => {
    navigate("/patients");
  };

  return (
    <div className="abhaContainer" style={{ width: "50%" }}>
      <div className="abhaContainerLeft">
        {variables.showAlert && (
          <ShowAlert
            title={variables.alertTitle}
            message={variables.alertMessage}
            onClose={handleAlertClose}
          />
        )}

        {/* to show otp screen */}
        {activeSection === "verifyotp" && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h2 style={{ textAlign: "center", flex: 1 }}>ABHA OTP REQUEST</h2>

              {/* Close Icon to Navigate Back */}
              <CloseIcon
                style={{ cursor: "pointer", fontSize: "24px", marginRight: "10px" }}
                // onClick={() => navigate(-1)} // Go back to the previous page
                onClick={() => window.history.back()}
              />
            </div>

            <h3 style={{ textAlign: "center" }}>
              Verify OTP to access your ABHA Account
            </h3>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <OtpInput
                value={variables.otp}
                onChange={handleChange}
                numInputs={6}
                renderSeparator={<span>{""}</span>}
                renderInput={(props) => <input {...props} type="tel" />}
                skipDefaultStyles={true}
                inputStyle="otp-input"
                inputType="number"
              />
            </div>

            <div
              onClick={handleResend}
              style={{
                marginLeft: "60%",
                color: "orange",
                cursor: disabled ? "not-allowed" : "pointer",
                opacity: disabled ? 0.6 : 1,
              }}
            >
              Resend OTP? {disabled && `(${timer}s)`}
            </div>

            <div className="Adharandmobile">
              <button
                className={"editButton2"}
                style={{
                  marginRight: "10px",
                  width: "30%",
                  margin: "0 auto",
                  height: "40px",
                  color: "white",
                }}
                onClick={validatePHROTP}
              >
                {showLoading && <CircularProgress size={24} color="inherit" />}
                Verify Otp
              </button>
            </div>
          </>
        )}

        {/* dispaly abha address in drodpown if have  */}
        {activeSection === "selectAbha" && (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Container maxWidth="md">
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <div style={{ padding: "20px", borderRadius: "10px" }}>
                      <Typography variant="h5" align="center" gutterBottom>
                        Select ABHA to continue
                      </Typography>

                      {variables && variables?.listOfAbhaAddress && variables?.listOfAbhaAddress?.length === 0 ? (
                        <Typography
                          variant="body1"
                          align="center"
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            marginBottom: "20px",
                          }}
                        >
                          We have found no ABHA addresses linked with your
                          mobile number
                        </Typography>
                      ) : (
                        <Typography
                          variant="body1"
                          align="center"
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            marginBottom: "20px",
                          }}
                        >
                          We have found these ABHA addresses linked with your
                          mobile number
                        </Typography>
                      )}

                      <FormControl fullWidth>
                        <InputLabel id="select-abha-address-label">
                          Select ABHA address
                        </InputLabel>
                        <Select
                          labelId="select-abha-address-label"
                          value={listValue || ""}
                          onChange={(event) => setListValue(event.target.value)}
                          fullWidth
                          style={{ width: "23rem" }}
                        >
                          {variables?.listOfAbhaAddress?.length === 0 ? (
                            <MenuItem disabled>
                              No ABHA address found. Click below to create
                            </MenuItem>
                          ) : (
                            variables?.listOfAbhaAddress?.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>

                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={handleNavigatePatients}
                        style={{ marginTop: "20px" }}
                      >
                        Done
                      </Button>

                      <Typography
                        variant="body1"
                        align="center"
                        style={{
                          color: "grey",
                          fontWeight: "bold",
                          marginTop: "20px",
                          cursor: "pointer",
                        }}
                        onClick={handleCreateNewAddress}
                      >
                        Click to create new ABHA address
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </>
        )}

        {/* create abha address screen */}
        {/* Raviteja changes */}
        {activeSection === "createNew" && (
          <>
            <div
              className="SetUpYourAbhaAddress"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div style={{ padding: "20px", borderRadius: "10px" }}>
                <Typography variant="h5" align="center" gutterBottom>
                  Set Up Your ABHA Address
                </Typography>

                {/* Form fields */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                  <div style={{ marginBottom: "10px", width: "49%" }}>
                    <label htmlFor="firstName">First Name *</label>
                    <input
                      type="text"
                      className="AbhaSetupInputs"
                      id="firstName"
                      value={formData.firstName}
                      onChange={(e) => handleInputChange(e, "firstName")}
                      required
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                        marginTop: "3px"
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "10px", width: "49%" }}>
                    <label htmlFor="lastname">Last Name *</label>
                    <input
                      type="text"
                      className="AbhaSetupInputs"
                      id="lastname"
                      value={formData.lastName}
                      onChange={(e) => handleInputChange(e, "lastName")}
                      required
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                        marginTop: "3px"
                      }}
                    />
                  </div>
                </div>

                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // marginBottom: "10px",
                }}>
                  <div style={{ marginBottom: "10px", width: "68%" }}>
                    <label htmlFor="email">
                      Email*
                    </label>
                    <input
                      type="text"
                      className="AbhaSetupInputs"
                      id="email"
                      value={formData.email}
                      onChange={(e) => handleInputChange(e, "email")}
                      required
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                        marginTop: "3px"
                      }}
                      name="email"
                    />
                    {/* {emailWarning && (
                        <p className="warning-message" style={{ color: "red" }}>
                          {emailWarning}
                        </p>
                      )} */}
                  </div>
                  <div style={{ marginBottom: "10px", width: "30%" }}>
                    <label htmlFor="gender">Gender *</label>
                    <select
                      id="gender"
                      className="AbhaSetupInputs"
                      value={formData.gender}
                      onChange={(e) => handleInputChange(e, "gender")}
                      required
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                        marginTop: "3px"
                        // marginTop: "10px"
                        // backgroundColor:"rgba(214, 229, 255, 1)"
                      }}
                    >
                      {genderItems.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.display}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}>
                  <div style={{ display: "flex", flexDirection: "column", width: "68%" }}>
                    <label htmlFor="address">Address *</label>
                    <input
                      type="text"
                      className="AbhaSetupInputs"
                      id="address"
                      value={formData.address}
                      onChange={(e) => handleInputChange(e, "address")}
                      required
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                        marginTop: "3px"
                        // marginTop: "10px"
                      }}
                    />
                  </div>

                  <div style={{ display: "flex", flexDirection: "column", width: "30%" }}>
                    <label htmlFor="pinCode">Pin Code *</label>
                    <input
                      type="text"
                      className="AbhaSetupInputs"
                      id="pinCode"
                      value={formData.pinCode}
                      onChange={(e) => handleInputChange(e, "pinCode")}
                      maxLength="6"
                      required
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                        marginTop: "3px"
                      }}
                    />
                  </div>
                </div>

                <div style={{ marginBottom: "10px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  {/* State Dropdown */}
                  <div style={{ display: "flex", flexDirection: "column", width: "49%" }}>
                    <label htmlFor="state">Select a State</label>
                    <select name="state" className="AbhaSetupInputs" value={formData.statecode} id="state">
                      <option value="">Select a State</option>
                      {Array.isArray(states) && states.length > 0 ? (
                        states.map((state) => (
                          <option key={state.code} value={state.code}>
                            {state.name}
                          </option>
                        ))
                      ) : (
                        <option disabled>Loading...</option>
                      )}

                    </select>
                  </div>

                  {/* District Dropdown */}
                  <div style={{ display: "flex", flexDirection: "column", width: "49%" }}>
                    <label htmlFor="district">Select a District</label>
                    <select
                      name="district"
                      className="AbhaSetupInputs"
                      value={formData.districtcode}
                      id="district"
                      onChange={handleDistrictChange}
                    >
                      <option value="">Select a District</option>
                      {Array.isArray(districts) && districts.length > 0 ? (
                        districts.map((district) => {
                          return (
                            <option key={district.code} value={district.code}>
                              {district.name}
                            </option>
                          );
                        })
                      ) : (
                        <option disabled>Loading...</option>
                      )}
                    </select>

                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ marginRight: "5px", width: "33%" }}>
                    <label htmlFor="date">Date *</label>
                    <input
                      type="text"
                      className="AbhaSetupInputs"
                      id="date"
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, "");

                        if (value.length > 2) value = value.slice(0, 2);
                        if (value > 31) value = "31";
                        if (value === "00") value = "1";

                        e.target.value = value;

                        handleInputChange(e, "dateOfBirth")
                      }}
                      maxLength="2"
                      required
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                        marginTop: "3px"
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "5px", width: "33%" }}>
                    <label htmlFor="month">Month *</label>
                    <input
                      type="text"
                      className="AbhaSetupInputs"
                      id="month"
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, "");

                        if (value.length > 2) value = value.slice(0, 2);
                        if (value > 12) value = "12";
                        if (value === "00") value = "1";

                        e.target.value = value;

                        handleInputChange(e, "monthOfBirth")
                      }}
                      maxLength="2"
                      required
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                        marginTop: "3px"
                      }}
                    />
                  </div>
                  <div style={{ width: "33%" }}>
                    <label htmlFor="year">Year *</label>
                    <input
                      type="text"
                      className="AbhaSetupInputs"
                      id="year"
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, "");

                        if (value.length > 4) value = value.slice(0, 4);
                        if (value.startsWith("0")) value = value.replace(/^0+/, "");

                        e.target.value = value;
                        handleInputChange(e, "yearOfBirth")
                      }}
                      maxLength="4"
                      required
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                        marginTop: "3px"
                      }}
                    />
                  </div>
                </div>


                {/* Submit button */}
                <div style={{ display: "flex", marginTop: "3%" }}>
                  <button
                    className={"editButton2"}
                    style={{
                      marginRight: "10px",
                      width: "30%",
                      margin: "0 auto",
                      height: "40px",
                      color: "white",
                    }}
                    onClick={() => setActiveSection("selectAbha")}
                  >
                    Cancel
                  </button>
                  <button
                    className={"editButton2"}
                    style={{
                      marginRight: "10px",
                      width: "30%",
                      margin: "0 auto",
                      height: "40px",
                      color: "white",
                    }}
                    // onClick={detailsToAbhaAddress}
                    onClick={getAbhaSuggestions}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {/* create abha address and password */}
        {activeSection === "createAbhaAddress" && (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ flex: 1 }}>
                <div style={{ textAlign: "center", padding: 15 }}>
                  <h1 style={{ fontSize: 30, fontWeight: "bold", color: "#52447d" }}>
                    ABHA Services
                  </h1>
                </div>

                <div style={{ textAlign: "center", padding: "20px 0" }}>
                  <p
                    style={{ color: "grey", fontSize: 20, fontWeight: "bold" }}
                  >
                    Create ABHA Address & Password to continue.
                  </p>
                </div>

                <div style={{ marginLeft: "30px", marginBottom: "10px", fontWeight: "400" }}>
                  Abha address
                </div>

                <div style={{ padding: "0 10px" }}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "90%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="text"
                        className="AbhaSetupInputs"
                        value={variables?.abhaAddress}
                        onChange={(e) => handleInputChange1(e, "abhaAddress")}
                        placeholder="Create your ABHA Address"
                        style={{
                          padding: 16,
                          width: "100%",
                          borderRadius: 10,
                          border: "1px solid #52447d",
                          backgroundColor: "white",
                        }}
                      />
                      <span>@abdm</span>
                    </div>
                  </div>
                </div>

                <div style={{ padding: "10px 28px" }}>
                  <p style={{ fontSize: "14px" }}>
                    You can use these ABHA address : {"     "}
                    <span
                      style={{
                        fontSize: 12,
                        color: "green",
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      {variables.suggestedAbhaAddress}
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: 10,
                      color: "grey",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Conditions for setting ABHA Address:
                  </p>
                  <p
                    style={{ fontSize: 10, color: "grey", fontStyle: "italic" }}
                  >
                    Example123@abdm,
                  </p>
                  <p
                    style={{ fontSize: 10, color: "grey", fontStyle: "italic" }}
                  >
                    4-32 characters alphabets and numbers only,
                  </p>
                  <p
                    style={{ fontSize: 10, color: "grey", fontStyle: "italic" }}
                  >
                    it should not start with a number or dot(.),
                  </p>
                  {/* <p
                    style={{ fontSize: 10,paddingTop:"10px"}}
                  >
                    hi
                  </p> */}
                </div>

                <div style={{ padding: "0 10px" }}>
                  <div
                    style={{ display: "flex" }}
                  >
                    <div style={{ width: "83%", paddingLeft: "5%" }}>
                      <input
                        type="password"
                        value={variables.password}
                        onChange={(e) => handleInputChange1(e, "password")}
                        placeholder="Create Password"
                        style={{
                          padding: 16,
                          width: "100%",
                          borderRadius: 10,
                          border: "1px solid #52447d",
                          backgroundColor: "white",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: 30,
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <button
                    onClick={handleCancel}
                    style={{
                      padding: "12px",
                      width: "20%",
                      backgroundColor: "#52447d",
                      borderRadius: 10,
                      color: "white",
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    // onClick={createAbhaAddress}
                    onClick={detailsToAbhaAddress}
                    style={{
                      padding: "12px",
                      backgroundColor: "#52447d",
                      width: "20%",
                      borderRadius: 10,
                      color: "white",
                      fontWeight: "bold",
                      border: "none",
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {activeSection === "register" && (
          <div style={{ padding: 20 }}>
            <h1 style={{ textAlign: "center" }}>Register with Mobile Number</h1>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Full Name"
                    name="fullName"
                    value={formData1.fullName}
                    onChange={handleFormChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="email"
                    label="Email"
                    name="email"
                    value={formData1.email}
                    onChange={handleFormChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Gender</InputLabel>
                    <Select
                      value={formData1.gender}
                      onChange={handleFormChange}
                      name="gender"
                      required
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="District"
                    name="district"
                    value={formData1.district}
                    onChange={handleFormChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    multiline
                    rows={3}
                    value={formData1.address}
                    onChange={handleFormChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="State"
                    name="state"
                    value={formData1.state}
                    onChange={handleFormChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Pincode"
                    name="pincode"
                    value={formData1.pincode}
                    onChange={handleFormChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>Date of Birth - Day</InputLabel>
                    <Select
                      value={formData1.birthDate.day}
                      onChange={handleDateChange}
                      name="day"
                      required
                    >
                      <MenuItem value="">Day</MenuItem>
                      {Array.from({ length: 31 }, (_, i) => (
                        <MenuItem key={i + 1} value={i + 1}>
                          {i + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>Date of Birth - Month</InputLabel>
                    <Select
                      value={formData1.birthDate.month}
                      onChange={handleDateChange}
                      name="month"
                      required
                    >
                      <MenuItem value="">Month</MenuItem>
                      <MenuItem value="01">January</MenuItem>
                      <MenuItem value="02">February</MenuItem>
                      <MenuItem value="03">March</MenuItem>
                      <MenuItem value="04">April</MenuItem>
                      <MenuItem value="05">May</MenuItem>
                      <MenuItem value="06">June</MenuItem>
                      <MenuItem value="07">July</MenuItem>
                      <MenuItem value="08">August</MenuItem>
                      <MenuItem value="09">September</MenuItem>
                      <MenuItem value="10">October</MenuItem>
                      <MenuItem value="11">November</MenuItem>
                      <MenuItem value="12">December</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Date of Birth - Year"
                    name="year"
                    placeholder="Year"
                    value={formData1.birthDate.year}
                    onChange={handleDateChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AbhaAddressSetUp;
