import { useState, useEffect } from "react";
import downArrow from "../images/downArrow.svg";
import config from "../Config/config";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import Axios from "axios";
import { Button, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const FacilityInformation = () => {
  const [facilityData, setFacilityData] = useState({});
  const [loading, setLoading] = useState(true);
  const [ownerSubTypeList, setOwnerSubTypeList] = useState([]);
  const [facilitySubTypeList, setfacilitySubTypeList] = useState([]);
  const [facilityTypeList, setfacilityTypeList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({
    OWNER: null,
    OWNER_SUBTYPE: null,
    MEDICINE: null,
    TYPESERVICE: null,
    FACILITYTYPE: null,
    FACILITYSUBTYPE: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [result, setResult] = useState();
  const [isFacilityType, setIsFacilityType] = useState();

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));

  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  useEffect(() => {
    // Fetch facility data for "OWNER" type first
    gettypes("OWNER");
    gettypes("FAC-STATUS");
  }, []);

  const gettypes = async (type) => {
    try {
      const options = {
        method: "POST",
        url: `${config.abhaBaseUrl}v3/getHfrMasterData/${type}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "x-caller": await encrypted,
        },
      };
      const response = await Axios(options);
      if (response.status === 200) {
        setResult(response.data);
        console.log(response.data, `getHfrMasterData/${type}`);
        const { type: facilityType, data: facilityOptions } = response.data;
        setFacilityData((prevState) => ({
          ...prevState,
          [facilityType]: facilityOptions,
        }));
        setLoading(false); // After data is fetched, stop loading
        // if (facilityType === "FAC-STATUS") {
        //     setFacStatusOptions(facilityOptions);
        //   }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFacilityOwnershipSubtype = async (selectedOwnershipCode) => {
    sessionStorage.setItem("ownershipcode", selectedOwnershipCode);
    try {
      const options = {
        method: "POST",
        url: `${config.abhaBaseUrl}v3/hfrOwnerSubType`,
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "x-caller": await encrypted,
        },
        data: {
          ownershipCode: selectedOwnershipCode,
          ownerSubtypeCode: "",
        },
      };
      const response = await Axios(options);

      if (response.status === 200) {
        console.log(response.data, "hfrOwnerSubType");
        const facilities = response.data?.data?.data || [];
        const subtypes = facilities.map((item) => ({
          value: item.value,
          code: item.code, // This is where we store 'C', 'P', or 'NP'
        }));
        setOwnerSubTypeList(subtypes);
      } else if (response.status === 404) {
        console.log("No data found for the given facility type.");
      } else {
        console.error("Error: ", response.body);
      }
    } catch (error) {
      console.error("Exception caught: ", error);
    }
  };

  const getFacilitytype = async (medicineCodes) => {
    console.log("getFacilitytypegetFacilitytype", medicineCodes);
    try {
      const options = {
        method: "POST",
        url: `${config.abhaBaseUrl}v3/hfrFacilityType`,
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "x-caller": await encrypted,
        },
        data: {
          ownershipCode: "G",
          systemofMedicineCode: medicineCodes,
        },
      };
      const response = await Axios(options);

      if (response.status === 200) {
        console.log(response.data, "FacilityType..........");
        setIsFacilityType(response.data);
        const facilities = response.data?.data?.data || [];
        const subtypes = facilities.map((item) => ({
          value: item.value,
          code: item.code,
        }));
        // setOwnerSubTypeList(subtypes);
        setfacilityTypeList(subtypes);
      } else if (response.status === 404) {
        console.log("No data found for the given facility type.");
      } else {
        console.error("Error: ", response.body);
      }
    } catch (error) {
      console.error("Exception caught: ", error);
    }
  };

  const getFacilitysubtype = async (selectedFacilityCode) => {
    sessionStorage.setItem("facilitycode", selectedFacilityCode);
    console.log("getFacilitytypegetFacilitytype", selectedFacilityCode);
    try {
      const options = {
        method: "POST",
        url: `${config.abhaBaseUrl}v3/hfrFacilitySubType`,
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "x-caller": await encrypted,
        },
        data: {
          facilitySubType: selectedFacilityCode,
        },
      };
      const response = await Axios(options);

      if (response.status === 200) {
        console.log(response.data, "FacilitysubtypeType..........");
        const facilities = response.data?.data?.data || [];
        const subtypes = facilities.map((item) => ({
          value: item.value,
          code: item.code,
        }));
        setfacilitySubTypeList(subtypes);
      } else if (response.status === 404) {
        console.log("No data found for the given facility type.");
      } else {
        console.error("Error: ", response.body);
      }
    } catch (error) {
      console.error("Exception caught: ", error);
    }
  };

  const handleOptionSelect = (type, option) => {
    setSelectedOptions((prevState) => {
      let updatedState = { ...prevState };
      if (type === "MEDICINE") {
        const isSelected = updatedState.MEDICINE?.includes(option.code);
        // If already selected, remove it
        if (isSelected) {
          updatedState.MEDICINE = updatedState.MEDICINE.filter(
            (code) => code !== option.code
          );
        } else {
          // Otherwise, add it
          updatedState.MEDICINE = updatedState.MEDICINE
            ? [...updatedState.MEDICINE, option.code]
            : [option.code]; // Initialize as array if empty
        }

        sessionStorage.setItem("selectedMedicineCodes", JSON.stringify(updatedState.MEDICINE));
        sessionStorage.getItem("selectedMedicineCodes");
        console.log("getitem of the medicine", sessionStorage.getItem("selectedMedicineCodes"));

        // After updating the state, check for MEDICINE selection
        if (updatedState.MEDICINE?.length > 0) {
          const medicineCodes = updatedState.MEDICINE.join(",");
          console.log(medicineCodes, "medicineCodes...");
          getFacilitytype(medicineCodes); // Call the API after updating the state
        }
      } else if (type === "TYPE-SERVICE") {
        // Handle TYPE-SERVICE selection
        const isSelected = updatedState["TYPE-SERVICE"]?.includes(option.code);

        // If already selected, remove it
        if (isSelected) {
          updatedState["TYPE-SERVICE"] = updatedState["TYPE-SERVICE"].filter(
            (code) => code !== option.code
          );
        } else {
          updatedState["TYPE-SERVICE"] = updatedState["TYPE-SERVICE"]
            ? [...updatedState["TYPE-SERVICE"], option.code]
            : [option.code]; // Initialize as array if empty
        }
      } else if (type === "FAC-STATUS") {
        updatedState["FAC-STATUS"] = option.code;
        sessionStorage.setItem("facilityStatus", option.code);
      } else {
        updatedState[type] =
          updatedState[type]?.value === option.value ? null : option;
      }

      console.log("Operational status code:", updatedState["FAC-STATUS"]);
      console.log("Updated Selected Options:", updatedState);
      // Return the updated state
      return updatedState;
    });
    // Trigger the ownership subtype function if "OWNER" is selected
    if (type === "OWNER") {
      getFacilityOwnershipSubtype(option.code);
    }
  };

  const handleDropdownServices = () => {
    gettypes("TYPE-SERVICE");
    setModalOpen(!modalOpen);
  };

  const handleSubtypeSelect = (subtype, type) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [type]: subtype.code,
    }));

    if (type === "OWNER_SUBTYPE") {
      sessionStorage.setItem("ownershipsubtype", subtype.code);
    } else if (type === "FACILITYSUBTYPE") {
      sessionStorage.setItem("facilitysubtypecode", subtype.code);
    } else if (type === "MEDICINE") {
      sessionStorage.setItem("selectedmedicinecode", subtype.code);
    }

    if (type === "FACILITYTYPE") {
      getFacilitysubtype(subtype.code);
    } else if (subtype.code === "C") {
      gettypes("CENTRAL-GOVERNMENT");
    } else if (subtype.code === "P") {
      gettypes("PROFIT-TYPE");
    } else if (subtype.code === "NP") {
      gettypes("NON-PROFIT-TYPE");
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleOktype = () => {
    setModalOpen(false);
  };

  const handleDropdownClick = () => {
    gettypes("MEDICINE");
    console.log(result, "result Medicine..........");
    setIsModalOpen(!isModalOpen); // Open the modal
  };

  // Utility function to render the options for a specific type
  const renderOptions = (type) => {
    if (!facilityData[type]) return null;
    return facilityData[type].map((option, index) => (
      <p
        className="facilityopt"
        key={index}
        onClick={() => handleOptionSelect(type, option)}
        style={{
          cursor: "pointer",
          backgroundColor:
            selectedOptions[type]?.value === option.value ? "#52447D" : "",
          color:
            selectedOptions[type]?.value === option.value ? "#fff" : "#000",
        }}
      >
        {option.value}
      </p>
    ));
  };

  const renderOptionsfacstatus = (type) => {
    if (!facilityData[type]) return null;
    return facilityData[type].map((option, index) => (
      <p
        className="facilityopt"
        key={index}
        onClick={() => handleOptionSelect(type, option)}
        style={{
          cursor: "pointer",
          backgroundColor:
            selectedOptions[type] === option.code ? "#52447D" : "",
          color: selectedOptions[type] === option.code ? "#fff" : "#000",
        }}
      >
        {option.value}
      </p>
    ));
  };

  // Utility function to render a section based on a type
  const renderSection = (type, title) => {
    return (
      <div className="facilitytypes" key={type}>
        <div className="facilityheading">
          <div style={{ marginLeft: "2%" }}>
            <h3 style={{ color: "#52447D" }}>{title}</h3>
          </div>
        </div>
        <div className="facilitysubopt">{renderOptions(type)}</div>
      </div>
    );
  };

  const renderSection1 = (type, title) => {
    return (
      <div className="facilitytypes" key={type}>
        <div className="facilityheading">
          <div style={{ marginLeft: "2%" }}>
            <h3 style={{ color: "#52447D" }}>{title}</h3>
          </div>
        </div>
        <div className="facilitysubopt">{renderOptionsfacstatus(type)}</div>
      </div>
    );
  };


  return (
    <div className="facilityFormContainer">
      <div className="addp-patientsHeading">
        <h2>Create Facility</h2>
      </div>
      <div style={{ padding: "2%" }}>
        {!loading ? (
          <>
            {/* Render "OWNER" section first */}
            {renderSection("OWNER", "Facility Ownership")}

            {/* Render subtypes dynamically based on the selected ownership */}
            {ownerSubTypeList.length > 0 && (
              <div className="facilitytypes">
                <div className="facilityheading">
                  <div style={{ marginLeft: "2%" }}>
                    <h3 style={{ color: "#52447D" }}>
                      Facility Ownership Subtype
                    </h3>
                  </div>
                </div>
                <div className="facilitysubopt">
                  {ownerSubTypeList.map((subtype, index) => (
                    <p
                      key={index}
                      className="facilityopt"
                      onClick={() => handleSubtypeSelect(subtype, "OWNER_SUBTYPE")}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          selectedOptions["OWNER_SUBTYPE"] === subtype.code
                            ? "#52447D"
                            : "",
                        color:
                          selectedOptions["OWNER_SUBTYPE"] === subtype.code
                            ? "#fff"
                            : "#000",
                      }}
                    >
                      {subtype.value}
                    </p>
                  ))}
                </div>
              </div>
            )}

            {/* Render only one component based on the selected ownership subtype */}
            {selectedOptions.OWNER_SUBTYPE === "C" &&
              renderSection("CENTRAL-GOVERNMENT", "Central Government")}
            {selectedOptions.OWNER_SUBTYPE === "P" &&
              renderSection("PROFIT-TYPE", "Profit")}
            {selectedOptions.OWNER_SUBTYPE === "NP" &&
              renderSection("NON-PROFIT-TYPE", "Non For Profit")}
            <>
              <div>
                <div className="facilityCheckbox" onClick={handleDropdownClick}>
                  <p>Select system of medicine</p>
                  <img
                    src={downArrow}
                    style={{ cursor: "pointer", width: "15px", height: "15px" }}
                  />
                </div>
              </div>
              <Dialog
                open={isModalOpen}
                style={{ width: "100%", alignItems: "center" }}
              >
                <DialogTitle className="multisymptoms">
                  Select System of Medicine
                </DialogTitle>
                <DialogContent className="medicinecheckbox">
                  {result.type === "MEDICINE" && result.data.length > 0 ? (
                    result.data.map((item, index) => (
                      <FormControlLabel
                        key={index} // Use a unique identifier
                        control={
                          <Checkbox
                            onChange={() =>
                              handleOptionSelect("MEDICINE", item)
                            }
                            value={item.value}
                            checked={selectedOptions.MEDICINE?.includes(
                              item.code
                            )}
                          />
                        }
                        label={item.value} // Use the item's value as the label
                      />
                    ))
                  ) : (
                    <div className="spinningLoader">
                      <CircularProgress size={48} color="inherit" />
                    </div>
                  )}
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleOk}>Cancel</Button>
                  <Button onClick={handleOk}>OK</Button>
                </DialogActions>
              </Dialog>

              {selectedOptions.MEDICINE?.length > 0 && (
                <div style={{ marginTop: "10px", display: "flex", flexWrap: "wrap", gap: "5px" }}>
                  {result.data
                    .filter((item) => selectedOptions.MEDICINE.includes(item.code))
                    .map((item, index) => (
                      <div
                        key={index}
                        style={{
                          background: "#E3EBFF",
                          padding: "7px 10px",
                          borderRadius: "7px",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px"
                        }}
                      >
                        {item.value}
                      </div>
                    ))}
                </div>
              )}

              {/* {selectedOptions.MEDICINE.length > 0 && (
                <div className="selectedItems">
                  {result.data
                    .filter((item) => selectedOptions.MEDICINE.includes(item.code))
                    .map((item, index) => (
                      <div key={index} 
                      style={{
                        background: "#E3EBFF",
                        marginRight: "5px",
                        padding: "7px 1rem",
                        borderRadius: "7px",
                        gap: "10rem",
                      }}>
                        {item.value}
                      </div>
                    ))}
                </div>
              )} */}
            </>

            {facilityTypeList.length > 0 && (
              <div className="facilitytypes">
                <div className="facilityheading">
                  <div style={{ marginLeft: "2%" }}>
                    <h3 style={{ color: "#52447D" }}>
                      Facility Type
                    </h3>
                  </div>
                </div>
                <div className="facilitysubopt">
                  {facilityTypeList.map((subtype, index) => (
                    <p
                      key={index}
                      className="facilityopt"
                      onClick={() => handleSubtypeSelect(subtype, "FACILITYTYPE")}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          selectedOptions["FACILITYTYPE"] === subtype.code
                            ? "#52447D"
                            : "",
                        color:
                          selectedOptions["FACILITYTYPE"] === subtype.code
                            ? "#fff"
                            : "#000",
                      }}
                    >
                      {subtype.value}
                    </p>
                  ))}
                </div>
              </div>
            )}

            {facilitySubTypeList.length > 0 && (
              <div className="facilitytypes">
                <div className="facilityheading">
                  <div style={{ marginLeft: "2%" }}>
                    <h3 style={{ color: "#52447D" }}>
                      Facility Sub Type's
                    </h3>
                  </div>
                </div>
                <div className="facilitysubopt">
                  {facilitySubTypeList.map((subtype, index) => (
                    <p
                      key={index}
                      className="facilityopt"
                      onClick={() => handleSubtypeSelect(subtype, "FACILITYSUBTYPE")}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          selectedOptions["FACILITYSUBTYPE"] === subtype.code
                            ? "#52447D"
                            : "",
                        color:
                          selectedOptions["FACILITYSUBTYPE"] === subtype.code
                            ? "#fff"
                            : "#000",
                      }}
                    >
                      {subtype.value}
                    </p>
                  ))}
                </div>
              </div>
            )}

            {/*{renderSection("FACILITY-TYPE", "Facility Type")}*/}

            {renderSection1("FAC-STATUS", "Facility Status")}

            {/*Type of Services*/}
            <>
              <div>
                <div
                  className="facilityCheckbox"
                  onClick={handleDropdownServices}
                >
                  <p>Type of Services</p>
                  <img
                    src={downArrow}
                    style={{ cursor: "pointer", width: "15px", height: "15px" }}
                  />
                </div>
              </div>
              <Dialog
                open={modalOpen}
                style={{ width: "100%", alignItems: "center" }}
              >
                <DialogTitle className="multisymptoms">
                  Select Type of Services
                </DialogTitle>

                <DialogContent className="medicinecheckbox">
                  {result.type === "TYPE-SERVICE" && result.data.length > 0 ? (
                    result.data.map((item, index) => (
                      <FormControlLabel
                        key={index} // Use index as the key or a unique property if available
                        control={
                          <Checkbox
                            onChange={() =>
                              handleOptionSelect("TYPE-SERVICE", item)
                            } // Pass the item's value to the handler
                            value={item.value} // The value for the checkbox
                            checked={selectedOptions["TYPE-SERVICE"]?.includes(
                              item.code
                            )} // Determine if the checkbox is checked based on the selected options
                          />
                        }
                        label={item.value} // Use the item's value as the label
                      />
                    ))
                  ) : (
                    <div className="spinningLoader">
                      <CircularProgress size={48} color="inherit" />
                    </div>
                  )}
                </DialogContent>

                <DialogActions>
                  <Button
                    onClick={handleOktype}
                  // variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleOktype}
                  // variant="contained"
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              {selectedOptions["TYPE-SERVICE"]?.length > 0 && (
                <div style={{ marginTop: "10px", display: "flex", flexWrap: "wrap", gap: "5px" }}>
                  {result.data
                    .filter((item) => selectedOptions["TYPE-SERVICE"].includes(item.code))
                    .map((item, index) => (
                      <div
                        key={index}
                        style={{
                          background: "#E3EBFF",
                          padding: "7px 10px",
                          borderRadius: "7px",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px"
                        }}
                      >
                        {item.value}
                      </div>
                    ))}
                </div>
              )}
            </>
          </>
        ) : (
          <div className="spinningLoader">
            <CircularProgress size={48} color="inherit" />
          </div>
        )}
      </div>
    </div>
  );
};

export default FacilityInformation;

/////////////////////////////////

// const FacilityInformation = () => {
//   const [facilityData, setFacilityData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [ownerSubTypeList, setOwnerSubTypeList] = useState([]);
//   const [selectedOptions, setSelectedOptions] = useState({
//     OWNER: null,
//     OWNER_SUBTYPE: null,
//     MEDICINE: null,
//     TYPESERVICE: null,
//   });
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [result, setResult] = useState();

//   const UserID = sessionStorage.getItem("RegUserId")
//     ? JSON.parse(sessionStorage.getItem("RegUserId"))
//     : sessionStorage.getItem("UserID");

//   const publicKey = sessionStorage.getItem("RegpublicKey")
//     ? sessionStorage.getItem("RegpublicKey")
//     : JSON.parse(sessionStorage.getItem("publicKey"));

//   const token = sessionStorage.getItem("Regtoken")
//     ? sessionStorage.getItem("Regtoken")
//     : JSON.parse(sessionStorage.getItem("token"));

//   const padding = constants.RSA_PKCS1_PADDING;
//   const data = Buffer.from(UserID.toString(), "utf-8");
//   const encrypted = publicEncrypt(
//     { key: publicKey, padding: padding },
//     data
//   ).toString("base64");

//   useEffect(() => {
//     // Fetch facility data for "OWNER" type first
//     gettypes("OWNER");
//     gettypes("FAC-STATUS");
//   }, []);

//   const gettypes = async (type) => {
//     try {
//       const options = {
//         method: "POST",
//         url: `${config.abhaBaseUrl}v3/getHfrMasterData/${type}`,
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "multipart/form-data",
//           "x-caller": await encrypted,
//         },
//       };
//       const response = await Axios(options);
//       if (response.status === 200) {
//         setResult(response.data);
//         console.log(response.data, `getHfrMasterData/${type}`);
//         const { type: facilityType, data: facilityOptions } = response.data;
//         setFacilityData((prevState) => ({
//           ...prevState,
//           [facilityType]: facilityOptions,
//         }));
//         setLoading(false); // After data is fetched, stop loading
//         // if (facilityType === "FAC-STATUS") {
//         //     setFacStatusOptions(facilityOptions);
//         //   }
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const getFacilityOwnershipSubtype = async (selectedOwnershipCode) => {
//     try {
//       const options = {
//         method: "POST",
//         url: `${config.abhaBaseUrl}v3/hfrOwnerSubType`,
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//           "x-caller": await encrypted,
//         },
//         data: {
//           ownershipCode: selectedOwnershipCode,
//           ownerSubtypeCode: "",
//         },
//       };
//       const response = await Axios(options);

//       if (response.status === 200) {
//         console.log(response.data, "hfrOwnerSubType");
//         const facilities = response.data?.data?.data || [];
//         const subtypes = facilities.map((item) => ({
//           value: item.value,
//           code: item.code, // This is where we store 'C', 'P', or 'NP'
//         }));
//         setOwnerSubTypeList(subtypes);
//       } else if (response.status === 404) {
//         console.log("No data found for the given facility type.");
//       } else {
//         console.error("Error: ", response.body);
//       }
//     } catch (error) {
//       console.error("Exception caught: ", error);
//     }
//   };

//   const getFacilitytype = async (medicineCodes) => {
//     console.log("getFacilitytypegetFacilitytype", medicineCodes);
//     try {
//       const options = {
//         method: "POST",
//         url: `${config.abhaBaseUrl}v3/hfrFacilityType`,
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//           "x-caller": await encrypted,
//         },
//         data: {
//           ownershipCode: "G",
//           systemofMedicineCode: medicineCodes,
//         },
//       };
//       const response = await Axios(options);

//       if (response.status === 200) {
//         console.log(response.data, "FacilityType..........");
//         const facilities = response.data?.data?.data || [];
//         const subtypes = facilities.map((item) => ({
//           value: item.value,
//           code: item.code,
//         }));
//         setOwnerSubTypeList(subtypes);
//       } else if (response.status === 404) {
//         console.log("No data found for the given facility type.");
//       } else {
//         console.error("Error: ", response.body);
//       }
//     } catch (error) {
//       console.error("Exception caught: ", error);
//     }
//   };
//   const getFacilitysubtype = async (selectedFacilityCode) => {
//     console.log("getFacilitytypegetFacilitytype", selectedFacilityCode);
//     try {
//       const options = {
//         method: "POST",
//         url: `${config.abhaBaseUrl}v3/hfrFacilitySubType`,
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//           "x-caller": await encrypted,
//         },
//         data: {
//           facilitySubType: selectedFacilityCode,
//         },
//       };
//       const response = await Axios(options);

//       if (response.status === 200) {
//         console.log(response.data, "FacilityType..........");
//         const facilities = response.data?.data?.data || [];
//         const subtypes = facilities.map((item) => ({
//           value: item.value,
//           code: item.code,
//         }));
//         setOwnerSubTypeList(subtypes);
//       } else if (response.status === 404) {
//         console.log("No data found for the given facility type.");
//       } else {
//         console.error("Error: ", response.body);
//       }
//     } catch (error) {
//       console.error("Exception caught: ", error);
//     }
//   };

//   const handleOptionSelect = (type, option) => {
//     setSelectedOptions((prevState) => {
//       let updatedState = { ...prevState };

//       if (type === "MEDICINE") {
//         const isSelected = updatedState.MEDICINE?.includes(option.code);

//         // If already selected, remove it
//         if (isSelected) {
//           updatedState.MEDICINE = updatedState.MEDICINE.filter(
//             (code) => code !== option.code
//           );
//         } else {
//           // Otherwise, add it
//           updatedState.MEDICINE = updatedState.MEDICINE
//             ? [...updatedState.MEDICINE, option.code]
//             : [option.code]; // Initialize as array if empty
//         }

//         // After updating the state, check for MEDICINE selection
//         if (updatedState.MEDICINE?.length > 0) {
//           const medicineCodes = updatedState.MEDICINE.join(",");
//           console.log(medicineCodes, "medicineCodes...");
//           getFacilitytype(medicineCodes); // Call the API after updating the state
//         }
//       } else if (type === "TYPE-SERVICE") {
//         // Handle TYPE-SERVICE selection
//         const isSelected = updatedState["TYPE-SERVICE"]?.includes(option.code);

//         // If already selected, remove it
//         if (isSelected) {
//           updatedState["TYPE-SERVICE"] = updatedState["TYPE-SERVICE"].filter(
//             (code) => code !== option.code
//           );
//         } else {
//           // Otherwise, add it
//           updatedState["TYPE-SERVICE"] = updatedState["TYPE-SERVICE"]
//             ? [...updatedState["TYPE-SERVICE"], option.code]
//             : [option.code]; // Initialize as array if empty
//         }
//       } else {
//         // For other types, just toggle between null and the option
//         updatedState[type] =
//           updatedState[type]?.value === option.value ? null : option;
//       }

//       console.log("Updated Selected Options:", updatedState);

//       // Return the updated state
//       return updatedState;
//     });

//     // Trigger the ownership subtype function if "OWNER" is selected
//     if (type === "OWNER") {
//       getFacilityOwnershipSubtype(option.code);
//     }
//   };

//   const handleDropdownServices = () => {
//     gettypes("TYPE-SERVICE");
//     setModalOpen(!modalOpen);
//   };

//   const handleSubtypeSelect = (subtype) => {
//     setSelectedOptions((prevState) => ({
//       ...prevState,
//       OWNER_SUBTYPE: subtype.code,
//     }));

//     if (subtype.code === "C") {
//       gettypes("CENTRAL-GOVERNMENT");
//     } else if (subtype.code === "P") {
//       gettypes("PROFIT-TYPE");
//     } else if (subtype.code === "NP") {
//       gettypes("NON-PROFIT-TYPE");
//     }
//   };

//   const handleOk = () => {
//     setIsModalOpen(false);
//   };
//   const handleOktype = () => {
//     setModalOpen(false);
//   };

//   const handleDropdownClick = () => {
//     gettypes("MEDICINE");
//     console.log(result, "result Medicine..........");
//     setIsModalOpen(!isModalOpen); // Open the modal
//   };

//   // Utility function to render the options for a specific type
//   const renderOptions = (type) => {
//     if (!facilityData[type]) return null;
//     return facilityData[type].map((option, index) => (
//       <p
//         className="facilityopt"
//         key={index}
//         onClick={() => handleOptionSelect(type, option)}
//         style={{
//           cursor: "pointer",
//           backgroundColor:
//             selectedOptions[type]?.value === option.value ? "#52447D" : "",
//           color:
//             selectedOptions[type]?.value === option.value ? "#fff" : "#000",
//         }}
//       >
//         {option.value}
//       </p>
//     ));
//   };

//   // Utility function to render a section based on a type
//   const renderSection = (type, title) => {
//     return (
//       <div className="facilitytypes" key={type}>
//         <div className="facilityheading">
//           <div style={{ marginLeft: "2%" }}>
//             <h3 style={{ color: "#52447D" }}>{title}</h3>
//           </div>
//         </div>
//         <div className="facilitysubopt">{renderOptions(type)}</div>
//       </div>
//     );
//   };

//   return (
//     <div className="facilityFormContainer">
//       <div className="addp-patientsHeading">
//         <h2>Create Facility</h2>
//       </div>
//       <div style={{ padding: "2%" }}>
//         {!loading ? (
//           <>
//             {/* Render "OWNER" section first */}
//             {renderSection("OWNER", "Facility Ownership")}

//             {/* Render subtypes dynamically based on the selected ownership */}
//             {ownerSubTypeList.length > 0 && (
//               <div className="facilitytypes">
//                 <div className="facilityheading">
//                   <div style={{ marginLeft: "2%" }}>
//                     <h3 style={{ color: "#52447D" }}>
//                       Facility Ownership Subtype
//                     </h3>
//                   </div>
//                 </div>
//                 <div className="facilitysubopt">
//                   {ownerSubTypeList.map((subtype, index) => (
//                     <p
//                       key={index}
//                       className="facilityopt"
//                       onClick={() => handleSubtypeSelect(subtype)}
//                       style={{
//                         cursor: "pointer",
//                         backgroundColor:
//                           selectedOptions["OWNER_SUBTYPE"] === subtype.code
//                             ? "#52447D"
//                             : "",
//                         color:
//                           selectedOptions["OWNER_SUBTYPE"] === subtype.code
//                             ? "#fff"
//                             : "#000",
//                       }}
//                     >
//                       {subtype.value}
//                     </p>
//                   ))}
//                 </div>
//               </div>
//             )}

//             {/* Render only one component based on the selected ownership subtype */}
//             {selectedOptions.OWNER_SUBTYPE === "C" &&
//               renderSection("CENTRAL-GOVERNMENT", "Central Government")}
//             {selectedOptions.OWNER_SUBTYPE === "P" &&
//               renderSection("PROFIT-TYPE", "Profit")}
//             {selectedOptions.OWNER_SUBTYPE === "NP" &&
//               renderSection("NON-PROFIT-TYPE", "Non For Profit")}
//             <>
//               <div>
//                 <div className="facilityCheckbox" onClick={handleDropdownClick}>
//                   <p>Select system of medicine</p>
//                   <img
//                     src={downArrow}
//                     style={{ cursor: "pointer", width: "15px", height: "15px" }}
//                   />
//                 </div>
//               </div>
//               <Dialog
//                 open={isModalOpen}
//                 style={{ width: "100%", alignItems: "center" }}
//               >
//                 <DialogTitle className="multisymptoms">
//                   Select System of Medicine
//                 </DialogTitle>
//                 <DialogContent className="medicinecheckbox">
//                   {result.type === "MEDICINE" && result.data.length > 0 ? (
//                     result.data.map((item, index) => (
//                       <FormControlLabel
//                         key={index} // Use a unique identifier
//                         control={
//                           <Checkbox
//                             onChange={() =>
//                               handleOptionSelect("MEDICINE", item)
//                             }
//                             value={item.value}
//                             checked={selectedOptions.MEDICINE?.includes(
//                               item.code
//                             )}
//                           />
//                         }
//                         label={item.value} // Use the item's value as the label
//                       />
//                     ))
//                   ) : (
//                     <div className="spinningLoader">
//                       <CircularProgress size={48} color="inherit" />
//                     </div>
//                   )}
//                 </DialogContent>

//                 <DialogActions>
//                   <Button onClick={handleOk}>Cancel</Button>
//                   <Button onClick={handleOk}>OK</Button>
//                 </DialogActions>
//               </Dialog>
//             </>

//             {/*{renderSection("FACILITY-TYPE", "Facility Type")}*/}

//             {renderSection("FAC-STATUS", "Facility Status")}

//             {/*Type of Services*/}
//             <>
//               <div>
//                 <div
//                   className="facilityCheckbox"
//                   onClick={handleDropdownServices}
//                 >
//                   <p>Type of Services</p>
//                   <img
//                     src={downArrow}
//                     style={{ cursor: "pointer", width: "15px", height: "15px" }}
//                   />
//                 </div>
//               </div>
//               <Dialog
//                 open={modalOpen}
//                 style={{ width: "100%", alignItems: "center" }}
//               >
//                 <DialogTitle className="multisymptoms">
//                   Select Type of Services
//                 </DialogTitle>

//                 <DialogContent className="medicinecheckbox">
//                   {result.type === "TYPE-SERVICE" && result.data.length > 0 ? (
//                     result.data.map((item, index) => (
//                       <FormControlLabel
//                         key={index} // Use index as the key or a unique property if available
//                         control={
//                           <Checkbox
//                             onChange={() =>
//                               handleOptionSelect("TYPE-SERVICE", item)
//                             } // Pass the item's value to the handler
//                             value={item.value} // The value for the checkbox
//                             checked={selectedOptions["TYPE-SERVICE"]?.includes(
//                               item.code
//                             )} // Determine if the checkbox is checked based on the selected options
//                           />
//                         }
//                         label={item.value} // Use the item's value as the label
//                       />
//                     ))
//                   ) : (
//                     <div className="spinningLoader">
//                       <CircularProgress size={48} color="inherit" />
//                     </div>
//                   )}
//                 </DialogContent>

//                 <DialogActions>
//                   <Button
//                     onClick={handleOktype}
//                     // variant="contained"
//                   >
//                     Cancel
//                   </Button>
//                   <Button
//                     onClick={handleOktype}
//                     // variant="contained"
//                   >
//                     OK
//                   </Button>
//                 </DialogActions>
//               </Dialog>
//             </>
//           </>
//         ) : (
//           <div className="spinningLoader">
//             <CircularProgress size={48} color="inherit" />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default FacilityInformation;

