import React, { useEffect, useState } from "react";
import Navbar from "../components/modules/Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import axios from "axios";
import config from "../Config/config";

import {
    Snackbar,
    Button,
    CircularProgress,
    Alert,
    colors,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";


const Demographics = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const skipRoute = queryParams.get("action");
    const source = queryParams.get("source"); // Extract source
    const userDetails = JSON.parse(sessionStorage.getItem("UserData"))[0];
    const UserID = sessionStorage.getItem("RegUserId")
        ? JSON.parse(sessionStorage.getItem("RegUserId"))
        : sessionStorage.getItem("UserID");

    const publicKey = sessionStorage.getItem("RegpublicKey")
        ? sessionStorage.getItem("RegpublicKey")
        : JSON.parse(sessionStorage.getItem("publicKey"));

    const token = sessionStorage.getItem("Regtoken")
        ? sessionStorage.getItem("Regtoken")
        : JSON.parse(sessionStorage.getItem("token"));

    const PATIENT_ID = sessionStorage.getItem("PATIENT_ID");

    const padding = constants.RSA_PKCS1_PADDING;
    const data = Buffer.from(UserID.toString(), "utf-8");
    const encrypted = publicEncrypt(
        { key: publicKey, padding: padding },
        data
    ).toString("base64");
    const [pngCard, setPngCard] = useState();
    const [qrcode, setqrcode] = useState();
    const [showLoading, setShowLoading] = useState(false);
    console.log("Navigated from:", source);
    const [variables, setVariables] = useState({
        showAlert: false,
        alertTitle: "",
        alertMessage: "",
    });


    // const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));

    const profile_data = sessionStorage.getItem('profileData');
    const abhaaddressnew = sessionStorage.getItem('newabhaaddress');
    const profileData = profile_data ? JSON.parse(profile_data) : null
    console.log("profile data", profileData);
    // console.log("profilephoto", profileData.profilePhoto);

    const navigate = useNavigate();

    const handleContinue = () => {
        if (skipRoute !== "linkabha") {
            navigate('/patients/add-patients?action=abhatoaddpatient')
        } else (
            uploadAbhaData()
            // navigate('/patients')
        )
    }

    const uploadAbhaData = async () => {
        const abhaNumber = sessionStorage.getItem("profileDataabhanumber");
        const abhaAddress = sessionStorage.getItem("profileDataabhaaddress");
        // console.log("abhadata", abhaData, JSON.stringify(abhaData));
        const body = {
            USER_ID: UserID,
            PATIENT_ID: PATIENT_ID,
            ABHA_NUMBER: abhaNumber,
            ABHA_ADDRESS: abhaAddress,
        };
        console.log("++++++++++", body);
        const response = await axios.post(`${config.apiURL}/me/ABHA_DATA`, body, {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
                "x-caller": await encrypted,
            },
        });

        //console.log("response in uplaod abha data", response);
        if (response.status === 201) {
            console.log("made abha card null successfully");
            sessionStorage.removeItem("profileDataabhanumber");
            sessionStorage.removeItem("profileDataabhaaddress");
            sessionStorage.removeItem('newabhaaddress');
            toast.success("Abha linked successfully");
            navigate('/patients');
        } else if (response.status === 404) {
            setVariables((prevState) => ({
                ...prevState,
                showAlert: true,
                alertTitle: "Sorry!!!",
                alertMessage:
                    "ABHA Data is not linked with the Profile. Please submit again.",
            }));
        } else {
            setVariables((prevState) => ({
                ...prevState,
                showAlert: true,
                alertTitle: "Error!!!",
                alertMessage:
                    "An unknown error occurred.Please try again after sometime.",
            }));
        }
    };

    const getAuthorizationToken = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "REQUEST-ID": crypto.randomUUID(),
                "TIMESTAMP": new Date().toISOString(),
                "X-CM-ID": "sbx",
            };

            const databody = {
                clientId: "SBX_001741",
                clientSecret: "3f1141e5-7924-4fb4-b83a-7b2d5102c526",
                grantType: "client_credentials"
            };

            console.log("Headers:", headers);

            const url = "https://dev.abdm.gov.in/api/hiecm/gateway/v3/sessions";

            const response = await axios.post(url, databody, { headers });

            console.log("Response: of public url", response);

            if (response.status === 202) {
                const token = response.data.accessToken;
                sessionStorage.setItem("transactionId", token);
                return token;
            } else {
                console.log("Authorization error");
                return null;
            }
        } catch (error) {
            console.error("Error fetching authorization token:", error);
            console.error("Error details:", error.response?.data);
            return null;
        }
    };

    // abha v3 call working

    // const getPNGCard = async () => {

    //     try {
    //         const token = await getAuthorizationToken();
    //         console.log("hie token is here", token);

    //         const XToken = sessionStorage.getItem("aadharverifytoken");
    //         // const XToken = sessionStorage.getItem("verifytoken").replace(/^"|"$/g, '');
    //         console.log("xtoken response", XToken);

    //         const headers = {
    //             "REQUEST-ID": crypto.randomUUID(),
    //             "TIMESTAMP": new Date().toISOString(),
    //             "Authorization": `Bearer ${token}`,
    //             "X-token": `Bearer ${XToken}`,
    //             // "X-Token": XToken,
    //         };

    //         console.log("Headers:", headers);

    // const response = await axios.get(
    //     "https://abhasbx.abdm.gov.in/abha/api/v3/profile/account/abha-card",
    //     { headers, responseType: "arraybuffer" }
    // );

    //         // const response = await axios.get(`https://abhasbx.abdm.gov.in/abha/api/v3/phr/web/login/profile/abha/phr-card`, {
    //         //     headers,
    //         //     responseType: "arraybuffer",
    //         // });

    //         console.log("Response:", response);
    //         console.log("Response status of png card:", response.status);

    //         const imageBlob = new Blob([response.data], { type: "image/png" });
    //         const imageUrl = URL.createObjectURL(imageBlob);
    //         console.log("Image URL:", imageUrl);

    //         return response.status === 202 ? new Uint8Array(response.data) : new Uint8Array(0);
    //     } catch (error) {
    //         console.error("Error fetching PNG:", error);
    //         console.error("Error details:", error.response?.data);
    //         return new Uint8Array(0);
    //     }
    // };


    // displaying card for abha address flow

    const getPNGCard1 = async () => {
        setShowLoading(true);
        try {
            const token = await getAuthorizationToken();
            console.log("Token is here:", token);

            // const XToken = sessionStorage.getItem("aadharverifytoken");
            let XToken = sessionStorage.getItem("abhanumberverifytoken");

            // Remove surrounding quotes and slashes if they exist
            XToken = XToken.replace(/^["/]+|["/]+$/g, '');
            console.log("XToken before processing:", XToken);
            console.log("Navigated from:", source);

            const hi = new Date().toISOString();
            console.log("hiiii", hi);

            const headers = {
                "request-id": crypto.randomUUID(),
                "authorization": `Bearer ${token}`,
                "x-token": `Bearer ${XToken}`,
            };

            console.log("Headers:", headers);

            let response;

            response = await axios.get(`${config.abhaBaseUrl}phr-abha-card`, {
                headers,
                responseType: "arraybuffer",
            });

            console.log("Response status:", response.status);
            console.log("Response bodybites:", response.data);

            const base64String = btoa(
                new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
            );
            const imageSrc = `data:image/png;base64,${base64String}`;
            sessionStorage.setItem("ABHA_CARD", base64String);
            setPngCard(imageSrc);
            setShowLoading(false);
        } catch (error) {
            console.error("Error fetching PNG:", error);
            setShowLoading(false);
            return null;
        }
    };

    const handlePngClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const abhaaddress = sessionStorage.getItem('abhaaddress');
        console.log("abhaaddress from sessionStorage:", abhaaddress);

        if (abhaaddress && abhaaddress.trim() !== "") {
            console.log("Calling getPNGCard()");
            getPNGCard1();
            // getqrcode();
        } else {
            console.log("Calling getPngcard1()");
            getPNGCard();
            // getqrcode(); [working call for displaying qr code]
        }
    };


    // working call for diasplaying card from create abha
    const getPNGCard = async () => {
        setShowLoading(true);
        const abhaaddress = sessionStorage.getItem('abhaaddress');
        console.log("abhaddress", abhaaddress);
        try {
            const token = await getAuthorizationToken();
            console.log("Token is here:", token);

            // const XToken = sessionStorage.getItem("aadharverifytoken");
            let XToken = sessionStorage.getItem("aadharverifytoken");

            // Remove surrounding quotes and slashes if they exist
            XToken = XToken.replace(/^["/]+|["/]+$/g, '');
            console.log("XToken before processing:", XToken);
            console.log("Navigated from:", source);
            console.log("XToken response:", XToken);

            const headers = {
                "request-id": crypto.randomUUID(),
                "authorization": `Bearer ${token}`,
                "x-token": `Bearer ${XToken}`,
            };

            console.log("Headers:", headers);

            const response = await axios.get(`${config.abhaBaseUrl}abha-card`, {
                headers,
                responseType: "arraybuffer",
            });

            console.log("Response:", response);
            console.log("Response status:", response.status);
            console.log("Response bodybites:", response.data);
            const base64String = btoa(
                new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
            );

            const imageSrc = `data:image/png;base64,${base64String}`;
            sessionStorage.setItem("ABHA_CARD", base64String);
            setPngCard(imageSrc);
            setShowLoading(false);
        } catch (error) {
            console.error("Error fetching PNG:", error);
            return null;
            setShowLoading(false);
        }
    };

    const getqrcode = async () => {
        setShowLoading(true);
        const abhaaddress = sessionStorage.getItem('abhaaddress');
        console.log("abhaddress", abhaaddress);
        try {
            const token = await getAuthorizationToken();
            console.log("qr is here:", token);

            // const XToken = sessionStorage.getItem("aadharverifytoken");
            let XToken = sessionStorage.getItem("aadharverifytoken");

            // Remove surrounding quotes and slashes if they exist
            XToken = XToken.replace(/^["/]+|["/]+$/g, '');
            console.log("XToken before processing:", XToken);
            console.log("Navigated from:", source);
            console.log("XToken response:", XToken);

            const headers = {
                "request-id": crypto.randomUUID(),
                "authorization": `Bearer ${token}`,
                "x-token": `Bearer ${XToken}`,
            };

            console.log("Headers:", headers);

            const response = await axios.get(`${config.abhaBaseUrl}qr-code`, {
                headers,
                responseType: "arraybuffer",
            });

            console.log("Response:", response);
            console.log("Response status:", response.status);

            const base64String = btoa(
                new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
            );
            const qrimageSrc = `data:image/png;base64,${base64String}`;
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const pdfBlob = new Blob([byteArray], { type: "application/pdf" });

            sessionStorage.setItem("ABHA_QR", base64String);
            sessionStorage.setItem("ABHA_QR_BLOB", URL.createObjectURL(pdfBlob));
            setqrcode(qrimageSrc);
            setShowLoading(false);
        } catch (error) {
            console.error("Error fetching PNG:", error);
            return null;
        }
    };

    // call
    // const getPNGCard = async () => {
    //     var accessToken = (await sessionStorage.getItem("accessToken")) ?? "";
    //     // var token = Token ? Token : sessionStorage.getItem("loginData") ?? "";
    //     const XToken = sessionStorage.getItem("aadharverifytoken");
    //     // const XToken = sessionStorage.getItem("verifyytoken");

    //     console.log("tokennnnnnnn", XToken);

    //     console.log("hie")
    //     const payload = {
    //         // accessToken: accessToken,
    //         refreshToken: XToken,
    //         // USER_ID: UserID,
    //     };
    //     const response = await axios.post(`${config.abhaBaseUrl}v3/getPHRCard`, payload, {
    //         headers: {
    //             "x-client-token": config.token,
    //             "content-type": "application/json",
    //         },
    //     });
    //     console.log("response of the status", response);

    //     if (response.status === 200) {
    //         let base64ImageString = Buffer.from(response.data, "utf8").toString(
    //             "base64"
    //         );
    //         setPNGCard(base64ImageString);
    //     }
    //     else {
    //     }
    // };

    const TabsContainer = () => {
        return (
            <div
                style={{
                    display: "flex",
                    backgroundColor: "#F5F5F5",
                    width: "80vw",
                    flexDirection: "column",
                    gap: "1rem",
                    overflow: "auto",
                    // overflow: "scroll",
                }}
            >
                <div
                    style={{
                        // backgroundColor: "#8873C9",
                        background: "#52447d",
                        display: "flex",
                        position: "sticky",
                        // flexDirection:'row'
                    }}
                >

                    <button className="review" style={{ color: "white" }}>ABHA DEMOGRAPHICS</button>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        // justifyContent: "space-between",
                        padding: "1rem",
                        gap: "10rem",
                    }}
                >
                    <div>
                        {/* <div className="patient_data_text">PATIENT DETAILS</div> */}
                        <div
                            style={{
                                display: "flex",
                                alignContent: "center",
                                flexDirection: "column",
                            }}
                        >
                            <p>
                                Please verify the details before continuing registration
                            </p>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1.3rem' }}>
                    <div style={{ background: 'white', width: '60%', padding: '2%' }}>
                        ABHA NUMBER : {profileData.healthIdNumber || profileData.ABHANumber || profileData.abhaNumber}
                    </div>
                    <div style={{ background: 'white', width: '60%', padding: '2%' }}>
                        ABHA ADDRESS : {profileData.healthId || profileData.preferredAbhaAddress || abhaaddressnew || profileData.abhaAddress}
                    </div>
                    <div style={{ background: 'white', width: '60%', padding: '2%' }}>
                        NAME :  {profileData.name || profileData.fullName || `${profileData.firstName} ${profileData.lastName}`}
                    </div>
                    <div style={{ background: 'white', width: '60%', padding: '2%', }}>
                        DATE OF BIRTH :
                        {profileData.dayOfBirth || profileData.monthOfBirth || profileData.yearOfBirth
                            ? `${profileData.dayOfBirth}/${profileData.monthOfBirth}/${profileData.yearOfBirth}`
                            : profileData.dob}
                        {/* DATE OF BIRTH :  {`${profileData.dayOfBirth}/${profileData.monthOfBirth}/${profileData.yearOfBirth}` || profileData.dob} */}
                    </div>
                    <div style={{ background: 'white', width: '60%', padding: '2%' }}>
                        ADDRESS :  {profileData.address
                        }
                    </div>
                    <div style={{ background: 'white', width: '60%', padding: '2%' }}>
                        STATE :   {profileData.stateName}
                    </div>
                    <div style={{ background: 'white', width: '60%', padding: '2%' }}>
                        GENDER :  {profileData.gender === 'M' ? 'Male' : 'Female'}
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                        className="addAppointmentsBtn"
                        style={{
                            Background: "#52447d !important",
                            boxShadow: "0px 3px 16px 1px #52447D75",
                        }}
                        onClick={handlePngClick}
                    // onClick={() => getPNGCard()}
                    >
                        {showLoading && <CircularProgress size={24} color="inherit" />}
                        Continue
                    </button>

                </div>
            </div >
        );
    };

    // const Abhacard = ({pngCard }) => {
    //     return (
    //         <div className="details">
    //             {" "}
    //             {/* {console.log(pngCard, "pngCard")} */}
    //             {/* <img
    //           id="pngCardImg"
    //           src={`data:image/png;base64,${pngCard}`}
    //           alt="Fetched Image"
    //           style={{ maxWidth: "100%", height: "auto" }}
    //         /> */}
    //             {/* {pngCard && <ImageComponent base64String={pngCard} />} */}
    //             <img
    //                 id="pngCardImg"
    //                 src={pngCard}
    //                 style={{
    //                     width: 482,
    //                     height: 308,
    //                     background: "none",
    //                     objectFit: "cover",
    //                 }}
    //                 alt="ABHA Card"
    //             />
    //             {/* <ImageFromByteArray binaryString={pngCard}/> */}
    //         </div>
    //     );
    // };

    const Abhacard = ({ pngCard, qrcode }) => {
        const handleDownload = () => {
            const link = document.createElement("a");
            link.href = pngCard;
            link.download = "ABHA_Card.png"; // Set download filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        return (
            <div className="details">
                <div
                    style={{
                        height: "90%",
                        width: "90%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    {/* PNG Image - Left Half */}
                    <img
                        src={pngCard}
                        style={{
                            width: "80%",
                            height: "80%",
                            marginLeft: "20%",
                            objectFit: "cover",
                        }}
                        alt="ABHA Card"
                    />

                    {/* QR Code - Right Half (Only if qrcode exists) */}
                    {qrcode && (
                        <img
                            src={qrcode}
                            style={{
                                width: "30%",
                                height: "100%",
                                marginLeft: "15%",
                                objectFit: "contain",
                            }}
                            alt="QR Code"
                        />
                    )}
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                    <button
                        className={"editButton2"}
                        style={{
                            marginRight: "10px",
                            width: "20%",
                            margin: "0 auto",
                            height: "40px",
                            color: "white",
                        }}
                        onClick={handleDownload}
                    >
                        {showLoading && <CircularProgress size={24} color="inherit" />}
                        Download
                    </button>
                    <button
                        className={"editButton2"}
                        style={{
                            marginRight: "10px",
                            width: "20%",
                            margin: "0 auto",
                            height: "40px",
                            color: "white",
                        }}
                        onClick={handleContinue}
                    >
                        {showLoading && <CircularProgress size={24} color="inherit" />}
                        Next
                    </button>
                </div>
                <ToastContainer />
            </div>
        );
    };

    return (
        <>
            {/* <Navbar /> */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "86vh",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "70vh",
                        overflow: "scroll",
                        gap: "1rem",
                    }}
                >
                    {!pngCard && !qrcode ?
                        <TabsContainer />
                        : (
                            <Abhacard pngCard={pngCard} qrcode={qrcode} />
                            // <Abhacard />
                        )}
                </div>
                <ToastContainer />
            </div>
        </>
    );
};

export default Demographics;
