import React, { useState, useEffect } from "react";
import backgroundCircle from "../../../images/backgroundCircle.png";
import Navbar from "../Navbar";
import { publicEncrypt, constants } from "crypto-browserify";
import "./ABHA.css";
import { Buffer } from "buffer";
import Config from "../../../Config/config";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import NHA from "../../../images/nha.svg";
import { encode as base64Encode } from 'base-64';
import utf8 from 'utf8';
import CloseIcon from "@mui/icons-material/Close";

import {
  Snackbar,
  Button,
  CircularProgress,
  Alert,
  colors,
} from "@mui/material";
import OtpInput from "react-otp-input";
import AbhaMobileValidation from "./AbhaMobileValidation";
import AbhaAddressSetUpPage from "./Abha_Address_SetUp";
import JSEncrypt from "jsencrypt";
import { ViewSidebar } from "@mui/icons-material";

const ABHAIntegration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const skipRoute = queryParams.get("action");

  const [selectedOption, setSelectedOption] = useState("alreadyRegistered");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [mobileOtpButton, setMobileOtpButton] = useState(false); //MOBILE OTP BUTTON AFTER INTEGRATE
  const [aadhaarOtpButton, setAadharOtpButton] = useState(false); //AADHAAR OTP BUTTON AFTER INTEGRATE
  const [activeSection, setActiveSection] = useState("verifyotp");
  const [PHRPublicKeystore, setPHRPublicKeystore] = useState("");
  const [timer, setTimer] = useState(30);
  const [disabled, setDisabled] = useState(true);


  const [variables, setVariables] = useState({
    abhaNo: "",
    forgotaadharnum: "",
    captcha: "",
    aadhaarNumber: "",
    mobilenumber: "",
    showAlert: false,
    alertTitle: "",
    alertMessage: "",
    abhanumber: "",
    abhaname: "",
    abhaAddress: "",
    type: "",
    formType: 0,
    otp: "",
    forgototp: "",
    phrMobile: "",
  });
  const [captchaCode, setCaptchaCode] = useState("");


  const [visible, setVisible] = useState({
    mobileOtp: false,
    verifyforgetotp: false,
    aadhaarOtp: false,
    aadharNoOtp: false, // for register screen
    getabhadetails: false,
    abhadetails: false,
    loading: null,
    MobileValidationScreen: false,
    mobileOtpValidationForMobileScreen: false,
  });


  const generateCaptcha = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#%^&*()abcdefghijklmnop';
    const random = Math.floor(Math.random() * characters.length);
    const newCaptcha = Array.from({ length: 6 }, () =>
      characters.charAt(Math.floor(Math.random() * characters.length))
    ).join('');
    setCaptchaCode(newCaptcha);
  };

  // Generate CAPTCHA on component mount
  useEffect(() => {
    generateCaptcha();
  }, []);

  // Handle OTP change
  const handleChange = (otp) => {
    setVariables((prevState) => ({
      ...prevState,
      otp: otp,
      forgototp: otp,
    }));
  };

  const userDetails = sessionStorage.getItem("UserData")
    ? JSON.parse(sessionStorage.getItem("UserData"))[0]
    : "";

  //api call data
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");
  //

  // useEffect(() => {
  //   getPublickey();
  // }, [UserID]
  // );



  const PATIENT_ID = sessionStorage.getItem("PATIENT_ID");

  const onActiveSectionChange = (activeSection) => {
    setActiveSection(activeSection);
  };

  const ShowAlert = ({ title, message, onClose }) => {
    return (
      <div className="popup">
        <div className="popup-inner">
          <p
            style={{ fontSize: "24px", fontWeight: "600", fontFamily: "bold" }}
          >
            {title}
          </p>
          <p>{message}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2rem",
              padding: "4%",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="ContinueBtn"
              onClick={
                variables.type === "uploadAbhaData"
                  ? () => handleNavigateToDemographics("Already Have ABHA")
                  : onClose
              }
              style={{
                background: "#52447d",
                width: "20%",
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleNavigateToDemographics = (source) => {
    navigate(`/abha/demographics?action=${skipRoute}&source=${source}`);
  };

  const handleAlertClose = () => {
    setVariables((prevState) => ({
      ...prevState,
      showAlert: false,
    }));
  };

  const getPublickey = async () => {
    // console.log(UserID,"jdified",token)
    Axios.get(`${Config.abhaBaseUrl}publicKey/${UserID}`, {
      headers: {
        authorization: `Bearer ${token}`,
        "x-caller": await encrypted,
      },
    })
      .then((response) => {
        // console.log("getPublickey response :", response);
        if (response.status == 200) {
          // console.log("PUBLICKEY :", response.data);
          sessionStorage.setItem("ABDMPublicKey", response.data);
          return response;
        }
      })
      .catch((err) => {
        console.log(`${err} Please reload the page and try again`);
      });
  };

  // const getSessionTokenAPI = async () => {
  //   if (variables.abhaNo.trim() !== "") {
  //     setShowLoading(true);

  //     const dataBody = {
  //       healthId: variables.abhaNo,
  //       USER_ID: UserID,
  //     };
  //     //console.log("Databody", dataBody, encrypted)

  //     await Axios.post(`${Config.abhaBaseUrl}getSessionToken`, dataBody, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: `Bearer ${token}`,
  //         "x-caller": encrypted,
  //       },
  //     })

  //       .then((response) => {
  //         sessionStorage.setItem("abhaAddress", dataBody.healthId);
  //         if (response.status === 422) {
  //           // console.log("response:", response);
  //         } else if (response.status === 200) {
  //           sessionStorage.setItem("accessToken", response.data.accessToken);
  //           setShowLoading(false);
  //           setMobileOtpButton(true);
  //           setAadharOtpButton(true);
  //         }
  //       })
  //       .catch(async (error) => {
  //         console.log(error);
  //         setShowLoading(false);
  //         setSnackbarMessage(
  //           "An error occurred.Please try again after sometime"
  //         );
  //         setShowSnackbar(true);
  //       });
  //   } else {
  //     setSnackbarMessage("Please enter a valid value");
  //     setShowSnackbar(true);
  //   }
  // };

  // Add a new function to enable buttons without API call

  const enableOtpButtons = () => {
    // if (variables.abhaNo.length == 14 || variables.abhaNo.includes("@sbx")) {
    const isNumericAbha = /^\d{14}$/.test(variables.abhaNo);
    // Check if abhaNo contains "@sbx"
    const isAbhaAddress = variables.abhaNo.includes("@sbx");

    if (isNumericAbha || isAbhaAddress) {
      console.log("ABHA Number:", variables.abhaNo);
      if (variables.abhaNo) {
        sessionStorage.removeItem("abhaaddress");
      }

      if (!isNaN(variables.abhaNo)) {
        console.log("Storing ABHA Number:", variables.abhaNo);
        sessionStorage.setItem("abhaNo", variables.abhaNo);
      } else if (variables.abhaNo.includes("@sbx")) {
        console.log("Storing ABHA Address:", variables.abhaNo);
        sessionStorage.setItem("abhaaddress", variables.abhaNo);
      }

      console.log("Enabling OTP buttons");
      setMobileOtpButton(true);
      setAadharOtpButton(true);
    } else {
      setSnackbarMessage("Please enter a valid abha number or address");
      setShowSnackbar(true);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setVisible((prevState) => ({
      ...prevState,
      mobileOtp: false,
      aadhaarOtp: false,
      // MobileValidationScreen: false,
    }));

    setVariables((prevState) => ({
      abhaNo: "",
      aadhaarNumber: "",
      showAlert: false,
      alertTitle: "",
      alertMessage: "",
      type: "",
      formType: 0,
      otp: "",
      forgototp: "",
      phrMobile: "",
    }));
    setVariables((prevState) => ({
      ...prevState,
      abhaNo: "",
    }));
    setMobileOtpButton(false);
    setAadharOtpButton(false);
  };

  const skipABHA = () => {
    if (skipRoute === "addPatient") {
      console.log("Skipped integration");
      navigate("/patients/add-patients?action=addpatient");
    } else {
      //console.log("Skipped integration");
      toast.info("You must complete this step. Skipping is not allowed.");
    }
  };


  const handleotpCancel = () => {
    setVariables((prevState) => ({
      ...prevState,
      otp: "",
    }));
    setVisible((prevState) => ({
      ...prevState,
      mobileOtp: false,
      aadhaarOtp: false,
    }));
  };

  const handleaadhaarotpCancel = () => {
    setVariables((prevState) => ({
      ...prevState,
      otp: "",
    }));
    setVisible((prevState) => ({
      ...prevState,
      aadharNoOtp: false,
    }));
  };

  // aadhar and mobile buttons
  // const handleButtonClick = async (component) => {
  //   const storedabhaaddress = await sessionStorage.getItem("abhaaddress");

  //     if (component === "mobileOtp") {
  //       setVisible((prevState) => ({
  //         ...prevState,
  //         loading: component,
  //       }));
  //       // await generateMobileOtpABHA(value);
  //       const value = "mobile-verify";
  //       await generateAadhaarOtpABHA(value);
  //     } else if (component === "aadhaarOtp") {
  //       setVisible((prevState) => ({
  //         ...prevState,
  //         loading: component,
  //       }));
  //       const value = "aadhaar-verify";
  //       await generateAadhaarOtpABHA(value);
  //     }

  //     setVisible((prevState) => ({
  //       ...prevState,
  //       loading: null,
  //     }));
  // };

  const handleButtonClick = async (component) => {
    try {
      const storedAbhaAddress = await sessionStorage.getItem("abhaaddress");
      const isSandbox = storedAbhaAddress && storedAbhaAddress.includes("@sbx");
      let value = component === "mobileOtp" ? "mobile-verify" : "aadhaar-verify";
      sessionStorage.setItem("componentvalue", value);

      setVisible((prevState) => ({
        ...prevState,
        loading: component,
      }));

      if (isSandbox) {
        await generateAbhaddressOtpABHA(value);
      } else {
        await generateAadhaarOtpABHA(value);
      }

      setVisible((prevState) => ({
        ...prevState,
        loading: null,
      }));
    } catch (error) {
      console.error("Error in handleButtonClick:", error);
    }
  };

  const handleresendButtonClick = async (component) => {
    try {
      const storedAbhaAddress = await sessionStorage.getItem("abhaaddress");
      const isSandbox = storedAbhaAddress && storedAbhaAddress.includes("@sbx");
      let value = await sessionStorage.getItem("componentvalue");

      setVisible((prevState) => ({
        ...prevState,
        loading: component,
      }));

      if (isSandbox) {
        await generateAbhaddressOtpABHA(value);
      } else {
        await generateAadhaarOtpABHA(value);
      }

      setVisible((prevState) => ({
        ...prevState,
        loading: null,
      }));
    } catch (error) {
      console.error("Error in handleButtonClick:", error);
    }
  };


  //having abha number api calls
  // const generateAadhaarOtpABHA = async (abhaId) => {
  //   try {
  //     const accessToken = await sessionStorage.getItem("accessToken");
  //     const dataBody = {
  //       abhaId: abhaId,
  //       USER_ID: UserID,
  //       token: accessToken,
  //     };

  //     const response = await Axios.post(
  //       `${Config.abhaBaseUrl}generateAadhaarOtpABHA`,
  //       dataBody,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //           "x-caller": encrypted,
  //         },
  //       }
  //     );

  //     // console.log(
  //     //   "response in generateAadhaarOtpABHA",
  //     //   response,
  //     //   "status",
  //     //   response.status
  //     // );

  //     if (response.status === 422) {
  //       setVisible((prevState) => ({
  //         ...prevState,
  //         mobileOtp: false,
  //         aadhaarOtp: false,
  //       }));
  //       let details = response.data.details[0];
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: response.data.code,
  //         alertMessage:
  //           details && details.length > 0
  //             ? details.message
  //             : response.data.message,
  //       }));
  //       setSelectedOption("alreadyRegistered");
  //       // setShowLoading(false)
  //     } else if (response.status === 200) {
  //       sessionStorage.setItem("authTransactionId", response.data.txnId);
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         formType: 4,
  //       }));

  //       setVisible((prevState) => ({
  //         ...prevState,
  //         mobileOtp: true,
  //         aadhaarOtp: true,
  //       }));
  //     } else {
  //       setVisible((prevState) => ({
  //         ...prevState,
  //         mobileOtp: false,
  //         aadhaarOtp: false,
  //       }));
  //       // setShowLoading(false)
  //       setSelectedOption("alreadyRegistered");
  //       setSnackbarMessage(
  //         "ABHA Server down!!!",
  //         "The ABHA server is down at this time. Please try after sometime."
  //       );
  //       setShowSnackbar(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     if (error.response && error.response.status === 422) {
  //       let details = error.response.data.details[0];
  //       console.log(details.message, "===", error.response.data.message);
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: error.response.data.code,
  //         alertMessage:
  //           details && details.message
  //             ? details.message
  //             : error.response.data.message,
  //       }));
  //       setVisible((prevState) => ({
  //         ...prevState,
  //         mobileOtp: false,
  //         aadhaarOtp: false,
  //       }));
  //     } else {
  //       setSnackbarMessage(
  //         "ABHA Server down!!!",
  //         "The ABHA server is down at this time. Please try after sometime."
  //       );
  //       setShowSnackbar(true);
  //     }
  //   }
  // };

  // M1 V3 VERSION OF AADHAR OTP GENERATION

  const formatAbhaNumber = (aadhaarNumber) => {
    return `${aadhaarNumber.substring(0, 2)}-${aadhaarNumber.substring(2, 6)}-${aadhaarNumber.substring(6, 10)}-${aadhaarNumber.substring(10, 14)}`;
  };

  // call for forgot abha addresss
  const generateforgotabhanumber = async () => {
    setShowLoading(true);
    try {
      const otpSystem = variables.forgotaadharnum.length === 10 ? "mobile-verify" : "aadhaar-verify";
      const otpRole = otpSystem === "mobile-verify" ? "abdm" : "aadhaar";
      const scope = otpSystem === "mobile-verify" ? "mobile-verify" : "aadhaar-verify";
      const login = otpSystem === "mobile-verify" ? "mobile" : "aadhaar";

      const dataBody = {
        loginHint: login || "",
        loginId: variables.forgotaadharnum,
        scope: scope || "",
        otpSystem: otpRole || "",
      };

      const response = await Axios.post(
        `${Config.abhaBaseUrl}v3/forgetAbhadetailsotp`,
        dataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token,
          },
        }
      );

      console.log("response", response)

      if (response.status === 422) {
        let details = response.data.details[0];
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            details && details.length > 0
              ? details.message
              : response.data.message,
        }));
        setSelectedOption("alreadyRegistered");
        setShowLoading(false);
      } else if (response.status === 200) {
        console.log("successs");
        sessionStorage.setItem("forgetabhaTransactionId", response.data.data.txnId)
        sessionStorage.setItem("forgetabhascope", dataBody.scope)
        setVisible((prevState) => ({
          ...prevState,
          verifyforgetotp: true
        }));
        setShowLoading(false);
      } else {
        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: false,
          aadhaarOtp: false,
        }));
        // setShowLoading(false)
        setSelectedOption("alreadyRegistered");
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
        setShowLoading(false);
      }
    } catch (error) {
      console.log("Error Details:", error);
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
        setShowLoading(false);
      } else {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
        setShowLoading(false);
      }
    }
  };

  // verify forget abhanumber otp

  const verifyforgetabhanumberotp = async () => {
    console.log("coming into verify forget abha");
    const scopetype = sessionStorage.getItem("forgetabhascope");
    console.log("scopetype", scopetype)
    setShowLoading(true);
    try {
      const txnId = await sessionStorage.getItem("forgetabhaTransactionId");
      const otpDataBody = {
        txnId: txnId,
        otp: variables.forgototp,
        scope: scopetype,
      };

      //console.log("otp databody", otpDataBody);

      const response = await Axios.post(
        `${Config.abhaBaseUrl}v3/verifyForgetAbhaDetailsotp`,
        otpDataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token,
          },
        }
      );


      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            response.data.details.length > 0
              ? response.data.details[0].message
              : response.data.message,
        }));
        setSelectedOption("alreadyRegistered");
      } else if (response.status === 200) {
        if (response.data.data.authResult == "failed") {
          setVariables((prevState) => ({
            ...prevState,
            showAlert: true,
            alertTitle: "Failed!!",
            alertMessage: "Please enter a valid OTP. Entered OTP is either expired or incorrect.",
          }));
          setSelectedOption("alreadyRegistered");
        } else {
          sessionStorage.setItem("abhadetailss", response.data.data.accounts)
          console.log("success", response);
          setVisible((prevState) => ({
            ...prevState,
            abhadetails: true
          }));
          setVariables((prevState) => ({
            ...prevState,
            abhanumber: response.data.data.accounts[0]?.ABHANumber || '',
            abhaname: response.data.data.accounts[0]?.name || '',
            abhaNo: response.data.data.accounts[0]?.preferredAbhaAddress || '',
            forgototp: '',
            otp: ''
          }));
        }
      } else {
        setSelectedOption("alreadyRegistered");
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
      setShowLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
      } else {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    }
  };


  // Call for abha address
  const generateAbhaddressOtpABHA = async (otpSystem) => {
    console.log("otpSystem", otpSystem);
    console.log("variables.abhaNo", variables.abhaNo)
    try {
      const otpRole = (otpSystem == "mobile-verify") ? "abdm" : "aadhaar";
      const scope = (otpSystem == "mobile-verify") ? "mobile-verify" : "aadhaar-verify";

      const dataBody = {
        abhaAddress: `${variables.abhaNo}`,
        scope: scope,
        otpSystem: otpRole,
      };

      console.log("data body", dataBody);

      const response = await Axios.post(
        `${Config.abhaBaseUrl}v3/abhaAddressLoginOtp`,
        dataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token,
          },
        }
      );

      if (response.status === 422) {
        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: false,
          aadhaarOtp: false,
        }));
        let details = response.data[0];
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data[0].code,
          alertMessage:
            details && details.length > 0
              ? details.message
              : response.data[0].message,
        }));
        setSelectedOption("alreadyRegistered");
        // setShowLoading(false)
      } else if (response.status === 200) {
        sessionStorage.setItem("abhaaddressTransactionId", response.data.data.txnId);
        sessionStorage.setItem("scope", dataBody.scope);
        setVariables((prevState) => ({
          ...prevState,
          formType: 4,
        }));

        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: true,
          aadhaarOtp: true,
        }));
      } else {
        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: false,
          aadhaarOtp: false,
        }));
        setShowLoading(false);
        let details = response.data[0];
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data[0].code,
          alertMessage:
            details && details.length > 0
              ? details.message
              : response.data[0].message,
        }));
        // setSelectedOption("alreadyRegistered");
        // setSnackbarMessage(
        //   "ABHA Server down!!!",
        //   "The ABHA server is down at this time. Please try after sometime."
        // );
        // setShowSnackbar(true);
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 422) {
        let details = error.response.data[0];
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data[0].code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data[0].message,
        }));
        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: false,
          aadhaarOtp: false,
        }));
      } else {
        let responseData = error.response.data;
        // Check if responseData is an array and contains at least one item
        let details = Array.isArray(responseData) && responseData.length > 0
          ? responseData[0]
          : responseData;

        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: details?.code || "Error",
          alertMessage: details?.message || "An error occurred.",
        }));
        // setSnackbarMessage(
        //   "ABHA Server down!!!",
        //   "The ABHA server is down at this time. Please try after sometime."
        // );
        // setShowSnackbar(true);
      }
    }
  };

  // console.log("print mobilr num",variables.mobilenumber)
  const generateAadhaarOtpABHA = async (otpSystem) => {
    console.log("otpSystem", otpSystem);
    try {

      if (variables.abhaNo.length !== 14) {
        throw new Error("Invalid Number");
      }

      const abhanumber = variables.abhaNo;

      const abhaId = formatAbhaNumber(variables.abhaNo);

      console.log("abhaid", abhaId);

      const otpRole = (otpSystem == "mobile-verify") ? "abdm" : "aadhaar";
      const scope = (otpSystem == "mobile-verify") ? "mobile-verify" : "aadhaar-verify";
      const dataBody = {
        // abhaNumber: variables.abhaNo ? String(variables.abhaNo) : "",
        abhaNumber: abhaId,
        scope: scope || "",
        otpSystem: otpRole || "",
      };

      console.log("response of databody", dataBody);
      const response = await Axios.post(
        `${Config.abhaBaseUrl}v3/abhaNumberloginOtp`,
        dataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token,
          },
        }
      );

      if (response.status === 422) {
        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: false,
          aadhaarOtp: false,
        }));
        let details = response.data.details[0];
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            details && details.length > 0
              ? details.message
              : response.data.message,
        }));
        setSelectedOption("alreadyRegistered");
        // setShowLoading(false)
      } else if (response.status === 200) {
        sessionStorage.setItem("authTransactionId", response.data.data.txnId);
        sessionStorage.setItem("scope", dataBody.scope);
        setVariables((prevState) => ({
          ...prevState,
          formType: 4,
        }));

        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: true,
          aadhaarOtp: true,
        }));
      } else {
        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: false,
          aadhaarOtp: false,
        }));
        // setShowLoading(false)
        setSelectedOption("alreadyRegistered");
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    } catch (error) {
      console.log("Error Details:", error);
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: false,
          aadhaarOtp: false,
        }));
      } else {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    }
  };

  // before v3 api call
  // const verifyAadhaarOtpABHA = async () => {
  //   setShowLoading(true);
  //   try {
  //     const accessToken = await sessionStorage.getItem("accessToken");
  //     const txnId = await sessionStorage.getItem("authTransactionId");
  //     const otpDataBody = {
  //       txnId: txnId,
  //       otp: variables.otp,
  //       USER_ID: UserID,
  //       token: accessToken,
  //     };

  //     //console.log("otp databody", otpDataBody);

  //     const response = await Axios.post(
  //       `${Config.abhaBaseUrl}verifyAadhaarOtpABHA`,
  //       otpDataBody,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //           "x-caller": encrypted,
  //         },
  //       }
  //     );

  //     // console.log(
  //     //   "response in verifyAadhaarOtpABHA",
  //     //   response,
  //     //   "status",
  //     //   response.status
  //     // );

  //     if (response.status === 422) {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: response.data.code,
  //         alertMessage:
  //           response.data.details.length > 0
  //             ? response.data.details[0].message
  //             : response.data.message,
  //       }));
  //       setSelectedOption("alreadyRegistered");
  //     } else if (response.status === 200) {
  //       sessionStorage.setItem("profileData", JSON.stringify(response.data));
  //       uploadAbhaData(response.data);
  //     } else {
  //       setSelectedOption("alreadyRegistered");
  //       setSnackbarMessage(
  //         "ABHA Server down!!!",
  //         "The ABHA server is down at this time. Please try after sometime."
  //       );
  //       setShowSnackbar(true);
  //     }
  //     setShowLoading(false);
  //   } catch (error) {
  //     if (error.response && error.response.status === 422) {
  //       let details = error.response.data.details[0];
  //       console.log(details.message, "===", error.response.data.message);
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: error.response.data.code,
  //         alertMessage:
  //           details && details.message
  //             ? details.message
  //             : error.response.data.message,
  //       }));
  //     } else {
  //       setSnackbarMessage(
  //         "ABHA Server down!!!",
  //         "The ABHA server is down at this time. Please try after sometime."
  //       );
  //       setShowSnackbar(true);
  //     }
  //   }
  // };

  // const generateMobileOtpABHA = async (abhaId) => {
  //   try {
  //     const accessToken = await sessionStorage.getItem("accessToken");
  //     const dataBody = {
  //       abhaId: abhaId,
  //       USER_ID: UserID,
  //       token: accessToken,
  //     };

  //     const response = await Axios.post(
  //       `${Config.abhaBaseUrl}generateMobileOtpABHA`,
  //       dataBody,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //           "x-caller": encrypted,
  //         },
  //       }
  //     );

  //     // console.log(
  //     //   "response in generateMobileOtpABHA",
  //     //   response,
  //     //   "status",
  //     //   response.status
  //     // );

  //     if (response.status === 422) {
  //       setVisible((prevState) => ({
  //         ...prevState,
  //         mobileOtp: false,
  //         aadhaarOtp: false,
  //       }));
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: response.data.code,
  //         alertMessage:
  //           response.data.details.length > 0
  //             ? response.data.details[0].message
  //             : response.data.message,
  //       }));
  //       setSelectedOption("alreadyRegistered");
  //     } else if (response.status === 200) {
  //       sessionStorage.setItem("authTransactionId", response.data.txnId);

  //       setVariables((prevState) => ({
  //         ...prevState,
  //         formType: 5,
  //       }));

  //       setVisible((prevState) => ({
  //         ...prevState,
  //         mobileOtp: true,
  //         aadhaarOtp: true,
  //       }));
  //     } else {
  //       setVisible((prevState) => ({
  //         ...prevState,
  //         mobileOtp: false,
  //         aadhaarOtp: false,
  //       }));
  //       setSelectedOption("alreadyRegistered");
  //       setSnackbarMessage(
  //         "ABHA Server down!!!",
  //         "The ABHA server is down at this time. Please try after sometime."
  //       );
  //       setShowSnackbar(true);
  //     }
  //   } catch (error) {
  //     // console.log(error);
  //     if (error.response && error.response.status === 422) {
  //       let details = error.response.data.details[0];
  //       console.log(details.message, "===", error.response.data.message);
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: error.response.data.code,
  //         alertMessage:
  //           details && details.message
  //             ? details.message
  //             : error.response.data.message,
  //       }));
  //       setVisible((prevState) => ({
  //         ...prevState,
  //         mobileOtp: false,
  //         aadhaarOtp: false,
  //       }));
  //     } else {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //     }
  //   }
  // };

  // M1 V3 VERSION

  const verifyAadhaarOtpABHA = async () => {
    console.log("coming into verify aadharotp abha");
    const scopetype = sessionStorage.getItem("scope");
    console.log("scopetype", scopetype)
    setShowLoading(true);
    try {
      const txnId = await sessionStorage.getItem("authTransactionId");
      const otpDataBody = {
        txnId: txnId,
        otp: variables.otp,
        scope: scopetype,
      };

      //console.log("otp databody", otpDataBody);

      const response = await Axios.post(
        `${Config.abhaBaseUrl}v3/abhaNumberVerifyOTP`,
        otpDataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token,
          },
        }
      );

      console.log("data response of xtoken", response.data.data.token);
      console.log("data response", response);
      console.log("data response inside data", response.data);

      if (response.status === 422) {
        // setVariables((prevState) => ({
        //   ...prevState,
        //   showAlert: true,
        //   alertTitle: response.data.data.code,
        //   alertMessage:
        //     response.data.data.details.length > 0
        //       ? response.data.data.details[0].message
        //       : response.data.data.message,
        // }));
        setSnackbarMessage(
          "Invalid Otp!!!",
          "Please enter a valid OTP. Entered OTP is either expired or incorrect."
        );
        setShowSnackbar(true);
        setSelectedOption("alreadyRegistered");
      } else if (response.status === 200) {
        console.log("came to verification");
        sessionStorage.setItem("aadharverifytoken", JSON.stringify(response.data.data.token));
        if (response.data.data.message == "Please enter a valid OTP. Entered OTP is either expired or incorrect.") {
          setSnackbarMessage(
            "Invalid Otp!!!",
            "Please enter a valid OTP. Entered OTP is either expired or incorrect."
          );
          setShowSnackbar(true);
        } else {
          getabhaprofiledata(response.data.data.token);
        }
      } else {
        setSelectedOption("alreadyRegistered");
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
        setSelectedOption("alreadyRegistered");
      }
      setShowLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.data.details[0];
        console.log(details.message, "===", error.response.data.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.data.message,
        }));
      } else {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    }
  };

  const verifyabhaaddressOtpABHA = async () => {
    console.log("coming into verify abhaaddress abha");
    const scopetype = sessionStorage.getItem("scope");
    setShowLoading(true);
    try {
      const txnId = await sessionStorage.getItem("abhaaddressTransactionId");
      const otpDataBody = {
        txnId: txnId,
        otp: variables.otp,
        scope: scopetype,
      };

      console.log("otp data body", otpDataBody)
      const response = await Axios.post(
        `${Config.abhaBaseUrl}v3/abhaAddressVerifyLoginOtp`,
        otpDataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token,
          },
        }
      );

      console.log("data response of xtoken", response.data.data.token);
      console.log("data response", response);
      console.log("data response inside data", response.data);

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.data.code,
          alertMessage:
            response.data.data.details.length > 0
              ? response.data.data.details[0].message
              : response.data.data.message,
        }));
        setSelectedOption("alreadyRegistered");
        setShowLoading(false);
      } else if (response.status === 200) {
        if (response.data.data.authResult == "failed") {
          setVariables((prevState) => ({
            ...prevState,
            showAlert: true,
            alertTitle: "failed",
            alertMessage: "Entered OTP is incorrect. Kindly re-enter valid OTP.",
          }));
          setSelectedOption("alreadyRegistered");
        } else {
          sessionStorage.setItem("abhanumberverifytoken", JSON.stringify(response.data.data.tokens.token));
          getabhaaddressprofiledata(response.data.data.tokens.token);
        }
        setShowLoading(false);
      } else {
        setSelectedOption("alreadyRegistered");
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
        setShowLoading(false);
      }
      setShowLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.data.details[0];
        console.log(details.message, "===", error.response.data.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.data.message,
        }));
        setShowLoading(false);
      } else {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
        setShowLoading(false);
      }
    }
  };

  const verifyMobileOtpABHA = async () => {
    setShowLoading(true);
    try {
      const accessToken = await sessionStorage.getItem("accessToken");
      const txnId = await sessionStorage.getItem("authTransactionId");
      const otpDataBody = {
        txnId: txnId || "",
        otp: variables.otp,
        USER_ID: UserID,
        token: accessToken,
      };

      console.log("otp databody", otpDataBody);

      const response = await Axios.post(
        `${Config.abhaBaseUrl}verifyMobileOtpABHA`,
        otpDataBody,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      // console.log(
      //   "response in verifyMobileOtpABHA",
      //   response,
      //   "status",
      //   response.status
      // );

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            response.data.details.length > 0
              ? response.data.details[0].message
              : response.data.message,
        }));
        setSelectedOption("alreadyRegistered");
      } else if (response.status === 200) {
        sessionStorage.setItem("profileData", JSON.stringify(response.data));
        // uploadAbhaData(response.data);
      } else {
        setSelectedOption("alreadyRegistered");
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
      setShowLoading(false);
      setVisible((prevState) => ({
        ...prevState,
        mobileOtp: false,
        aadhaarOtp: false,
      }));
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
      setVisible((prevState) => ({
        ...prevState,
        mobileOtp: false,
        aadhaarOtp: false,
      }));
    }
  };
  //

  //register abha api calls
  // const generateAadhaarOTP = async () => {
  //   console.log("aad", variables.aadhaarNumber);
  //   if (variables.aadhaarNumber.trim() !== "") {
  //     setShowLoading(true);
  //     await getPublickey();

  //     const dataBody = {
  //       aadhaar: variables.aadhaarNumber,
  //       USER_ID: UserID,
  //     };
  //     console.log(dataBody, "dataBody........generateAadhaarOTP", encrypted);
  //     await Axios.post(`${Config.abhaBaseUrl}generateAadhaarOTP`, dataBody, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: `Bearer ${token}`,
  //         "x-caller": encrypted,
  //       },
  //     })

  //       .then((response) => {
  //         console.log("response in generateAadhaarOTP:", response);
  //         if (response.status === 422) {
  //           setVariables((prevState) => ({
  //             ...prevState,
  //             showAlert: true,
  //             alertTitle: response.data.code,
  //             alertMessage:
  //               response.data.details.length > 0
  //                 ? response.data.details[0].message
  //                 : response.data.message,
  //           }));
  //           setSelectedOption("haventRegistered");
  //           setShowLoading(false);
  //         } else if (response.status === 200) {
  //           setVisible((prevState) => ({
  //             ...prevState,
  //             aadharNoOtp: true,
  //           }));
  //           setVariables((prevState) => ({
  //             ...prevState,
  //             formType: 2,
  //           }));
  //           setShowLoading(false);
  //           sessionStorage.setItem("authTransactionId", response.data.txnId);
  //           sessionStorage.setItem("accessToken", response.data.accessToken);
  //         } else {
  //           setVariables((prevState) => ({
  //             ...prevState,
  //             showAlert: true,
  //             alertTitle: "ABHA Server down!!!",
  //             alertMessage:
  //               "The ABHA server is down at this time. Please try after sometime.",
  //           }));
  //           setShowLoading(false);
  //         }
  //       })
  //       .catch(async (error) => {
  //         console.log(error);
  //         if (error.response && error.response.status === 422) {
  //           let details = error.response.data.details[0];
  //           console.log(details.message, "===", error.response.data.message);
  //           setVariables((prevState) => ({
  //             ...prevState,
  //             showAlert: true,
  //             alertTitle: error.response.data.code,
  //             alertMessage:
  //               details && details.message
  //                 ? details.message
  //                 : error.response.data.message,
  //           }));

  //           setShowLoading(false);
  //         } else {
  //           setVariables((prevState) => ({
  //             ...prevState,
  //             showAlert: true,
  //             alertTitle: "ABHA Server down!!!",
  //             alertMessage:
  //               "The ABHA server is down at this time. Please try after sometime.",
  //           }));
  //           setShowLoading(false);
  //         }
  //       });
  //   } else {
  //     setSnackbarMessage("Please enter a valid value");
  //     setShowSnackbar(true);
  //   }
  // };

  //register abha V3 api calls
  const generateAadhaarOTP = async () => {
    console.log("aad", variables.aadhaarNumber);
    if (variables.aadhaarNumber.trim() !== "" && variables.aadhaarNumber.length == "12") {
      setShowLoading(true);
      // await getPublickey();

      const dataBody = {
        aadhaar: variables.aadhaarNumber,
        "consentAccept": "User accepted the consent"
        // USER_ID: UserID,
      };
      await Axios.post(`${Config.abhaBaseUrl}v3/enrollmentaadhaarOtp`, dataBody, {
        headers: {
          "Content-Type": "application/json",
          // authorization: `Bearer ${token}`,
          "x-client-token": Config.token,
        },
      })
        .then((response) => {
          console.log("response in generateAadhaarOTP:", response);
          if (response.status === 422) {
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: response.data.code,
              alertMessage:
                response.data.details.length > 0
                  ? response.data.details[0].message
                  : response.data.message,
            }));
            setSelectedOption("haventRegistered");
            setShowLoading(false);
          } else if (response.status === 200) {
            setVisible((prevState) => ({
              ...prevState,
              aadharNoOtp: true,
            }));
            setVariables((prevState) => ({
              ...prevState,
              formType: 2,
            }));
            setShowLoading(false);
            sessionStorage.setItem("authTransactionId", response.data.data.txnId);
            sessionStorage.setItem("accessToken", response.data.accessToken);
          } else {
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: "ABHA Server down!!!",
              alertMessage:
                "The ABHA server is down at this time. Please try after sometime.",
            }));
            setShowLoading(false);
          }
        })
        .catch(async (error) => {
          console.log(error);
          if (error.response && error.response.status === 422) {
            let details = error.response.data.error[0];
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: error.response.data.error[0].code,
              alertMessage:
                details && details.message
                  ? details.message
                  : error.response.data.error[0].message,
            }));

            setShowLoading(false);
          } else {
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: "ABHA Server down!!!",
              alertMessage:
                "The ABHA server is down at this time. Please try after sometime.",
            }));
            setShowLoading(false);
          }
        });
    } else {
      setSnackbarMessage("Please enter a valid aadhaar number");
      setShowSnackbar(true);
    }
  };

  // Call before v3 integration public api working in postman
  // const verifyAadhaarOTP = async () => {
  //   setShowLoading(true);
  //   try {
  //     const accessToken = await sessionStorage.getItem("accessToken");
  //     const transactionId = await sessionStorage.getItem("authTransactionId");

  //     const otpDataBody = {
  //       txnId: transactionId,
  //       otp: variables.otp,
  //       USER_ID: UserID,
  //       token: accessToken,
  //     };

  //     const response = await Axios.post(
  //       `${Config.abhaBaseUrl}verifyAadhaarOTP`,
  //       otpDataBody,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //           "x-caller": encrypted,
  //         },
  //       }
  //     );

  //     if (response.status === 422) {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "Attention !!",
  //         alertMessage: "Please enter valid OTP.",
  //       }));
  //       setVisible((prevState) => ({
  //         ...prevState,
  //         aadharNoOtp: true,
  //       }));
  //     } else if (response.status === 200) {
  //       //console.log("cmng to if");
  //       sessionStorage.setItem("transactionId", response.data.txnId);
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: false,
  //         alertTitle: "",
  //         alertMessage: "",
  //       }));
  //       setVisible((prevState) => ({
  //         ...prevState,
  //         MobileValidationScreen: true,
  //       }));
  //     } else {
  //       setSelectedOption("haventRegistered");
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //       setShowLoading(false);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 422) {
  //       let details = error.response.data.details[0];
  //       console.log(details.message, "===", error.response.data.message);
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: error.response.data.code,
  //         alertMessage:
  //           details && details.message
  //             ? details.message
  //             : error.response.data.message,
  //       }));
  //     } else {
  //       setVariables((prevState) => ({
  //         ...prevState,
  //         showAlert: true,
  //         alertTitle: "ABHA Server down!!!",
  //         alertMessage:
  //           "The ABHA server is down at this time. Please try after sometime.",
  //       }));
  //     }
  //   }
  // };

  // ABHA V3 CALL FOR getabhaprofiledata


  // v3 integration api call

  const verifyAadhaarOTP = async () => {
    setShowLoading(true);
    try {
      // const accessToken = await sessionStorage.getItem("accessToken");
      const transactionId = await sessionStorage.getItem("authTransactionId");
      // console.log("mobilenumber", variables.mobilenumber);

      const otpDataBody = {
        txnId: transactionId,
        otp: variables.otp,
        mobile: variables.mobilenumber,
        // USER_ID: UserID,
        // token: accessToken,
      };
      console.log("otpbody", otpDataBody)

      const response = await Axios.post(
        `${Config.abhaBaseUrl}v3/verifyAadhaarOtp`,
        otpDataBody,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token
          },
        }
      );
      console.log("response of aadhar verify", response);

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "Attention !!",
          alertMessage: "Please enter valid OTP.",
        }));
        setVisible((prevState) => ({
          ...prevState,
          aadharNoOtp: true,
        }));
        setShowLoading(false);
      } else if (response.status === 200) {
        //console.log("cmng to if");
        sessionStorage.setItem("transactionId", response.data.data.txnId);
        sessionStorage.setItem("aadharverifytoken", response.data.data.tokens.token);
        sessionStorage.setItem("verifyytoken", response.data.data.tokens.refreshToken);
        sessionStorage.setItem("aadharmobilenumber", response.data.data.ABHAProfile.mobile);
        sessionStorage.setItem("phraddress", response.data.data.ABHAProfile.phrAddress);
        sessionStorage.setItem("profileData", JSON.stringify(response.data.data.ABHAProfile));
        // sessionStorage.setItem("profileData", response.data.data.ABHAProfile);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: false,
          alertTitle: "",
          alertMessage: "",
        }));
        setVisible((prevState) => ({
          ...prevState,
          MobileValidationScreen: true,
        }));
        setShowLoading(false);
      } else {
        setSelectedOption("haventRegistered");
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
        setShowLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        console.log("response ", error.response);
        let details = error.response.data.error;
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.error.message,
        }));
        setShowLoading(false);
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
        setShowLoading(false);
      }
    }
  };


  // for abha address profile data
  const getabhaaddressprofiledata = async (token) => {
    console.log("coming into verify abha address abha", token);
    setShowLoading(true);
    try {
      const body = {
        xToken: token,
      };
      const response = await Axios.post(
        `${Config.abhaBaseUrl}v3/getabhaAddressprofiledata`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token,
          },
        }
      );

      console.log("abha data response", response);
      console.log("abha data response inside data", response.data.data);

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.data.code,
          alertMessage:
            response.data.data.details.length > 0
              ? response.data.data.details[0].message
              : response.data.data.message,
        }));
        setSelectedOption("alreadyRegistered");
      } else if (response.status === 200) {
        sessionStorage.setItem("profileData", JSON.stringify(response.data.data));
        console.log("came to profiledata abhaaddress", response.data.data);
        sessionStorage.setItem("profileDataabhanumber", response.data.data.abhaNumber);
        sessionStorage.setItem("profileDataabhaaddress", response.data.data.abhaAddress);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "Success!!",
          alertMessage:
            "ABHA details of the current patient have been successfully fetched. Continue with patient registation process.",
          type: "uploadAbhaData",
        }));
        // uploadAbhaData();
      } else {
        setSelectedOption("alreadyRegistered");
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
      setShowLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.data.details[0];
        console.log(details.message, "===", error.response.data.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.data.message,
        }));
      } else {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    }
  };


  // for abha numberv profile data
  const getabhaprofiledata = async (token) => {
    console.log("coming into abh address abha", token);
    setShowLoading(true);
    try {
      const body = {
        xToken: token,
      };
      const response = await Axios.post(
        `${Config.abhaBaseUrl}v3/getabhaprofiledata`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token,
          },
        }
      );

      console.log("abha data response", response);
      console.log("abha data response inside data", response.data.data);

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.data.code,
          alertMessage:
            response.data.data.details.length > 0
              ? response.data.data.details[0].message
              : response.data.data.message,
        }));
        setSelectedOption("alreadyRegistered");
      } else if (response.status === 200) {
        console.log("came to getabhaprofiledata")
        sessionStorage.setItem("profileData", JSON.stringify(response.data.data));
        console.log("data without json stringify", response.data.data);
        sessionStorage.setItem("profileDataabhanumber", response.data.data.ABHANumber);
        sessionStorage.setItem("profileDataabhaaddress", response.data.data.preferredAbhaAddress);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "Success!!",
          alertMessage:
            "ABHA details of the current patient have been successfully fetched. Continue with patient registation process.",
          type: "uploadAbhaData",
        }));
        // uploadAbhaData();
      } else {
        setSelectedOption("alreadyRegistered");
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
      setShowLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.data.details[0];
        console.log(details.message, "===", error.response.data.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.data.message,
        }));
      } else {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    }
  };

  // CALL FOR ABHA DATA
  const uploadAbhaData = async () => {
    const abhaNumber = sessionStorage.getItem("profileDataabhanumber");
    const abhaAddress = sessionStorage.getItem("profileDataabhaaddress");
    // console.log("abhadata", abhaData, JSON.stringify(abhaData));
    const body = {
      USER_ID: UserID,
      PATIENT_ID: PATIENT_ID,
      ABHA_NUMBER: abhaNumber,
      ABHA_ADDRESS: abhaAddress,
    };
    console.log("++++++++++", body);
    const response = await Axios.post(`${Config.apiURL}/me/ABHA_DATA`, body, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
        "x-caller": await encrypted,
      },
    });

    //console.log("response in uplaod abha data", response);
    if (response.status === 201) {
      console.log("made abha card null successfully")
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "Success!!",
        alertMessage:
          "ABHA details of the current patient have been successfully fetched. Continue with patient registation process.",
        type: "uploadAbhaData",
      }));
    } else if (response.status === 404) {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "Sorry!!!",
        alertMessage:
          "ABHA Data is not linked with the Profile. Please submit again.",
      }));
    } else {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "Error!!!",
        alertMessage:
          "An unknown error occurred.Please try again after sometime.",
      }));
    }
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleInputChange = (e, ctrlName) => {
    const { value } = e.target;

    if (ctrlName === "aadhaarNumber") {
      // Check if the input is a number
      if (/^\d*$/.test(value)) {
        setVariables({
          ...variables,
          [ctrlName]: value,
        });
      }
    } else if (ctrlName === "abhaNo") {
      // Allow any characters for ABHA No/ABHA Address
      setVariables({
        ...variables,
        [ctrlName]: value,
      });
    }
    else if (ctrlName === "mobilenumber") {
      // Allow any characters for ABHA No/ABHA Address
      setVariables({
        ...variables,
        [ctrlName]: value,
      });
    }
    else if (ctrlName === "forgotaadharnum") {
      // Handle the forgot number field
      setVariables({
        ...variables,
        [ctrlName]: value,
      });
    }
    else if (ctrlName === "captcha") {
      setVariables({
        ...variables,
        [ctrlName]: value,
      });
    }
  };

  const handleCancel = () => {
    // navigate("/patients");
    window.history.back();
  };

  // const handleProceed = async (component) => {
  //   setShowLoading(true);

  //   switch (variables.formType) {
  //     case 1:
  //       // await validateOTP();
  //       break;
  //     case 2:
  //       // await verifyAadhaarOTP();
  //       await verifyAadhaarOtpABHA();
  //       break;
  //     case 4:
  //       await verifyAadhaarOtpABHA();
  //       break;
  //     // case 5:
  //     //   // await verifyMobileOtpABHA();
  //     //   await verifyAadhaarOtpABHA();
  //     //   break;
  //     case 5:
  //       // await validatePHROTP();
  //       break;
  //     default:
  //   }
  //   setShowLoading(false);
  // };

  function getMaskedText(value) {
    // console.log("value n masked text", value)
    if (value.length === 12) {
      const newValue = value.replace(value.substring(0, 8), "********");
      // console.log("Masked Value :", newValue);
      return newValue;
    } else {
      return value;
    }
  }

  //api for create abha address using the mobile number

  const handleMobileChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setVariables({
        ...variables,
        phrMobile: value,
      });
    }
  };

  // Encryption of mobile number  using JSEncrypt
  const mobileEncryption = async (mobile) => {
    // console.log("mobile",mobile);
    var data = mobile;
    var key = await getPhrPublicKeyreturn();
    // var key = sessionStorage.getItem("PHRPublicKey")      ? sessionStorage.getItem("PHRPublicKey")     : keyvalue;
    // console.log(key, "key...........", PHRPublicKeystore, "sdjs",keyvalue);
    var jsencrypt = new JSEncrypt();
    // console.log(jsencrypt, "jsencrypt");
    jsencrypt.setPublicKey(key);
    // console.log(jsencrypt.setPublicKey(key),"jsencrypt.setPublicKey(key)");
    var encrypt = jsencrypt.encrypt(data);
    console.log("encrypted mobile :", encrypt);
    return encrypt;
  };

  const getPhrPublicKey = async () => {
    // console.log(UserID, "UserID..............");
    Axios.get(`${Config.abhaBaseUrl}getPhrPublicKey/${UserID}`, {
      headers: {
        authorization: `Bearer ${token}`,
        "x-caller": encrypted,
      },
    })
      .then((response) => {
        //console.log("PHRPublicKey :", response.data);
        if (response.status === 200) {
          setPHRPublicKeystore(response.data);
          sessionStorage.setItem("PHRPublicKey", response.data);
          // console.log(response.data,"response.data..........PHRPublicKey.......");

          return response;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPhrPublicKeyreturn = async () => {
    // console.log(UserID, "UserID..............");

    try {
      const response = await Axios.get(
        `${Config.abhaBaseUrl}getPhrPublicKey/${UserID}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status === 200) {
        setPHRPublicKeystore(response.data);
        sessionStorage.setItem("PHRPublicKey", response.data);
        console.log("PHRPublicKey:", response.data); // Log the data
        return response.data; // Return the data for external access
      }
    } catch (err) {
      console.error(err);
      // throw err; // Optionally rethrow the error to handle it in the caller
    }
  };

  /**************************/
  // console.log(variables.phrMobile,"variables.phrMobile");

  // const testingLinkabhaMobile = async () => {
  //   // await getPhrPublicKey();
  //   if (variables.phrMobile.trim() !== "") {
  //     setShowLoading(true);

  //       const dataBody = {
  //         mobile: variables.phrMobile
  //       };
  // console.log(dataBody,"dataBody.......");
  //       sessionStorage.setItem("mobile", variables.phrMobile);
  //       console.log("generatePHRMobileOTP", dataBody);

  //       await Axios.post(`${Config.abhaBaseUrl}clinally/v3/generateMobileOtp`, dataBody, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //           "x-caller": encrypted,
  //         },
  //       })

  //         .then((response) => {
  //           console.log("response in generatePHRMobileOTP:", response);
  //           setShowLoading(false);
  //         })
  //         .catch(async (error) => {
  //           console.log(error);
  //           if (
  //             error.response &&
  //             (error.response.status === 422 || error.response.status === 400)
  //           ) {
  //             let details = "error.response.data.details[0]";
  //             console.log(details.message, "===", error.response.data.message);

  // if (
  //   details.message ===
  //   "Request is invalid. Please enter the correct data."
  // ) {
  //   setVariables((prevState) => ({
  //     ...prevState,
  //     showAlert: true,
  //     alertTitle: "",
  //     alertMessage:
  //       "Please re-enter your mobile number.",
  //   }));
  // } else {
  //   setVariables((prevState) => ({
  //     ...prevState,
  //     showAlert: true,
  //     alertTitle: error.response.data.code || error.response.data[0].code,
  //     alertMessage:
  //       details && details.message
  //         ? details.message
  //         : error.response.data.code || error.response.data[0].message,
  //   }));
  // }

  //             setShowLoading(false);
  //           } else {
  //             setVariables((prevState) => ({
  //               ...prevState,
  //               showAlert: true,
  //               alertTitle: "ABHA Server down!!!",
  //               alertMessage:
  //                 "The ABHA server is down at this time. Please try after sometime.",
  //             }));
  //             setShowLoading(false);
  //           }
  //         });
  //     } else {
  //       setSnackbarMessage("Please enter a valid value");
  //       setShowSnackbar(true);
  //     }
  //   };
  /**************************/

  // working
  // const generatePHRMobileOTP = async () => {
  //   await getPhrPublicKey();
  //   console.log(getPhrPublicKey(), "getPhrPublicKey()");
  //   if (variables.phrMobile.trim() !== "") {
  //     setShowLoading(true);

  // const username = 'rev';
  // const password = '1234';
  // const basicAuth = 'Basic ' + base64Encode(utf8.encode(`${username}:${password}`));
  //     console.log

  //     const dataBody = {
  //       encryptedMobile: await mobileEncryption(variables.phrMobile),
  //       USER_ID: UserID,
  //     };

  //     sessionStorage.setItem("mobile", variables.phrMobile);
  //     console.log("mobile", variables.phrMobile);
  //     console.log("generatePHRMobileOTP", dataBody);
  //     console.log("encrypted", encrypted);
  //     await Axios.post(`${Config.abhaBaseUrl}generatePHRMobileOTP`, dataBody, {
  //     // await Axios.post(`${Config.abhaBaseUrl}clinally/v3/generateMobileOtp`, dataBody, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: `Bearer ${token}`,
  //         "x-caller": encrypted,
  //       },
  //     })

  //       .then((response) => {
  //         console.log("response in generatePHRMobileotp:", response);
  //         if (response.status === 422 || response.status === 400) {
  //           setVariables((prevState) => ({
  //             ...prevState,
  //             showAlert: true,
  //             alertTitle: response.data.code,
  //             alertMessage:
  //               response.data.details.length > 0
  //                 ? response.data.details[0].message
  //                 : response.data.message,
  //           }));
  //         } else if (response.status === 200) {
  //           sessionStorage.setItem(
  //             "transactionIdPhr",
  //             response.data.transactionId
  //           );
  //           sessionStorage.setItem("phraccessToken", response.data.accessToken);
  //           setVisible((prevState) => ({
  //             ...prevState,
  //             mobileOtpValidationForMobileScreen: true,
  //           }));
  //         } else {
  //           setVariables((prevState) => ({
  //             ...prevState,
  //             showAlert: true,
  //             alertTitle: "ABHA Server down!!!",
  //             alertMessage:
  //               "The ABHA server is down at this time. Please try after sometime.",
  //           }));
  //         }
  //         setShowLoading(false);
  //       })
  //       .catch(async (error) => {
  //         console.log(error);
  //         if (
  //           error.response &&
  //           (error.response.status === 422 || error.response.status === 400)
  //         ) {
  //           let details = error.response.data.details[0];
  //           console.log(details.message, "===", error.response.data.message);

  //           if (
  //             details.message ===
  //             "Request is invalid. Please enter the correct data."
  //           ) {
  //             setVariables((prevState) => ({
  //               ...prevState,
  //               showAlert: true,
  //               alertTitle: "",
  //               alertMessage: "Please re-enter your mobile number.",
  //             }));
  //           } else {
  //             setVariables((prevState) => ({
  //               ...prevState,
  //               showAlert: true,
  //               alertTitle: error.response.data.code,
  //               alertMessage:
  //                 details && details.message
  //                   ? details.message
  //                   : error.response.data.message,
  //             }));
  //           }

  //           setShowLoading(false);
  //         } else {
  //           setVariables((prevState) => ({
  //             ...prevState,
  //             showAlert: true,
  //             alertTitle: "ABHA Server down!!!",
  //             alertMessage:
  //               "The ABHA server is down at this time. Please try after sometime.",
  //           }));
  //           setShowLoading(false);
  //         }
  //       });
  //   } else {
  //     setSnackbarMessage("Please enter a valid value");
  //     setShowSnackbar(true);
  //   }
  // };

  const generatePHRMobileOTP = async () => {
    setShowLoading(true);
    try {
      // await getPhrPublicKey();
      if (variables.phrMobile && variables.phrMobile.trim() !== "" && variables.phrMobile.length == '10') {
        const dataBody = {
          "mobile": `${variables.phrMobile}`,
        };

        sessionStorage.setItem("mobile", variables.phrMobile);

        const response = await Axios.post(
          `${Config.abhav3BaseUrl}generateMobileOtp`,
          dataBody,
          {
            headers: {
              "x-client-token": Config.token,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          sessionStorage.setItem("transactionIdPhr", response.data.data.txnId);
          sessionStorage.setItem("phraccessToken", response.data.data.accessToken);
          setVisible((prevState) => ({
            ...prevState,
            mobileOtpValidationForMobileScreen: true,
          }));
          setShowLoading(false);
        } else {
          throw new Error("Unexpected response from the server.");
          setShowLoading(false);
        }
      } else {
        setSnackbarMessage("Please enter a valid mobile number");
        setShowSnackbar(true);
        setShowLoading(false);
      }
    } catch (error) {
      console.error("Error in generatePHRMobileOTP:", error);

      if (error.response?.status === 422 || error.response?.status === 400) {
        let responseData = error.response.data;

        // Check if responseData is an array and contains at least one item
        let details = Array.isArray(responseData) && responseData.length > 0
          ? responseData[0]
          : responseData;

        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: details?.code || "Error",
          alertMessage: details?.message || "An error occurred.",
        }));
        // const details = error.response.data?.[0];
        // setVariables((prevState) => ({
        //   ...prevState,
        //   showAlert: true,
        //   alertTitle: error.response.data[0].code || "Error",
        //   alertMessage: details?.message || error.response.data[0].message,
        // }));
        setShowLoading(false);
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage: "Please try again later.",
        }));
        setShowLoading(false);
      }
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setDisabled(false);
    }
  }, [timer]);

  const handleResend = () => {
    if (!disabled) {
      setDisabled(true);
      setTimer(30);
      generateAadhaarOTP();
    }
  };

  const handleResend1 = () => {
    if (!disabled) {
      setDisabled(true);
      setTimer(30);
      handleresendButtonClick();
    }
  };


  return (
    <>
      {/* <Navbar /> */}
      <div className="homePatient">
        <div className="homeBackground">
          <img src={backgroundCircle} alt="" />
        </div>
        <>
          <div className="abhadetails">
            <div
              className="homeBodyLeft"
              style={{
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <p className="ABHAHeading"> Link ABHA </p>
              <div className="customContainer1">
                {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2rem",
                        padding: "0px 100px",
                      }}
                    >
                      <p style={{ display: "flex", paddingTop: "15px" }}>
                        What is ABHA?
                      </p>
                      <img src={NHA} />
                    </div> */}
                <div className="abhaText">
                  ABHA - Ayushman Bharat Health Account
                </div>

                <p className="abhacontent">
                  ABHA (Ayushman Bharat Health Account) is a unique health ID
                  that uses a 14-digit identification number and can be
                  generated using an Aadhaar card or your mobile number. It
                  allows users to share their health records digitally with
                  hospitals, clinics, insurance providers and others. Citizens
                  can create their unique ABHA without any cost.
                </p>
              </div>
              <div className="abhaRadio">
                <div className="radioBtnTextGap">
                  <input
                    type="radio"
                    value="alreadyRegistered"
                    checked={selectedOption === "alreadyRegistered"}
                    onChange={handleOptionChange}
                    style={{ cursor: "pointer" }}
                  />
                  {/* <label>Already Registered with ABHA</label> */}
                  <label>Already Have ABHA</label>
                </div>
                <div className="radioBtnTextGap">
                  <input
                    type="radio"
                    value="haventRegistered"
                    checked={selectedOption === "haventRegistered"}
                    onChange={handleOptionChange}
                    style={{ cursor: "pointer" }}
                  />
                  {/* <label>Haven’t Registered with ABHA</label> */}
                  {/* <label>Don't Have ABHA</label> */}
                  <label>Create ABHA</label>
                </div>
                <div className="radioBtnTextGap">
                  <input
                    type="radio"
                    value="createABHA"
                    checked={selectedOption === "createABHA"}
                    onChange={handleOptionChange}
                    style={{ cursor: "pointer" }}
                  />
                  <label>Create ABHA Address with Mobile</label>
                </div>
              </div>
            </div>

            {visible.MobileValidationScreen &&
              selectedOption === "haventRegistered" ? (
              <>
                {selectedOption === "haventRegistered" && (
                  <AbhaMobileValidation />
                )}
              </>
            ) : visible.mobileOtpValidationForMobileScreen &&
              selectedOption === "createABHA" ? (
              <>
                {selectedOption === "createABHA" && (
                  <AbhaAddressSetUpPage
                    activeSectionInput={activeSection}
                    onActiveSectionChange={onActiveSectionChange}
                  />
                )}
              </>
            ) : (
              <div className="abhaContainer" style={{ width: "50%" }}>
                <>
                  <div className="abhaContainerLeft">
                    {!visible.mobileOtp &&
                      !visible.aadhaarOtp &&
                      !visible.aadharNoOtp && (
                        <p className="abhaTtile">
                          {" "}
                          Ayushman Bharat Health Account
                        </p>
                      )}

                    {selectedOption === "alreadyRegistered" &&
                      !visible.mobileOtp &&
                      !visible.aadhaarOtp && !visible.getabhadetails &&
                      !visible.verifyforgetotp && !visible.abhadetails && (
                        <>
                          <p className="content">
                            Have ABHA Number, Set up your profile using ABHA
                            Number
                          </p>
                          <div className="Abhanumfield">
                            {selectedOption === "aadharNumber" ? (
                              <>
                                <div>
                                  <input type="number" maxLength={12} />
                                </div>
                              </>
                            ) : (
                              <>
                                <input
                                  style={{
                                    display: "block",
                                    width: "70%",
                                  }}
                                  className="abhanoInfoInput"
                                  type="text"
                                  placeholder="Enter ABHA No/ABHA Address"
                                  value={variables.abhaNo}
                                  onChange={(e) =>
                                    handleInputChange(e, "abhaNo")
                                  }
                                />
                                <p className="SkipAbhatext"
                                  style={{ fontSize: "16px", fontWeight: "600", marginLeft: "30px" }}
                                  onClick={() => setVisible((prev) => ({ ...prev, getabhadetails: true }))}
                                >
                                  Get ABHA details ?
                                </p>
                              </>
                            )}
                          </div>

                          {mobileOtpButton && aadhaarOtpButton ? (
                            <>
                              <div className="Adharandmobile">
                                <div
                                  className={"editButton2"}
                                  style={{
                                    width: "32%",
                                    margin: "0 auto",
                                    height: "40px",
                                    color: "white"
                                  }}
                                  onClick={() =>
                                    handleButtonClick("aadhaarOtp")
                                  }
                                >
                                  {visible.loading === "aadhaarOtp" && (
                                    <CircularProgress
                                      size={24}
                                      color="inherit"
                                    />
                                  )}
                                  <p className="OtpBtn">Aadhaar otp</p>
                                </div>
                                <div
                                  className={"editButton2"}
                                  style={{
                                    width: "32%",
                                    margin: "0 auto",
                                    height: "40px",
                                    color: "white"
                                  }}
                                  onClick={() => handleButtonClick("mobileOtp")}
                                >
                                  {visible.loading === "mobileOtp" && (
                                    <CircularProgress
                                      size={24}
                                      color="inherit"
                                    />
                                  )}
                                  <p className="OtpBtn">Mobile otp</p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              className={"editButton2"}
                              style={{
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                              }}
                              onClick={enableOtpButtons}
                            >
                              {showLoading && (
                                <CircularProgress size={24} color="inherit" />
                              )}
                              <p className="OtpBtn">Integrate</p>
                            </div>
                          )}
                        </>
                      )}

                    {variables.showAlert && (
                      <ShowAlert
                        title={variables.alertTitle}
                        message={variables.alertMessage}
                        onClose={handleAlertClose}
                      />
                    )}

                    {selectedOption === "alreadyRegistered" &&
                      (visible.mobileOtp
                        || visible.aadhaarOtp
                      ) && (
                        <>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ flex: 1, textAlign: "center" }}>
                              <h2>ABHA OTP REQUEST</h2>
                            </div>
                            <CloseIcon
                              style={{ cursor: "pointer", fontSize: "24px", marginRight: "10px" }}
                              // onClick={() => navigate(-1)} // Go back to the previous page
                              onClick={handleotpCancel}
                            />
                          </div>
                          <h3 style={{ textAlign: "center" }}>
                            Verify OTP to access your ABHA Account
                          </h3>
                          {variables.abhaNo && (
                            <p>
                              Enter the code sent to Mobile number which is
                              linked with this ABHA Address/Number
                              <h4
                                style={{
                                  color: "blue",
                                  display: "inline",
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                }}
                              >
                                {variables.abhaNo}
                              </h4>
                            </p>
                          )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <OtpInput
                              value={variables.otp}
                              onChange={handleChange}
                              numInputs={6}
                              renderSeparator={<span>{""}</span>}
                              renderInput={(props) => (
                                <input {...props} type="tel" />
                              )}
                              skipDefaultStyles={true}
                              inputStyle="otp-input"
                              inputType="number"
                            />
                          </div>

                          <div
                            onClick={handleResend1}
                            style={{
                              marginLeft: "60%",
                              color: "orange",
                              cursor: disabled ? "not-allowed" : "pointer",
                              opacity: disabled ? 0.6 : 1,
                            }}
                          >
                            Resend OTP? {disabled && `(${timer}s)`}
                          </div>

                          <div className="Adharandmobile">
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              onClick={handleotpCancel}
                            >
                              Cancel
                            </button>
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              onClick={async () => {
                                // verifyAadhaarOtpABHA();
                                if (!isNaN(variables.abhaNo) && variables.abhaNo.trim() !== "") {
                                  await verifyAadhaarOtpABHA();
                                } else {
                                  await verifyabhaaddressOtpABHA();
                                }
                              }}
                            >
                              {showLoading && (
                                <CircularProgress size={24} color="inherit" />
                              )}
                              Proceed
                            </button>
                          </div>
                        </>
                      )}

                    {/* for abha detials */}

                    {selectedOption === "alreadyRegistered" &&
                      visible.getabhadetails && !visible.verifyforgetotp && !visible.abhadetails && (
                        <>
                          <p style={{
                            color: "black", fontSize: "20px", fontWeight: "400",
                            letterSpacing: "0.046", textAlign: "center"
                          }}>
                            Enter Details
                          </p>
                          <p style={{
                            color: "black", fontSize: "18px", fontWeight: "400",
                            letterSpacing: "0.046", textAlign: "center"
                          }}>
                            Enter your Aadhaar or Mobile Number to get the ABHA details
                          </p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <input
                              style={{
                                display: "block",
                                width: "70%",
                              }}
                              className="abhanoInfoInput"
                              type="text"
                              placeholder="Enter Aadhaar Number/Mobile"
                              value={variables.forgotaadharnum}
                              onChange={(e) => handleInputChange(e, "forgotaadharnum")}
                            />
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", marginLeft: "15%" }}>
                            <div style={{
                              display: "flex", justifyContent: "center", alignItems: "center",
                              marginRight: "10%", flexDirection: "column"
                            }}>
                              <p style={{ fontSize: "20px" }}>CAPTCHA: {captchaCode}</p>
                            </div>

                            <input
                              style={{
                                display: "block",
                                width: "40%",
                              }}
                              className="abhanoInfoInput"
                              type="text"
                              placeholder="Enter CAPTCHA"
                              value={variables.captcha}
                              onChange={(e) =>
                                handleInputChange(e, "captcha")
                              }
                              onPaste={(e) => e.preventDefault()}
                            />
                          </div>

                          <div className="Adharandmobile">
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              // onClick={generateforgotabhanumber}
                              onClick={() => {
                                if (captchaCode !== variables.captcha) {
                                  toast.error("Captcha does not match. Please try again.");
                                } else if (!variables.forgotaadharnum || variables.forgotaadharnum.length !== 12) {
                                  toast.error("Please enter a valid 12-digit Aadhaar number.");
                                } else {
                                  generateforgotabhanumber();
                                }
                              }}
                            >
                              {showLoading && (
                                <CircularProgress size={24} color="inherit" />
                              )}
                              Continue
                            </button>
                          </div>

                        </>
                      )}

                    {selectedOption === "alreadyRegistered" &&
                      visible.verifyforgetotp && !visible.abhadetails
                      && (
                        <>
                          <h2 style={{ textAlign: "center" }}>
                            ABHA OTP REQUEST
                          </h2>
                          <h3 style={{ textAlign: "center" }}>
                            Verify OTP to access your ABHA Account
                          </h3>
                          {variables.forgotaadharnum && (
                            <p style={{ textAlign: "center" }}>
                              Enter the code sent to your mobile
                              <h4
                                style={{
                                  color: "blue",
                                  display: "inline",
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                }}
                              >
                                {variables.forgotaadharnum}
                              </h4>
                            </p>
                          )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <OtpInput
                              value={variables.forgototp}
                              onChange={handleChange}
                              numInputs={6}
                              renderSeparator={<span>{""}</span>}
                              renderInput={(props) => (
                                <input {...props} type="tel" />
                              )}
                              skipDefaultStyles={true}
                              inputStyle="otp-input"
                              inputType="number"
                            />
                          </div>

                          <div className="Adharandmobile">
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              onClick={() => {
                                verifyforgetabhanumberotp();
                              }}
                            >
                              {showLoading && (
                                <CircularProgress size={24} color="inherit" />
                              )}
                              Proceed
                            </button>
                          </div>
                        </>
                      )}

                    {selectedOption === "alreadyRegistered" &&
                      visible.abhadetails && (
                        <>
                          <h2 style={{ textAlign: "center" }}>
                            Available ABHA Accounts
                          </h2>

                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "40px" }}>
                            <div style={{
                              border: '1px solid #0b4876',
                              borderRadius: "10px",
                              width: '80%',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '10px',
                              marginRight: '10px'
                            }}>
                              <input
                                type="radio"
                                name="abhaOption"
                                value="yes"
                                style={{ marginRight: '30px' }}
                              />
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                <p>{variables.abhaname}</p>
                                <h4>{variables.abhanumber}</h4>
                                <h4>{variables.abhaNo}</h4>
                              </div>
                            </div>
                          </div>

                          <div className="Adharandmobile">
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              onClick={() => {
                                setVisible({
                                  ...visible,
                                  mobileOtp: false,
                                  aadhaarOtp: false,
                                  getabhadetails: false,
                                  verifyforgetotp: false,
                                  abhadetails: false,
                                });

                                setSelectedOption("alreadyRegistered");
                                // setVariables((prev) => ({
                                //   ...prev,
                                //   abhaAddress: prev.abhaNo,
                                // }));
                              }}
                            >
                              {showLoading && (
                                <CircularProgress size={24} color="inherit" />
                              )}
                              Ok
                            </button>
                          </div>
                        </>
                      )}


                    {selectedOption === "haventRegistered" &&
                      !visible.aadharNoOtp && (
                        <>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <p className="content" style={{ fontSize: "16px", alignItems: "center", marginBottom: "10px" }}>
                              Don't have ABHA id? Register your ABHA id
                              using your Aadhaar Number.
                            </p>
                            <div className="Abhanumfield">
                              <input
                                style={{
                                  display: "block",
                                  width: "70%",
                                  marginBottom: "20px"
                                }}
                                className="abhanoInfoInput"
                                type="text"
                                maxLength="12"
                                placeholder="Enter Aadhaar Number"
                                value={variables.aadhaarNumber}
                                onChange={(e) =>
                                  handleInputChange(e, "aadhaarNumber")
                                }
                              />
                            </div>
                            <div className="content" style={{
                              fontSize: "16px", alignItems: "center",
                              width: "95%", marginLeft: "20px"
                            }}>
                              Enter mobile number. It can be you AADHAAR linked mobile abhaNumberVerifyOTP
                              or any other number. This will be your ABHA communication number.
                            </div>
                            <div className="Abhanumfield">
                              <input
                                style={{
                                  display: "block",
                                  width: "70%",
                                  marginTop: "10px"
                                }}
                                className="abhanoInfoInput"
                                type="text"
                                maxLength="10"
                                placeholder="Enter Mobile Number"
                                value={variables.mobilenumber}
                                onChange={(e) =>
                                  handleInputChange(e, "mobilenumber")
                                }
                              />
                            </div>

                            <div
                              className={"editButton2"}
                              style={{
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                marginTop: "25px",
                                color: "white"
                              }}
                              onClick={
                                () => {
                                  if (!variables.mobilenumber || variables.mobilenumber.length != 10) {
                                    setSnackbarMessage("Please enter a valid mobile number");
                                    setShowSnackbar(true);
                                  }
                                  else {
                                    generateAadhaarOTP();
                                  }
                                }}
                            >
                              {showLoading && (
                                <CircularProgress size={24} color="inherit" />
                              )}
                              <p className="OtpBtn">Register</p>
                            </div>
                          </div>
                        </>
                      )}

                    {selectedOption === "haventRegistered" &&
                      visible.aadharNoOtp && (
                        <>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ flex: 1, textAlign: "center" }}>
                              <h2>ABHA OTP REQUEST</h2>
                            </div>
                            <CloseIcon
                              style={{ cursor: "pointer", fontSize: "24px", marginRight: "10px" }}
                              onClick={handleaadhaarotpCancel}
                            />
                          </div>

                          <h3 style={{ textAlign: "center" }}>
                            Verify OTP to access your ABHA Account
                          </h3>
                          {variables.aadhaarNumber && (
                            <p style={{ textAlign: "center" }}>
                              Enter the code sent to Mobile number linked to
                              this Aadhaar Number
                              <h4
                                style={{
                                  color: "blue",
                                  display: "inline",
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                }}
                              >
                                {getMaskedText(variables.aadhaarNumber)}
                              </h4>
                            </p>
                          )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <OtpInput
                              value={variables.otp}
                              onChange={handleChange}
                              numInputs={6}
                              renderSeparator={<span>{""}</span>}
                              renderInput={(props) => (
                                <input {...props} type="tel" />
                              )}
                              skipDefaultStyles={true}
                              inputStyle="otp-input"
                              inputType="number"
                            />
                          </div>
                          <div
                            onClick={handleResend}
                            style={{
                              marginLeft: "60%",
                              color: "orange",
                              cursor: disabled ? "not-allowed" : "pointer",
                              opacity: disabled ? 0.6 : 1,
                            }}
                          >
                            Resend OTP? {disabled && `(${timer}s)`}
                          </div>

                          <div className="Adharandmobile">
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              onClick={handleaadhaarotpCancel}
                            >
                              Cancel
                            </button>
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              // onClick={handleProceed}
                              onClick={verifyAadhaarOTP}
                            >
                              {showLoading && (
                                <CircularProgress size={24} color="inherit" />
                              )}
                              Proceed
                            </button>
                          </div>
                        </>
                      )}

                    {selectedOption === "createABHA" && (
                      <>
                        <p className="content">
                          Create your ABHA Address using your Mobile Number
                        </p>
                        {/* <div className="Abhanumfield">
                          <input
                            style={{
                              display: "block",
                              width: "70%",
                            }}
                            className="abhanoInfoInput"
                            type="text"
                            maxLength="10"
                            placeholder="Enter Mobile Number"
                            value={variables.phrMobile}
                            onChange={handleMobileChange}
                          />
                        </div>

                        <div
                          className={"editButton2"}
                          style={{
                            width: "30%",
                            margin: "0 auto",
                            height: "40px",
                          }}
                          onClick={generatePHRMobileOTP}
                        >
                          <p className="OtpBtn">CREATE</p>
                        </div>*/}
                        {/*Another format for Mobile number*/}
                        <div className="Abhanumfield">
                          <input
                            style={{
                              display: "block",
                              width: "70%",
                            }}
                            className="abhanoInfoInput"
                            type="text"
                            maxLength="10"
                            placeholder="Enter Mobile Number"
                            value={variables.phrMobile}
                            onChange={handleMobileChange}
                          />
                        </div>

                        <div
                          className={"editButton2"}
                          style={{
                            width: "30%",
                            margin: "0 auto",
                            height: "40px",
                            color: "white"
                          }}
                          onClick={generatePHRMobileOTP}
                        >
                          {showLoading && (
                            <CircularProgress size={24} color="inherit" />
                          )}
                          Create
                        </div>
                      </>
                    )}

                    {!visible.mobileOtp && !visible.aadhaarOtp && (
                      <>
                        <p
                          className="SkipAbhatext"
                          style={{ fontSize: "16px", fontWeight: "600" }}
                          onClick={skipABHA}
                        >
                          Skip ABHA
                        </p>

                        <div className="NHAandimage">
                          <p className="nhaText" style={{ paddingTop: "10px" }}>
                            APPROVED BY NHA
                          </p>
                          <img src={NHA} />
                        </div>
                      </>
                    )}
                  </div>
                </>
              </div>
            )}
          </div>
        </>

        <ToastContainer />

        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="warning"
            sx={{ width: "100%", bgcolor: "#FDAD00", color: "white" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default ABHAIntegration;
