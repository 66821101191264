import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./DiabetesRiskAssesment.css";
// import ButtonGroup from "./Visits/CustomButton";
import { number } from "echarts";

const DiabetesRiskAssessment = () => {
  const [formValues, setFormValues] = useState({
    ageScore: "",
    bmiScore: "",
    genderWaistScore: "",
    activityScore: "",
    vegetableScore: "",
    bpMedicationScore: "",
    glucoseScore: "",
    familyScore: "",
  });

  const [totalScore, setTotalScore] = useState(0);
  const [condition, setCondition] = useState("");
  const [message, setMessage] = useState("");

  const options = {
    ageScore: {
      Under_45_years: 0,
      Between_45_54_years: 2,
      Between_55_64_years: 3,
      Over_64_years: 4,
    },
    bmiScore: {
      Lower_than_25kgm2: 0,
      Between_25_to_30kgm2: 1,
      Higher_than_30kgm2: 3,
    },
    genderWaistScore: {
      Male: 0,
      Female: 1,
    },
    maleWaistOptions: {
      Less_than_94cm: 0,
      Between_95_to_102cm: 3,
      More_than_102cm: 4,
    },
    femaleWaistOptions: {
      Less_than_80cm: 0,
      Betwen_80_to_88cm: 3,
      More_than_88cm: 4,
    },
    activityScore: {
      Yes: 0,
      No: 2,
    },
    vegetableScore: {
      Everyday: 0,
      Not_Everyday: 1,
    },
    bpMedicationScore: {
      Yes: 2,
      No: 0,
    },
    glucoseScore: {
      Yes: 5,
      No: 0,
    },
    familyScore: {
      No: 0,
      Yes_Grandparent_Aunt_Uncle_or_First_Cousin: 3,
      Yes_Parent_Brother_Sister_or_own_child: 5,
    },
  };

  const calculateMaleWaistScore = (selectedValue) => {
    return options.maleWaistOptions[selectedValue];
  };

  const calculateFemaleWaistScore = (selectedValue) => {
    return options.femaleWaistOptions[selectedValue];
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Assuming options is defined outside the component function
    const numericValue = options[name][value];

    let updatedFormValues;

    if (name === "maleWaistOptions") {
      calculateMaleWaistScore(value);
      updatedFormValues = {
        ...formValues,
        genderWaistScore: calculateMaleWaistScore(value),

      };
      console.log(value, "malegendervalue");
    } else if (name === "femaleWaistOptions") {
      calculateFemaleWaistScore(value);
      updatedFormValues = {
        ...formValues,
        genderWaistScore: calculateFemaleWaistScore(value),
      };
      console.log(value, "femalegendervalue");
    } else {
      updatedFormValues = {
        ...formValues,
        [name]: numericValue,
      };
    }

    setFormValues(updatedFormValues);
    // console.log(setFormValues, "setFormValues");
  };

  const handleGenderChange = (event) => {
    const genderValue = event.target.value;
    const maleWaistSection = document.getElementById("maleWaistSection");
    const femaleWaistSection = document.getElementById("femaleWaistSection");
    const maleWaistDropdown = document.getElementById("maleWaistDropdown");
    const femaleWaistDropdown = document.getElementById("femaleWaistDropdown");

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      genderWaistScore: genderValue,
    }));

    if (genderValue === "Male") {
      maleWaistSection.style.display = "block";
      femaleWaistSection.style.display = "none";
      maleWaistDropdown.disabled = false;
      femaleWaistDropdown.disabled = true;
    } else if (genderValue === "Female") {
      maleWaistDropdown.value = "selectOption";
      maleWaistSection.style.display = "none";
      femaleWaistSection.style.display = "block";
      maleWaistDropdown.disabled = true;
      femaleWaistDropdown.disabled = false;
    }
  };

  const checkTotal = () => {
    // console.log("genderWaist",formValues.genderWaistScore !== "" && typeof formValues.genderWaistScore === "number"
    // ,formValues.genderWaistScore,typeof(formValues.genderWaistScore));
    if (
      formValues.ageScore !== "" &&
      formValues.bmiScore !== "" &&
      formValues.genderWaistScore !== "" && typeof formValues.genderWaistScore === "number"
      &&
      formValues.activityScore !== "" &&
      formValues.vegetableScore !== "" &&
      formValues.bpMedicationScore !== "" &&
      formValues.glucoseScore !== "" &&
      formValues.familyScore !== "" &&
      (options.maleWaistOptions !== "" || options.femaleWaistOptions !== "")
    ) {
      // console.log("genderWaist",formValues);
      calculateTotal()
    } else {
      toast.error("Enter all the fields");
    }
  };

  const calculateTotal = () => {
    const {
      ageScore,
      bmiScore,
      genderWaistScore,
      activityScore,
      vegetableScore,
      bpMedicationScore,
      glucoseScore,
      familyScore,
    } = formValues;

    if (
      ageScore !== null &&
      bmiScore !== null &&
      genderWaistScore !== null &&
      activityScore !== null &&
      vegetableScore !== null &&
      bpMedicationScore !== null &&
      glucoseScore !== null &&
      familyScore !== null
    ) {
      const calculatedTotalScore =
        formValues.ageScore +
        formValues.bmiScore +
        formValues.activityScore +
        formValues.genderWaistScore +
        formValues.vegetableScore +
        formValues.bpMedicationScore +
        formValues.glucoseScore +
        formValues.familyScore;
      setTotalScore(calculatedTotalScore);
      //console.log(calculatedTotalScore,"calculatedTotalScore");
      //console.log("+++", ageScore);
      //console.log("----", bmiScore);
      //console.log(";;;", activityScore);
      //console.log("]]]", genderWaistScore);
      //console.log("!!!!", vegetableScore);
      //console.log("~~~", bpMedicationScore);
      //console.log("@@@", glucoseScore);
      //console.log("$$$$$", familyScore);
      // Set condition and message based on total score
      if (calculatedTotalScore >= 0 && calculatedTotalScore <= 6) {
        setCondition("Low");
        setMessage(
          "ESTIMATED 1 IN 100 WILL DEVELOP DISEASE \n\nYou can’t do anything about your age or your genetic predisposition. Other factors predisposing to type 2 diabetes, such as being overweight, abdominal obesity, sedentary lifestyle, eating habits and smoking, are up to you. Your lifestyle choices can prevent type 2 diabetes or at least delay its onset until a much greater age. \n\nIf there is diabetes in your family, you should be careful not to put on weight over the years. Growth of the waistline, in particular, increases the risk of type 2 diabetes, whereas regular moderate physical activity will lower the risk. You should also pay attention to your diet: take care to eat plenty of fibre-rich cereal products and vegetables every day. Omit excess hard fats from your diet and favour soft vegetable fats."
        );
      } else if (calculatedTotalScore >= 7 && calculatedTotalScore <= 11) {
        setCondition("Slightly Elevated");
        setMessage(
          "ESTIMATED 1 IN 25 WILL DEVELOP DISEASE \n\nYou can’t do anything about your age or your genetic predisposition. Other factors predisposing to type 2 diabetes, such as being overweight, abdominal obesity, sedentary lifestyle, eating habits and smoking, are up to you. Your lifestyle choices can prevent type 2 diabetes or at least delay its onset until a much greater age. \n\nIf there is diabetes in your family, you should be careful not to put on weight over the years. Growth of the waistline, in particular, increases the risk of type 2 diabetes, whereas regular moderate physical activity will lower the risk. You should also pay attention to your diet: take care to eat plenty of fibre-rich cereal products and vegetables every day. Omit excess hard fats from your diet and favour soft vegetable fats"
        );
      } else if (calculatedTotalScore >= 12 && calculatedTotalScore <= 14) {
        setCondition("Moderate");

        setMessage(
          "ESTIMATED 1 IN 6 WILL DEVELOP DISEASE \n\nEarly stages of type 2 diabetes seldom cause any symptoms. You would be well advised to seriously consider your physical activity and eating habits and pay attention to your weight, to prevent yourself from developing diabetes. Please contact a public-health nurse or your own doctor for further guidance and tests. \n\nYou can’t do anything about your age or your genetic predisposition. Other factors predisposing to type 2 diabetes, such as being overweight, abdominal obesity, sedentary lifestyle, eating habits and smoking, are up to you. eating habits and smoking, are up to you. Your lifestyle choices can prevent type 2 diabetes or at least delay its onset until a much greater age. \n\nIf there is diabetes in your family, you should be careful not to put on weight over the years. Growth of the waistline, in particular, increases the risk of type 2 diabetes, whereas regular moderate physical activity will lower the risk. You should also pay attention to your diet: take care to eat plenty of fibre-rich cereal products and vegetables every day. Omit excess hard fats from your diet and favour soft vegetable fats. "
        );
      } else if (calculatedTotalScore <= 20) {
        setCondition("Very High");
        setMessage(
          "ESTIMATED 1 IN 2 WILL DEVELOP DISEASE \n\nYou should have your blood glucose measured (both fasting value and value after a dose of glucose or a meal) to determine if you have diabetes without symptoms. \n\nEarly stages of type 2 diabetes seldom cause any symptoms. You would be well advised to seriously consider your physical activity and eating habits and pay attention to your weight, to prevent yourself from developing diabetes. Please contact a public-health nurse or your own doctor for further guidance and tests."
        );
      } else if (calculatedTotalScore >= 15 && calculatedTotalScore <= 20) {
        setCondition(" High");
        setMessage(
          "ESTIMATED 1 IN 3 WILL DEVELOP DISEASE \n\nYou should have your blood glucose measured (both fasting value and value after a dose of glucose or a meal) to determine if you have diabetes without symptoms. \n\nEarly stages of type 2 diabetes seldom cause any symptoms. You would be well advised to seriously consider your physical activity and eating habits and pay attention to your weight, to prevent yourself from developing diabetes. Please contact a public-health nurse or your own doctor for further guidance and tests"
        );
      }
    }
  };

  return (
    <div className="calcRightContainer">
      <div className="HeadingBmiCalculator">
        <p className="BmiCalculatorHeading">Diabetes Risk Assessment</p>
      </div>
      <div style={{ margin: "1%" }}>
        <div className="row-container">
          <div style={{ display: "flex", gap: "8%" }}>
            <div className="Diabetesrow-containerinner">
              <label className="CalculatorLabel" htmlFor="ageDropdown">
                Age Range
              </label>
              <select
                className="DiabetesSelectTag"
                name="ageScore"
                id="ageDropdown"
                onChange={handleInputChange}
              >
                <option></option>
                <option value="Under_45_years">Under 45 years</option>
                <option value="Between_45_54_years">
                  Between 45 - 54 years
                </option>
                <option value="Between_55_64_years">
                  Between 55 - 64 years
                </option>
                <option value="Over_64_years">Over 64 years</option>
              </select>
            </div>
            <div className="Diabetesrow-containerinner">
              <label className="CalculatorLabel" htmlFor="bmiDropdown">
                BMI Range
              </label>
              <select
                className="DiabetesSelectTag"
                id="bmiDropdown"
                name="bmiScore"
                onChange={handleInputChange}
              >
                <option></option>
                <option value="Lower_than_25kgm2">Lower than 25 kg/m2</option>
                <option value="Between_25_to_30kgm2">
                  Between 25 - 30 kg/m2
                </option>
                <option value="Higher_than_30kgm2">Higher than 30 kg/m2</option>
              </select>
            </div>
          </div>
          <div className="waistManpulation" style={{ gap: "8%" }}>
            <div className="Diabetesrow-containerinner">
              <label className="CalculatorLabel" htmlFor="genderDropdown">
                Gender
              </label>
              <select
                id="genderDropdown"
                className="DiabetesSelectTag"
                name="genderWaistScore"
                onChange={handleGenderChange}
              >
                <option></option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>

            <div id="maleWaistSection" style={{ display: "none" }}>
              <div className="Diabetesrow-containerinner">
                <label className="CalculatorLabel" htmlFor="maleWaistDropdown">
                  Waist
                </label>
                <select
                  id="maleWaistDropdown"
                  name="maleWaistOptions"
                  onChange={handleInputChange}
                >
                  <option></option>
                  <option value="Less_than_94cm">Less than 94 cm</option>
                  <option value="Between_95_to_102cm">Between 95-102 cm</option>
                  <option value="More_than_102cm">More than 102 cm</option>
                </select>
              </div>
            </div>

            <div id="femaleWaistSection" style={{ display: "none" }}>
              <div className="Diabetesrow-containerinner">
                <label
                  className="CalculatorLabel"
                  htmlFor="femaleWaistDropdown"
                >
                  Waist
                </label>
                <select
                  id="femaleWaistDropdown"
                  name="femaleWaistOptions"
                  onChange={handleInputChange}
                >
                  <option></option>
                  <option value="Less_than_80cm">Less than 80 cm</option>
                  <option value="Betwen_80_to_88cm">80-88 cm</option>
                  <option value="More_than_88cm">More than 88 cm</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row-container1">
          <div className="textparagsize">
            <label className="diabetes-text" htmlFor="physicalActivityDropdown">
              Do you usually do at least 30 min of daily physical activity at
              work and/or during leisure time? (including normal daily activity)
            </label>

            {/* <ButtonGroup onChange={handleInputChange} /> */}
            <select
              id="physicalActivityDropdown"
              name="activityScore"
              className="diabetesphysicalactivities"
              onChange={handleInputChange}
            >
              <option></option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="textparagsize">
            <label
              className="diabetes-text"
              htmlFor="vegetableFrequencyDropdown"
            >
              How often do you eat vegetables, fruit or berries?
            </label>
            <select
              id="vegetableFrequencyDropdown"
              className="diabetesvegetablefreq"
              name="vegetableScore"
              onChange={handleInputChange}
            >
              <option></option>
              <option value="Everyday">Everyday</option>
              <option value="Not_Everyday">Not Everyday</option>
            </select>
          </div>
        </div>
        <div className="row-container1">
          <div className="textparagsize">
            <label
              className="diabetes-text"
              htmlFor="medicationForHighBPDropdown"
            >
              Have you ever taken medication for high blood pressure on a
              regular basis?
            </label>
            <select
              id="medicationForHighBPDropdown"
              name="bpMedicationScore"
              className="diabetesmedichighbp"
              onChange={handleInputChange}
            >
              <option></option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="textparagsize">
            <label className="diabetes-text" htmlFor="highSugarDropdown">
              Have you ever been found to have high blood glucose? (eg. in a
              health examination, during an illness, during pregnancy)
            </label>
            <select
              id="highSugarDropdown"
              name="glucoseScore"
              className="diabeteshighsugar"
              onChange={handleInputChange}
            >
              <option></option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        {/*
        <div className="row-container1">
        <div style={{ width: "45%", display: "flex" }}>
          <label className="diabetes-text"  htmlFor="medicationForHighBPDropdown">
            Have you ever taken medication <br />
            for high blood pressure on a regular basis?:
          </label>
          <select
            id="medicationForHighBPDropdown"
            name="bpMedicationScore"
            className="diabetesmedichighbp"
            onChange={handleInputChange}
          >
            <option value="selectOption" selected></option>
            <option className="default-values" value="none" selected disabled hidden>Yes or No</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div style={{ width: "45%", display: "flex" }}>
          <label className="diabetes-text"  htmlFor="highSugarDropdown">
            Have you ever been found to have high blood glucose? (eg. in a
            health examination, during an illness, during pregnancy)
          </label>
          <select
            id="highSugarDropdown"
            name="glucoseScore"
            className="diabeteshighsugar"
            onChange={handleInputChange}
          >
            <option value="selectOption" selected></option>
            <option className="default-values" value="none" selected disabled hidden>Yes or No</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
      */}


        <div className="row-container2">
          <label className="diabetes-text" htmlFor="familyDBDiagnosisDropdown">
            Have you or any other members of your immediate family or other
            relatives been diagnosed with diabetes? (type 1 or type 2)
          </label>
          <div className="select-wrapper">
            <select
              id="familyDBDiagnosisDropdown"
              name="familyScore"
              className="diabetesfamilydiagnos"
              onChange={handleInputChange}

            >
              <option value="">Select an option</option>
              <option value="No">No</option>
              <option value="Yes_Grandparent_Aunt_Uncle_or_First_Cousin">
                Yes: Grandparent, Aunt, Uncle or First Cousin
              </option>
              <option value="Yes_Parent_Brother_Sister_or_own_child">
                Yes: Parent, Brother, Sister or own child
              </option>
            </select>
          </div>
        </div>

        <div className="DiabetiescalBtn">
          <button
            className="diabetesCalculateButtonCalculate"
            onClick={checkTotal}
          >
            Calculate
          </button>
        </div>
        <div className="diabetesCalculateButtonCalculateinnerall">
          <div className="diabetesCalculateButtonCalculateinner">
            <p id="totalScore">Total Score: {totalScore}</p>

            <p id="condition">Condition: {condition}</p>
          </div>
          {message !== "" ? (
            <div className="diabetesCalculateButtonCalculatemesage">
              <p id="message">Message: {message}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default DiabetesRiskAssessment;
