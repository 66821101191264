import React, { useState, useEffect } from "react";
import "./AddPatients.css";
import icon from "../images/icon.png";
import nurse from "../images/nurse.png";
import profile from "../images/profile.png";
import backgroundCircle from "../images/backgroundCircle.png";
import { Link } from "react-router-dom";
import Navbar from "../components/modules/Navbar";
import Axios from "axios";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config from "../Config/config";
import dayjs from "dayjs";
import LeftArrow from "../images/leftArrow.svg";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import NHA from "../images/nha.svg";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import backArrow from "../images/Group 417.svg";
import ABHAIntegration from "../components/modules/ABHA/Abha_Integration";
import { useLocation } from "react-router-dom";
import { TextField } from "@mui/material";
import { differenceInYears, parseISO } from "date-fns";
import { PDFDocument } from "pdf-lib";

const AddPatients = () => {
  const [skip, setSkip] = useState(false);
  const [patientCount, setPatientCount] = useState(0);
  const [showAlert, setshowAlert] = useState(false);
  const [value, setValue] = useState(new Date());
  const [activeSection, setActiveSection] = useState("ABHA");
  const [selectedOption, setSelectedOption] = useState("alreadyRegistered");
  const [PatientID, setPatientID] = useState(null);
  const [error, setError] = useState({ firstName: "", lastName: "" });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const routeaction = queryParams.get("action");
  const DoctorCity = JSON.parse(sessionStorage.getItem("City"));
  const DoctorState = JSON.parse(sessionStorage.getItem("State"));
  const [isLoading, setIsLoading] = useState(true);
  const [emailWarning, setEmailWarning] = useState("");

  //console.log("userdata", DoctorState);

  const [patientDetails, setpatientDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    patientUniqueId: "",
    email: "",
    abhaNo: "",
    abhaAddress: "",
    address1: "",
    address2: "",
    address3: "",
    state: DoctorState ? DoctorState : "",
    city: DoctorCity ? DoctorCity : "",
    gender: "",
    pinCode: "",
    Age: "",
    dateOfBirth: "",
    govtID: "",
  });
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");
  const abhaaddressnew = sessionStorage.getItem('newabhaaddress');


  function getDateOfBirth(age) {
    const today = new Date();
    const birthYear = today.getFullYear() - age;
    // Create a new date for the birth date, assuming the birthdate is today in the birth year
    const birthDate = new Date(today.setFullYear(birthYear));
    return birthDate;
  }

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const PatientID = queryParams.get("patientId");

  // routeaction
  function calculateAgeAbha(day, month, year) {
    let birthdate = new Date(year, month - 1, day);
    let today = new Date();
    let age = today.getFullYear() - birthdate.getFullYear();
    let monthDifference = today.getMonth() - birthdate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthdate.getDate())
    ) {
      age--;
    }

    return age;
  }

  function calculateAgeAbhadbo(dob) {
    // Split DOB string into day, month, and year
    let [day, month, year] = dob.split("-").map(Number);

    let birthdate = new Date(year, month - 1, day); // Month is 0-based in JS
    let today = new Date();

    let age = today.getFullYear() - birthdate.getFullYear();
    let monthDifference = today.getMonth() - birthdate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthdate.getDate())
    ) {
      age--;
    }

    return age;
  }

  useEffect(() => {
    const linkabha = sessionStorage.getItem("selectedpatient_linkabha");
    if (routeaction === "addpatient") {

      //console.log(routeaction, "sjdoiosoiasionion");
    } else if (routeaction === "abhatoaddpatient" && linkabha == "ADD_ABHA") {
      const profile_data = sessionStorage.getItem("profileData");
      console.log("my profiledata", profile_data);
      const profileData = profile_data ? JSON.parse(profile_data) : null;
      console.log("profile data", profileData.preferredAbhaAddress);
      // const age = calculateAgeAbha(
      //   profileData?.dayOfBirth,
      //   profileData?.monthOfBirth,
      //   profileData?.yearOfBirth
      // );
      const age = profileData?.dob
        ? calculateAgeAbha(
          Number(profileData.dob.split("-")[0]),
          Number(profileData.dob.split("-")[1]),
          Number(profileData.dob.split("-")[2])
        )
        : calculateAgeAbha(
          profileData?.dayOfBirth,
          profileData?.monthOfBirth,
          profileData?.yearOfBirth
        )
      // const dob = calculateAgeAbhadbo(profileData.dob);
      if (profileData) {
        // console.log(
        //   profileData,
        //   "profileDataprofileDataprofileDataprofileDataprofileDataprofileData"
        // );
        setpatientDetails({
          firstName: `${profileData.firstName} ${profileData.middleName}` || "",
          lastName: profileData.lastName || "",
          phoneNumber: profileData.mobile || "",
          patientUniqueId: profileData.PATIENT_ID || `CLA/${profileData.mobile}/001`,
          email: profileData?.EMAIL || profileData?.email || "",
          abhaNo: profileData?.healthIdNumber || profileData?.abhaNumber || profileData?.ABHANumber || "",
          abhaAddress: profileData?.healthId || abhaaddressnew || profileData?.preferredAbhaAddress || profileData?.abhaAddress || "",
          address1: profileData?.address || "",
          address2: profileData?.ADDRESS2 || "",
          address3: profileData?.ADDRESS3 || "",
          state: profileData?.stateName || DoctorState || "",
          city: profileData?.villageName || profileData?.districtName || DoctorCity,
          gender:
            profileData?.gender === "M"
              ? "Male"
              : profileData?.gender === "F"
                ? "Female"
                : "" || "",
          pinCode: profileData?.pincode || "",
          Age: age || "",
          // dateOfBirth: profileData?.dob ? dayjs(profileData.dob, "DD-MM-YYYY").format("YYYY-MM-DD") : "",
          dateOfBirth: profileData?.dob
            ? dayjs(profileData.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
            : profileData?.yearOfBirth && profileData?.monthOfBirth && profileData?.dayOfBirth
              ? dayjs(`${profileData.yearOfBirth}-${profileData.monthOfBirth}-${profileData.dayOfBirth}`).format("YYYY-MM-DD")
              : "",
          // dateOfBirth: getDateOfBirth(age) || "",
          govtID: profileData?.GOVT_ID_NUMBER || "",
        });
      }
    } else {
      const PatientID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
      const selectedPatientDetails = JSON.parse(
        sessionStorage.getItem("selectedPatientDetails")
      );
      const profile_data = sessionStorage.getItem("profileData");
      const profileData = profile_data ? JSON.parse(profile_data) : null;
      console.log("patient id", profile_data, PatientID, selectedPatientDetails);
      setPatientID(PatientID);
      if (PatientID !== "") {
        try {
          const apiUrl = `${Config.apiURL}${Config.routes.getPatientAPI}/${PatientID}/${UserID}`;

          console.log("Request URL:", apiUrl);

          Axios.get(apiUrl, {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
              "x-caller": encrypted,
            },
          })
            .then((res) => {
              console.log("response........", res);
              setpatientDetails({
                firstName: res.data.data[0].FIRST_NAME || "",
                lastName: res.data.data[0].LAST_NAME || "",
                phoneNumber: res.data.data[0].MOBILE || "",
                patientUniqueId: res.data.data[0].PATIENT_ID || "",
                email: res.data.data[0].EMAIL || "",
                abhaNo:
                  res.data.data[0].ABHA_NUMBER || res.data.data[0].ABHA_NUMBER ||
                  selectedPatientDetails.ABHA_NUMBER || profileData?.ABHANumber ||
                  "",
                abhaAddress:
                  res.data.data[0].ABHA_HPR_ADDRESS ||
                  selectedPatientDetails.ABHA_ADDRESS || abhaaddressnew ||
                  "",
                address1: res.data.data[0].ADDRESS1 || "",
                address2: res.data.data[0].ADDRESS2 || "",
                address3: res.data.data[0].ADDRESS3 || "",
                state: res.data.data[0].STATE || DoctorState,
                city: res.data.data[0].CITY || DoctorCity,
                gender:
                  res.data.data[0].GENDER === "Male"
                    ? "Male"
                    : res.data.data[0].GENDER === "Female"
                      ? "Female"
                      : "" || "",
                pinCode: res.data.data[0].PINCODE || "",
                Age: res.data.data[0].age || "",
                dateOfBirth: res.data.data[0].DATE_OF_BIRTH
                  ? dayjs(res.data.data[0].DATE_OF_BIRTH).format("YYYY-MM-DD")
                  : "",
                // dateOfBirth: res.data.data[0].DATE_OF_BIRTH || "",
                govtID: res.data.data[0].GOVT_ID_NUMBER || "",
              });
            })
            .catch((err) => {
              console.log("error", err);
            });
        } catch (error) {
          console.log("Error parsing selectedPatientData:", error);
          setpatientDetails(null);
        }
      } else {
        setpatientDetails({
          firstName: "",
          lastName: "",
          phoneNumber: "",
          patientUniqueId: "",
          email: "",
          abhaNo: "",
          abhaAddress: "",
          address1: "",
          address2: "",
          address3: "",
          state: "",
          city: "",
          gender: "",
          pinCode: "",
          Age: "",
          dateOfBirth: "",
          govtID: "",
        });
      }
    }
  }, [PatientID]);

  const navigate = useNavigate();

  let patientID = "";

  // const handleInputChange = (event, field) => {
  //   setpatientDetails((prevVariables) => ({
  //     ...prevVariables,
  //     [field]: event.target.value,
  //   }));
  //   if (field === "Age") {
  //     const dob = calculateDateOfBirth(value);
  //     setpatientDetails({
  //       ...patientDetails,
  //       dateOfBirth: dob,
  //       Age: event.target.value,
  //     });
  //   }
  // };

  const handleInputChange = (event, field) => {
    if (field === "Age") {
      const age = event.target.value;
      if (age === "" || (age >= 0 && age <= 120)) {
        handleAgeChange(age);
      } else {
        toast.warning("Please enter a valid age between 0 and 120.");
      }
    } else {
      setpatientDetails((prevDetails) => ({
        ...prevDetails,
        [field]: event.target.value,
      }));
    }
  };

  //to check whether user already registered or not
  const checkPatientWithMobileNo = async () => {
    const patientMobile = patientDetails.phoneNumber;
    //console.log("patientMobile", patientDetails);

    if (
      patientDetails.firstName !== null &&
      patientDetails.phoneNumber !== "" &&
      patientDetails.Age !== "" &&
      patientDetails.gender !== ""
    ) {
      try {
        const response = await Axios.get(
          `${Config.base_url}PATIENTSCOUNT/${patientMobile}`,
          {
            headers: {
              "content-type": "application/json",
              authorization: `Bearer ${token}`,
              "x-caller": await encrypted,
            },
          }
        );
        console.log("response of patients", response);
        if (response.status === 200) {
          const { recordset } = response.data;
          const userCount = recordset?.[0]?.USER_COUNT || 0;

          if (userCount === 0) {
            await patientRegister();
          } else {
            setshowAlert(true);
          }
          setPatientCount(userCount);
        } else if (response.status === 404) {
          return 0;
        } else {
          return 0;
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.warning("Please fill the mandatory fields");
    }
  };

  const calculateAge = (birthDate) => {
    const birthDateObj = dayjs(birthDate);
    const today = dayjs();
    let age = today.year() - birthDateObj.year();
    if (
      !(
        today.isSame(birthDateObj.add(age, "year")) ||
        today.isAfter(birthDateObj.add(age, "year"))
      )
    ) {
      age--;
    }
    return age;
  };

  // const handleDateChange = (dateString) => {
  //   const originalDate = new Date(dateString);
  //   const formattedDate = `${originalDate.getMonth() + 1
  //     }/${originalDate.getDate()}/${originalDate.getFullYear()}`;

  //   const dob = dayjs(formattedDate);
  //   const today = dayjs();

  //   if (dob.isAfter(today)) {
  //     // Date is in the future, do not calculate age
  //     setpatientDetails({
  //       ...patientDetails,
  //       dateOfBirth: formattedDate,
  //       Age: "",
  //     });
  //     return;
  //   }

  //   const diff = today.diff(dob, "day");
  //   const years = Math.floor(diff / 365);
  //   const months = Math.floor((diff % 365) / 30);
  //   const days = diff - years * 365 - months * 30;

  //   let ageString = "";
  //   if (years > 0) {
  //     ageString += `${years} year${years > 1 ? "s" : ""}, `;
  //   }
  //   if (months > 0) {
  //     ageString += `${months} month${months > 1 ? "s" : ""}, `;
  //   }
  //   if (days > 0) {
  //     ageString += `${days} day${days > 1 ? "s" : ""}`;
  //   }

  //   setpatientDetails({
  //     ...patientDetails,
  //     dateOfBirth: formattedDate,
  //     Age: years,
  //     AgeString: ageString.trim(),
  //   });
  // };

  const handleAgeChange = (age) => {
    if (!isNaN(age) && age !== "") {
      const numericAge = age.slice(0, 3); // Limit to 3 digits
      const today = dayjs();
      const birthdate = today.subtract(numericAge, "year");
      const formattedDate = birthdate.format("YYYY-MM-DD");

      setpatientDetails({
        ...patientDetails,
        dateOfBirth: formattedDate,
        Age: numericAge,
      });
    } else {
      setpatientDetails({
        ...patientDetails,
        dateOfBirth: "",
        Age: age.slice(0, 3), // Limit to 3 digits
      });
    }
  };

  const handleClose = () => {
    setshowAlert(false);
  };

  const handleAction = async () => {
    const updatedPatientCount = patientCount + 1;
    //console.log("patientCount", updatedPatientCount);
    const newPatientID = `CLA/${patientDetails.phoneNumber}/00${updatedPatientCount}`;
    //console.log("newPatientID", newPatientID);
    setPatientCount(updatedPatientCount);
    setpatientDetails({
      ...patientDetails,
      patientUniqueId: newPatientID,
    });
    await patientRegister(newPatientID);
    setshowAlert(false);
  };

  // async function convertImageToPDF(base64Image) {
  //   const pdfDoc = await PDFDocument.create();
  //   const page = pdfDoc.addPage([800, 600]);

  //   const imageBytes = atob(base64Image);
  //   const uint8Array = new Uint8Array(imageBytes.length);
  //   for (let i = 0; i < imageBytes.length; i++) {
  //     uint8Array[i] = imageBytes.charCodeAt(i);
  //   }

  //   const pngImage = await pdfDoc.embedPng(uint8Array);
  //   const { width, height } = pngImage;

  //   page.drawImage(pngImage, {
  //     x: 50,
  //     y: 200,
  //     width: 600,
  //     height: 500,
  //   });

  //   const pdfBytes = await pdfDoc.save();
  //   return new Blob([pdfBytes], { type: "application/pdf" });
  // }

  async function convertImageToPDF(base64Image) {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([800, 600]); // Increased page size

    const imageBytes = atob(base64Image);
    const uint8Array = new Uint8Array(imageBytes.length);
    for (let i = 0; i < imageBytes.length; i++) {
      uint8Array[i] = imageBytes.charCodeAt(i);
    }

    const pngImage = await pdfDoc.embedPng(uint8Array);
    let { width, height } = pngImage;

    // Get page dimensions
    const pageWidth = page.getWidth();
    const pageHeight = page.getHeight();

    const maxWidth = pageWidth - 100;
    const maxHeight = pageHeight - 100;
    const scale = Math.min(maxWidth / width, maxHeight / height);

    width *= scale;
    height *= scale;

    // Center the image
    const x = (pageWidth - width) / 2;
    const y = (pageHeight - height) / 2;

    page.drawImage(pngImage, {
      x,
      y,
      width,
      height,
    });

    const pdfBytes = await pdfDoc.save();
    return new Blob([pdfBytes], { type: "application/pdf" });
  }



  // before base 64 abha card
  // async function patientRegister(newPatientID) {
  //   //console.log(newPatientID, "patients details_____________", patientDetails);

  //   try {
  //     const payload = {
  //       data: JSON.stringify({
  //         patientId: newPatientID ? newPatientID : patientDetails.patientUniqueId,
  //         firstName: patientDetails.firstName,
  //         lastName: patientDetails.lastName,
  //         govtIdNumber: patientDetails.govtID,
  //         email: patientDetails.email,
  //         mobile: patientDetails.phoneNumber,
  //         address1: patientDetails.address1,
  //         address2: patientDetails.address2,
  //         address3: patientDetails.address3,
  //         city: patientDetails.city,
  //         state: patientDetails.state || "",
  //         pincode: patientDetails.pinCode,
  //         gender: patientDetails.gender || "",
  //         dateOfBirth: patientDetails.dateOfBirth,
  //         nurseId: UserID,
  //         doctorId: "",
  //         age: patientDetails.Age,
  //         ABHA_NUMBER: patientDetails.abhaNo,
  //         ABHA_ADDRESS: patientDetails.abhaAddress,
  //         ABHA_CARD: null,
  //         ABHA_QR: 'TEST QR',
  //       }),
  //     };
  //     console.log("paylaod --------0", payload);
  //     console.log("Payload being sent:", JSON.stringify(payload, null, 2));

  //     const response = await Axios.post(
  //       `${Config.base_url}patientregister`,
  //       payload,
  //       {
  //         headers: {
  //           "content-type": "application/json",
  //           authorization: `Bearer ${token}`,
  //           "x-caller": await encrypted,
  //         },
  //       }
  //     );

  //     if (response.status === 201) {
  //       //console.log("Patient Registered Successfully", response);
  //       toast.success("Patient Registered Successfully", {
  //         onClose: () => {
  //           setTimeout(() => {
  //             navigate("/patients");
  //           }, 2000);
  //         },
  //       });
  //       setpatientDetails({
  //         patientUniqueId: null,
  //         firstName: null,
  //         lastName: null,
  //         govtID: null,
  //         email: null,
  //         phoneNumber: null,
  //         address1: null,
  //         address2: null,
  //         address3: null,
  //         city: null,
  //         state: null,
  //         pinCode: null,
  //         gender: null,
  //         dateOfBirth: null,
  //         Age: null,
  //         abhaNo: null,
  //         abhaAddress: null,
  //       });
  //     } else if (response.status === 404) {
  //       toast.warning(" Sorry", "Data is not submitted Please submit again");
  //     } else {
  //       //console.log(await response.text());
  //       toast.warning("Error", "An unknown error occurred.");
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       console.error("Server Response:", error.response.data);
  //       console.error("Status Code:", error.response.status);
  //     } else {
  //       console.error("Request Failed:", error.message);
  //     }
  //   }
  // }

  // base 64  abha card

  async function patientRegister(newPatientID) {
    const ABHA_CARD = sessionStorage.getItem("ABHA_CARD");
    const ABHA_QR = sessionStorage.getItem("ABHA_QR");
    const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));

    // const abhaCardBlob = await convertImageToPDF(ABHA_CARD);
    // const abhaCardFile = new File([abhaCardBlob], `ABHA_Card_${PATIENT_ID}.pdf`, { type: "application/pdf" });

    let abhaCardFile = null;

    if (ABHA_CARD) {
      try {
        const abhaCardBlob = await convertImageToPDF(ABHA_CARD);
        abhaCardFile = new File([abhaCardBlob], `ABHA_Card_${PATIENT_ID}.pdf`, { type: "application/pdf" });
      } catch (error) {
        console.error("Error converting image to PDF:", error);
      }
    }

    // Prepare FormData
    const formData = new FormData();
    // working only for abha card
    // formData.append("files", abhaCardFile);

    if (abhaCardFile) {
      formData.append("files", abhaCardFile);
    } else {
      formData.append("abha_card", "abha_card");  // Send placeholder value if no ABHA card
    }
    // formData.append("files", abhaQrFile);  // Add ABHA_QR PDF
    const dateOfBirth = await calculateDateOfBirth(patientDetails.Age);
    // formData.append(
    //   "data",
    //   JSON.stringify({
    //     patientId: newPatientID ? newPatientID : patientDetails.patientUniqueId,
    //     firstName: patientDetails.firstName,
    //     lastName: patientDetails.lastName,
    //     govtIdNumber: patientDetails.govtID,
    //     email: patientDetails.email,
    //     mobile: patientDetails.phoneNumber,
    //     address1: patientDetails.address1,
    //     address2: patientDetails.address2,
    //     address3: patientDetails.address3,
    //     city: patientDetails.city,
    //     state: patientDetails.state || "",
    //     pincode: patientDetails.pinCode,
    //     gender: patientDetails.gender || "",
    //     dateOfBirth: patientDetails.dateOfBirth,
    //     // dateOfBirth: new Date(dateOfBirth).toLocaleDateString("en-GB"),
    //     // patientDetails.dateOfBirth,
    //     nurseId: UserID,
    //     doctorId: "",
    //     age: patientDetails.Age,
    //     ABHA_NUMBER: patientDetails.abhaNo || "",
    //     // ABHA_ADDRESS: `${patientDetails.abhaAddress}@sbx`,
    //     ABHA_ADDRESS: patientDetails.abhaAddress.includes("@sbx")
    //       ? patientDetails.abhaAddress
    //       : `${patientDetails.abhaAddress}@sbx` || "",
    //     ABHA_QR: 'TEST_QR',
    //   })
    // );

    const dataBody = {
      patientId: newPatientID ? newPatientID : patientDetails.patientUniqueId,
      firstName: patientDetails.firstName,
      lastName: patientDetails.lastName,
      govtIdNumber: patientDetails.govtID,
      email: patientDetails.email,
      mobile: patientDetails.phoneNumber,
      address1: patientDetails.address1,
      address2: patientDetails.address2,
      address3: patientDetails.address3,
      city: patientDetails.city,
      state: patientDetails.state || "",
      pincode: patientDetails.pinCode,
      gender: patientDetails.gender || "",
      dateOfBirth: dayjs(patientDetails.dateOfBirth, "YYYY-MM-DD").format("DD/MM/YYYY"),
      // dateOfBirth: patientDetails.dateOfBirth,
      nurseId: UserID,
      doctorId: "",
      age: patientDetails.Age,
      ABHA_NUMBER: patientDetails.abhaNo || "",
      ABHA_ADDRESS: patientDetails.abhaAddress.includes("@sbx")
        ? patientDetails.abhaAddress
        : `${patientDetails.abhaAddress}@sbx` || "",
      ABHA_QR: 'TEST_QR',
    };

    // Log the data body
    console.log("Data Body:", dataBody);

    formData.append("data", JSON.stringify(dataBody));

    try {
      const response = await Axios.post(`${Config.base_url}patientregister`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
          "x-caller": await encrypted,
        },
      });

      if (response.status === 201) {
        // for removing the abha address  new 
        sessionStorage.removeItem("newabhaaddress");
        sessionStorage.removeItem("abhaaddress");
        // sessionStorage.removeItem("newabhaaddress");
        toast.success("Patient Registered Successfully", {
          onClose: () => {
            setTimeout(() => {
              navigate("/patients");
            }, 1000);
          },
        });
      } else if (response.status === 404) {
        toast.warning("Data is not submitted. Please submit again.");
      } else {
        toast.warning("An unknown error occurred.");
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Response:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else {
        console.error("Request Failed:", error.message);
      }
    }
  }

  const formatDate = (date) => {
    if (!date) return null;

    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) return null;

    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const year = parsedDate.getFullYear();

    return `${day}/${month}/${year}`;
  };


  const editPatientDetails = async () => {
    if (
      patientDetails.firstName !== "" &&
      patientDetails.phoneNumber !== "" &&
      patientDetails.Age !== "" &&
      patientDetails.city !== ""
    ) {
      if (patientDetails.dateOfBirth === null) {
        //console.log("detaaa.....");
      }
      const dateOfBirth = await calculateDateOfBirth(patientDetails.Age);
      const payload = {
        PATIENT_ID: PatientID,
        FIRST_NAME: patientDetails.firstName,
        LAST_NAME: patientDetails.lastName,
        EMAIL: patientDetails.email,
        MOBILE: patientDetails.phoneNumber,
        ADDRESS1: patientDetails.address1,
        ADDRESS2: patientDetails.address2,
        ADDRESS3: patientDetails.address3,
        CITY: patientDetails.city,
        ABHA_NUMBER: patientDetails.abhaNo,
        ABHA_ADDRESS: patientDetails.abhaAddress,
        STATE: patientDetails.state || "",
        PINCODE: patientDetails.pinCode,
        GENDER: patientDetails.gender || "",
        DATE_OF_BIRTH: formatDate(patientDetails.dateOfBirth),
        // DATE_OF_BIRTH: patientDetails.dateOfBirth,
        // DATE_OF_BIRTH: new Date(dateOfBirth).toLocaleDateString("en-GB"),
        doctorId: "",
        AGE: patientDetails.Age,
        ROLE: 3,
        CREATED_BY: UserID,
      };

      console.log("payload of edittt", payload);
      Axios.post(
        `${Config.base_url}EDIT_PATIENT_PROFILE`,
        { data: JSON.stringify(payload) },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      ).then((response) => {
        if (response.status === 201) {
          setIsLoading(false);
          // toast.success("Your Patient profile has been updated Successfully");
          setpatientDetails({
            patientUniqueId: null,
            firstName: null,
            lastName: null,
            govtID: null,
            email: null,
            phoneNumber: null,
            address1: null,
            address2: null,
            address3: null,
            city: null,
            state: null,
            pinCode: null,
            gender: null,
            dateOfBirth: null,
            Age: null,
            abhaNo: null,
            abhaAddress: null,
          });

          toast.success("Your Patient profile has been updated Successfully", {
            onClose: () => navigate("/patients"),
          });
        } else if (response.status === 404) {
          toast.error(" Sorry", "Data is not submitted Please submit again");
        } else {
          toast.warning("Error", "An unknown error occurred.");
        }
      });
    } else {
      toast.warning("Please fill the mandatory fields");
    }
  };

  function calculateDateOfBirth(age) {
    const today = dayjs();
    const dob = today.subtract(age, "year").subtract(1, "day");
    //console.log("dob", dob);
    return dob.format("YYYY-MM-DD");
  }

  const handleAddAddress = () => {
    <div className="patientDetailsContainer">
      <div className="addp-inputFieldAddress" style={{ width: "100%" }}>
        <label htmlFor="address1" className="description">
          Address Line 1
        </label>
        <input
          type="text"
          // onChange={(e) => {
          //   setpatientDetails((prevState) => ({
          //     ...prevState,
          //     address1: e.target.value,
          //   }));
          // }}
          value={patientDetails.address1}
          onChange={(e) => handleInputChange(e, "address1")}
          name="address1"
          placeholder="Enter your address"
          className="addp1-patientsInputField"
        />
      </div>
      <div className="addp-addPatientsAddressButtonContainer">
        <Link to="/patients" className="link">
          <button className="addp-addPatientsButton"> +Add Address</button>
        </Link>
      </div>
      {/* {PatientID === null ? (
      <div
       className="addp-inputField"
      >
        <label htmlFor="pinCode" className="description">
          Pin Code
        </label>
        <input
          type="text"
          name="pinCode"
          // onChange={(e) => {
          //   setpatientDetails((prevState) => ({
          //     ...prevState,
          //     pinCode: e.target.value,
          //   }));
          // }}
          value={patientDetails.pinCode}
          onChange={(e) => handleInputChange(e, "pinCode")}
          className="addp-patientsInputField"
        />
      </div>
    ) : (
      ""
    )} */}
    </div>;
    return <div></div>;
  };

  const handleChangeFirstName = (e) => {
    const { name, value } = e.target;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      if (value.length <= 50) {
        setpatientDetails((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        setError((prevState) => ({
          ...prevState,
          [name]: "",
        }));
      } else {
        setError((prevState) => ({
          ...prevState,
          [name]: "First Name cannot exceed 50 characters",
        }));
      }
    } else {
      setError((prevState) => ({
        ...prevState,
        [name]: "Enter only alphabetic characters in First Name",
      }));
    }
  };

  const handleBackbutton = () => {
    navigate("/patients");
  };


  const handleChangeLastName = (e) => {
    const { name, value } = e.target;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      if (value.length <= 50) {
        setpatientDetails((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        setError((prevState) => ({
          ...prevState,
          [name]: "",
        }));
      } else {
        setError((prevState) => ({
          ...prevState,
          [name]: "Last Name cannot exceed 50 characters",
        }));
      }
    } else {
      setError((prevState) => ({
        ...prevState,
        [name]: "Enter only alphabetic characters in Last Name",
      }));
    }
  };

  return (
    <>
      {/* <Navbar /> */}
      <div className="addp-homePatient">
        <div className="homeBackground">
          <img src={backgroundCircle} alt="" />
        </div>

        <div className="homeBodyPatients">
          {/* <div className="homeBottomContainer"> */}
          {/* <div
            className="homeBodyLeft"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <img src={nurse} className="nurseImage" alt="" />
          </div> */}
          <div className="patientContainer">
            <div className="addp-patientsHeading">
              <div style={{ display: "flex", gap: "1rem" }}>
                {/* <img
                  src={backArrow}
                  style={{ width: "13px", cursor: "pointer" }}
                  onClick={handleBack}
                /> */}
                {PatientID ? (
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ marginRight: "10px", cursor: "pointer" }} onClick={handleBackbutton}>
                      <img className="image" src={LeftArrow} alt="Logo" style={{ width: "24px", height: "22px" }} />
                    </div>
                    <h2 style={{ flex: 1, textAlign: "center", margin: 0 }}>EDIT PATIENT DETAILS</h2>
                  </div>
                ) : (
                  <h2>PATIENTS REGISTRATION</h2>
                )}
              </div>
              <div>
                {/* <label
                  htmlFor="patientUniqueId"
                  className="description"
                ></label> */}
                {patientDetails.patientUniqueId
                  ? `Unique Patient ID - ${patientDetails.patientUniqueId}`
                  : ""}
              </div>
            </div>
            <div className="addp-formContainer">
              <div className="patientDetailsContainer">
                {/* <div
                 className="addp-inputField"
                >
                  <label htmlFor="patientUniqueId" className="description">
                    Unique Patient ID
                  </label>
                  <input
                    type="text"
                    value={patientDetails.patientUniqueId}
                    name="patientUniqueId"
                    className="addp-patientsInputField"
                  />
                </div> */}

                {/* <div
                 className="addp-inputField"
                >
                  <label htmlFor="gender" className="description">
                    Gender
                  </label>
                  <input
                    type="text"
                    value={patientDetails.gender}
                    onChange={(e) => {
                      setpatientDetails((prevState) => ({
                        ...prevState,
                        gender: e.target.value,
                      }));
                    }}
                    name="gender"
                    className="addp-patientsInputField"
                  />
                </div> */}
                <div className="addp-inputField">
                  <label htmlFor="phoneNumber" className="description">
                    Mobile Number<span style={{ color: "red" }}> *</span>
                  </label>
                  {/* <input
                    type="text"
                    name="phoneNumber"
                    className="addp-patientsInputField"
                    maxLength={10}
                    value={patientDetails.phoneNumber}
                    onChange={(e) => {
                      patientID = "CLA/" + e.target.value + "/001";
                      setpatientDetails((prevState) => ({
                        ...prevState,
                        phoneNumber: e.target.value,
                        patientUniqueId: patientID,
                      }));
                    }}
                  /> */}
                  <input
                    type="text"
                    name="phoneNumber"
                    className="addp-patientsInputField"
                    maxLength={10}
                    disabled={PatientID !== null}
                    value={patientDetails.phoneNumber}
                    onChange={(e) => {
                      const enteredValue = e.target.value.replace(/\D/, "");
                      patientID = "CLA/" + enteredValue + "/001";
                      setpatientDetails((prevState) => ({
                        ...prevState,
                        phoneNumber: enteredValue,
                        patientUniqueId: patientID,
                      }));
                    }}
                    pattern="[0-9]*"
                  />
                </div>

                <div className="addp-inputField">
                  <label htmlFor="patientUniqueId" className="description">
                    Unique Patient ID <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    disabled
                    type="text"
                    value={patientDetails.patientUniqueId}
                    name="patientUniqueId"
                    className="addp-patientsInputField"
                    style={{ color: "grey" }}
                  />
                </div>
                {/* <select
                    className="addp-patientsInputField"
                    value={patientDetails.gender} // Controlled by state
                    // onChange={(e) => {
                    //   setPatientDetails((prevState) => ({
                    //     ...prevState,
                    //     gender: e.target.value,
                    //   }));
                    // }}

                    onChange={(e) => handleInputChange(e, "gender")}
                    name="gender"
                    id="gender"
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </select> */}
              </div>
              <div className="patientDetailsContainer">
                <div className="addp-inputField">
                  <label htmlFor="firstName" className="description">
                    First Name<span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    className="addp-patientsInputField"
                    value={patientDetails.firstName}
                    onChange={handleChangeFirstName}
                  />
                  {error.firstName && (
                    <p style={{ color: "red" }}>{error.firstName}</p>
                  )}
                </div>
                <div className="addp-inputField">
                  <label htmlFor="lastName" className="description">
                    Last Name <span style={{ color: "red" }}></span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    className="addp-patientsInputField"
                    value={patientDetails.lastName}
                    onChange={handleChangeLastName}
                  />
                  {error.firstName && (
                    <p style={{ color: "red" }}>{error.lastName}</p>
                  )}
                </div>
              </div>

              <div className="patientDetailsContainer">
                <div className="addp-inputField">
                  <label htmlFor="age" className="description">
                    Age<span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="number"
                    // value={patientDetails.Age}
                    // onChange={(e) =>
                    //   setpatientDetails({
                    //     ...patientDetails,
                    //     Age: e.target.value,
                    //   })
                    // }
                    value={patientDetails.Age}
                    onChange={(e) => handleInputChange(e, "Age")}
                    name="age"
                    maxLength="3"
                    className="addp-patientsInputField"
                  />
                </div>

                <div className="addp-inputField">
                  <label className="description">
                    Date of Birth<span style={{ color: "red" }}> *</span>
                  </label>
                  {/* <div className="inputContainer"> */}

                  <LocalizationProvider
                    class="addp-patientsInputField "
                    dateAdapter={AdapterDayjs}
                  >
                    {/* <DatePicker
                        // defaultValue={dayjs(value)}
                        value={dayjs(patientDetails.dateOfBirth)}
                        onChange={(newValue) => handleDateChange(newValue.$d)}
                      /> */}
                    {/* <DatePicker
                      format="DD/MM/YYYY"
                      sx={{
                        border: "1px solid #CCC",
                        borderRadius: "10px",
                        height: "45px",
                        justifyContent: "center",
                      }}
                      value={
                        patientDetails.dateOfBirth
                          ? dayjs(patientDetails.dateOfBirth)
                          : null
                      }
                      onChange={(newValue) => {
                        if (newValue && newValue.isValid()) {
                          let age = calculateAge(newValue);
                          setpatientDetails({
                            ...patientDetails,
                            Age: age,
                            dateOfBirth: newValue.format("YYYY-MM-DD"),
                          });
                          // setpatientDetails({
                          //   ...patientDetails,
                          //   Age: age,
                          //   dateOfBirth: newValue
                          //     ? newValue.format("YYYY-MM-DD")
                          //     : "",
                          // });
                        }
                      }}
                    /> */}
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={patientDetails.dateOfBirth ? dayjs(patientDetails.dateOfBirth, "YYYY-MM-DD") : null}
                      onChange={(newValue) => {
                        if (newValue && newValue.isValid()) {
                          let age = calculateAge(newValue);
                          setpatientDetails({
                            ...patientDetails,
                            Age: age,
                            dateOfBirth: newValue.format("YYYY-MM-DD"), // Keep as DD/MM/YYYY for display
                          });
                        }
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="patientDetailsContainer">
                <>
                  <div className="addp-inputField">
                    <label htmlFor="abhaNo" className="description">
                      ABHA NO
                    </label>
                    <input
                      disabled
                      type="text"
                      name="abhaNo"
                      value={patientDetails.abhaNo}
                      onChange={(e) => {
                        setpatientDetails((prevState) => ({
                          ...prevState,
                          abhaNo: e.target.value,
                        }));
                      }}
                      className="addp-patientsInputField"
                      style={{ color: "grey" }}
                    />
                  </div>
                  <div className="addp-inputField">
                    <label htmlFor="abhaAdress" className="description">
                      ABHA Address
                    </label>
                    <input
                      disabled={true}
                      type="text"
                      value={patientDetails.abhaAddress}
                      onChange={(e) => {
                        setpatientDetails((prevState) => ({
                          ...prevState,
                          abhaAddress: e.target.value,
                        }));
                      }}
                      name="abhaAdress"
                      className="addp-patientsInputField"
                      style={{ color: "grey" }}
                    />
                  </div>
                  {/* 
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "30.33%",
                        gap: "0.8rem",
                      }}
                    >
                      <label htmlFor="abhaNo" className="description">
                        AADHAR NUMBER:
                      </label>
                      <input
                        type="text"
                        name="abhaNo"
                        onChange={(e) => {
                          setpatientDetails((prevState) => ({
                            ...prevState,
                            govtID: e.target.value,
                          }));
                        }}
                        value={patientDetails.govtID}
                        maxLength={12}
                        className="addp-patientsInputField"
                      />
                    </div> */}
                </>

                {/* <div
                 className="addp-inputField"
                >
                  <label htmlFor="address2" className="description">
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setpatientDetails((prevState) => ({
                        ...prevState,
                        address2: e.target.value,
                      }));
                    }}
                    name="address2"
                    className="addp-patientsInputField"
                  />
                </div> */}
              </div>
              <div className="patientDetailsContainer">
                <div className="addp-inputField">
                  <label htmlFor="email" className="description">
                    Email ID
                  </label>
                  <input
                    type="text"
                    value={patientDetails.email}
                    onChange={(e) => {
                      const email = e.target.value;
                      setpatientDetails((prevState) => ({
                        ...prevState,
                        email,
                      }));
                      if (!email.includes("@")) {
                        setEmailWarning("Please enter a valid email address.");
                      } else {
                        setEmailWarning(""); // clear the warning if '@' is present
                      }
                    }}
                    maxLength={50}
                    name="email"
                    className="addp-patientsInputField"
                  />
                  {emailWarning && (
                    <p className="warning-message" style={{ color: "red" }}>
                      {emailWarning}
                    </p>
                  )}
                </div>
                <div className="addp-inputField">
                  <label htmlFor="gender" className="description">
                    Gender<span style={{ color: "red" }}> *</span>
                  </label>
                  <select
                    className="addp-patientsInputField"
                    value={patientDetails.gender} // Controlled by state
                    // onChange={(e) => {
                    //   setPatientDetails((prevState) => ({
                    //     ...prevState,
                    //     gender: e.target.value,
                    //   }));
                    // }}

                    onChange={(e) => handleInputChange(e, "gender")}
                    name="gender"
                    id="gender"
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </select>
                  {/* <input
                    type="text"
                    value={patientDetails.gender}
                    onChange={(e) => {
                      setpatientDetails((prevState) => ({
                        ...prevState,
                        gender: e.target.value,
                      }));
                    }}
                    name="gender"
                    className="addp-patientsInputField"
                  /> */}
                </div>
              </div>

              <div className="patientDetailsContainer">
                <div className="addp-inputFieldAddress" style={{ width: "100%" }}>
                  <label htmlFor="address1" className="description">
                    Address
                  </label>
                  <input
                    type="text"
                    // onChange={(e) => {
                    //   setpatientDetails((prevState) => ({
                    //     ...prevState,
                    //     address1: e.target.value,
                    //   }));
                    // }}
                    value={patientDetails.address1}
                    onChange={(e) => handleInputChange(e, "address1")}
                    name="address1"
                    maxLength={100}
                    placeholder="Enter your address"
                    className="addp1-patientsInputField"
                  />
                </div>
                {/* <div className="addp-addPatientsAddressButtonContainer" onClick={handleAddAddress}>
                  <Link className="link">
                    <button className="addp-addPatientsButton" > +Add Address</button>
                  </Link>
                </div> */}
                {/* {PatientID === null ? (
                  <div
                   className="addp-inputField"
                  >
                    <label htmlFor="pinCode" className="description">
                      Pin Code
                    </label>
                    <input
                      type="text"
                      name="pinCode"
                      // onChange={(e) => {
                      //   setpatientDetails((prevState) => ({
                      //     ...prevState,
                      //     pinCode: e.target.value,
                      //   }));
                      // }}
                      value={patientDetails.pinCode}
                      onChange={(e) => handleInputChange(e, "pinCode")}
                      className="addp-patientsInputField"
                    />
                  </div>
                ) : (
                  ""
                )} */}
              </div>

              <div className="patientDetailsContainer">
                <div className="addp-inputField">
                  <label htmlFor="city" className="description">
                    City
                    {/*<span style={{ color: "red" }}>*</span> */}
                  </label>
                  <input
                    type="text"
                    maxLength={50}
                    value={patientDetails.city}
                    onChange={(e) => handleInputChange(e, "city")}
                    name="city"
                    className="addp-patientsInputField"
                  />
                </div>
                <div className="addp-inputField">
                  <label htmlFor="state" className="description">
                    Select a State
                  </label>
                  <select
                    name="state"
                    className="addp-patientsInputField"
                    // onClick={(e) => {
                    //   setpatientDetails((prevState) => ({
                    //     ...prevState,
                    //     state: e.target.value,
                    //   }));
                    // }}
                    onChange={(e) => {
                      setpatientDetails((prevState) => ({
                        ...prevState,
                        state: e.target.value,
                      }));
                    }}
                    value={patientDetails.state}
                    id="state"
                  >
                    <option selected disabled></option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bhir">Bhir</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisa">Odisa</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                    <option value="Andaman and Nicobar Islands (UT)">
                      Andaman and Nicobar Islands (UT)
                    </option>
                    <option value="opeChandigarh (UT)l">Chandigarh (UT)</option>
                    <option value="Dadra & Nagar Haveli and Daman & Diu (UT)">
                      Dadra & Nagar Haveli and Daman & Diu (UT)
                    </option>
                    <option value="Delhi (UT)">Delhi (UT)</option>
                    <option value="Jammu and Kashmir (UT)">
                      Jammu and Kashmir (UT)
                    </option>
                    <option value="Lakshadweep (UT)">Lakshadweep (UT)</option>
                    <option value="Puducherry (UT)">Puducherry (UT)</option>
                    <option value="Ladakh (UT)">Ladakh (UT)</option>
                  </select>
                </div>
              </div>

              <div className="addp-addPatientsButtonContainer">
                {/* <div className="addPatientsButtonContainer">
                  <Link to="/patients" className="link">
                    <button className="addPatientsButton">BACK</button>
                  </Link>
                </div> */}
                <Link to="" className="link">
                  {PatientID === null ? (
                    <button
                      className="addp1-addPatientsButton"
                      onClick={checkPatientWithMobileNo}
                    >
                      REGISTER PATIENT
                    </button>
                  ) : (
                    <button
                      className="addp1-addPatientsButton"
                      onClick={editPatientDetails}
                    >
                      Submit Details
                    </button>
                  )}
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="patientContainer addp-addressContainer">
          <div className="addp-patientsHeading">
                  <h2>Address 1</h2>
                  <div className="addp-addPatientsAddressButtonContainer">
                    <Link className="link">
                      <button className="addp-addPatientsButton"> +Add Address</button>
                    </Link>
                </div>
          </div> 
          <div className="addp-formContainer">
            <div className="patientDetailsContainer">
                <div
                 className="addp-inputField"
                >
                  <label htmlFor="city" className="description">
                    City<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    value={patientDetails.city}
                    onChange={(e) => handleInputChange(e, "city")}
                    name="city"
                    className="addp-patientsInputField"
                  />
                </div>
                <div
                 className="addp-inputField"
                >
                  <label htmlFor="state" className="description">
                    Select a State
                  </label>
                  <select
                    name="state"
                    className="addp-patientsInputField"
                    // onClick={(e) => {
                    //   setpatientDetails((prevState) => ({
                    //     ...prevState,
                    //     state: e.target.value,
                    //   }));
                    // }}
                    onChange={(e) => {
                      setpatientDetails((prevState) => ({
                        ...prevState,
                        state: e.target.value,
                      }));
                    }}
                    value={patientDetails.state}
                    id="state"
                  >
                    <option selected disabled></option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bhir">Bhir</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisa">Odisa</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                    <option value="Andaman and Nicobar Islands (UT)">
                      Andaman and Nicobar Islands (UT)
                    </option>
                    <option value="opeChandigarh (UT)l">Chandigarh (UT)</option>
                    <option value="Dadra & Nagar Haveli and Daman & Diu (UT)">
                      Dadra & Nagar Haveli and Daman & Diu (UT)
                    </option>
                    <option value="Delhi (UT)">Delhi (UT)</option>
                    <option value="Jammu and Kashmir (UT)">
                      Jammu and Kashmir (UT)
                    </option>
                    <option value="Lakshadweep (UT)">Lakshadweep (UT)</option>
                    <option value="Puducherry (UT)">Puducherry (UT)</option>
                    <option value="Ladakh (UT)">Ladakh (UT)</option>
                  </select>
                </div>
               
                </div>   
          </div>
          </div>
          <div className=" addp-addSaveButtonContainer">
              <Link to="" className="link">
              <button
                    className="addp1-addPatientsButton"
                    // onClick={checkPatientWithMobileNo}
                  >
                   Reset
                  </button>
              </Link>
              <Link to="" className="link">
                {PatientID === null ? (
                  <button
                    className="addp1-addPatientsButton"
                    onClick={checkPatientWithMobileNo}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="addp1-addPatientsButton"
                    onClick={editPatientDetails}
                  >
                    Save
                  </button>
                )}
              </Link>
          </div> */}
          {/* </div> */}
        </div>

        <Dialog open={showAlert} onClose={handleClose}>
          <DialogTitle>Mobile Already Exist !!</DialogTitle>

          <DialogContent>
            <p style={{ marginTop: "15px" }}>
              Mobile number already exist. Do you want to register new patient
              with the same mobile number?
            </p>
          </DialogContent>

          <DialogActions>
            <Button
              // class="editButton2"
              onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              // class="editButton2"
              onClick={handleAction} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>

        <ToastContainer />
      </div>
    </>
  );
};

export default AddPatients;
