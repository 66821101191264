// code chnages by revathi
// import React, { useEffect, useState } from "react";
// import Axios from "axios";
// import { CircularProgress } from "@mui/material";
// import { publicEncrypt, constants } from "crypto-browserify";
// import { Buffer } from "buffer";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Config from "../../Config/config.jsx";
// import Select from "react-select";
// import DegreeImg from "../../images/education-graduation-learning-school-study 3.svg";
// import DeleteIcon from "../../images/delete 8.svg";


// const UpdateEducation = () => {
//   const [variables, setVariables] = useState({
//     listOfCategories: [],
//     showOtherInput: false,
//   });

//   const [degree, setDegree] = useState({
//     selectedDegree: "",
//     otherDegree: "",
//   });
//   const [speciality, setSpeciality] = useState({
//     selectedSpeciality: "",
//     otherSpeciality: "",
//   });

//   const [activeSection, setActiveSection] = useState("");
//   const [educationDetails, setEducationDetails] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   const mobile = sessionStorage.getItem("RegMobileNum")
//     ? sessionStorage.getItem("RegMobileNum")
//     : sessionStorage.getItem("UserMOBILE");

//   const UserID = sessionStorage.getItem("RegUserId")
//     ? JSON.parse(sessionStorage.getItem("RegUserId"))
//     : sessionStorage.getItem("UserID");

//   const publicKey = sessionStorage.getItem("RegpublicKey")
//     ? sessionStorage.getItem("RegpublicKey")
//     : JSON.parse(sessionStorage.getItem("publicKey"));

//   const token = sessionStorage.getItem("Regtoken")
//     ? sessionStorage.getItem("Regtoken")
//     : JSON.parse(sessionStorage.getItem("token"));

//   const padding = constants.RSA_PKCS1_PADDING;
//   const data = Buffer.from(UserID.toString(), "utf-8");
//   const encrypted = publicEncrypt(
//     { key: publicKey, padding: padding },
//     data
//   ).toString("base64");

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await Axios.get(Config.base_url + "GET_DEGREE", {
//           headers: {
//             authorization: `Bearer ${token}`,
//             "x-caller": encrypted,
//           },
//         });
//         // console.log(response.data.recordset,"response.data.recordset");
//         setIsLoading(false);
//         setVariables((prevState) => ({
//           ...prevState,
//           listOfCategories: response.data.recordset,
//         }));

//         const getEducationList = await Axios.get(
//           `${Config.base_url}GET_QUALIFICATION/${UserID}`,
//           {
//             headers: {
//               authorization: `Bearer ${token}`,
//               "x-caller": await encrypted,
//             },
//           }
//         );

//         if (getEducationList.status === 200) {
//           const jsonData = getEducationList.data;
//           if (jsonData.length > 0) {

//             const parsedData = JSON.parse(jsonData[0]["EDUCATION_DETAILS"]);
//             console.log(jsonData, "jsonData.............EDUCATION_DETAILS..............", parsedData)
//             setEducationDetails(parsedData);
//           }
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     };
//     fetchData();
//   }, []);

//   const degrees = [
//     { label: "MBBS", value: "MBBS" },
//     { label: "BDS", value: "BDS" },
//     { label: "BAMS", value: "BAMS" },
//     { label: "BHMS", value: "BHMS" },
//     { label: "BUMS", value: "BUMS" },
//     { label: "BSMS", value: "BSMS" },
//     { label: "BNYS", value: "BNYS" },
//     { label: "Others", value: "Others" },
//   ];

//   const handleDegreeChange = (deg) => {
//     const selectedDegree = deg.value;

//     if (selectedDegree === "Others") {
//       setVariables((prevState) => ({
//         ...prevState,
//         showOtherInput: true,
//         filteredCategories: [],
//       }));
//       setDegree((prevState) => ({
//         ...prevState,
//         selectedDegree: selectedDegree,
//       }));
//       // setDegree({ selectedDegree: deg.value });
//       setSpeciality({ selectedSpeciality: "" });
//     } else {
//       const categoryOptions = variables.listOfCategories.filter(
//         (item) => item.DEGREE === selectedDegree
//       );
//       setVariables((prevState) => ({
//         ...prevState,
//         showOtherInput: false,
//         filteredCategories: categoryOptions,
//       }));
//       setDegree((prevState) => ({
//         ...prevState,
//         selectedDegree: selectedDegree,
//       }));
//       setSpeciality({ selectedSpeciality: "" });
//     }

//   };

//   const getYears = (startYear) => {
//     const currentYear = new Date().getFullYear();
//     const yearsTilPresent = [];

//     while (startYear <= currentYear) {
//       yearsTilPresent.push({
//         label: startYear.toString(),
//         value: startYear.toString(),
//       });
//       startYear++;
//     }

//     return yearsTilPresent;
//   };

//   const years = getYears(1994);

//   const handleYearChange = (year) => {
//     setVariables({ ...variables, selectedYear: year });
//   };

//   const handleSpecalityChange = (category) => {
//     setSpeciality({ selectedSpeciality: category });
//   };

//   const handleOtherDegreeInputChange = (e) => {
//     const updatedDegree = { ...degree, otherDegree: e.target.value };
//     setDegree(updatedDegree);
//     console.log(updatedDegree, "otherDegree");
//   };

//   const handleOtherSpecialityInputChange = (e) => {
//     const updatedSpeciality = {
//       ...speciality,
//       otherSpeciality: e.target.value,
//     };
//     setSpeciality(updatedSpeciality);
//     console.log(updatedSpeciality, "otherSpeciality");
//   };

//   function restructureData(data) {
//     const degreeType = data.speciality
//       ? data.speciality.otherSpeciality ||
//       data.speciality.selectedSpeciality.value ||
//       ""
//       : "";
//     const degree = data.degree
//       ? data.degree.otherDegree || data.degree.selectedDegree?.value || ""
//       : "";
//     const year = data.year ? data.year.value || "" : "";

//     const newData = {
//       degreeType: degreeType,
//       degree: degree,
//       year: year,
//       hospital: null,
//       city: null,
//       roleTitle: null,
//       startingMonth: null,
//       startingYear: null,
//       endMonth: null,
//       tillYear: null,
//     };

//     return newData;
//   }

//   const updateEducation = async (EducationDetails, mode) => {
//     console.log(
//       degree.selectedDegree,
//       "degree.selectedDegree",
//       variables.selectedYear,
//       "variables.selectedYear",
//       speciality.selectedSpeciality,
//       "speciality.selectedSpeciality",
//       speciality.otherSpeciality
//     );
//     if (mode === "add") {
//       if (!degree.selectedDegree || !variables.selectedYear || !(speciality.selectedSpeciality || speciality.otherSpeciality)) {
//         toast.warning("Please fill the mandatory fields");
//         return;
//       }
//     }

//     try {
//       const data = {
//         degree: degree,
//         year: variables.selectedYear,
//         speciality: speciality,
//       };

//       let modifiedData = restructureData(data);
//       if (mode === "add") {
//         modifiedData = [...educationDetails, modifiedData];
//         setEducationDetails(modifiedData);
//       } else if (mode === "remove") {
//         modifiedData = EducationDetails;
//       }

//       const payload = {
//         EDUCATION: modifiedData,
//         mode: mode,
//         userid: UserID,
//         mobile: mobile,
//       };

//       setVariables({ ...variables, selectedYear: "", showOtherInput: false });
//       setDegree({ selectedDegree: "", otherDegree: "" });
//       setSpeciality({ selectedSpeciality: "", otherSpecality: "" });

//       // const response = Axios.post(Config.apiURL + Config.routes.updateEducationAPI, payload, {
//       //   headers: {
//       //     "Content-Type": "application/json",
//       //     authorization: `Bearer ${token}`,
//       //     "x-caller": encrypted,
//       //   },
//       // });
//       const response = await Axios.post(
//         `${Config.apiURL}${Config.routes.updateEducationAPI}`,
//         payload,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             authorization: `Bearer ${token}`,
//             "x-caller": encrypted,
//           },
//         }
//       );
//       console.log("response of ed", response);
//       console.log("response of payload", response);
//       if (response.status === 200 || response.status === 201) {
//         if (mode === "add") {
//           toast.success("saved successfully");
//           setActiveSection("");
//         }
//       }
//       else {
//         toast.error(
//           "Sorry !!! ",
//           "We're facing a server issue. Please try after some time."
//         );
//       }
//     }
//     // .then((response) => {
//     //   //console.log("response", response);
//     // if (mode === "add") {
//     //   toast.success("saved successfully");
//     //   setActiveSection("");
//     // }
//     // })
//     catch (error) {
//       if (error.response) {
//         console.log("error response", error.response);
//       } else {
//         console.log("error message", error.message);
//       }
//       toast.error("Not Saved."); // Show an error toast if the save fails
//     };
//   };

//   const removeEducation = (index) => {
//     setEducationDetails((prevDetails) => {
//       const updatedEducationList = [...prevDetails];
//       updatedEducationList.splice(index, 1);

//       // Filter out objects with all properties empty or null
//       const filteredList = updatedEducationList.filter((detail) => {
//         return Object.values(detail).some(
//           (value) => value !== "" && value !== null
//         );
//       });

//       // Update the state with the filtered array
//       setEducationDetails(filteredList);

//       // Call the updateEducation function with the filtered array
//       updateEducation(filteredList, "remove");

//       // Return the filtered array
//       return filteredList;
//     });
//   };

//   const DropdownIndicator = (props) => {
//     return (
//       <svg
//         width="40px"
//         height="30px"
//         viewBox="0 0 24 24"
//         cursor="pointer"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//         {...props}
//       >
//         <path
//           d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
//           fill="black" // Set your desired arrow color here
//         />
//       </svg>
//     );
//   };

//   const customStyles = {
//     option: (provided, state) => ({
//       ...provided,
//       backgroundColor: state.isSelected
//         ? "lightblue"
//         : provided.backgroundColor,
//       color: "black",
//       "&:hover": {
//         backgroundColor: "lightblue", // Ensures the hover background is fully visible
//         color: "black",
//       },
//       boxSizing: "border-box", // Ensures the width includes padding and border
//       padding: "8px 12px", // Adjust padding to ensure full width coverage
//       width: "100%", // Ensure the option takes the full width of the dropdown
//       margin: "0", // Remove any margin that might cause the cutting
//     }),
//     menu: (provided) => ({
//       ...provided,
//       width: "auto", // Auto width based on the content
//       minWidth: "100%", // Ensure menu is at least as wide as the select component
//     }),
//   };

//   const toggleSection = (sectionName) => {
//     setActiveSection(sectionName);
//   };

//   let parsedEducationDetails = [];

//   if (Array.isArray(educationDetails) || typeof educationDetails === "object") {
//     parsedEducationDetails = educationDetails;
//   } else {
//     try {
//       parsedEducationDetails = JSON.parse(educationDetails);
//     } catch (error) {
//       console.error("Error parsing educationDetails:", error);
//     }
//   }
//   return (
//     <>
//       <div
//         className="profileRightContainer"
//         style={{ display: "flex", gap: "1rem" }}
//       >
//         <div className="leftContainer" style={{ width: "70%" }}>

//           <div className="educationd-bottomContainer">
//             {isLoading ? (
//               <div className="spinningLoader">
//                 <CircularProgress size={48} color="inherit" />
//               </div>
//             ) : (
//               <div className="cardsCols">
//                 {Array.isArray(educationDetails) &&
//                   educationDetails.length > 0 ? (
//                   educationDetails.map((details, index) => (
//                     <div
//                       className="educationd-cardContainer"
//                       key={index} /*style={{width:"80%"}}*/
//                     >

//                       <div className="innerdeleteicon">
//                         <img
//                           class="innerdeleteicon-img"
//                           src={DeleteIcon}
//                           alt="Delete"
//                           onClick={() =>
//                             removeEducation(index)
//                           }
//                         />
//                       </div>
//                       <div className="educationd-cardImgContainer">
//                         <div className="educationd-cardImg">
//                           <img src={DegreeImg} />
//                         </div>
//                       </div>

//                       <div className="educationd-cardContentContainer">
//                         <p>
//                           <span className="hospital-row">Degree: </span>
//                           {/* {details.degree || ''} */}
//                           {details.degree?.label || details.degree?.value || details.degree || ''}
//                         </p>
//                         <p>
//                           <span className="hospital-row">Year: </span>{" "}
//                           {details.year?.label || details.year?.value || details.year || ''}
//                         </p>
//                         <p>
//                           <span className="hospital-row">Speciality: </span>{" "}
//                           {details.degreeType?.label || details.degreeType?.value || details.degreeType || ''}
//                         </p>
//                       </div>
//                     </div>
//                   ))
//                 ) : (
//                   <p>No education details available</p>
//                 )}

//                 <div
//                   className="educationd-cardContainer educationd-addContainer"
//                   onClick={() => toggleSection("AddEducation")}
//                 >
//                   <p className="educationd-add">+</p>
//                 </div>
//               </div>
//             )}

//             {/* <ToastContainer /> */}
//           </div>
//         </div>

//         {activeSection === "AddEducation" ? (
//           <div className="hospitalpopup">
//             <div className="another-Boxshadow">
//               <p className="hsptlsetup">
//                 <span className="plus-icon">+</span>
//                 <span className="header-text">Add Education Qualification</span>
//                 <span className="close" onClick={() => toggleSection("")}>
//                   &times;
//                 </span>
//               </p>
//               <div className="Updbtn">
//                 <div className="Boxwidth">
//                   {/* <p className="Profdet">
//                     Section A: Profile details
//                   </p>
//                   <p className="Profdet">
//                     Please enter your basic level Qualification
//                   </p> */}
//                   <div
//                     className="personalInfoContainer"
//                     style={{
//                       display: "flex",
//                       gap: "2rem",
//                       flexWrap: "wrap",
//                       marginBottom: "10px",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <div className="educationd-inputField">
//                       <label style={{ marginBottom: "10px" }}>Degree</label>
//                       <Select
//                         id="stateDropdown"
//                         // value={degree.selectedDegree}
//                         value={degrees.find((deg) => deg.value === degree.selectedDegree) || null}
//                         onChange = {handleDegreeChange}
//                         options={degrees}
//                         placeholder="Degree*"
//                         isSearchable
//                         //isClearable
//                         styles={customStyles}
//                         components={{ DropdownIndicator }}
//                       />
//                     </div>

//                     {variables.showOtherInput && (
//                       <div
//                         style={{
//                           display: "flex",
//                           flexDirection: "column",
//                           width: "100%",
//                         }}
//                       >
//                         <input
//                           style={{
//                             padding: "0.7rem 0.7rem",
//                             display: "block",
//                             width: "47%",
//                             height: "39px",
//                             borderRadius: "10px",
//                             border: "1px solid #AAA",
//                           }}
//                           className="otherInput"
//                           type="text"
//                           value={degree.otherDegree}
//                           onChange={handleOtherDegreeInputChange}
//                           placeholder="Enter Other Degree"
//                         />
//                       </div>
//                     )}

//                     <div className="educationd-inputField">
//                       <label style={{ marginBottom: "10px" }}>Year</label>
//                       <Select
//                         id="stateDropdown"
//                         value={variables.selectedYear}
//                         onChange={handleYearChange}
//                         options={years}
//                         placeholder="Year of Completion*"
//                         isClearable
//                         isSearchable
//                         styles={customStyles}
//                         components={{ DropdownIndicator }}
//                       />
//                     </div>

//                     {variables.showOtherInput ? (
//                       <>
//                         <div
//                           style={{
//                             display: "flex",
//                             flexDirection: "column",
//                             width: "100%",
//                           }}
//                         >
//                           <input
//                             type="text"
//                             value={speciality.otherSpeciality}
//                             onChange={handleOtherSpecialityInputChange}
//                             placeholder="Enter Specialty"
//                             style={{
//                               padding: "0.7rem 0.7rem",
//                               display: "block",
//                               width: "47%",
//                               height: "39px",
//                               borderRadius: "10px",
//                               border: "1px solid #AAA",
//                             }}
//                             className="otherInput"
//                           />
//                         </div>
//                       </>
//                     ) : (
//                       <>
//                         <div className="Specialitybox">
//                           {degree.selectedDegree !== "" &&
//                             variables.selectedYear !== "" && (
//                               <>
//                                 <label style={{ marginBottom: "10px" }}>
//                                   Speciality
//                                 </label>

//                                 {/* {console.log(variables.filteredCategories
//                               .filter((item) => item.CATEGORY !== 'BDS')
//                               .map(
//                                   (item) => ({
//                                     label: item.CATEGORY,
//                                     value: item.CATEGORY,
//                                   })
//                                 ),"list ......")} */}
//                                 {console.log(
//                                   degree?.selectedDegree,
//                                   "degree?.selectedDegree"
//                                 )}
//                                 <Select
//                                   id="specialityDropdown"
//                                   value={speciality.selectedSpeciality}
//                                   onChange={handleSpecalityChange}
//                                   options={variables.filteredCategories
//                                     .filter(
//                                       (item) =>
//                                         item.CATEGORY !==
//                                         degree?.selectedDegree?.value
//                                     )
//                                     .map((item) => ({
//                                       label: item.CATEGORY,
//                                       value: item.CATEGORY,
//                                     }))}
//                                   placeholder="Choose the Speciality*"
//                                   isSearchable
//                                   styles={customStyles}
//                                   components={{ DropdownIndicator }}
//                                 />
//                               </>
//                             )}
//                         </div>
//                       </>
//                     )}
//                   </div>
//                 </div>
//                 {/* <div
//                   style={{
//                     width: "90%",
//                     height: "45px",
//                     background: "white",
//                   }}
//                   className={"editButton2"}
//                   onClick={addEducation}
//                 >
//                   <p
//                     style={{
//                       color: "#52447d",
//                       fontSize: "14px",
//                       fontFamily: "Verdana",
//                       fontWeight: "400",
//                       lineHeight: "18.38px",
//                       letterSpacing: "15%",
//                     }}
//                   >
//                     Add
//                   </p>
//                 </div> */}
//                 <p className="horizontal-line"></p>
//                 <div className="educationd-buttonContainer">
//                   <div
//                     className={"educationd-Btn educationd-close-Btn"}
//                     onClick={() => toggleSection("")}
//                   >
//                     <p>Close</p>
//                   </div>
//                   <div
//                     className={"educationd-Btn educationd-save-Btn"}
//                     onClick={() => updateEducation(educationDetails, "add")}
//                   >
//                     <p>Save</p>
//                   </div>
//                 </div>
//               </div>
//               <ToastContainer position="top-right" />
//             </div>
//           </div>
//         ) : (
//           ""
//         )}
//       </div>
//     </>
//   );
// };
// export default UpdateEducation;


import React, { useEffect, useState } from "react";
import Axios from "axios";
import { CircularProgress } from "@mui/material";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config from "../../Config/config.jsx";
import Select from "react-select";
import DegreeImg from "../../images/education-graduation-learning-school-study 3.svg";
import DeleteIcon from "../../images/delete 8.svg";


const UpdateEducation = () => {
  const [variables, setVariables] = useState({
    listOfCategories: [],
    showOtherInput: false,
  });

  const [degree, setDegree] = useState({
    selectedDegree: "",
    otherDegree: "",
  });
  const [speciality, setSpeciality] = useState({
    selectedSpeciality: "",
    otherSpeciality: "",
  });

  const [activeSection, setActiveSection] = useState("");
  const [educationDetails, setEducationDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const mobile = sessionStorage.getItem("RegMobileNum")
    ? sessionStorage.getItem("RegMobileNum")
    : sessionStorage.getItem("UserMOBILE");

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));

  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(Config.base_url + "GET_DEGREE", {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        });
        // console.log(response.data.recordset,"response.data.recordset");
        setIsLoading(false);
        setVariables((prevState) => ({
          ...prevState,
          listOfCategories: response.data.recordset,
        }));

        const getEducationList = await Axios.get(
          `${Config.base_url}GET_QUALIFICATION/${UserID}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "x-caller": await encrypted,
            },
          }
        );
       
        if (getEducationList.status === 200) {
          const jsonData = getEducationList.data;
          if (jsonData.length > 0) {
          
            const parsedData = JSON.parse(jsonData[0]["EDUCATION_DETAILS"]);
            console.log(jsonData,"jsonData.............EDUCATION_DETAILS..............",parsedData)
            setEducationDetails(parsedData);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const degrees = [
    { label: "MBBS", value: "MBBS" },
    { label: "BDS", value: "BDS" },
    { label: "BAMS", value: "BAMS" },
    { label: "BHMS", value: "BHMS" },
    { label: "BUMS", value: "BUMS" },
    { label: "BSMS", value: "BSMS" },
    { label: "BNYS", value: "BNYS" },
    { label: "Others", value: "Others" },
  ];

  const handleDegreeChange = (deg) => {
    const selectedDegree = deg.value;

    if (selectedDegree === "Others") {
      setVariables({
        ...variables,
        showOtherInput: true,
        filteredCategories: [], // Clear filtered categories when "Others" is selected
      });
      setDegree({ selectedDegree: deg }); // Set the selected degree
      setSpeciality({ selectedSpeciality: "" });
    } else {
      const categoryOptions = variables.listOfCategories.filter(
        (item) => item.DEGREE === selectedDegree
      );
      setVariables({
        ...variables,
        showOtherInput: false, // Hide the other input if "Others" is not selected
        filteredCategories: categoryOptions,
      });
      setDegree({ selectedDegree: deg });
      setSpeciality({ selectedSpeciality: "" });
    }
  };

  const getYears = (startYear) => {
    const currentYear = new Date().getFullYear();
    const yearsTilPresent = [];

    while (startYear <= currentYear) {
      yearsTilPresent.push({
        label: startYear.toString(),
        value: startYear.toString(),
      });
      startYear++;
    }

    return yearsTilPresent;
  };

  const years = getYears(1994);

  const handleYearChange = (year) => {
    setVariables({ ...variables, selectedYear: year });
  };

  const handleSpecalityChange = (category) => {
    setSpeciality({ selectedSpeciality: category });
  };

  const handleOtherDegreeInputChange = (e) => {
    const updatedDegree = { ...degree, otherDegree: e.target.value };
    setDegree(updatedDegree);
    console.log(updatedDegree, "otherDegree");
  };

  const handleOtherSpecialityInputChange = (e) => {
    const updatedSpeciality = {
      ...speciality,
      otherSpeciality: e.target.value,
    };
    setSpeciality(updatedSpeciality);
    console.log(updatedSpeciality, "otherSpeciality");
  };

  function restructureData(data) {
    const degreeType = data.speciality
      ? data.speciality.otherSpeciality ||
        data.speciality.selectedSpeciality.value ||
        ""
      : "";
    const degree = data.degree
      ? data.degree.otherDegree || data.degree.selectedDegree.value || ""
      : "";
    const year = data.year ? data.year.value || "" : "";

    const newData = {
      degreeType: degreeType,
      degree: degree,
      year: year,
      hospital: null,
      city: null,
      roleTitle: null,
      startingMonth: null,
      startingYear: null,
      endMonth: null,
      tillYear: null,
    };

    return newData;
  }

  const updateEducation = (EducationDetails, mode) => {
    console.log(
      degree.selectedDegree,
      "degree.selectedDegree",
      variables.selectedYear,
      "variables.selectedYear",
      speciality.selectedSpeciality,
      "speciality.selectedSpeciality",
      speciality.otherSpeciality
    );
    if (mode === "add") {
      if (!degree.selectedDegree || !variables.selectedYear || !(speciality.selectedSpeciality || speciality.otherSpeciality)) {
        toast.warning("Please fill the mandatory fields");
        return;
      }
    }

    const data = {
      degree: degree,
      year: variables.selectedYear,
      speciality: speciality,
    };

    let modifiedData = restructureData(data);
    if (mode === "add") {
      modifiedData = [...educationDetails, modifiedData];
      setEducationDetails(modifiedData);
    } else if (mode === "remove") {
      modifiedData = EducationDetails;
    }

    const payload = {
      EDUCATION: modifiedData,
      mode: mode,
      userid: UserID,
      mobile: mobile,
    };

    setVariables({ ...variables, selectedYear: "", showOtherInput: false });
    setDegree({ selectedDegree: "", otherDegree: "" });
    setSpeciality({ selectedSpeciality: "", otherSpecality: "" });

    Axios.post(Config.apiURL + Config.routes.updateEducationAPI, payload, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
        "x-caller": encrypted,
      },
    })
      .then((response) => {
        //console.log("response", response);
        if (mode === "add") {
          toast.success("saved successfully");
          setActiveSection("");
        }
      })
      .catch(() => {
        toast.error("Not Saved."); // Show an error toast if the save fails
      });
  };

  const removeEducation = (index) => {
    setEducationDetails((prevDetails) => {
      const updatedEducationList = [...prevDetails];
      updatedEducationList.splice(index, 1);

      // Filter out objects with all properties empty or null
      const filteredList = updatedEducationList.filter((detail) => {
        return Object.values(detail).some(
          (value) => value !== "" && value !== null
        );
      });

      // Update the state with the filtered array
      setEducationDetails(filteredList);

      // Call the updateEducation function with the filtered array
      updateEducation(filteredList, "remove");

      // Return the filtered array
      return filteredList;
    });
  };

  const DropdownIndicator = (props) => {
    return (
      <svg
        width="40px"
        height="30px"
        viewBox="0 0 24 24"
        cursor="pointer"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
          fill="black" // Set your desired arrow color here
        />
      </svg>
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "lightblue"
        : provided.backgroundColor,
      color: "black",
      "&:hover": {
        backgroundColor: "lightblue", // Ensures the hover background is fully visible
        color: "black",
      },
      boxSizing: "border-box", // Ensures the width includes padding and border
      padding: "8px 12px", // Adjust padding to ensure full width coverage
      width: "100%", // Ensure the option takes the full width of the dropdown
      margin: "0", // Remove any margin that might cause the cutting
    }),
    menu: (provided) => ({
      ...provided,
      width: "auto", // Auto width based on the content
      minWidth: "100%", // Ensure menu is at least as wide as the select component
    }),
  };

  const toggleSection = (sectionName) => {
    setActiveSection(sectionName);
  };

  let parsedEducationDetails = [];

  if (Array.isArray(educationDetails) || typeof educationDetails === "object") {
    parsedEducationDetails = educationDetails;
  } else {
    try {
      parsedEducationDetails = JSON.parse(educationDetails);
    } catch (error) {
      console.error("Error parsing educationDetails:", error);
    }
  }
  return (
    <>
      <div
        className="profileRightContainer"
        style={{ display: "flex", gap: "1rem" }}
      >
        <div className="leftContainer" style={{ width: "70%" }}>
          
          <div className="educationd-bottomContainer">
            {isLoading ? (
              <div className="spinningLoader">
                <CircularProgress size={48} color="inherit" />
              </div>
            ) : (
              <div className="cardsCols">
                {Array.isArray(educationDetails) &&
                educationDetails.length > 0 ? (
                  parsedEducationDetails.map((details, index) => (
                    <div
                      className="educationd-cardContainer"
                      key={index} /*style={{width:"80%"}}*/
                    >
                     
                      <div className="innerdeleteicon">
                        <img
                          class="innerdeleteicon-img"
                          src={DeleteIcon}
                          alt="Delete"
                          onClick={() =>
                            removeEducation(index)
                          }
                        />
                      </div>
                      <div className="educationd-cardImgContainer">
                        <div className="educationd-cardImg">
                          <img src={DegreeImg} />
                        </div>
                      </div>

                      <div className="educationd-cardContentContainer">
                        <p>
                          <span className="hospital-row">Degree: </span>
                          {details.degree}
                        </p>
                        <p>
                          <span className="hospital-row">Year: </span>{" "}
                          {details.year}
                        </p>
                        <p>
                          <span className="hospital-row">Speciality: </span>{" "}
                          {details.degreeType}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No education details available</p>
                )}

                <div
                  className="educationd-cardContainer educationd-addContainer"
                  onClick={() => toggleSection("AddEducation")}
                >
                  <p className="educationd-add">+</p>
                </div>
              </div>
            )}

            {/* <ToastContainer /> */}
          </div>
        </div>

        {activeSection === "AddEducation" ? (
          <div className="hospitalpopup">
            <div className="another-Boxshadow">
              <p className="hsptlsetup">
                <span className="plus-icon">+</span>
                <span className="header-text">Add Education Qualification</span>
                <span className="close" onClick={() => toggleSection("")}>
                  &times;
                </span>
              </p>
              <div className="Updbtn">
                <div className="Boxwidth">
                  {/* <p className="Profdet">
                    Section A: Profile details
                  </p>
                  <p className="Profdet">
                    Please enter your basic level Qualification
                  </p> */}
                  <div
                    className="personalInfoContainer"
                    style={{
                      display: "flex",
                      gap: "2rem",
                      flexWrap: "wrap",
                      marginBottom: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="educationd-inputField">
                      <label style={{ marginBottom: "10px" }}>Degree</label>
                      <Select
                        id="stateDropdown"
                        value={degree.selectedDegree}
                        onChange={handleDegreeChange}
                        options={degrees}
                        placeholder="Degree*"
                        isSearchable
                        //isClearable
                        styles={customStyles}
                        components={{ DropdownIndicator }}
                      />
                    </div>

                    {variables.showOtherInput && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <input
                          style={{
                            padding: "0.7rem 0.7rem",
                            display: "block",
                            width: "47%",
                            height: "39px",
                            borderRadius: "10px",
                            border: "1px solid #AAA",
                          }}
                          className="otherInput"
                          type="text"
                          value={degree.otherDegree}
                          onChange={handleOtherDegreeInputChange}
                          placeholder="Enter Other Degree"
                        />
                      </div>
                    )}

                    <div className="educationd-inputField">
                      <label style={{ marginBottom: "10px" }}>Year</label>
                      <Select
                        id="stateDropdown"
                        value={variables.selectedYear}
                        onChange={handleYearChange}
                        options={years}
                        placeholder="Year of Completion*"
                        isClearable
                        isSearchable
                        styles={customStyles}
                        components={{ DropdownIndicator }}
                      />
                    </div>

                    {variables.showOtherInput ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <input
                            type="text"
                            value={speciality.otherSpeciality}
                            onChange={handleOtherSpecialityInputChange}
                            placeholder="Enter Specialty"
                            style={{
                              padding: "0.7rem 0.7rem",
                              display: "block",
                              width: "47%",
                              height: "39px",
                              borderRadius: "10px",
                              border: "1px solid #AAA",
                            }}
                            className="otherInput"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="Specialitybox">
                          {degree.selectedDegree !== "" &&
                            variables.selectedYear !== "" && (
                              <>
                                <label style={{ marginBottom: "10px" }}>
                                  Speciality
                                </label>

                                {/* {console.log(variables.filteredCategories
                              .filter((item) => item.CATEGORY !== 'BDS')
                              .map(
                                  (item) => ({
                                    label: item.CATEGORY,
                                    value: item.CATEGORY,
                                  })
                                ),"list ......")} */}
                                {console.log(
                                  degree?.selectedDegree,
                                  "degree?.selectedDegree"
                                )}
                                <Select
                                  id="specialityDropdown"
                                  value={speciality.selectedSpeciality}
                                  onChange={handleSpecalityChange}
                                  options={variables.filteredCategories
                                    .filter(
                                      (item) =>
                                        item.CATEGORY !==
                                        degree?.selectedDegree?.value
                                    )
                                    .map((item) => ({
                                      label: item.CATEGORY,
                                      value: item.CATEGORY,
                                    }))}
                                  placeholder="Choose the Speciality*"
                                  isSearchable
                                  styles={customStyles}
                                  components={{ DropdownIndicator }}
                                />
                              </>
                            )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* <div
                  style={{
                    width: "90%",
                    height: "45px",
                    background: "white",
                  }}
                  className={"editButton2"}
                  onClick={addEducation}
                >
                  <p
                    style={{
                      color: "#52447d",
                      fontSize: "14px",
                      fontFamily: "Verdana",
                      fontWeight: "400",
                      lineHeight: "18.38px",
                      letterSpacing: "15%",
                    }}
                  >
                    Add
                  </p>
                </div> */}
                <p className="horizontal-line"></p>
                <div className="educationd-buttonContainer">
                  <div
                    className={"educationd-Btn educationd-close-Btn"}
                    onClick={() => toggleSection("")}
                  >
                    <p>Close</p>
                  </div>
                  <div
                    className={"educationd-Btn educationd-save-Btn"}
                    onClick={() => updateEducation(educationDetails, "add")}
                  >
                    <p>Save</p>
                  </div>
                </div>
              </div>
              <ToastContainer position="top-right" />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default UpdateEducation;
