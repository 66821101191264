import React, { useState, useEffect } from "react";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import Config from "../../Config/config";
import axios from "axios";
import NHA from "../../images/nha.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FacilityDetails from "../FacilityDetails";
import CreateHfrId from "../CreateHfrId";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { CircularProgress } from "@material-ui/core";
import { fireEvent } from "@testing-library/react";
import { Diversity1 } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const ABDM = ({ onButtonClick }) => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("HPR");
  const [selectedOption, setSelectedOption] = useState("");
  const [HospiralId, setHospiralId] = useState("");
  const [suggesstions, setsuggesstions] = useState([]);
  const [selectedHprId, setSelectedHprId] = useState("");
  const [showHfrId, setShowHfrId] = useState(false);
  const [variables, setVariables] = useState({
    hprId: "",
    hfrId: "",
    aadharnumber: "",
    mobilenumber: "",
    otp: "",
    hprnumber: "",
    hpraddress: "",
    firstname: "",
    lastname: "",
    middlename: "",
    statecode: "",
    districtcode: "",
    profilephoto: "",
    address: "",
    statename: "",
    pincode: "",
    districtname: "",
    email: "",
    password: "",
  });

  const [timer, setTimer] = useState(30);
  const [disabled, setDisabled] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);


  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));
  const hospitalId = sessionStorage.getItem("hospitalId") || "";
  const HOSPITAL_SETUP = sessionStorage.getItem("HOSPITAL_SETUP") || "";

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const [visible, setVisible] = useState({
    createabha: false,
    createabhaotp: false,
    hprdetails: false,
    hpraddresscreation: false,
    hprdetailscreation: false,
    hprmobile: false,
    createhfr: false,
    createhpraadhar: false,
    successscreen: false,
  });

  const handleNext = async () => {
    if (activeSection === "HPR") {
      setActiveSection("HFR");
      return;
    }
    const hospitalId = sessionStorage.getItem("hospitalId") || "";
    const UserID = sessionStorage.getItem("RegUserId") || "";
    const publicKey = sessionStorage.getItem("RegpublicKey") || "";
    const token = sessionStorage.getItem("Regtoken") || "";
    var padding = constants.RSA_PKCS1_PADDING;
    var data = Buffer.from(UserID.toString(), "utf-8");
    var encrypted = publicEncrypt(
      { key: publicKey, padding: padding },
      data
    ).toString("base64");
    try {
      const body = JSON.stringify({
        // hospitalId: hospitalId,
        // userId: UserID,
        // hprId: variables.hprId,
        // hfrId: variables.hfrId,
      });
      //console.log("dataforabdm", body);
      axios
        .post(Config.base_url + Config.routes.updateHFRID, body, {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        })
        .then((response) => {
          //console.log("res", response);
          if (response.status == 200) {
            toast.success("ABDM Updated Sucessfully");
          }
        })
        .catch((error) => {
          toast.error("Server Error!");
          console.error(error);
        });
    } catch (error) {
      console.error(error);
      return "Error";
    }
  };

  const handleRadioChange = (e) => {
    setSelectedHprId(e.target.value);
  };

  const onButtonClickhfr = () => {
    if (variables.hprId != "") {
      sessionStorage.setItem("hpraddress", variables.hprId);
      setShowHfrId(true);
      setActiveSection("HFR");
      setVisible((prevState) => ({
        ...prevState,
        createhfr: true,
      }));
    } else {
      toast.error("Please enter Hprid");
    }
  };

  const onButtonClickhpr = () => {
    setShowHfrId(true);
    setActiveSection("HFR");
    setVisible((prevState) => ({
      ...prevState,
      createhpraadhar: true,
    }));
  };

  const handleCancel = () => {
    navigate("/patients");
  };

  const handleChange = (otp) => {
    setVariables((prevState) => ({
      ...prevState,
      otp: otp,
    }));
  };

  const handleResend1 = () => {
    if (!disabled) {
      setDisabled(true);
      setTimer(30);
      generateaadharotp();
    }
  };

  const toggleSection = (sectionName) => {
    setActiveSection(sectionName);

  };

  const toggleOptions = (option) => {

    setSelectedOption(option);
    // if(option ==='Yes'){
    //   setActiveSection("HPR");
    // }
    if (option === "Yes") {
      setVisible((prev) => ({
        ...prev,
        createabha: false,
      }));
    }
  };

  const toggleOptions1 = (option) => {

    setSelectedOption(option);
    // if(option ==='Yes'){
    //   setActiveSection("HPR");
    // }
    if (option === "Yes") {
      setVisible((prev) => ({
        ...prev,
        createabha: false,
      }));
    } else if (option === "No") {
      setActiveSection("HPR");
      setVisible((prev) => ({
        ...prev,
        createhpraadhar: false,
      }));
    }
  };

  const updateHPRId = () => {
    if (!variables.hprId) {
      toast.error("Please Enter HPR ID");
      return;
    }
    sessionStorage.setItem("hpraddress", variables.hprId);
    setActiveSection("HFR");
  };

  const createHPRId = () => {
    setVisible((prevState) => ({
      ...prevState,
      createabha: true,
    }));
    // setActiveSection("createHPRId");
  };

  const handleInputChange = (event, field) => {
    const value = event.target.value;

    if (field === "password") {
      const isValid = /^(?=.*[A-Z]).{8,}$/.test(value); // Regex for length & uppercase
      if (!isValid) {
        console.log("Password must be at least 8 characters long and contain at least one uppercase letter.");
      }
    }

    if (field === "email") {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value); // Standard email regex
      if (!isValidEmail) {
        console.log("Please enter a valid email address.");
      }
    }

    setVariables((prevVariables) => ({
      ...prevVariables,
      [field]: event.target.value,
    }));
  };

  useEffect(() => {
    defaultHospital();
  }, []);

  const defaultHospital = async () => {
    const UserID = sessionStorage.getItem("RegUserId")
      ? JSON.parse(sessionStorage.getItem("RegUserId"))
      : sessionStorage.getItem("UserID");

    const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");
    var options = {
      method: "GET",
      url: `${Config.base_url}getDefaultHospital/${UserID}`,
      headers: {
        authorization: authentication,
        "Content-Type": "application/json",
        "x-caller": encrypted,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(
        //   "setHospitalList",
        //   response.data.recordset?.[0].HOSPITAL_ID
        // );
        setHospiralId(response.data.recordset?.[0].HOSPITAL_ID);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const updateHFRId = async () => {
    //console.log(hospitalId, " const hospitalId");
    const PATIENT_ID = sessionStorage.getItem("PATIENT_ID")
      ? JSON.parse(sessionStorage.getItem("PATIENT_ID"))
      : sessionStorage.getItem("PATIENT_ID");
    if (variables.hfrId !== "") {
      try {
        const paylaod = {
          hfrId: variables.hfrId ?? '',
          hospitalId: HospiralId,
          // userId: PATIENT_ID?PATIENT_ID:UserID,
          userId: UserID,
          hprId: variables.hprId,
        };

        console.log("paylaod0,", paylaod);
        const response = await axios.post(
          `${Config.base_url}v2/updateHFRId`,
          paylaod,
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
              authorization: `Bearer ${token}`,
              "x-caller": await encrypted,
            },
          }
        );

        if (response.status === 200) {
          console.log("respoknnnolnlnmse", response);
          setVariables((prevVariables) => ({
            hfrId: "",
            hprId: "",
          }));
          setActiveSection("HPR");
          setVisible((prevState) => ({
            ...prevState,
            createabhaotp: false,
            createabha: false,
            hprdetails: false,
            successscreen: false,
            createhfr: false,
            createhpraadhar: false,
            hpraddresscreation: false,
            hprdetailscreation: false,
            hprmobile: false,
          }));
          toast.success("We have linked HPR ID with hospital.");
        } else {
          toast.error(
            "Sorry !!! ",
            "We're facing a server issue. Please try after some time."
          );
        }
      } catch (error) {
        console.error(error);
        toast.error(
          "Sorry !!! We're facing a server issue. Please try after some time."
        );
      }
    } else {
      toast.error("Please Enter HFR ID");
    }
  };

  const generateaadharotp = async () => {
    setShowLoading(true);
    if (variables.aadharnumber !== "" && variables.aadharnumber.length == "12") {
      try {
        const paylaod = {
          "aadhaar": variables.aadharnumber,
        };

        console.log("paylaod0,", paylaod);
        const response = await axios.post(
          `${Config.abhaBaseUrl}hprGenerateAadhaarOtp`,
          paylaod,
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
              authorization: `Bearer ${token}`,
              "x-caller": await encrypted,
            },
          }
        );

        console.log("response of aadhar otp", response);

        if (response.status === 200) {
          setShowLoading(false);
          console.log("respoknnnolnlnmse", response);
          sessionStorage.setItem("hpraccesstoken", response.data.accessToken);
          sessionStorage.setItem("hprtxnIdtoken", response.data.txnId);
          setVisible((prevState) => ({
            ...prevState,
            createabhaotp: true,
          }));
          toast.success("Otp sent Successfully");
        } else {
          setShowLoading(false);
          toast.error(
            "Sorry !!! ",
            "We're facing a server issue. Please try after some time."
          );
        }
      } catch (error) {
        setShowLoading(false);
        if (error.response) {
          console.log("Error Response:", error.response.data);
        } else {
          console.log("Request Error:", error.message);
        }
        toast.error(
          "Sorry !!! We're facing a server issue. Please try after some time."
        );
      }
    } else {
      setShowLoading(false);
      toast.error("Please Enter Aadhaar number");
    }
  };


  const verifygenerateaadharotp = async () => {
    setShowLoading(true);
    if (variables.otp !== "" && variables.otp.length == "6") {
      const txnId = sessionStorage.getItem("hprtxnIdtoken");
      try {
        const paylaod = {
          "otp": variables.otp,
          "txnId": txnId
        };

        console.log("paylaod0,", paylaod);
        const response = await axios.post(
          `${Config.abhaBaseUrl}hprVerifyAadhaarOtp`,
          paylaod,
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
              authorization: `Bearer ${token}`,
              "x-caller": await encrypted,
            },
          }
        );

        console.log("response of aadhar otp", response);

        if (response.status === 200) {
          setShowLoading(false);
          sessionStorage.setItem("hpotpxnIdtoken", response.data.txnId);
          await isexisthprid(response.data.txnId);
          toast.success("Otp Verified");
        } else {
          setShowLoading(false);
          toast.error(
            "Sorry !!! ",
            "We're facing a server issue. Please try after some time."
          );
        }
      } catch (error) {
        setShowLoading(false);
        if (error.response) {
          console.log("Error Response:", error.response.data);
        } else {
          console.log("Request Error:", error.message);
        }
        toast.error(
          "Sorry !!! We're facing a server issue. Please try after some time."
        );
      }
    } else {
      setShowLoading(false);
      toast.error("Please Enter Aadhaar number");
    }
  };

  // if hpr number exists
  const isexisthprid = async (txnId) => {
    setShowLoading(true);
    try {
      const paylaod = {
        "txnId": txnId
      };

      console.log("paylaod0,", paylaod);
      const response = await axios.post(
        `${Config.abhaBaseUrl}hprHprIdExist`,
        paylaod,
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      );

      console.log("response of hpr id", response);
      if (response.status === 200) {
        setShowLoading(false);
        // sessionStorage.setItem("hpotpxnIdtoken", response.data.txnId);
        setVariables((prevState) => ({
          ...prevState,
          hprId: response.data.data.hprIdNumber,
          hpraddress: response.data.data.hprId,
        }));
        if (response.data.data.hprIdNumber !== '') {
          setVisible((prevState) => ({
            ...prevState,
            hprdetails: true,
          }));
        } else {
          setVisible((prevState) => ({
            ...prevState,
            hprdetails: false,
            createabhaotp: false,
            hpraddresscreation: true,
          }));
          sessionStorage.setItem("hprfirstName", response.data.data.firstName);
          sessionStorage.setItem("hprlastName", response.data.data.lastName);
          sessionStorage.setItem("hprmiddleName", response.data.data.middleName);
          sessionStorage.setItem("hpraddress", response.data.data.address);
          sessionStorage.setItem("hprpincode", response.data.data.pincode);
          sessionStorage.setItem("hprprofilePhoto", response.data.data.profilePhoto);
          sessionStorage.setItem("hprstateCode", response.data.data.stateCode);
          sessionStorage.setItem("hprstateName", response.data.data.stateName);
          sessionStorage.setItem("hprdistrictCode", response.data.data.districtCode);
          sessionStorage.setItem("hprdistrictName", response.data.data.districtName);
          setVariables((prevState) => ({
            ...prevState,
            firstname: response.data.data.firstName,
            lastname: response.data.data.lastName,
            address: response.data.data.address,
            pincode: response.data.data.pincode,
            statename: response.data.data.stateName,
            districtname: response.data.data.districtName,
            statecode: response.data.data.stateCode,
            profilephoto: response.data.data.profilePhoto,
            districtcode: response.data.data.districtCode
          }));
        }

      } else {
        setShowLoading(false);
        toast.error(
          "Sorry !!! ",
          "We're facing a server issue. Please try after some time."
        );
      }
    } catch (error) {
      setShowLoading(false);
      if (error.response) {
        console.log("Error Response:", error.response.data);
      } else {
        console.log("Request Error:", error.message);
      }
      toast.error(
        "Sorry !!! We're facing a server issue. Please try after some time."
      );
    }
  };

  // FOR HPR ID HPRDEMOGRAPHIC MOBILE
  const hprdemographic = async () => {
    setShowLoading(true);
    const txnId = sessionStorage.getItem("hpotpxnIdtoken");
    try {
      const paylaod = {
        "txnId": txnId,
        "mobileNumber": variables.mobilenumber,
      };

      console.log("body params,", paylaod);
      const response = await axios.post(
        `${Config.abhaBaseUrl}hprDemographicMobile`,
        paylaod,
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      );

      console.log("response of hpr demographics", response);
      if (response.status === 200) {
        setShowLoading(false);
        // sessionStorage.setItem("hpotpxnIdtoken", response.data.txnId);
        console.log("hprdem response ", response.data);
        if (response.data.data.verified !== 'true') {
          await hpraddresssuggestions();
        } else {
          toast.error(
            "Sorry !!! ",
            "Aadhaar number is not linked with mobile number"
          );
        }

      } else {
        setShowLoading(false);
        toast.error(
          "Sorry !!! ",
          "We're facing a server issue. Please try after some time."
        );
      }
    } catch (error) {
      setShowLoading(false);
      if (error.response) {
        console.log("Error Response:", error.response.data);
      } else {
        console.log("Request Error:", error.message);
      }
      toast.error(
        "Sorry !!! We're facing a server issue. Please try after some time."
      );
    }
  };

  // FOR HPR ADDRESS SUGGESSTIONS
  const hpraddresssuggestions = async () => {
    setShowLoading(true);
    const txnId = sessionStorage.getItem("hpotpxnIdtoken");
    try {
      const paylaod = {
        "txnId": txnId,
      };
      console.log("body params,", paylaod);
      const response = await axios.post(
        `${Config.abhaBaseUrl}hprHpidSuggestion`,
        paylaod,
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      );

      console.log("response of hpr suggesstions", response);
      if (response.status === 200) {
        setShowLoading(false);
        setVisible((prevState) => ({
          ...prevState,
          hprdetailscreation: true,
        }));
        console.log("hprdem suggesstions response ", response.data);
        setsuggesstions(response.data.data);
      } else {
        setShowLoading(false);
        toast.error(
          "Sorry !!! ",
          "We're facing a server issue. Please try after some time."
        );
      }
    } catch (error) {
      setShowLoading(false);
      if (error.response) {
        console.log("Error Response:", error.response.data);
      } else {
        console.log("Request Error:", error.message);
      }
      toast.error(
        "Sorry !!! We're facing a server issue. Please try after some time."
      );
    }
  };


  // CREATING HPR ID WITH AADHAR email
  const createidwithaadhar = async () => {
    setShowLoading(true);
    const txnId = sessionStorage.getItem("hpotpxnIdtoken");
    try {
      const paylaod = {
        "email": variables.email,
        "firstName": variables.firstname,
        "lastName": variables.lastname,
        "middleName": variables.middlename,
        "stateCode": variables.statecode,
        "districtCode": variables.districtcode,
        "address": variables.address,
        "pincode": variables.pincode,
        "txnId": txnId,
        "hprId": selectedHprId + "@hpr.abdm",
        "password": variables.password,
        "profilePhoto": variables.profilephoto
      };
      console.log("body params,", paylaod);
      const response = await axios.post(
        `${Config.abhaBaseUrl}hprCreateWithPreVerified`,
        paylaod,
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      );

      console.log("response of hpr suggesstions", response);
      if (response.status === 200) {
        setShowLoading(false);
        console.log("hprdem suggesstions response ", response.data);
        setVisible((prevState) => ({
          ...prevState,
          hprdetailscreation: false,
          successscreen: true,
        }));
        setVariables((prevState) => ({
          ...prevState,
          hprId: response.data.data.hprId,
        }));
      } else {
        setShowLoading(false);
        toast.error(
          "Sorry !!! ",
          "We're facing a server issue. Please try after some time."
        );
      }
    } catch (error) {
      setShowLoading(false);
      if (error.response) {
        console.log("Error Response:", error.response.data);
      } else {
        console.log("Request Error:", error.message);
      }
      toast.error(
        "Sorry !!! We're facing a server issue. Please try after some time."
      );
    }
  };

  const handleButtonClick = () => {
    setShowHfrId(true);
  };

  const skipABDM = () => {
    // if (skipRoute === "addPatient") {
    console.log("Skipped integration");
    navigate("/patients");
    // }
  };

  const handleSkipClick = () => {
    setShowAlert(true); // Show the dialog first
  };

  const handleClose = () => {
    setShowAlert(false); // Close dialog when "Cancel" is clicked
  };

  const handleAction = () => {
    setShowAlert(false);
    skipABDM();
  };


  return (
    <>
      <div className="profileRightContainer">
        <div
          className="leftContainer"
          style={{
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="bottomContainer"
            style={{ gap: "1rem", overflow: "scroll" }}
          >
            <div className="HprHir">
              <div
                className={`${activeSection === "HPR" ? "activeSection" : ""}`}
                onClick={() => toggleSection("HPR")}
                style={{
                  width: "50%",
                  textAlign: "center",
                  alignContent: "center",
                  border:
                    activeSection === "HPR" ? "rgba(93, 74, 135, 1)" : "none",
                  padding: "0.2rem 1.5rem",
                  cursor: "pointer",
                }}
              >
                <p style={{ cursor: "pointer" }}>HPR ID</p>
              </div>
              <div
                className={`${activeSection === "HFR" ? "activeSection" : ""}`}
                onClick={() => toggleSection("HFR")}
                style={{
                  width: "212px",
                  border:
                    activeSection === "HFR" ? "rgba(93, 74, 135, 1)" : "none",
                  padding: "0.3rem 3rem",
                  textAlign: "center",
                  alignContent: "center",
                  cursor: "pointer",
                }}
              >
                <p style={{ cursor: "pointer" }}>HFR ID</p>
              </div>
            </div>
            {activeSection === "HPR" && (
              <div className="hpr_inner">
                <div className="hpr_indiv">
                  <p className="health-service">
                    Health Professional Registry Service
                  </p>
                  <p className="Pargtext" style={{ marginLeft: "30px" }}>
                    Do you have Ayushman Bharat Digital Mission Health
                    Professional Registry ID ?
                  </p>
                  {/* <input type="text" className="abdm-input-feild"/> */}
                  {selectedOption === "Yes" && (
                    <input
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        background: "#eeecf5",
                        borderRadius: "9px",
                        height: "6vh",
                        padding: "0 1rem",
                        border: "none",
                      }}
                      className="personalInfoValue"
                      type="text"
                      placeholder="Enter HPR ID"
                      value={variables.hprId}
                      onChange={(e) => handleInputChange(e, "hprId")}
                    />
                  )}
                  <div className="yes-no-buttons-section">
                    <p
                      style={{
                        width: "97px",
                        height: "32px",
                        borderRadius: "8px",
                        border:
                          selectedOption === "Yes"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "Yes" ? "#8873C9" : "#FFF",

                        color: selectedOption === "Yes" ? "white" : "#8873C9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions("Yes")}
                    >
                      Yes
                    </p>
                    <p
                      style={{
                        width: "97px",
                        height: "32px",
                        borderRadius: "8px",
                        border:
                          selectedOption === "No"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "No"
                            ? "rgba(136, 115, 201, 1)"
                            : "white",
                        color:
                          selectedOption === "No"
                            ? "white"
                            : "rgba(136, 115, 201, 1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions("No")}
                    >
                      No
                    </p>
                  </div>
                  <Dialog open={showAlert} onClose={handleClose} PaperProps={{
                    style: { width: "25%" }
                  }}>
                    <DialogTitle>ALERT !!</DialogTitle>
                    <DialogContent>
                      <p style={{ paddingTop: "20px" }}>
                        Do you want to Skip HPR creation??
                      </p>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">Cancel</Button>
                      <Button onClick={handleAction} color="primary">Continue</Button>
                    </DialogActions>
                  </Dialog>
                  <p
                    className="SkipAbhatext"
                    style={{ fontSize: "16px", fontWeight: "600", marginTop: "10px" }}
                    onClick={handleSkipClick}
                  >
                    Skip HPR creation for now
                  </p>
                  <div className="skip_div" style={{ width: '100%' }}>
                    {/* <p className="skip_p">Skip ABDM integration for now</p> */}
                    <div
                      className={"editButton2"}
                      style={{ width: "28%", height: "40px", textAlign: "left" }}
                      onClick={updateHPRId}
                    >
                      <p style={{ color: "white" }}>Next</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeSection === "HFR" && (
              <div className="div_p_text">
                <div className="p_text_main">
                  <p className="health-service">
                    Health Facility Registry Service
                  </p>
                  <p className="p_text" style={{ marginLeft: "30px" }}>
                    Do you have Ayushman Bharat Digital Mission Health Facility
                    Registry ID ?
                  </p>
                  <div className="p_textinner">
                    <p
                      style={{
                        width: "97px",
                        height: "32px",
                        borderRadius: "12px",
                        border:
                          selectedOption === "YesHfr"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "YesHfr" ? "#8873C9" : "white",
                        color:
                          selectedOption === "YesHfr" ? "white" : "#8873C9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions("YesHfr")}
                    >
                      Yes
                    </p>
                    <p
                      style={{
                        width: "97px",
                        height: "32px",
                        borderRadius: "12px",
                        border:
                          selectedOption === "NoHfr"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "NoHfr"
                            ? "rgba(136, 115, 201, 1)"
                            : "white",
                        color:
                          selectedOption === "NoHfr"
                            ? "white"
                            : "rgba(136, 115, 201, 1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions("NoHfr")}
                    >
                      No
                    </p>
                  </div>
                  {selectedOption === "YesHfr" && (
                    <>
                      <input
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          background: "#eeecf5",
                          height: "6vh",
                          padding: "0 1rem",
                          border: "none",
                        }}
                        className="personalInfoValue"
                        type="text"
                        placeholder="Enter HFR ID"
                        value={variables.hfrId}
                        onChange={(e) => handleInputChange(e, "hfrId")}
                      />
                    </>
                  )}
                </div>
                <Dialog open={showAlert} onClose={handleClose} PaperProps={{
                  style: { width: "25%" }
                }}>
                  <DialogTitle>ALERT !!</DialogTitle>
                  <DialogContent>
                    <p style={{ paddingTop: "20px" }}>
                      Do you want to Skip HFR creation??
                    </p>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleAction} color="primary">Continue</Button>
                  </DialogActions>
                </Dialog>
                <p
                  className="SkipAbhatext"
                  style={{ fontSize: "16px", fontWeight: "600", marginTop: "20px", marginBottom: "10px" }}
                  onClick={handleSkipClick}
                >
                  Skip HFR creation for now
                </p>
                <div className="skip_div">
                  {/* <p className="skip_p">Skip ABDM integration for now</p> */}
                  <div
                    className={"editButton2"}
                    style={{ width: "28%", height: "40px", textAlign: "left" }}
                    onClick={updateHFRId}
                  >
                    <p style={{ color: "white" }}>Submit</p>
                  </div>
                </div>
              </div>
            )}

            <ToastContainer />
          </div>
        </div>

        {activeSection === "HPR" && selectedOption === "No" && !visible.createabha && !visible.createabhaotp && !visible.successscreen && (
          <div
            className="leftContainer"
            style={{ width: "50%", gap: "1rem", justifyContent: "center" }}
          >
            <div className="hfr_hpr_container">
              <div
                className="bottomContainer"
                style={{
                  gap: "1rem",
                  height: "100%",
                  overflow: "scroll",
                  justifyContent: "center",
                }}
              >
                <div style={{ gap: "3rem" }}>
                  <p style={{ fontWeight: "600", fontSize: "19px", marginLeft: "10px" }}>
                    {/* Do you want to complete HPR registration ? */}
                    HPR registration
                  </p>
                  <p className="Benfits_abdm">
                    Benefits of Health Provider Registry
                  </p>
                  <ul className="ul_online">
                    <li>i. Online presence and discoverability.</li>
                    <li>
                      ii. Faster Registration, License Renewal, and NOC Issuance
                      with councils and other governing bodies.
                    </li>
                    <li>iii. Digital Health Records</li>
                    <li>iv. Telemedicine</li>
                  </ul>

                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ fontWeight: "600" }}>To create your HPR ID -</p>
                    <a className="a_clickhere">Create HPR ID</a>
                  </div> */}
                  <div className="skip_div" style={{ marginRight: "30px" }} >
                    {/* <p className="skip_p">Skip ABDM integration for now</p> */}
                    <div
                      className={"editButton2"}
                      style={{ width: "28%", height: "40px", textAlign: "center" }}
                      onClick={createHPRId}
                    >
                      <p style={{ color: "white" }}>Create HPR ID</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeSection === "HFR" && selectedOption === "YesHfr" && !visible.createabha && !visible.createabhaotp && (
          <div className="leftContainer" style={{ width: "50%", gap: "1rem" }}>
            <div
              className="bottomContainer"
              style={{
                gap: "1rem",
                height: "100%",
                overflow: "scroll",
                justifyContent: "center",
              }}
            >
              <p>
                Please use below Bridge ID & URL to link Clinally mPower with
                your Health Facility:
              </p>
              <p className="bridge_ul">
                <ul>
                  <li>Bridge ID : CPHSPL_001</li>
                  <li>Callback URL : https://abhaservices.clinally.com</li>
                </ul>
              </p>
              <p className="p_stepts">
                Steps for Linkage of Software From the dashboard
              </p>
              <ul style={{ paddingLeft: "1.5rem", color: "gray" }}>
                <li>i. Login to HFR portal using HFR ID.</li>
                <li>
                  ii. Click on Link your Software from your respective facility.
                </li>
                <li>
                  iii. Enter the Bridge ID and call back URL provided to your
                </li>
              </ul>
            </div>
          </div>
        )}

        {activeSection === "HFR" && selectedOption === "NoHfr"
          && !visible.createabha && !visible.createabhaotp && !visible.createhfr && !visible.createhpraadhar && (
            <div
              className="leftContainer"
              style={{ width: "50%", gap: "1rem", justifyContent: "center" }}
            >
              <div
                className="bottomContainer"
                style={{
                  gap: "1rem",
                  height: "100%",
                  overflow: "scroll",
                  justifyContent: "center",
                }}
              >
                <div style={{ gap: "3rem" }}>
                  <p className="hprRegistration">
                    HFR registration
                  </p>
                  <p className="benofHeal">
                    Benefits of Health Facility Registry
                  </p>
                  <ul className="ul_Disc">
                    <li>i. Unique & Trustable Identity.</li>
                    <li>ii. Online Presence & Discoverability.</li>
                    <li>iii. Ease of doing business</li>
                    <li>iv. Unified Digital Services</li>
                  </ul>

                  {/* <div className="div_clickme">
                    <p style={{ fontWeight: "600" }}>To create your HFR ID -</p>
                    <button className="clickmeButtton" onClick={onButtonClickhfr}>
                      Click Here
                    </button> */}
                  {/*{currentPage === "createHFRId" && <FacilityDetails />}*/}
                  {/* </div> */}
                  <div className="skip_div" style={{ marginRight: "30px" }} >
                    {/* <p className="skip_p">Skip ABDM integration for now</p> */}
                    <div
                      className={"editButton2"}
                      style={{ width: "28%", height: "40px", textAlign: "center" }}
                      onClick={onButtonClickhpr}
                    >
                      <p style={{ color: "white" }}>Create HFR ID</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {visible.createhpraadhar && !visible.createhfr && (
          <div
            className="leftContainer"
            style={{ width: "50%", gap: "1rem", justifyContent: "center", marginLeft: "5%" }}
          >
            <div className="abhaContainer">
              <div className="hpr_inner" style={{ width: "100%", justifyContent: "center", paddingTop: "20px", paddingBottom: "20px" }}>
                <div className="hpr_indiv">
                  <div style={{ justifyContent: "start" }}>
                    <p className="health-service" >
                      HPR ID
                    </p>
                  </div>
                  <p className="health-service">
                    Health Professional Registry Service
                  </p>
                  <p className="Pargtext" style={{ marginLeft: "30px" }}>
                    Do you have Ayushman Bharat DigitalMission Health
                    Professional Registry ID ?
                  </p>
                  {/* <input type="text" className="abdm-input-feild"/> */}
                  {selectedOption === "Yes" && (
                    <input
                      style={{
                        cursor: "pointer",
                        width: "70%",
                        background: "#eeecf5",
                        borderRadius: "9px",
                        height: "6vh",
                        padding: "0 1rem",
                        border: "none",
                      }}
                      className="personalInfoValue"
                      type="text"
                      placeholder="Enter HPR ID"
                      value={variables.hprId}
                      onChange={(e) => handleInputChange(e, "hprId")}
                    />
                  )}
                  <div className="yes-no-buttons-section">
                    <p
                      style={{
                        width: "97px",
                        height: "32px",
                        borderRadius: "8px",
                        border:
                          selectedOption === "Yes"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "Yes" ? "#8873C9" : "#FFF",

                        color: selectedOption === "Yes" ? "white" : "#8873C9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions1("Yes")}
                    >
                      Yes
                    </p>
                    <p
                      style={{
                        width: "97px",
                        height: "32px",
                        borderRadius: "8px",
                        border:
                          selectedOption === "No"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "No"
                            ? "rgba(136, 115, 201, 1)"
                            : "white",
                        color:
                          selectedOption === "No"
                            ? "white"
                            : "rgba(136, 115, 201, 1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions1("No")}
                    >
                      No
                    </p>
                  </div>
                  <Dialog open={showAlert} onClose={handleClose} PaperProps={{
                    style: { width: "25%" }
                  }}>
                    <DialogTitle>ALERT !!</DialogTitle>
                    <DialogContent>
                      <p style={{ paddingTop: "20px" }}>
                        Do you want to Skip HPR creation??
                      </p>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">Cancel</Button>
                      <Button onClick={handleAction} color="primary">Continue</Button>
                    </DialogActions>
                  </Dialog>
                  <p
                    className="SkipAbhatext"
                    style={{ fontSize: "16px", fontWeight: "600", marginTop: "10px" }}
                    onClick={handleSkipClick}
                  >
                    Skip HPR creation for now
                  </p>
                  <div className="skip_div" style={{ width: '100%' }}>
                    {/* <p className="skip_p">Skip ABDM integration for now</p> */}
                    <div
                      className={"editButton2"}
                      style={{ width: "28%", height: "40px", textAlign: "left" }}
                      onClick={onButtonClickhfr}
                    >
                      <p style={{ color: "white" }}>Next</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {visible.createhfr && showHfrId && <CreateHfrId />}

        {/* for creating hpr through aadhar number */}
        {visible.createabha && !visible.createabhaotp && !visible.hpraddresscreation
          && (
            <div
              className="leftContainer"
              style={{
                gap: "1rem",
                margin: "0 auto",
                backgroundColor: "white",
                border: "1px solid #d5e2f4",
                borderRadius: "8px",
                padding: "1rem",
                height: "30%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "11%"
              }}
            >
              <div className="hfr_hpr_container">
                <div
                  className="bottomContainer"
                  style={{
                    gap: "1rem",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <div style={{ gap: "3rem" }}>
                    <p style={{ fontWeight: "600", fontSize: "19px", marginLeft: "10px" }}>
                      {/* Do you want to complete HPR registration ? */}
                      ABHA HPR
                    </p>
                    <p className="Benfits_abdm">
                      Please enter Aadhaar Number or Virtual Id
                    </p>
                    <input
                      style={{
                        cursor: "pointer",
                        width: "60%",
                        background: "#eeecf5",
                        height: "6vh",
                        padding: "0 1rem",
                        border: "none",
                      }}
                      className="personalInfoValue"
                      type="text"
                      placeholder="Enter Aadhaar Number / Virtual Id"
                      value={variables.aadharnumber}
                      maxLength={12}
                      onChange={(e) => handleInputChange(e, "aadharnumber")}
                    />
                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                      *We will send an OTP on the mobile number linked to this Aadhaar
                    </p>
                    <div className="skip_div" style={{ marginRight: "30px" }} >
                      {/* <p className="skip_p">Skip ABDM integration for now</p> */}
                      <div
                        className={"editButton2"}
                        style={{
                          width: "28%", height: "40px", textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                        onClick={generateaadharotp}
                      >
                        <p style={{ color: "white" }}>
                          {showLoading && (
                            <CircularProgress size={24} color="inherit" />
                          )}
                          Continue</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {visible.createabhaotp && !visible.hprdetails && (
          <>
            <div
              className="leftContainer"
              style={{ width: "50%", gap: "1rem", justifyContent: "center" }}
            >
              <div className="hfr_hpr_container">
                <div
                  className="bottomContainer"
                  style={{
                    gap: "1rem",
                    height: "100%",
                    overflow: "scroll",
                    justifyContent: "center",
                  }}
                >
                  <h2 style={{ textAlign: "center" }}>
                    ABHA OTP REQUEST
                  </h2>
                  <h3 style={{ textAlign: "center" }}>
                    Verify OTP to access your ABHA Account
                  </h3>
                  {variables.abhaNo && (
                    <p>
                      Enter the code sent to Mobile number which is
                      linked with this ABHA Address/Number
                      <h4
                        style={{
                          color: "blue",
                          display: "inline",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      >
                        {variables.abhaNo}
                      </h4>
                    </p>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <OTPInput
                      value={variables.otp}
                      onChange={handleChange}
                      numInputs={6}
                      renderSeparator={<span>{""}</span>}
                      renderInput={(props) => (
                        <input {...props} type="tel" />
                      )}
                      skipDefaultStyles={true}
                      inputStyle="otp-input"
                      inputType="number"
                    />
                  </div>

                  <div
                    onClick={handleResend1}
                    style={{
                      marginLeft: "60%",
                      color: "orange",
                      cursor: disabled ? "not-allowed" : "pointer",
                      opacity: disabled ? 0.6 : 1,
                    }}
                  >
                    Resend OTP? {disabled && `(${timer}s)`}
                  </div>

                  <div className="Adharandmobile">
                    <button
                      className={"editButton2"}
                      style={{
                        marginRight: "10px",
                        width: "30%",
                        margin: "0 auto",
                        height: "40px",
                        color: "white",
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className={"editButton2"}
                      style={{
                        marginRight: "10px",
                        width: "30%",
                        margin: "0 auto",
                        height: "40px",
                        color: "white",
                      }}
                      onClick={() => {
                        verifygenerateaadharotp();
                      }}
                    >
                      {showLoading && (
                        <CircularProgress size={24} color="inherit" />
                      )}
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {visible.hprdetails
          && !visible.hpraddresscreation && (
            <div
              className="leftContainer"
              style={{ width: "50%", gap: "1rem", justifyContent: "center" }}
            >
              <div style={{ justifyContent: "center" }}>
                <div
                  className="bottomContainer"
                  style={{
                    gap: "1rem",
                    height: "100%",
                    overflow: "scroll",
                    justifyContent: "center",
                  }}
                >
                  <div style={{
                    marginLeft: "25%", width: "70%",
                    gap: "2rem",
                    justifyContent: "center",
                    position: "relative",
                    padding: "20px",
                    border: "2px solid #ccc",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    marginLeft: "20%"
                  }}>
                    <p style={{ fontWeight: "600", fontSize: "19px", marginTop: "10px", marginBottom: "20px" }}>
                      {/* Do you want to complete HPR registration ? */}
                      HPR DETAILS
                    </p>
                    <p className="Pargtext">
                      You already have HPR ID
                    </p>
                    <p className="Pargtext" style={{ marginTop: "20px" }}>
                      HPR ID: <span style={{ fontWeight: "bold" }}>{variables.hprId}</span>
                    </p>
                    <p className="Pargtext" style={{ marginTop: "20px" }}>
                      HPR Address: <span style={{ fontWeight: "bold" }}>{variables.hpraddress}</span>
                    </p>
                    <div className="Pargtext" style={{ marginLeft: "20px", marginTop: "30px" }} >
                      <p style={{ fontWeight: "600", fontSize: "19px", marginTop: "10px", marginBottom: "20px" }}>
                        Do you want to link with hospital?
                      </p>
                    </div>
                    <div className="Adharandmobile" style={{ marginBottom: "30px" }}>
                      <button
                        className={"editButton2"}
                        style={{
                          marginRight: "10px",
                          width: "30%",
                          margin: "0 auto",
                          height: "40px",
                          color: "white",
                        }}
                        onClick={updateHFRId}
                      >
                        {showLoading && (
                          <CircularProgress size={24} color="inherit" />
                        )}
                        Yes
                      </button>
                      <button
                        className={"editButton2"}
                        style={{
                          marginRight: "10px",
                          width: "30%",
                          margin: "0 auto",
                          height: "40px",
                          color: "white",
                        }}
                        onClick={handleCancel}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {visible.hpraddresscreation
          && !visible.createabhaotp && !visible.hprdetailscreation && !visible.successscreen && (
            <div
              className="leftContainer"
              style={{ width: "50%", gap: "1rem", justifyContent: "center" }}
            >
              <div style={{ justifyContent: "center" }}>
                <div
                  className="bottomContainer"
                  style={{
                    gap: "1rem",
                    height: "100%",
                    overflow: "scroll",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginLeft: "25%" }}>
                    <p style={{ fontWeight: "600", fontSize: "19px", marginTop: "10px", marginBottom: "20px" }}>
                      {/* Do you want to complete HPR registration ? */}
                      HPR DETAILS
                    </p>
                    <div className="Pargtext" style={{ marginLeft: "20px", marginTop: "30px" }} >
                      <p style={{ fontWeight: "600", fontSize: "19px", marginTop: "10px", marginBottom: "20px" }}>
                        Would you like to create HPR ID?
                      </p>
                    </div>
                    <div className="Adharandmobile">
                      <button
                        className={"editButton2"}
                        style={{
                          marginRight: "10px",
                          width: "30%",
                          margin: "0 auto",
                          height: "40px",
                          color: "white",
                        }}
                        onClick={() => setVisible((prevState) => ({
                          ...prevState,
                          hprmobile: true
                        }))}
                      >
                        Yes
                      </button>
                      <button
                        className={"editButton2"}
                        style={{
                          marginRight: "10px",
                          width: "40%",
                          margin: "0 auto",
                          height: "40px",
                          color: "white",
                        }}
                        onClick={handleCancel}
                      >
                        No
                      </button>
                    </div>
                    {visible.hprmobile && (
                      <>
                        <p className="Pargtext">
                          Please enter mobile number linked with aadhaar*
                        </p>
                        <input
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            background: "#eeecf5",
                            height: "6vh",
                            padding: "0 1rem",
                            border: "none",
                          }}
                          className="personalInfoValue"
                          type="text"
                          placeholder="Enter Mobile Number"
                          value={variables.mobilenumber}
                          maxLength={10}
                          onChange={(e) => handleInputChange(e, "mobilenumber")}
                        />
                        <div className="skip_div" style={{ marginRight: "30px" }} >
                          {/* <p className="skip_p">Skip ABDM integration for now</p> */}
                          <div
                            className={"editButton2"}
                            style={{
                              width: "28%", height: "40px", textAlign: "center", display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                            onClick={hprdemographic}
                          >
                            <p style={{ color: "white" }}>  {showLoading && (
                              <CircularProgress size={24} color="inherit" />
                            )}Continue</p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

        {visible.hprdetailscreation
          && !visible.createabhaotp && !visible.successscreen && (
            <div
              className="leftContainer"
              style={{ width: "50%", gap: "1rem", justifyContent: "start" }}
            >
              <div style={{ justifyContent: "start" }}>
                <div
                  className="bottomContainer"
                  style={{
                    gap: "1rem",
                    height: "100%",
                    overflow: "scroll",
                    justifyContent: "start",
                  }}
                >
                  <div style={{ marginLeft: "25%" }}>
                    <p style={{ fontWeight: "600", fontSize: "19px", marginTop: "10px", marginBottom: "20px" }}>
                      {/* Do you want to complete HPR registration ? */}
                      The details to Create HPR
                    </p>
                    <input
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        background: "#eeecf5",
                        height: "6vh",
                        padding: "0 1rem",
                        border: "none",
                        marginBottom: "10px"
                      }}
                      className="personalInfoValue"
                      type="text"
                      placeholder="Enter Email"
                      value={variables.email}
                      // maxLength={10}
                      onChange={(e) => handleInputChange(e, "email")}
                    />
                    {variables.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(variables.email) && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        Please enter a valid email address.
                      </p>
                    )}
                    <input
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        background: "#eeecf5",
                        height: "6vh",
                        padding: "0 1rem",
                        border: "none",
                        marginBottom: "10px"
                      }}
                      className="personalInfoValue"
                      type="text"
                      placeholder="HPR ID"
                      value={selectedHprId}
                    // maxLength={10}
                    // onChange={(e) => handleRadioChange (e)}
                    />
                    <input
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        background: "#eeecf5",
                        height: "6vh",
                        padding: "0 1rem",
                        border: "none",
                        marginBottom: "10px"
                      }}
                      className="personalInfoValue"
                      type="text"
                      placeholder="Enter Password"
                      value={variables.password}
                      // maxLength={10}
                      onChange={(e) => handleInputChange(e, "password")}
                    />
                    {variables.password && !/^(?=.*[A-Z]).{8,}$/.test(variables.password) && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        Password must be at least 8 characters long and contain at least one uppercase letter.
                      </p>
                    )}
                    <p className="Pargtext" style={{ marginTop: "20px", marginBottom: "10px" }}>
                      *Select HprId from Suggestions below:
                    </p>
                    {suggesstions.length > 0 && (
                      <div>
                        {suggesstions.map((suggestion, index) => (
                          <div key={index} style={{ marginBottom: "20px" }}>
                            <input
                              type="radio"
                              id={suggestion}
                              name="hprId"
                              value={suggestion}
                              onChange={(e) => handleRadioChange(e)}
                              style={{ marginRight: "10px" }}
                            />
                            <label htmlFor={suggestion} style={{ fontSize: "16px" }}>{suggestion}</label>
                          </div>
                        ))}
                      </div>
                    )}
                    <div style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                      justifyContent: "start",
                      padding: "20px",
                      border: "2px solid #ccc",
                      borderRadius: "8px",
                      backgroundColor: "#fff",
                      marginTop: "1%"
                    }}>
                      <div style={{ textAlign: "start", fontSize: "19px" }}>
                        First Name: <span style={{ fontWeight: "bold" }}>{variables.firstname}</span>
                      </div>
                      <div style={{ textAlign: "start", fontSize: "19px" }}>
                        Last Name: <span style={{ fontWeight: "bold" }}>{variables.lastname}</span>
                      </div>
                      <div style={{ textAlign: "start", fontSize: "19px" }}>
                        Address: <span style={{ fontWeight: "bold" }}>{variables.address}</span>
                      </div>
                      <div style={{ textAlign: "start", fontSize: "19px" }}>
                        District Name: <span style={{ fontWeight: "bold" }}>{variables.districtname}</span>
                      </div>
                      <div style={{ textAlign: "start", fontSize: "19px" }}>
                        State Name: <span style={{ fontWeight: "bold" }}>{variables.statename}</span>
                      </div>
                      <div style={{ textAlign: "start", fontSize: "19px" }}>
                        PinCode: <span style={{ fontWeight: "bold" }}>{variables.pincode}</span>
                      </div>
                    </div>
                    <div className="skip_div" style={{ marginRight: "30px" }} >
                      {/* <p className="skip_p">Skip ABDM integration for now</p> */}
                      <div
                        className={"editButton2"}
                        style={{
                          width: "28%", height: "40px", textAlign: "center", display: "flex", alignItems: "center",
                          justifyContent: "center"
                        }}
                        onClick={createidwithaadhar}
                      >
                        <p style={{ color: "white" }}>  {showLoading && (
                          <CircularProgress size={24} color="inherit" />
                        )}Create</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {visible.successscreen && (
          <div
            className="leftContainer"
            style={{ width: "50%", gap: "1rem", justifyContent: "center" }}
          >
            <div style={{
              width: "70%",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              justifyContent: "center",
              padding: "20px",
              border: "2px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#fff",
              marginTop: "1%"
            }}>
              <p>Success !!!</p>
              <div> We've linked HPR ID withthe hospital</div>
              <div className="Adharandmobile" style={{ marginTop: "10px", }}>
                <button
                  className={"editButton2"}
                  style={{
                    marginRight: "10px",
                    width: "30%",
                    margin: "0 auto",
                    height: "40px",
                    color: "white",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  className={"editButton2"}
                  style={{
                    marginRight: "10px",
                    width: "30%",
                    margin: "0 auto",
                    height: "40px",
                    color: "white",
                  }}
                  onClick={updateHFRId}
                >
                  {showLoading && (
                    <CircularProgress size={24} color="inherit" />
                  )}
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}

        <ToastContainer />
      </div >
    </>
  );
};

export default ABDM;
