import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./FacilityDetails.css";
import config from "../Config/config";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Configpincode = {
  pincodeApi: "https://api.postalpincode.in/pincode/",
};

const FacilityDetails = () => {
  const navigate = useNavigate();
  const [pincode, setPincode] = useState("");
  const [result, setResult] = useState({ PostOffice: [] });
  const [disNames, setDisNames] = useState([]);
  const [subDistricts, setSubDistricts] = useState([]);
  const [selectedSubDistrict, setSelectedSubDistrict] = useState([]);
  const [state, setState] = useState("");
  const [error, setError] = useState("");
  const [pincodeError, setPincodeError] = useState(false);

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));

  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const handlePincodeChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setPincode(value);
      setPincodeError(false);
    }
  };

  useEffect(() => {
    if (pincode.length === 6) {
      const fetchData = async () => {
        setResult({ PostOffice: [] });
        setDisNames([]);
        setSubDistricts([]);
        setSelectedSubDistrict("");
        setState("");
        setError("");
        try {
          const url = `${Configpincode.pincodeApi}${pincode}`;
          const response = await Axios.get(url);

          if (response.data[0]?.PostOffice?.length) {
            setResult(response.data[0]);
            const stateName = response.data[0]?.PostOffice[0]?.State;
            const countryName = response.data[0]?.PostOffice[0]?.Country;
            console.log(state, "response.data[0]?.PostOffice[0]?.State...");

            sessionStorage.setItem("pincode", pincode);
            sessionStorage.setItem("state", stateName);
            sessionStorage.setItem("country", countryName);


            await getDataDistrictCode(stateName);
          } else {
            setError("Your entered pincode is wrong.");
          }
        } catch (error) {
          setError("Error occurred while fetching data.");
          console.error("Error occurred:", error);
        }
      };

      fetchData();
    }
  }, [pincode]);

  const getDataDistrictCode = async (state) => {
    try {
      const response = await Axios.post(`${config.abhaBaseUrl}v3/getHfrStates`, {}, {
        headers: {
          "x-client-token": config.token,
          "content-Type": "application/json",
          "x-caller": await encrypted,
        },
      });

      if (response.status === 200) {
        console.log(response.data, "getDataDistrict");

        const stateCodes = response.data.map((item) => ({
          code: item.code,
          name: item.name.trim().toLowerCase(),
        }));

        console.log(stateCodes, "State Codes Array");

        const normalizedState = state.trim().toLowerCase();
        const matchedState = stateCodes.find(
          (item) => item.name === normalizedState
        );
        console.log(matchedState, "Matched State Object");

        if (matchedState) {
          console.log(`Matched State Code: ${matchedState.code}`);
          setState(matchedState.name); // Update the state field
          await getDataDistrict(matchedState.code);
        } else {
          console.log("No matching state found.");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDataDistrict = async (stateCodeno) => {
    sessionStorage.setItem("statecode", stateCodeno)
    try {
      const options = {
        method: "POST",
        url: `${config.abhaBaseUrl}v3/getHfrDistricts/${stateCodeno}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "x-caller": await encrypted,
        },
      };
      const response = await Axios(options);
      if (response.status === 200) {
        console.log(response.data, "Districtnames");
        setDisNames(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSubDistricts = async (districtCode) => {
    sessionStorage.setItem("districtcode", districtCode);
    try {
      console.log("Fetching sub-districts for district code:", districtCode);
      const options = {
        method: "POST",
        url: `${config.abhaBaseUrl}v3/getHfrSubDistricts/${districtCode}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "x-caller": await encrypted,
        },
      };
      const response = await Axios(options);
      if (response.status === 200) {
        console.log("Sub-districts response:", response.data);
        setSubDistricts(response.data);
        setSelectedSubDistrict("");
      }
    } catch (error) {
      console.error("Error occurred while fetching sub-districts:", error);
    }
  };

  const handleDistrictChange = (event) => {
    if (!pincode) {
      setPincodeError(true);
      toast.error("Please enter Pincode to get districts and sub-districts");
      return;
    }

    const selectedDistrictCode = event.target.value;
    console.log("Selected district code:", selectedDistrictCode);
    if (selectedDistrictCode) {
      getSubDistricts(selectedDistrictCode);
    }
  };

  const handleDistrictFocus = () => {
    if (!pincode) {
      setPincodeError(true);
      toast.error("Please enter Pincode to get districts and sub-districts");
    }
  };

  const handleSubDistrictChange = (event) => {
    const selectedCode = event.target.value;
    const selectedSubDistrict = subDistricts.find(
      (subDistrict) => subDistrict.code === selectedCode
    );
    setSelectedSubDistrict(selectedSubDistrict ? selectedSubDistrict.name : "");
    sessionStorage.setItem("subdistrictcode", selectedCode);
    console.log("Selected sub-district:", selectedSubDistrict);
    console.log("Selected sub-district code:", selectedCode);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formObject = {};
    formData.forEach((value, key) => {
      formObject[key] = value;
    });
    sessionStorage.setItem("facilityFormData", JSON.stringify(formObject));
    console.log("Form Data:", formObject);
  };

  const handleFacilityChange = (event) => {
    const value = event.target.value;
    sessionStorage.setItem("facilityName", value);
  };

  const handleaddressChange = (event) => {
    const value = event.target.value;
    sessionStorage.setItem("address", value);
  };

  return (
    <>
      <div className="facilityFormContainer">
        <div className="addp-patientsHeading">
          <h2>Create Facility</h2>
        </div>
        <div className="addp-formContainer1">
          <form className="facilityDetailsform" onSubmit={handleSubmit}>
            <div className="forminputs">
              <div className="formGroup">
                <label className="facilitydesc" htmlFor="facilityName">Facility Name</label>
                <input className="facilityinput" type="text" id="facilityName" name="facilityName" onChange={handleFacilityChange} />
              </div>
              <div className="formGroup">
                <label className="facilitydesc" htmlFor="location">Location of Facility</label>
                <input className="facilityinput" type="text" id="location" name="location" />
              </div>
            </div>
            <div className="forminputs">
              <div className="formGroup">
                <label className="facilitydesc" htmlFor="pincode">Pincode</label>
                <input
                  // className="facilityinput"
                  className={`facilityinput ${pincodeError ? 'error' : ''}`}
                  type="text"
                  id="pincode"
                  name="pincode"
                  value={pincode}
                  onChange={handlePincodeChange}
                  maxLength="6"
                  placeholder="Enter 6-digit pincode"
                />
                {error && <div className="error" style={{ fontSize: "10px", marginTop: "10px", color: "red" }}>{error}</div>}
              </div>
              <div className="formGroup">
                <label className="facilitydesc" htmlFor="address">Address</label>
                <input className="facilityinput" type="text" id="address" name="address" onChange={handleaddressChange} />
              </div>
            </div>
            <div className="forminputs">
              {/* <div className="formGroup">
                <label className="facilitydesc" htmlFor="gender">Gender</label>
                <select className="facilityinput" id="gender" name="gender">
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div> */}
              <div className="formGroup">
                <label className="facilitydesc" htmlFor="state">State</label>
                <input
                  className="facilityinput"
                  type="text"
                  id="state"
                  name="state"
                  value={state}
                  readOnly
                />
              </div>
              <div className="formGroup">
                <label className="facilitydesc" htmlFor="district">District</label>
                <select className="facilityinput" id="district" name="district" onChange={handleDistrictChange} onFocus={handleDistrictFocus}>
                  <option value="">Select District</option>
                  {disNames.map((district, index) => (
                    <option key={index} value={district.code}>
                      {district.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="forminputs">
              <div className="formGroup">
                <label className="facilitydesc" htmlFor="subDistrict">Sub-District</label>
                <select className="facilityinput" id="subDistrict" name="subDistrict" onChange={handleSubDistrictChange}>
                  <option value="">Select Sub-District</option>
                  {subDistricts.map((subDistrict, index) => (
                    <option key={index} value={subDistrict.code}>
                      {subDistrict.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formGroup">
                <label className="facilitydesc" htmlFor="country">Country</label>
                <input
                  className="facilityinput"
                  type="text"
                  id="country"
                  name="country"
                  value={result.PostOffice[0]?.Country}
                  readOnly
                />
              </div>
            </div>


          </form>
          {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
            <button className='createHfridbuttons' style={{ width: "20%" }} onClick={handleCancel}>Cancel</button>
            <button className='createHfridbuttons' style={{ width: "20%" }} type="submit">
              Continue
            </button> */}
          {/*<button className="facilitySubmit" type="submit">Submit</button>*/}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default FacilityDetails;

