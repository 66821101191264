import React, { useState, useEffect } from "react";
import "./Navbar.css";
import logo from "../../images/logo.png";
import patient from "../../assets/icons/patient.svg";
import calender from "../../assets/icons/calendar-mark.svg";
import bellIcon from "../../assets/icons/bell-icon.svg";
import helpCircle from "../../assets/icons/help-circle.svg";
import aboutFilled from "../../assets/icons/about-filled.svg";
import time from "../../assets/icons/time1.svg";
import calenderIcon from "../../assets/icons/calendar-icon.svg";
import calculatorMinimalistic from "../../assets/icons/calculator-minimalistic2.svg";
import articleSearchIcon from "../../assets/icons/article-search-line.svg";
import logoutIcon from "../../assets/icons/log-out-icon.svg";
import clinallyLogo from "../../assets/Clinally-another.png";
import profileIcon from "../../assets/icons/profile3.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import RightArrow from "../../images/right-arrow-image.svg";
import leftArrow from "../../images/leftArrow.svg";
import { Popover } from "antd";

const Navbar = ({ navbarMapulationvalue, selectedRow }) => {
  const [navbarMapulation, setNavbarMapulation] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [actionselectedRow, setactionselectedRow] = useState(selectedRow);
  const navigate = useNavigate();
  const location = useLocation();
  const toggleSection = (section, path) => {
    setActiveSection(section);
    navigate(path);
  };
  const toggleSectionlogout = () => {
    sessionStorage.clear();
    navigate("/");
  };
  const path = window.location.pathname;
  const firstSegment = path.split("/")[1];
  useEffect(() => {
    const action = sessionStorage.getItem("action");
    const sectionName =
      location.pathname.slice(1).charAt(0).toUpperCase() +
      location.pathname.slice(2);
    setActiveSection(sectionName || "Patients");
  }, [location.pathname]);

  const toggleSidebarMode = () => {
    navbarMapulationvalue(navbarMapulation);
    setNavbarMapulation((prevState) => !prevState);
  };

  const userPhoto = sessionStorage.getItem("UserPhoto");

  // working jsx code
  // return (
  //   <>
  //     <div
  //       className="side-navHeader"
  //       style={{ width: navbarMapulation ? "5%" : "15%" }}
  //     >
  //       <div className="side-navItems">
  //         <div className="logoContainer">
  //           <button onClick={toggleSidebarMode} className="logoToggle">
  //             <img
  //               src={navbarMapulation ? leftArrow : RightArrow}
  //               className={navbarMapulation ? "leftArrow" : "RightArrow"}
  //               alt="logo"
  //             />
  //             <img
  //               src={navbarMapulation ? clinallyLogo : logo}
  //               className={navbarMapulation ? "logo-small" : "logo-large"}
  //               alt="logo"
  //             />
  //           </button>
  //           <div className="sideNavTopMenu">
  //             {/*Raviteja changes*/}
  //             <div
  //               className={`buttonContainer ${activeSection === "Patients" || firstSegment === "patients"
  //                 ? "active"
  //                 : ""
  //                 } ${navbarMapulation ? "small-navbar-icon" : ""}`}
  //               onClick={() => toggleSection("Patients", "/patients")}
  //             >
  //               <div className="navItemIconContainer">
  //                 <img className="navItemIcon" src={patient} alt="Patients" />
  //                 {!navbarMapulation && (
  //                   <span
  //                     className={`toggle-button ${activeSection === "Patients" ||
  //                       firstSegment === "patients"
  //                       ? "active"
  //                       : ""
  //                       }`}
  //                   >
  //                     Patients
  //                   </span>
  //                 )}
  //               </div>
  //               <div
  //                 className={`${activeSection === "Patients" || firstSegment === "patients"
  //                   ? "underline"
  //                   : ""
  //                   }`}
  //               ></div>
  //               {navbarMapulation && <span className="tooltip">Patients</span>}
  //             </div>

  //             <div
  //               className={`buttonContainer ${activeSection === "Appointments" ? "active" : ""
  //                 } ${navbarMapulation ? "small-navbar-icon" : ""}`}
  //               onClick={() => toggleSection("Appointments", "/appointments")}
  //             >
  //               <div className="navItemIconContainer">
  //                 <img
  //                   className="navItemIcon"
  //                   src={calender}
  //                   alt="Appointments"
  //                 />
  //                 {!navbarMapulation && (
  //                   <span
  //                     className={`toggle-button ${activeSection === "Appointments" ? "active" : ""
  //                       }`}
  //                   >
  //                     Appointments
  //                   </span>
  //                 )}
  //               </div>
  //               <div
  //                 className={`${activeSection === "Appointments" ? "underline" : ""
  //                   }`}
  //               ></div>
  //             </div>

  //             <div
  //               className={`buttonContainer ${activeSection === "Followups" ? "active" : ""
  //                 } ${navbarMapulation ? "small-navbar-icon" : ""}`}
  //               onClick={() => toggleSection("Followups", "/followups")}
  //             >
  //               <div className="navItemIconContainer">
  //                 <img className="navItemIcon" src={time} alt="Followups" />
  //                 {!navbarMapulation && (
  //                   <span
  //                     className={`toggle-button ${activeSection === "Followups" ? "active" : ""
  //                       }`}
  //                   >
  //                     Follow Ups
  //                   </span>
  //                 )}
  //               </div>
  //               <div
  //                 className={`${activeSection === "Followups" ? "underline" : ""
  //                   }`}
  //               ></div>
  //             </div>

  //             <div
  //               className={`buttonContainer ${activeSection === "Calculator" ? "active" : ""
  //                 } ${navbarMapulation ? "small-navbar-icon" : ""}`}
  //               onClick={() => toggleSection("Calculator", "/calculator")}
  //             >
  //               <div className="navItemIconContainer">
  //                 <img
  //                   className="navItemIcon"
  //                   src={calculatorMinimalistic}
  //                   alt="Calculator"
  //                 />
  //                 {!navbarMapulation && (
  //                   <span
  //                     className={`toggle-button ${activeSection === "Calculator" ? "active" : ""
  //                       }`}
  //                   >
  //                     Calculator
  //                   </span>
  //                 )}
  //               </div>
  //               <div
  //                 className={`${activeSection === "Calculator" ? "underline" : ""
  //                   }`}
  //               ></div>
  //             </div>

  //             <div
  //               className={`buttonContainer ${activeSection === "Article" ? "active" : ""
  //                 } ${navbarMapulation ? "small-navbar-icon" : ""}`}
  //               onClick={() => toggleSection("Article", "/Article")}
  //             >
  //               <div className="navItemIconContainer">
  //                 <img
  //                   className="navItemIcon"
  //                   src={articleSearchIcon}
  //                   alt="Articles"
  //                 />
  //                 {!navbarMapulation && (
  //                   <span
  //                     className={`toggle-button ${activeSection === "Article" ? "active" : ""
  //                       }`}
  //                   >
  //                     Articles
  //                   </span>
  //                 )}
  //               </div>
  //               <div
  //                 className={`${activeSection === "Article" ? "underline" : ""
  //                   }`}
  //               ></div>
  //             </div>

  //             <div
  //               className={`buttonContainer ${activeSection === "Help" ? "active" : ""
  //                 } ${navbarMapulation ? "small-navbar-icon" : ""}`}
  //               onClick={() => toggleSection("Help", "/help")}
  //             >
  //               <div className="navItemIconContainer">
  //                 <img className="navItemIcon" src={helpCircle} alt="Help" />
  //                 {!navbarMapulation && (
  //                   <span
  //                     className={`toggle-button ${activeSection === "Help" ? "active" : ""
  //                       }`}
  //                   >
  //                     Help
  //                   </span>
  //                 )}
  //               </div>
  //               <div
  //                 className={`${activeSection === "Help" ? "underline" : ""}`}
  //               ></div>
  //             </div>

  //             <div
  //               className={`buttonContainer ${activeSection === "About Us" || firstSegment === "aboutus"
  //                 ? "active"
  //                 : ""
  //                 } ${navbarMapulation ? "small-navbar-icon" : ""}`}
  //               onClick={() => toggleSection("About Us", "/aboutus")}
  //             >
  //               <div className="navItemIconContainer">
  //                 <img
  //                   className="navItemIcon"
  //                   src={aboutFilled}
  //                   alt="About Us"
  //                 />
  //                 {!navbarMapulation && (
  //                   <span
  //                     className={`toggle-button ${activeSection === "About Us" ||
  //                       firstSegment === "aboutus"
  //                       ? "active"
  //                       : ""
  //                       }`}
  //                   >
  //                     About Us
  //                   </span>
  //                 )}
  //               </div>
  //               <div
  //                 className={`${activeSection === "About Us" || firstSegment === "aboutus"
  //                   ? "underline"
  //                   : ""
  //                   }`}
  //               ></div>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="sideNavMenu">
  //           <div className="sideNavBottomMenu">
  //             <div
  //               className={`buttonContainer ${activeSection === "Profile" ? "active" : ""
  //                 } ${navbarMapulation ? "small-navbar-icon" : ""}`}
  //               onClick={() => toggleSection("Profile", "/profile")}
  //             >
  //               <div className="navItemIconContainer">
  //                 <img
  //                   src={profileIcon}
  //                   className="navItemIcon"
  //                   alt="Profile"
  //                 />
  //                 {!navbarMapulation && (
  //                   <span
  //                     className={`toggle-button ${activeSection === "Profile" ? "active" : ""
  //                       }`}
  //                   >
  //                     Profile
  //                   </span>
  //                 )}
  //               </div>
  //               <div
  //                 className={`${activeSection === "Profile" ? "underline" : ""
  //                   }`}
  //               ></div>
  //             </div>

  //             <div
  //               className={`buttonContainer logout-menu ${activeSection === "Logout" ? "active" : ""
  //                 } ${navbarMapulation ? "small-navbar-icon" : ""}`}
  //               onClick={() => toggleSectionlogout("Logout", "/logout")}
  //             >
  //               <div className="navItemIconContainer">
  //                 <img className="navItemIcon" src={logoutIcon} alt="Logout" />
  //                 {!navbarMapulation && (
  //                   <span
  //                     style={{ color: "rgba(220, 68, 74, 1)" }}
  //                     className={`toggle-button ${activeSection === "Logout" ? "active" : ""
  //                       }`}
  //                   >
  //                     Logout
  //                   </span>
  //                 )}
  //               </div>
  //               <div
  //                 className={`${activeSection === "Logout" ? "underline" : ""}`}
  //               ></div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );

  return (
    <>
      <div
        className="side-navHeader"
        style={{ width: navbarMapulation ? "5%" : "15%" }}
      >
        <div className="side-navItems">
          <div className="logoContainer">
            <button onClick={toggleSidebarMode} className="logoToggle">
              <img
                src={navbarMapulation ? leftArrow : RightArrow}
                className={navbarMapulation ? "leftArrow" : "RightArrow"}
                alt="logo"
              />
              <img
                src={navbarMapulation ? clinallyLogo : logo}
                className={navbarMapulation ? "logo-small" : "logo-large"}
                alt="logo"
              />
            </button>
            <div className="sideNavTopMenu">
              {/* Patients Section */}
              <div
                className={`buttonContainer ${activeSection === "Patients" || firstSegment === "patients"
                  ? "active"
                  : ""
                  } ${navbarMapulation ? "small-navbar-icon" : ""}`}
                onClick={() => toggleSection("Patients", "/patients")}
              >
                <div className="navItemIconContainer">
                  {navbarMapulation ? (
                    <Popover content="Patients" placement="right" trigger="hover">
                      <img className="navItemIcon" src={patient} alt="Patients" />
                    </Popover>
                  ) : (
                    <img className="navItemIcon" src={patient} alt="Patients" />
                  )}
                  {!navbarMapulation ? (
                    <span
                      className={`toggle-button ${activeSection === "Patients" || firstSegment === "patients"
                        ? "active"
                        : ""
                        }`}
                    >
                      Patients
                    </span>
                  ) : (
                    <span className="small-text">Patients</span>
                  )}
                </div>
                <div
                  className={`${activeSection === "Patients" || firstSegment === "patients"
                    ? "underline"
                    : ""
                    }`}
                ></div>
              </div>
              {/* Appointments Section */}
              <div
                className={`buttonContainer ${activeSection === "Appointments" ? "active" : ""
                  } ${navbarMapulation ? "small-navbar-icon" : ""}`}
                onClick={() => toggleSection("Appointments", "/appointments")}
              >
                <div className="navItemIconContainer">
                  {navbarMapulation ? (
                    <Popover content="Appointments" placement="right" trigger="hover">
                      <img
                        className="navItemIcon"
                        src={calender}
                        alt="Appointments"
                      />
                    </Popover>
                  ) : (
                    <img
                      className="navItemIcon"
                      src={calender}
                      alt="Appointments"
                    />
                  )}
                  {!navbarMapulation ? (
                    <span
                      className={`toggle-button ${activeSection === "Appointments" ? "active" : ""
                        }`}
                    >
                      Appointments
                    </span>
                  ) : (
                    <span className="small-text">Appointments</span>
                  )}
                </div>
                <div
                  className={`${activeSection === "Appointments" ? "underline" : ""
                    }`}
                ></div>
              </div>

              {/* Followups Section */}
              <div
                className={`buttonContainer ${activeSection === "Followups" ? "active" : ""
                  } ${navbarMapulation ? "small-navbar-icon" : ""}`}
                onClick={() => toggleSection("Followups", "/followups")}
              >
                <div className="navItemIconContainer">
                  {navbarMapulation ? (
                    <Popover content="Followups" placement="right" trigger="hover">
                      <img className="navItemIcon" src={time} alt="Followups" />
                    </Popover>
                  ) : (<img className="navItemIcon" src={time} alt="Followups" />)}
                  {!navbarMapulation ? (
                    <span
                      className={`toggle-button ${activeSection === "Followups" ? "active" : ""
                        }`}
                    >
                      Follow Ups
                    </span>
                  ) : (
                    <span className="small-text">Follow Ups</span>
                  )}
                </div>
                <div
                  className={`${activeSection === "Followups" ? "underline" : ""
                    }`}
                ></div>
              </div>

              {/* Calculator Section */}
              <div
                className={`buttonContainer ${activeSection === "Calculator" ? "active" : ""
                  } ${navbarMapulation ? "small-navbar-icon" : ""}`}
                onClick={() => toggleSection("Calculator", "/calculator")}
              >
                <div className="navItemIconContainer">
                  {navbarMapulation ? (
                    <Popover content="Calculator" placement="right" trigger="hover">
                      <img
                        className="navItemIcon"
                        src={calculatorMinimalistic}
                        alt="Calculator"
                      />
                    </Popover>
                  ) : (<img
                    className="navItemIcon"
                    src={calculatorMinimalistic}
                    alt="Calculator"
                  />)}
                  {!navbarMapulation ? (
                    <span
                      className={`toggle-button ${activeSection === "Calculator" ? "active" : ""
                        }`}
                    >
                      Calculator
                    </span>
                  ) : (
                    <span className="small-text">Calculator</span>
                  )}
                </div>
                <div
                  className={`${activeSection === "Calculator" ? "underline" : ""
                    }`}
                ></div>
              </div>

              {/* Article Section */}
              <div
                className={`buttonContainer ${activeSection === "Article" ? "active" : ""
                  } ${navbarMapulation ? "small-navbar-icon" : ""}`}
                onClick={() => toggleSection("Article", "/Article")}
              >
                <div className="navItemIconContainer">
                  {navbarMapulation ? (
                    <Popover content="Articles" placement="right" trigger="hover">
                      <img
                        className="navItemIcon"
                        src={articleSearchIcon}
                        alt="Articles"
                      />
                    </Popover>
                  ) : (<img
                    className="navItemIcon"
                    src={articleSearchIcon}
                    alt="Articles"
                  />)}
                  {!navbarMapulation ? (
                    <span
                      className={`toggle-button ${activeSection === "Article" ? "active" : ""
                        }`}
                    >
                      Articles
                    </span>
                  ) : (
                    <span className="small-text">Articles</span>
                  )}
                </div>
                <div
                  className={`${activeSection === "Article" ? "underline" : ""
                    }`}
                ></div>
              </div>

              {/* Help Section */}
              <div
                className={`buttonContainer ${activeSection === "Help" ? "active" : ""
                  } ${navbarMapulation ? "small-navbar-icon" : ""}`}
                onClick={() => toggleSection("Help", "/help")}
              >
                <div className="navItemIconContainer">
                  {navbarMapulation ? (
                    <Popover content="Help" placement="right" trigger="hover">
                      <img className="navItemIcon" src={helpCircle} alt="Help" />
                    </Popover>
                  ) : (<img className="navItemIcon" src={helpCircle} alt="Help" />)}
                  {!navbarMapulation ? (
                    <span
                      className={`toggle-button ${activeSection === "Help" ? "active" : ""
                        }`}
                    >
                      Help
                    </span>
                  ) : (
                    <span className="small-text">Help</span>
                  )}
                </div>
                <div
                  className={`${activeSection === "Help" ? "underline" : ""}`}
                ></div>
              </div>

              {/* About Us Section */}
              <div
                className={`buttonContainer ${activeSection === "About Us" || firstSegment === "aboutus"
                  ? "active"
                  : ""
                  } ${navbarMapulation ? "small-navbar-icon" : ""}`}
                onClick={() => toggleSection("About Us", "/aboutus")}
              >
                <div className="navItemIconContainer">
                  {navbarMapulation ? (
                    <Popover content="About Us" placement="right" trigger="hover">
                      <img
                        className="navItemIcon"
                        src={aboutFilled}
                        alt="About Us"
                      />
                    </Popover>
                  ) : (<img
                    className="navItemIcon"
                    src={aboutFilled}
                    alt="About Us"
                  />)}
                  {!navbarMapulation ? (
                    <span
                      className={`toggle-button ${activeSection === "About Us" || firstSegment === "aboutus"
                        ? "active"
                        : ""
                        }`}
                    >
                      About Us
                    </span>
                  ) : (
                    <span className="small-text">About Us</span>
                  )}
                </div>
                <div
                  className={`${activeSection === "About Us" || firstSegment === "aboutus"
                    ? "underline"
                    : ""
                    }`}
                ></div>
              </div>
            </div>
          </div>

          <div className="sideNavMenu">
            <div className="sideNavBottomMenu">
              {/* Profile Section */}
              <div
                className={`buttonContainer ${activeSection === "Profile" ? "active" : ""
                  } ${navbarMapulation ? "small-navbar-icon" : ""}`}
                onClick={() => toggleSection("Profile", "/profile")}
              >
                <div className="navItemIconContainer">
                  {navbarMapulation ? (
                    <Popover content="Profile" placement="right" trigger="hover">
                      <img className="navItemIcon" src={profileIcon} alt="Profile" />
                    </Popover>
                  ) : (<img className="navItemIcon" src={profileIcon} alt="Profile" />)}
                  {!navbarMapulation ? (
                    <span
                      className={`toggle-button ${activeSection === "Profile" ? "active" : ""
                        }`}
                    >
                      Profile
                    </span>
                  ) : (
                    <span className="small-text">Profile</span>
                  )}
                </div>
                <div
                  className={`${activeSection === "Profile" ? "underline" : ""
                    }`}
                ></div>
              </div>



              {/* Logout Section */}
              <div
                className={`buttonContainer logout-menu ${activeSection === "Logout" ? "active" : ""
                  } ${navbarMapulation ? "small-navbar-icon" : ""}`}
                onClick={() => toggleSectionlogout("Logout", "/logout")}
              >
                <div className="navItemIconContainer">
                  {navbarMapulation ? (
                    <Popover content="Logout" placement="right" trigger="hover">
                      <img className="navItemIcon" src={logoutIcon} alt="Logout" />
                    </Popover>
                  ) : (<img className="navItemIcon" src={logoutIcon} alt="Logout" />)}
                  {!navbarMapulation ? (
                    <span
                      style={{ color: "rgba(220, 68, 74, 1)" }}
                      className={`toggle-button ${activeSection === "Logout" ? "active" : ""
                        }`}
                    >
                      Logout
                    </span>
                  ) : (
                    <span className="small-text">Logout</span>
                  )}
                </div>
                <div
                  className={`${activeSection === "Logout" ? "underline" : ""
                    }`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
