import React, { useState } from "react";
import { publicEncrypt, constants } from "crypto-browserify";
import axios from "axios";
import Config from "../../Config/config";

const UpdateHospitalsAssociation = ({ selectUser, updateParentState, userListUPdate }) => {
  // console.log("selectUser in child component:", selectUser);
  // const [approveUser, setApproveUser] = useState([]);

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));
  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const ApproveUsersList = async () => {
    try {
      //console.log("userid", selectUser.HOSPITAL_ID);
      const body = JSON.stringify({
        mobile: "mobile",
        hospitalID: selectUser.HOSPITAL_ID,
        userID: UserID,
        mode: "add",
        status: "ACCEPTED",
      });

      const response = await axios.post(
        `${Config.apiURL}${Config.routes.addHospitalToUserAPI}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );
      // console.log(response);
      if (response.status === 200) {
        //console.log("response from api", response);
        ApproveclosePopup();
        userListUPdate()
      }
    } catch (error) {
      console.error(error);
      return "Error";
    }
  };

  const disassociateCancel = async () => {
    try {
      // console.log("entred into try block");

      const body = JSON.stringify({
        hospitalID: selectUser.HOSPITAL_ID,
        mobile: "mobile",
        userID: UserID,
        mode: "delete",
        status: "DELETED",
      });

      const response = await axios.post(
        `${Config.apiURL}${Config.routes.addHospitalToUserAPI}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );
      // console.log(response);
      if (response.status === 200) {
        //console.log("response from api", response);
        ApproveclosePopup(response);
        userListUPdate()
      }
    } catch (error) {
      console.error(error);
      return "Error";
    }
  };

  const ApproveclosePopup = () => {
    // window.location.reload();
    updateParentState(false);
  };

  return (
    <div>
      {selectUser.USER_STATUS === null || selectUser.USER_STATUS === "" ? (
        <div className="hospitalpopup">
          <div className="hospitalpopup-inner">
            <h2>Associate with Hospital</h2>
            <p>
              You are tyring to associate with an existing hospital. Approval is
              required for this activity.Notification will be sent to admin for
              approval.Are you sure you want to continue?.
            </p>

            <div className="hospitalpopup-buttons">
              <button onClick={ApproveclosePopup}>Cancel</button>
              <button onClick={ApproveUsersList}>Continue</button>
            </div>
          </div>
        </div>
      ) : selectUser.USER_STATUS === "ACCEPTED" ? (
        <div className="hospitalpopup">
          <div className="hospitalpopup-inner">
            <h2> ALERT - Hospital Removal</h2>

            <p>
              Your are the admin. You are removing your hospital and entire data
              associated with your hospital. This can not be undone. Are you
              sure?
            </p>

            <div className="hospitalpopup-buttons">
            <button onClick={ApproveclosePopup}>Cancel</button>
              <button onClick={disassociateCancel}>Continue</button>
            </div>
          </div>
        </div>
      ) : (
        <div className="hospitalpopup">
          <div className="hospitalpopup-inner">
            <h2>Cancel Request</h2>
            <p>
              Your request is waiting for approval by Hospital Admin.Are you
              sure you want to cancel the request?
            </p>

            <div className="hospitalpopup-buttons">
              <button onClick={disassociateCancel}>Cancel Request</button>
              <button onClick={ApproveclosePopup}>Don't Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateHospitalsAssociation;
