import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../../images/Vector 34.svg";
// import "echarts/lib/component/tooltip";
import { publicEncrypt, constants } from "crypto-browserify";
import { ToastContainer, toast } from "react-toastify";
import { Buffer } from "buffer";
import Config from "../../../Config/config";
import Axios from "axios";
import "./ConsolidatedReport.css";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";

import OtpInput from "react-otp-input";
import { Snackbar, Button, CircularProgress, Alert } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { use } from "echarts";

const PriviewPrescription = () => {
  const [pages, setPages] = useState(null);
  const [precsriptionClose, setPrecsriptionClose] = useState(false);
  const [otpBox, setotpBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [HospitalDetails, setHospitalDetails] = useState("");
  const [getTokenData, setgetTokenData] = useState("");
  const [apiData, setApiData] = useState({
    accessToken: "",
    v4: "",
    initReq: "",
  });
  const [variables, setVariables] = useState({
    otp: "",
    showAlert: false,
    alertTitle: "",
    alertMessage: "",
  });
  const navigate = useNavigate();

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const userDetails = JSON.parse(sessionStorage.getItem("UserData"))[0];
  //console.log("User------", userDetails);

  const patient_id = sessionStorage.getItem("PATIENT_ID");
  //console.log("patient_id", patient_id);

  const patientDetails = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );
  //console.log("patientDetails", patientDetails.HOSPITAL_ID);
  //console.log("Patients------", patientDetails);

  const visitdata = JSON.parse(sessionStorage.getItem("visitData"));
  //console.log("VISIT------", visitdata);
  const hospitalId = patientDetails.HOSPITAL_ID;
  //console.log("hospitalId------", hospitalId);
  const examinationhistory = JSON.parse(visitdata.examinationHistory);
  //console.log("History------", examinationhistory);

  const htdiagnosis = JSON.parse(visitdata.htDiagnosis);
  //console.log("htdiagnosis---", htdiagnosis);

  const dbdiagnosis = JSON.parse(visitdata.dbDiagnosis);
  //console.log("dbdiagnosis----", dbdiagnosis);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");
  const patientData = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );
  useEffect(() => {
    const fetchData = async () => {
      const UserID = JSON.parse(sessionStorage.getItem("UserID"));
      const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
      let data = JSON.stringify({
        userId: UserID,
        id: PATIENT_ID,
        hospId: hospitalId,
      });
      let config = {
        method: "get",
        // maxBodyLength: Infinity,
        url: `${Config.base_url}${Config.routes.getHfrId}/${hospitalId}`,
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "application/json",
          "x-caller": encrypted,
        },
        // data: data,
      };

      //console.log(hospitalId, "hospitalId............");
      Axios.request(config)
        .then((response) => {
          // console.log(
          //   "ABHA---setHospitalDetails------",
          //   response.data.recordset[0]["HOSPITAL_HFR_ADDRESS"]
          // );
          // console.log(
          //   "ABHA---setHospitalDetails------",
          //   response.data.recordset
          // );
          setHospitalDetails(response.data.recordset[0]); // Assuming you want to set visit data from the first object
          if (response.data.recordset[0].length > 0) {
            patientDetails.ABHA_HPR_ADDRESS =
              response.data.recordset[0]["HOSPITAL_HFR_ADDRESS"];

            sessionStorage.setItem(
              "selectedPatientDetails",
              JSON.stringify(patientDetails)
            );
          }
          //console.log("response.data.recordset====", response.data.recordset);
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
        });
    };
    fetchData();
  }, []);
  const handleChange = (otp) => {
    setVariables((prevState) => ({
      ...prevState,
      otp: otp,
    }));
  };

  //init call for OTP
  const initModeResponse = async (accessToken, V4) => {
    if (patientData && patientData.ABHA_ADDRESS && patientData.MOBILE) {
      const timestamp = new Date();

      const dataBody = {
        USER_ID: UserID,
        requestId: V4,
        timestamp: format(timestamp, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS"),
        token: accessToken,
        ABHA_ADDRESS: patientData.ABHA_ADDRESS,
        ABHA_HPR_ADDRESS: "clinallyhip",
        // patientData.ABHA_HPR_ADDRESS
        // ? patientData.ABHA_HPR_ADDRESS
        // : "Clinallyhip",
      };
      //console.log(dataBody, "....dataBody....");
      await Axios.post(`${Config.abhaBaseUrl}initModeResponse`, dataBody, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
        },
      })

        .then((response) => {
          if (response.status === 422) {
            // console.log("response:", response);
          } else if (response.status === 200) {
            //console.log("initModeResponse");

            setApiData((prevState) => ({
              ...prevState,
              initReq: V4,
            }));
            getToken(V4);
          }
        })
        .catch(async (error) => {
          console.log(error);
          setIsLoading(false);
          toast.error("Server Error!");
        });
    } else alert("Please Check Your Details, Some Details is Missing");
  };
  //fetchModes
  const fetchModes = async (accessToken) => {
    //console.log(UserID, patientData.USER_ID);
    if (patientData && patientData.ABHA_ADDRESS && patientData.MOBILE) {
      const V4 = uuidv4();
      const timestamp = new Date();
      const dataBody = {
        USER_ID: UserID,
        requestId: V4,
        timestamp: format(timestamp, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS"),
        token: accessToken,
        healthId: patientData.ABHA_ADDRESS,

        ABHA_HPR_ADDRESS: patientData.ABHA_HPR_ADDRESS,
      };
      await Axios.post(`${Config.abhaBaseUrl}fetchMode`, dataBody, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
        },
      })

        .then((response) => {
          if (response.status === 422) {
            // console.log("response:", response);
          } else if (response.status === 200) {
            // sessionStorage.setItem("accessToken", response.data.accessToken);
            //console.log("fetchMode");
            uploadAbhaData(accessToken);
          }
        })
        .catch(async (error) => {
          console.log(error);
          setIsLoading(false);
          toast.error("Server Error!");
        });
    } else alert("Please Check Your Details, Some Details is Missing");
  };

  //sessionToken
  const getSessionTokenAPI = async (notify) => {
    if (patientData.ABHA_ADDRESS.trim() !== "") {
      const dataBody = {
        healthId: patientData.ABHA_ADDRESS,
        USER_ID: UserID,
      };

      await Axios.post(`${Config.abhaBaseUrl}getSessionToken`, dataBody, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
        },
      })

        .then((response) => {
          sessionStorage.setItem("abhaAddress", dataBody.healthId);
          if (response.status === 422) {
            // console.log("response:", response);
          } else if (response.status === 200) {
            sessionStorage.setItem("accessToken", response.data.accessToken);
            setApiData((prevState) => ({
              ...prevState,
              accessToken: response.data.accessToken,
            }));
            if (notify) {
              abhaLinkContextNotify(response.data.accessToken);
            } else {
              fetchModes(response.data.accessToken);
            }

            //console.log(response.data.accessToken);
          }
        })
        .catch(async (error) => {
          console.log(error);
          setIsLoading(false);
          toast.error("Server Error!");
        });
    } else {
      // alert("Please enter a valid value");
      alert("Uesr not have ABHA ADDRESS");
    }
  };

  //publickey
  const getPublickey = async (notify) => {
    Axios.get(`${Config.abhaBaseUrl}publicKey/${UserID}`)
      .then((response) => {
        //console.log("getPublickey response :", response);
        if (response.status === 200) {
          //console.log("PUBLICKEY :", response.data);
          sessionStorage.setItem("ABDMPublicKey", response.data);
          getSessionTokenAPI(notify);
          return response;
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error("Server Error!");
      });
  };

  //confirm
  const confirmotp = async () => {
    if (patientData && patientData.ABHA_ADDRESS && patientData.MOBILE) {
      const timestamp = new Date();
      const dataBody = {
        USER_ID: UserID,
        requestId: apiData.v4,
        timestamp: format(timestamp, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS"),
        txnId: getTokenData.INIT_TXN_ID,
        token: apiData.accessToken,
        otp: variables.otp,
      };
      await Axios.post(`${Config.abhaBaseUrl}confirmotp`, dataBody, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
        },
      })

        .then((response) => {
          if (response.status === 422) {
            // console.log("response:", response);
          } else if (response.status === 200) {
            // sessionStorage.setItem("accessToken", response.data.accessToken);
            getToken(apiData.initReq);
            //console.log("response", response);
          }
        })
        .catch(async (error) => {
          console.log(error);
          setIsLoading(false);
          toast.error("Server Error!");
        });
    } else alert("Please Check Your Details, Some Details is Missing");
  };
  // recall get token
  const regetToken = async (V4, Skip) => {
    await getToken(V4, Skip);
  };
  //trdid get token
  const getToken = async (V4, Skip) => {
    try {
      const response = await Axios.get(
        `${Config.base_url}GET_TOKEN/${UserID}/${patientData.ABHA_ADDRESS}/${V4}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status === 200) {
        if (!Skip) {
          //console.log(response, "..");
          uploadConfirmData(V4);
        } else {
          //console.log(response, "responseresponseresponse");

          if (response.data.recordset[0].INIT_TXN_ID) {
            // console.log(
            //   response,
            //   response.data.recordset[0].INIT_TXN_ID,
            //   "txnid"
            // );

            if (response.data.recordset[0].ACCESS_TOKEN) {
              setIsLoading(true);
              // console.log(
              //   "ACCESS_TOKEN",
              //   response.data.recordset[0].ACCESS_TOKEN
              // );
              await abhaLinkCareContext(
                response.data.recordset[0].ACCESS_TOKEN
              );
            } else {
              setgetTokenData(response.data.recordset[0]);
              setIsLoading(false);
              setotpBox(true);
            }
          } else {
            //console.log(response, "txnid");
            await new Promise((resolve) => setTimeout(resolve, 5000));
            await regetToken(V4, Skip);
          }
        }
      } else {
        console.error("Failed to fetch :", response.statusText);
      }
    } catch (error) {
      console.error("Error :", error);
      setIsLoading(false);
      toast.error("Server Error!");
    }
  };

  //LINK_ABHA_DATA_QR
  const uploadAbhaData = async (accessToken) => {
    const V4 = uuidv4();
    try {
      const body = {
        USER_ID: UserID,
        ABHA_ID: patientData.ABHA_ADDRESS,
        ABHA_DATA: "",
        INIT_REQUEST_ID: V4,
      };
      const response = await Axios.post(
        `${Config.base_url}LINK_ABHA_DATA_QR`,
        body,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      //console.log(response, "uploadAbha");
      if (response.status === 201) {
        //console.log(response, "uploadAbhaData");
        await initModeResponse(accessToken, V4);
      } else {
        console.error("Failed to fetch:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      toast.error("Server Error!");
    }
  };
  //LINK_ABHA_CONFIRM_ID
  const uploadConfirmData = async (INIT_REQUEST_ID) => {
    const V4 = uuidv4();
    try {
      const body = {
        USER_ID: UserID,
        ABHA_ID: patientData.ABHA_ADDRESS,
        CONFIRM_REQUEST_ID: V4,
        INIT_REQUEST_ID: INIT_REQUEST_ID,
      };
      const response = await Axios.post(
        `${Config.base_url}LINK_ABHA_CONFIRM_ID`,
        body,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status === 201) {
        //console.log(response, "uploadConfirmData");
        setApiData((prevState) => ({
          ...prevState,
          v4: V4,
        }));
        await getToken(INIT_REQUEST_ID, "Skip");
      } else {
        console.error("Failed to fetch:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      toast.error("Server Error!");
    }
  };
  //abhaLinkCareContext
  const abhaLinkCareContext = async (ACCESS_TOKEN) => {
    const timestamp = new Date();
    const V4 = uuidv4();
    //console.log("ACCESS_TOKEN");
    try {
      const body = {
        USER_ID: UserID,
        V4: V4,
        timestamp: format(timestamp, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS"),
        token: apiData.accessToken,
        accessToken: ACCESS_TOKEN,
        patientId: patient_id.toString(),
        patientName: patientData.FIRST_NAME,
        visitId: visitdata.visitId,
      };
      //console.log(body, "abhaLinkCareContext body");
      const response = await Axios.post(
        `${Config.abhaBaseUrl}abhaLinkCareContext`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status === 200) {
        //console.log(response, "abhaLinkCareContext");
        getabhaLinkContextNotify();
        // setIsLoading(false);
      } else {
        console.error("Failed to fetch:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      toast.error("Server Error!");
    }
  };

  //abhaLinkContextNotify get public key session token
  const getabhaLinkContextNotify = async () => {
    //console.log("getabhaLinkContextNotify.........");
    await getPublickey("notify");
  };

  // /abhaLinkContextNotify
  const abhaLinkContextNotify = async (ACCESS_TOKEN) => {
    const timestamp = new Date();
    const V4 = uuidv4();

    try {
      const body = {
        USER_ID: UserID,
        requestId: V4,
        timestamp: format(timestamp, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        date: format(timestamp, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS"),
        token: apiData.accessToken,
        accessToken: ACCESS_TOKEN,
        patientId: patient_id.toString(),
        healthId: patientData.ABHA_ADDRESS,
        // hfrAddress: HospitalDetails?.HOSPITAL_HFR_ADDRESS
        //   ? HospitalDetails?.HOSPITAL_HFR_ADDRESS
        //   : patientData.ABHA_HPR_ADDRESS,
        hfrAddress: "clinallyhip",
        visitId: visitdata.visitId.toString(),
      };
      //console.log(body, "abhaLinkCareContext body");
      const response = await Axios.post(
        `${Config.abhaBaseUrl}abhaLinkContextNotify`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status === 201) {
        //console.log(response, "abhaLinkCareContextNotify");
        setPrecsriptionClose(false);
        setIsLoading(!isLoading);
        navigate("/patients");
      } else {
        console.error("Failed to fetch:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      toast.error("Server Error!");
    }
  };

  //   console.log("HospitalDetails*********", HospitalDetails);
  if (HospitalDetails && HospitalDetails.length > 0) {
    // HospitalDetails.forEach((address) => {
    if (
      HospitalDetails.HOSPITAL_HFR_ADDRESS &&
      HospitalDetails.HOSPITAL_HFR_ADDRESS !== null
    ) {
      // console.log(
      //   "HOSPITAL_HFR_ADDRESS:",
      //   HospitalDetails.HOSPITAL_HFR_ADDRESS
      // );
    } else {
      //console.log("HOSPITAL_HFR_ADDRESS is null or undefined in this object.");
    }
    // });
  } else {
    //console.log("HospitalDetails is either null or an empty array.");
  }

  // hemanth = JSON.parse(HospitalDetails);
  // console.log('&&&&&&&',hemanth)

  const handleClickABHAOpen = async () => {
    // setIsLoading(!isLoading);
    // await getPublickey();

    setPrecsriptionClose(true);
  };
  const linkPrescription = async () => {
    setPrecsriptionClose(false);
    setIsLoading(!isLoading);
    await getPublickey();
    // navigate("/patients");
  };

  const handleClickPrecsriptionClose = () => {
    setPrecsriptionClose("");
  };

  // const handleClick = () => {
  //     if (!pages) return;
  //     html2PDF(pages, {
  //         jsPDF: {
  //             format: 'a4',
  //         },
  //         imageType: 'image/jpeg',
  //         output: './pdf/ConsolidatedReport.pdf',
  //         html2canvas: {
  //             scrollX: 0,
  //             scrollY: -window.scrollY,
  //         },
  //         success: function (pdf) {
  //             pdf.save('ConsolidatedReport.pdf');
  //         },
  //         options: {
  //             jsPDF: {
  //                 unit: 'px',
  //                 format: 'a4',
  //             },
  //             html2canvas: {
  //                 imageTimeout: 15000,
  //                 logging: true,
  //                 useCORS: false,
  //             },
  //             imageType: 'image/jpeg',
  //         },
  //     });
  // };

  // console.log("parsedData=====", parsedData);
  const handleVarifyOTP = () => {
    setotpBox(false);
    //console.log(variables.otp);
    setIsLoading(!isLoading);
    confirmotp();
  };

  return (
    <>
      {/* <Navbar /> */}
      <div>
        <div className="entireA4sheet">
          <div className="A4sheet">
            <div>
              <div className="patientNameInReport">
                <div className="backButton" onClick={handleClickABHAOpen}>
                  <img id="image" src={BackArrow} />
                </div>
                <button className="review">
                  <h3>
                    {patientDetails && patientDetails.FIRST_NAME
                      ? patientDetails.FIRST_NAME.toUpperCase()
                      : ""}
                    'S PREVIEW
                  </h3>
                </button>
              </div>
              <div
                // ref={setPages}
                className="ConsolidatedReport"
              >
                <div className="patientReport">
                  <p id="DoctorName">
                    {" "}
                    Dr {userDetails.FIRST_NAME}
                    {userDetails.LAST_NAME}
                  </p>
                  <p id="DoctorPh">
                    Emial: {userDetails.EMAIL} Phone: {userDetails.MOBILE}
                  </p>
                  <hr></hr>
                  <p id="patientVisitHistory">Prescription</p>
                  <div className="patientData1">
                    <div className="patientData2">
                      <p id="patient">Name: {patientDetails.FIRST_NAME}</p>
                      <p id="patient">MobileNumber: {patientDetails.MOBILE}</p>
                      <p id="patient">
                        Date of Birth:{patientDetails.DATE_OF_BIRTH}
                      </p>
                      <p id="patient">Gender:{patientDetails.GENDER}</p>
                    </div>
                    <div className="patientData3">
                      <p id="patient">
                        Visit Date: {formatDate(patientDetails.LAST_VISIT_DATE)}
                      </p>
                      <p id="patient">Patient ID:{patientDetails.PATIENT_ID}</p>
                      <p id="patient">Age:{patientDetails.age}</p>
                    </div>
                  </div>
                  <div>
                    <p id="patientVisitHistory">Vitals</p>
                    <div>
                      <table>
                        <thead>
                          <tr>
                            <th>BloodPressure(mmHg)</th>
                            <th>FBS(mg/dl)</th>
                            <th>RBS(mg/dl)</th>
                            <th>PPBS(mg/dl)</th>
                            <th>HbA1c%</th>
                            <th>weight</th>
                            <th>BMI(Kg/m²)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {/* <td>{visit.VISIT_DATA && JSON.parse(visit.VISIT_DATA)?.visitDate ? formatDate(JSON.parse(visit.VISIT_DATA).visitDate) : ""}</td> */}
                            <td>
                              {examinationhistory.sbp || "0"}/
                              {examinationhistory.dbp || "0"}
                            </td>
                            <td>{examinationhistory.fbs || "0"}</td>
                            <td>{examinationhistory.rbs || "0"}</td>
                            <td>{examinationhistory.ppbs || "0"}</td>
                            <td>{examinationhistory.hba1c || "0"}</td>
                            <td>{examinationhistory.weight || "0"}</td>
                            <td>
                              {!isNaN(parseFloat(examinationhistory.bmi))
                                ? parseFloat(examinationhistory.bmi).toFixed(0)
                                : "0"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div style={{ textAlign: "start", marginTop: "3%" }}>
                      <p>
                        Hypertension Diagnosis -{" "}
                        {htdiagnosis.diagnosis ? "yes" : "no"}
                      </p>
                      <p>Medication Advice</p>
                      <hr id="line"></hr>
                    </div>
                    <div style={{ textAlign: "start", marginTop: "3%" }}>
                      <p>
                        Diabetes Diagnosis -{" "}
                        {dbdiagnosis.diagnosis ? "yes" : "no"}
                      </p>
                      <p>Medication Advice</p>
                      <hr id="line"></hr>
                    </div>
                    <div style={{ textAlign: "start", marginTop: "3%" }}>
                      <p>Other Complaints </p>
                      <p>Remarks</p>
                      <p>Medication Advice</p>
                      <hr id="line"></hr>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div><button className="downloadButton"
                            //  onClick={handleClick}
                            >
                                Download
                            </button>
                            </div> */}
            </div>
          </div>
        </div>
        <ToastContainer />
        <Dialog open={precsriptionClose} onClose={handleClickPrecsriptionClose}>
          <DialogTitle>Attention !!</DialogTitle>
          {/* {console.log(
            patientDetails,
            "patientDetails...........patientDetails......."
          )} */}
          {patientDetails.ABHA_HPR_ADDRESS === null ||
            patientDetails.ABHA_HPR_ADDRESS === "" ||
            HospitalDetails.HOSPITAL_HFR_ADDRESS === null ||
            HospitalDetails.HOSPITAL_HFR_ADDRESS === "" ? (
            <DialogContent>
              <p>
                Please link/create Health Facility Registry ID to access the
                ABHA services for patients.
              </p>
            </DialogContent>
          ) : (
            <DialogContent>
              {patientDetails.ABHA_ADDRESS !== null &&
                patientDetails.ABHA_ADDRESS !== "" ? (
                <p>
                  Would you like to link this prescription to the patient's ABHA
                  ID.
                </p>
              ) : (
                <p>
                  There is no ABHA ID linked to this patient. An SMS alert will
                  be sent to the patient's registered mobile number. In the
                  future, please link/create the ABHA ID to our system.
                </p>
              )}
            </DialogContent>
          )}

          {/* <DialogActions>
                        {patientDetails.ABHA_ADDRESS ? (
                            <React.Fragment>
                                <Button onClick={() => navigate("/patients/treatmentplan/Prescription")} style={{ background: '#9b00d3', color: 'white' }}>Cancel</Button>
                                <Button onClick={() => navigate("/patients")} style={{ background: '#9b00d3', color: 'white' }}>OK</Button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Button onClick={() => navigate("/patients/treatmentplan/Prescription")} style={{ background: '#9b00d3', color: 'white' }}>Cancel</Button>
                                <Button onClick={() => navigate("/ABHA")} style={{ background: '#9b00d3', color: 'white' }}>Link</Button>
                            </React.Fragment>
                        )}
                    </DialogActions> */}

          <DialogActions>
            <Button
              onClick={() => navigate("/patients/treatmentplan/Prescription")}
              // style={{ background: "#9b00d3", color: "white" }}
            >
              Cancel
            </Button>
            {patientDetails.ABHA_HPR_ADDRESS === null ||
              patientDetails.ABHA_HPR_ADDRESS === "" ? (
              <Button
                onClick={() => {
                  sessionStorage.setItem(
                    "hospitalId",
                    patientDetails.HOSPITAL_ID
                  );
                  navigate(`/profile?action="ABDM"`);
                }}
                // style={{ background: "#9b00d3", color: "white" }}
              >
                Link
              </Button>
            ) : (
              <Button
                onClick={() => linkPrescription()}
                // style={{ background: "#9b00d3", color: "white" }}
              >
                OK
              </Button>
            )}
          </DialogActions>
        </Dialog>
        {isLoading ? (
          <div
            style={{
              position: "absolute",
              top: "0",
              width: "100%",
              height: "100%",
              background: "rgba(51, 46, 46, 0.21)", // Equivalent to #332e2e36
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={30} color="inherit" />
          </div>
        ) : (
          <>
            <Dialog open={otpBox}>
              <div style={{ padding: "8%" }}>
                <DialogTitle> Enter Password </DialogTitle>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <OtpInput
                    value={variables.otp}
                    onChange={handleChange}
                    numInputs={6}
                    renderSeparator={<span>{""}</span>}
                    renderInput={(props) => <input {...props} type="tel" />}
                    skipDefaultStyles={true}
                    inputStyle="otp-input"
                    inputType="number"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "2rem",
                    padding: "4%",
                    marginTop: "5%",
                  }}
                >
                  <button
                    className="ContinueBtn"
                    style={{
                      Background: "#52447d !important",
                      height: "40px",
                    }}
                    onClick={handleVarifyOTP}
                  >
                    Verify OTP
                  </button>

                  <button
                    className="ContinueBtn"
                    onClick={() => {
                      setotpBox(!otpBox);
                    }}
                    style={{
                      Background: "#52447d !important",
                      height: "40px",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};

export default PriviewPrescription;
