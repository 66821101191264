import React, { useState, useEffect } from "react";
import Logo from "../images/logo.png";
import "./Login.css";
import Computer from "../images/computer.gif";
import LoginForm from "../components/modules/LoginForm";
import RegisterForm from "../components/modules/RegisterForm";
import aiimsLogo from "../images/aiims_logo.png";
// import ccdcLogo from "../images/CCDC_WHO Collaborating centre logo.jpg";
import ccdcLogo from "../images/CCDC-new-logo-1-1.png";
import { act } from "react-dom/test-utils";
import clinallyLogo from "../images/clinallylogo.png";

const Landing = () => {
  const [activeSection, setActiveSection] = useState("Login");
  const [isLogin, setIsLogin] = useState(true);

  const toggleForm = (sectionName) => {
    setIsLogin(!isLogin);
    setActiveSection(sectionName);
  };

  return (
    <div className="login">
      {/* <img src={Doctor} className="doctor-image" alt="" /> */}
      <div className="loginHeader">
        <div className="logo-Resetpin" >
          <img
            src={Logo}
            className="clinally-logo"
            alt=""

          />
          <div style={{ display: "flex", flexDirection: "column", marginTop: "2%" }}>
            <p className="clinallytext">CLINALLY mPOWER HEALTH</p>
            <p style={{ width: "50%", textAlign: "justify", marginTop: "1%", fontSize: "14px" }}>
              Clinally mPower Health is designed to assist and support doctors to provide
              the best possible care to their patients along with managing the patient's
              visit records. It has a proprietary AI-based clinical decision support
              system to provide recommendations based on clinical parameters of the patients.
            </p>
          </div>
        </div>
        {/**/}
      </div>
      <div className="body">
        <div className="image-container">
          {/* <div
            style={{
              margin: "5% 15% 0 0%",
              // border: "2px solid black",
              border: "none",
              // backgroundColor: "#F2F2F2 ",
              borderRadius: "10px",
              padding: "2%",
            }}
          >
            Clinally mPower Health is designed to assist and support doctors to
            provide best possible care to their patients along with managing the
            patient's visit records. It has a proprietary AI based clinical
            decision support system to provide recommendations based clinical
            parameters of the patients.
          </div> */}
          <img src={Computer} className="computer-image" alt="" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >

          <div className="button-box">
            {/*Button Border*/}
            <div id="btn" style={{ right: !isLogin ? "-6px" : "122px" }}></div>

            {/*Under Line */}
            <div
              id="under_line"
              style={{ left: !isLogin ? "43.5px" : "172px" }}
            ></div>

            {/*Login Button */}
            {isLogin ? (
              <button type="button" className="toggle-btn1" name="login">
                Log In
              </button>
            ) : (
              <button
                type="button"
                className="toggle-btn1"
                name="login"
                onClick={() => toggleForm("Login")}
              >
                Log In
              </button>
            )}

            {/* Register Button*/}
            {isLogin ? (
              <button
                type="button"
                className="toggle-btn2"
                name="register"
                onClick={() => toggleForm("Register")}
              >
                Register
              </button>
            ) : (
              <button type="button" className="toggle-btn2" name="register">
                Register
              </button>
            )}
          </div>

          {activeSection === "Login" && <LoginForm />}
          {activeSection === "Register" && <RegisterForm />}
          <div className="LandingImage"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "3%",
                justifyContent: "space-around",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#58748b",
                  paddingRight: "10px",
                }}
              >
                {" "}
                Powered by
              </p>
              <img src={clinallyLogo} className="Clogo" alt="" width="50%" />
              {/* <p style={{ fontSize: '25px', fontWeight: 'bold', color: "#313b57" }}>Clin</p>
              <p style={{ fontSize: '25px', fontWeight: 'bold', color: "#efb968" }}>Ally</p> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <h2 style={{ textAlign: "center", marginTop: "20px" }}>
                CDSS Developed In Collaboration With
              </h2>
              <div
                style={{
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: "center",
                  gap: "3rem",
                  alignItems: "center",
                  marginLeft: "15%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <img style={{ height: "8vh" }} src={aiimsLogo} />
                  {/* <label className="About" style={{ fontSize: "18px" }}>
                    AIIMS
                  </label> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ height: "8vh" }}
                    src={ccdcLogo}
                  />
                  {/* <label className="About" style={{ fontSize: "18px" }}>
                    CCDC
                  </label> */}
                </div>
              </div>

              {/* <p style={{ fontSize: "16px", textAlign: "center" }}>
                By continuing you agree to our <a href=""> Terms of service </a>{" "}
                and <a href="">Privacy policy</a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
