import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import "./AppointmentsPatientList.css";
import "./AppointmentsCalender.css";
// import "./Appointments.css";
import Calendar from "../components/Calendar.jsx";
import searchAlt from "../images/searchAlt.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { format, addHours, startOfHour } from "date-fns";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config from "../Config/config";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const Appointments = () => {
  const [patients, setPatients] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const statusString = [
    "Add Details",
    "Checked In",
    "Screening",
    "Examination",
    "Analysis",
    "Prescription",
    "Closed",
    "Followup",
  ];

  const [visitHistory, setVisitHistory] = useState([]);
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));
  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  useEffect(() => {
    const fetchData = async () => {
      //console.log("date", selectedDate);
      const fromDate = format(selectedDate, "yyyy-MM-dd 00:00:00");
      //console.log("fromDate", fromDate);
      const startOfSelectedHour = startOfHour(selectedDate);
      const toDateIncremented = addHours(startOfSelectedHour, 1);
      const toDate = format(toDateIncremented, "yyyy-MM-dd HH:mm:ss");

      let data = JSON.stringify({
        fromDate: fromDate,
        toDate: toDate,
        userid: UserID,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${Config.apiURL}${Config.routes.getAppointmentsAPI}`,
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-caller": encrypted,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          //console.log(JSON.stringify(response.data.data));
          setPatients(response.data.data);
          setSelectedPatient(null);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
    fetchVisitsData();
  }, [selectedDate]);

  const fetchVisitsData = async (index, PATIENT_ID) => {
    const UserID = JSON.parse(sessionStorage.getItem("UserID"));
    let data = JSON.stringify({
      userId: UserID,
      id: PATIENT_ID,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-caller": encrypted,
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        console.log("heyyy", response);
        console.log(response.data.data);
        setVisitHistory(response.data.data);
        handleVisitHistory(index, response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handlePatientClick = async (index, patientData) => {

  //   setSelectedPatient(patients[index]);
  //   sessionStorage.setItem(
  //     "PATIENT_ID",
  //     JSON.stringify(patients.flat()[index].id)
  //   );
  //   sessionStorage.setItem(
  //     "selectedPatientDetails",
  //     JSON.stringify(patientData)
  //   );
  //   await fetchVisitsData(index, patientData.id);
  // };

  const handleVisitHistory = (index, VisitData) => {
    console.log(VisitData, "..........");
    const visitData = VisitData[index].VISIT_DATA;
    sessionStorage.setItem("VISIT_ID", VisitData.VISIT_ID);
    sessionStorage.setItem("visitData", JSON.stringify(visitData));
    const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));

    sessionStorage.setItem(
      "selectedPatientHistory",
      JSON.stringify(visitHistory.flat()[index])
    );
    const patient = JSON.parse(
      sessionStorage.getItem("selectedPatientHistory")
    );

    navigate(
      `/patients/testvisits/${PATIENT_ID || selectedPatient.id
      }?source=appointments`
    );
  };

  const handleDateChange = (newDate) => {
    console.log("new date", newDate);
    setSelectedDate(newDate);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearch = debounce((value) => {
    setSearchQuery(value);
  }, 300);

  // const filteredData = patients.flat().filter((item) => {
  //   const fullName = `${item.first_name} ${item.last_name}`.toLowerCase();
  //   const searchQueryLower = searchQuery.toLowerCase();

  //   return Array.from(searchQueryLower).every((char) =>
  //     fullName.includes(char)
  //   );
  // });
  const filteredData = patients.flat().filter((item) => {
    const fullName = `${item.first_name} ${item.last_name}`.toLowerCase();
    if (!searchQuery) return true;
    const searchQueryLower = searchQuery.toLowerCase();
    return fullName.includes(searchQueryLower);
  });

  const handleSkipClick = () => {
    setShowAlert(true);
  };


  const handleClose = () => {
    setShowAlert(false);
  };

  const handleAction = () => {
    setShowAlert(false);
    handleButtonClick();
  };

  const handleButtonClick = async () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    console.log(selectedDate, "++++++++++++++++++");
    if (selectedDate < currentDate) {
      toast.error("Please select a future date to create an appointment.");
    } else {
      if (selectedDate.getDate() > currentDate.getDate()) {
        console.log(selectedDate.getDate() > currentDate.getDate());
        localStorage.setItem("visit_date", selectedDate);
      } else {
        localStorage.removeItem("visit_date");
      }

      navigate("/patients?action=addappointment");
    }
  };

  const isDateTodayOrEarlier =
    new Date(selectedDate).getTime() - new Date().getTime() < 0;

  const handlePatientClick = async (index, patientData) => {
    console.log("patientData", patientData, "parients", patients);

    sessionStorage.setItem("PATIENT_ID", JSON.stringify(patientData.USER_ID));
    sessionStorage.setItem(
      "selectedPatientDetails",
      JSON.stringify(patientData)
    );
    sessionStorage.removeItem("VISIT_ID");
    sessionStorage.removeItem("htResult");
    sessionStorage.removeItem("dbResult");

    await handleVisitHistory(0, visitHistory[0]);
  };

  return (
    <div className="mainAppointmentSection">
      <div className="bgAppointmentsPatientsBodyContainerAPM">
        <div className="calendarSection">
          <div className="CalenderHeading">
            <h2 className="innerCalenderheading">APPOINTMENTS</h2>
            {/* <img src={icon} className="icon" alt="" /> */}
            <div className="CalenderAddButtonContainer">
              <Link className="CalenderLink1">
                <button
                  className="addCalenderButton1"
                  // onClick={handleButtonClick}
                  onClick={handleSkipClick}
                >
                  + Add Appointment
                </button>
              </Link>
              {/* <ToastContainer position="top-right" /> */}
            </div>
          </div>
          <Calendar onDateChange={handleDateChange} />
        </div>
        <Dialog open={showAlert} onClose={handleClose} PaperProps={{
          style: { width: "25%" }
        }}>
          <DialogTitle>Patient Selection Needed</DialogTitle>
          <DialogContent>
            <p style={{ paddingTop: "20px" }}>
              Please select a patient and add a new visit.
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cancel</Button>
            <Button onClick={handleAction} color="primary">Continue</Button>
          </DialogActions>
        </Dialog>
        <div className="AppointmentMainSection">
          <div className="patientsListContainer">
            <div className="innerListContainerSection">
              <div id="patientFullDetails">
                <h1 className="patientDetailHeading">APPOINTMENTS LIST</h1>
                {isLoading ? (
                  <div className="spinningLoader">
                    <CircularProgress size={48} color="inherit" />
                  </div>
                ) : (
                  <>
                    <div
                      className="patientEachItemContainer"
                      style={{ margin: "10px 0" }}
                    >
                      <div className="patientEachItemView columnsColor">
                        Date
                      </div>
                      <div className="patientEachItemView columnsColor">
                        Name
                      </div>
                      <div className="patientEachItemView columnsColor">
                        Gender
                      </div>
                      <div className="appointmentsSearchContainer">
                        <img src={searchAlt} className="searchIcon" alt="" />
                        <input
                          type="text"
                          class="inputFeildSearch"
                          placeholder="Search"
                          onKeyUp={(e) => handleSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    {filteredData.length > 0 ? (
                      <div style={{ height: "325px", overflowY: "auto" }}>
                        {filteredData.map((user, index) => (
                          <div
                            key={index}
                            className="filteredDataSection"
                          // onClick={() => handlePatientClick(index, user)}
                          >
                            <div className="tableHeadingSection">
                              <div className="anotherPatientEachItemView extraStyle">
                                <p className="appointmentDate">{`${user.visit_date.substring(
                                  0,
                                  10
                                )} `}</p>
                              </div>
                              <div className="anotherPatientEachItemView appointments-shortnameicon">
                                <div>
                                  <p className="patientName">{`${user.first_name} ${user.last_name}`}</p>
                                </div>
                              </div>
                              <div className="anotherPatientEachItemView">
                                <p className="appointmentUserGender">{`${user.gender} `}</p>
                              </div>
                              <div className="anotherPatientEachItemView extraStyle">
                                {isDateTodayOrEarlier ? (
                                  <p
                                    className="screeningDetailView"
                                  // onClick={() =>
                                  //   user.status === 0 &&
                                  //   handlePatientClick("", user)
                                  // }
                                  >
                                    {statusString[user.status]}
                                  </p>
                                ) : (
                                  <div className="anotherPatientEachItemView extraStyle">
                                    <p className="appointmentDate">Scheduled</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="displayMessage">
                        No appointments on the date
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <ToastContainer position="top-right" />
        </div>
      </div>
    </div>
  );
};

export default Appointments;
