import React, { useEffect, useState, useRef } from "react";
import QuestionsAnswers from "../QuestionsAnswers";
import Config from "../../../Config/config";
import axios from "axios";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { CircularProgress } from "@mui/material";
import "./MedicalTab.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
const ExaminationTab = ({
  activeSection,
  ExaminationHistory,
  toggleSection4,
  toggleSection2,
}) => {
  const patientDetails = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));

  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");
  const navigate = useNavigate();
  const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
  // console.log("patieeeeeeeeeee", PATIENT_ID)

  const selectedPatientHistory =
    sessionStorage.getItem("selectedPatientHistory") !== "undefined"
      ? JSON.parse(sessionStorage.getItem("selectedPatientHistory"))
      : [];
  // console.log("selectedPatientHistory----------", selectedPatientHistory)
  const isSameDay = (date1, date2) =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

  function formatVisitDate(visitDate) {
    const initialDate = new Date(visitDate);

    // Extract the date and time components
    const year = initialDate.getFullYear();
    const month = String(initialDate.getMonth() + 1).padStart(2, "0");
    const day = String(initialDate.getDate()).padStart(2, "0");
    const hours = String(initialDate.getHours()).padStart(2, "0");
    const minutes = String(initialDate.getMinutes()).padStart(2, "0");
    const seconds = String(initialDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(initialDate.getMilliseconds()).padStart(5, "0");

    // Construct the formatted visit date string
    const formattedVisitDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    //console.log("======----formated", formattedVisitDateString);

    return formattedVisitDateString;
  }

  const VISITDATA = {
    patientId: selectedPatientHistory.PATIENT_ID,
    mobile: selectedPatientHistory.MOBILE,
    nurseId: selectedPatientHistory.NURSE_USER_ID,
    doctorId: selectedPatientHistory.DOCTOR_USER_ID,
    visitId: selectedPatientHistory.VISIT_ID,
    visitDate: formatVisitDate(selectedPatientHistory.VISIT_DATE),
    medicalHistory: {
      isDiabetes: null,
      isHypertension: null,
      isCKD: null,
      isLiverFailure: null,
      isCOPD: null,
      isAsthma: null,
      isHeartDisease: null,
      isStroke: null,
      isPVD: null,
      isHeartAttack: null,
      isFamilyHypertension: null,
      isFamilyDiabetes: null,
      isFamilyCardioVascular: null,
      isFamilyOtherDisease: null,
      cheifComplaint: [],
      otherChiefComplaint: null,
    },
    drugHistory: {
      isHypertensiveDrugs: null,
      isDiabeticDrugs: null,
      isLipidDrugs: null,
      isAsprin: null,
      isInsulin: null,
      currentHTDrugs: [],
      currentDBDrugs: [],
      currentInsulin: [],
      currentAsprin: [],
      currentLipid: [],
    },
    personalHistory: {
      isPhysicalActivity: null,
      isSmoking: null,
      isDrinking: null,
      drinkingFrequency: null,
      isFamilyHistoryOfMI: null,
      isPolyuria: null,
      isPolydypsia: null,
      isWeightloss: null,
      isOnDialysis: null,
    },
    examinationHistory: {
      sbp: null,
      dbp: null,
      heartRate: null,
      sbp2: null,
      dbp2: null,
      sbp3: null,
      dbp3: null,
      averageSBP: 0.0,
      averageDBP: 0.0,
      fbs: null,
      ppbs: null,
      rbs: null,
      hba1c: null,
      waist: null,
      height: null,
      weight: null,
      bmi: null,
      ldl: null,
      creatinine: null,
      cholestrol: null,
      isEdema: null,
      isBreathlessness: null,
      isChestPain: null,
      isDeficit: null,
      isAlteredConciousness: null,
      isFundus: null,
      isGeneralizedSeizures: null,
      gfr: null,
      showBP3: false,
      waistUnit: 0,
      height: "", // Store height value in centimeters
      heightFt: "", // Store height value in feet
      heightIn: "", // Store height value in inches
      heightUnit: 0, //for radio button
      errorMessage: "",
      errorMessageField: "",
    },
    htDiagnosis: {
      diagnosis: null,
      control: null,
      recommendation: null,
      notes: null,
      followup: null,
      doctorAcceptance: null,
      doctorNotes: null,
      doctorId: null,
      systemDecision: null,
    },
    dbDiagnosis: {
      diagnosis: null,
      control: null,
      recommendation: null,
      notes: null,
      followup: null,
      doctorAcceptance: null,
      doctorNotes: null,
      doctorId: null,
      systemDecision: null,
    },
    copdDiagnosis: [],
    tbDiagnosis: [],
    copdHistory: {
      isCopd: null,
      isProgressiveDyspnoea: null,
      isChronicCough: null,
      isChronicSputum: null,
      isChestPain: null,
      isFever: null,
      isWeightLoss: null,
      isOrthopnoea: null,
      isParoxysmalNocturnal: null,
      isHaemoptysis: null,
      isSpirometeryTest: null,
      spirometeryValue: null,
      isSmoking: null,
      isBiomassFuel: null,
      isPollution: null,
      mmrcGrade: null,
      isDifficultInBreathing: null,
      isExacerbation: null,
      isAdmitHospitalWithExacerbation: null,
      severeBreathingDifficulty: null,
      exacerbationSymptoms: [],
      severeExacerbationSymptoms: [],
      drugOptions: [],
    },

    tbHistory: {
      confirmedTBType: [],
      presumptiveTBType: [],
      suspectedTBSymptoms: [],
      suspectedComorbidities: [],
      suspectedOtherTBDrugs: [],
      tbPreviousDrugList: [],
      tbCurrentDrugList: [],
      tbAdditionalDrugList: [],
    },
    osteoArthritisHistory: {
      oaAComorbidList: [],
      oaPharmaList: [],
      oaLifestyleList: [],
      oaNonPharmaList: [],
    },
    orderTest: {
      clinicalPathology: [],
      radiology: [],
      bioChemistry: [],
      stoolAnalysis: [],
      serology: [],
      urineAnalysis: [],
      cardiology: [],
    },
  };

  const [visitData, setVisitData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const VISIT_ID = sessionStorage.getItem("VISIT_ID");

  const DECIMAL_THREE_DOT_TWO_REGEX = /^(\d{0,3})?(\.\d{0,2})?$/;
  const DECIMAL_TWO_DOT_TWO_REGEX = /^(\d{0,2})?(\.\d{0,2})?$/;
  const DECIMAL_ONE_DOT_TWO_REGEX = /^(\d{0,1})?(\.\d{0,2})?$/;
  const INTEGER_THREE_DIGITS_REGEX = /^\d{0,3}$/;

  //console.log("VISITDATA.examinationHistory", VISITDATA.examinationHistory);
  const [filteredRecord, setFilteredRecord] = useState([]);
  const [examinationValues, setExaminationValues] = useState(
    VISITDATA.examinationHistory
  );
  const [isRecordClosed, setIsRecordClosed] = useState(false);
  // {
  //   sbp: null,
  //   dbp: null,
  //   heartRate: null,
  //   sbp2: null,
  //   dbp2: null,
  //   sbp3: null,
  //   dbp3: null,
  //   averageSBP: 0.0,
  //   averageDBP: 0.0,
  //   fbs: null,
  //   ppbs: null,
  //   rbs: null,
  //   hbac1: null,
  //   waist: null,
  //   weight: null,
  //   bmi: 0,
  //   ldl: null,

  //   creatinine: null,
  //   cholestrol: null,
  //   isEdema: null,
  //   isBreathlessness: null,
  //   isChestPain: null,
  //   isDeficit: null,
  //   isAlteredConciousness: null,
  //   isFundus: null,
  //   isGeneralizedSeizures: null,
  //   gfr: null,

  //   showBP3: false,
  //   waistUnit: 0,
  //   height: "", // Store height value in centimeters
  //   heightFt: "", // Store height value in feet
  //   heightIn: "", // Store height value in inches
  //   heightUnit: 0, //for radio button
  //   errorMessage: "",
  //   errorMessageField: "",
  // });
  const [error, setError] = useState(null);
  const [errorFt, setErrorFt] = useState(null);
  const [errorIn, setErrorIn] = useState(null);
  const [errorWaist, setErrorWaist] = useState(null);
  const [errorWeight, setErrorWeight] = useState(null);
  const [errorldl, setErrorldl] = useState(null);
  const [VisitHistory, setVisitHistory] = useState([])
  const examinationValuesRef = useRef({
    height: "",
    heightFt: "",
    heightIn: "",
    weight: "",
    sbp: null,
    dbp: null,
    heartRate: null,
    sbp2: null,
    dbp2: null,
    sbp3: null,
    dbp3: null,
  });

  // useEffect(() => {
  //   try {
  //     const data = JSON.parse(ExaminationHistory);

  //     setExaminationValues(data);
  // const waistFt = getFtFromCm(data.waist || 0).toString();
  // const waistIn = getInchesFromCm(data.waist || 0).toString();
  // const heightFt = getFtFromCm(data.height || 1).toString();
  // const heightIn = getInchesFromCm(data.height || 0).toString();

  // // Update the state with the converted values
  // setExaminationValues((prev) => ({
  //   ...prev,
  //   waistFt: waistFt,
  //   waistIn: waistIn,
  //   heightFt: heightFt,
  //   heightIn: heightIn,
  //   heightUnit: 0,
  // }));
  //   } catch (err) {
  //     console.log(err, "err");
  //   }
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      const UserID = JSON.parse(sessionStorage.getItem("UserID"));
      const PATIENT_ID = (() => {
        try {
          const item = sessionStorage.getItem("PATIENT_ID");
          return item ? item : [];
        } catch {
          return [];
        }
      })();
      let data = JSON.stringify({
        userId: UserID,
        id: PATIENT_ID,
      });

      //console.log("data in visits", data);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: "https://mpowerqa.clinally.com/patient/patientvisits",
        url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-caller": encrypted,
        },
        data: data,
      };
      //console.log(config, "config data ...........");
      axios
        .request(config)
        .then((response) => {
          // if (response.data.data[0].status === 2) {
          setVisitHistory(response.data.data);
          // setIsLoading(false);
          //console.log("response----------------", response.data.data);
          // }

          sessionStorage.setItem(
            "selectedPatientHistory",
            JSON.stringify(response.data.data[0])
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchDataAndSetDrugHistory = async () => {
      try {
        let data = JSON.stringify({
          userId: UserID,
          id: PATIENT_ID,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-caller": encrypted,
          },
          data: data,
        };

        const response = await axios.request(config);
        //console.log("Response from ..:", response, response.data.data);

        const sessionVisitID = parseInt(VISIT_ID, 10);

        const filteredRecords = response.data.data.filter((record) => {
          return record.VISIT_ID === sessionVisitID;
        });
        if (
          filteredRecords &&
          filteredRecords.length > 0 &&
          filteredRecords[0].STATUS === 6
        ) {
          setIsRecordClosed(true);
        }
        setFilteredRecord(filteredRecords);
        //console.log("Filtered Records:", filteredRecords, typeof VISIT_ID);

        // if (response.data.data === null || response.data.data.length === 0 || response.data.data[0].VISIT_DATA === null) {
        //   //console.log("=in useeffect if=")
        //   setVisitData(VISITDATA);
        // }
        if (filteredRecords[0].VISIT_DATA === null) {
          //console.log("=in useeffect if=");
          setVisitData(VISITDATA);
        } else {
          let res = await filteredRecords[0].VISIT_DATA;
          //console.log("---res----", res);

          let ExaminationData = res ? JSON.parse(res).examinationHistory : null;

          let parsedRes = !!res ? JSON.parse(res) : null;
          //console.log("parsedRes", parsedRes);

          setVisitData(parsedRes);

          if (ExaminationData !== null) {
            const parsedExaminationData = JSON.parse(ExaminationData);

            setExaminationValues(parsedExaminationData);
            //console.log("parsedexamination", parsedExaminationData);

            const waistFt = getFtFromCm(
              parsedExaminationData.waist || 0
            ).toString();
            const waistIn = getInchesFromCm(
              parsedExaminationData.waist || 0
            ).toString();
            const heightFt = getFtFromCm(
              parsedExaminationData.height || 1
            ).toString();
            const heightIn = getInchesFromCm(
              parsedExaminationData.height || 0
            ).toString();

            // Update the state with the converted values
            setExaminationValues((prev) => ({
              ...prev,
              waistFt: waistFt,
              waistIn: waistIn,
              heightFt: heightFt,
              heightIn: heightIn,
              heightUnit: 0,
            }));
          }
        }
      } catch (err) {
        console.log(err, "err");
      }
    };

    fetchDataAndSetDrugHistory();
  }, [VISIT_ID]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let errorMessage = "";
    let errorMessageField = "";

    switch (name) {
      case "sbp":
      case "dbp":
      case "sbp2":
      case "dbp2":
      case "sbp3":
      case "dbp3":
        if (!DECIMAL_THREE_DOT_TWO_REGEX.test(value)) {
          return;
        }
        if (
          value !== "" &&
          (isNaN(value) || parseFloat(value) < 0 || parseFloat(value) > 400)
        ) {
          errorMessage = "Range (0 - 400)";
          errorMessageField = name;
        }
        break;

      case "heartRate":
        if (!INTEGER_THREE_DIGITS_REGEX.test(value)) {
          return;
        }
        if (
          value !== "" &&
          (isNaN(value) || parseInt(value) < 0 || parseInt(value) > 270)
        ) {
          errorMessage = "Range (0 - 270)";
          errorMessageField = "heartRate";
        }
        break;
      default:
        break;
    }

    setExaminationValues((prev) => ({
      ...prev,
      [name]: value,
      errorMessage: errorMessage,
      errorMessageField: errorMessageField,
    }));

    examinationValuesRef.current = {
      ...examinationValuesRef.current,
      [name]: value,
    };

    if (
      name === "sbp" ||
      name === "dbp" ||
      name === "sbp2" ||
      name === "dbp2" ||
      name === "sbp3" ||
      name === "dbp3"
    ) {
      calculateAverageBP();
    }
  };

  const calculateAverageBP = () => {
    let avgSBP = 0.0;
    let avgDBP = 0.0;

    const sbp1 = parseInt(examinationValuesRef.current.sbp) || examinationValues.sbp || 0.0;
    const sbp2 =
      parseInt(examinationValuesRef.current.sbp2) ||
      examinationValues.sbp2 ||
      0;
    const sbp3 =
      parseInt(examinationValuesRef.current.sbp3) ||
      examinationValues.sbp3 ||
      0;

    const dbp1 = parseInt(examinationValuesRef.current.dbp) || examinationValues.dbp || 0.0;
    const dbp2 =
      parseInt(examinationValuesRef.current.dbp2) ||
      examinationValues.dbp2 ||
      0;
    const dbp3 =
      parseInt(examinationValuesRef.current.dbp3) ||
      examinationValues.dbp3 ||
      0;

    // console.log("--dbp-------", sbp1, dbp1);

    if (sbp2 > 0 && dbp2 > 0) {
      if (Math.abs(sbp1 - sbp2) > 10 || Math.abs(dbp1 - dbp2) > 6) {
        setExaminationValues((prev) => ({
          ...prev,
          showBP3: true,
        }));
      } else {
        setExaminationValues((prev) => ({
          ...prev,
          showBP3: false,
        }));
      }
    }

    if (sbp3 === 0) {
      avgSBP = sbp2 === 0 ? sbp1 : (sbp1 + sbp2) / 2;
    } else {
      avgSBP =
        Math.abs(sbp3 - sbp1) > Math.abs(sbp3 - sbp2)
          ? (sbp3 + sbp2) / 2
          : (sbp3 + sbp1) / 2;
    }

    if (dbp3 === 0) {
      avgDBP = dbp2 === 0 ? dbp1 : (dbp1 + dbp2) / 2;
    } else {
      avgDBP =
        Math.abs(dbp3 - dbp1) > Math.abs(dbp3 - dbp2)
          ? (dbp3 + dbp2) / 2
          : (dbp3 + dbp1) / 2;
    }

    setExaminationValues((prev) => ({
      ...prev,
      averageSBP: avgSBP,
      averageDBP: avgDBP,
    }));
  };

  const handleOptionSelection = (option, questionState) => {
    setExaminationValues((prevMedicalHistory) => ({
      ...prevMedicalHistory,
      [questionState]: option,
    }));
  };

  function getFtFromCm(number) {
    return Math.floor(number / (2.54 * 12));
  }

  function getInchesFromCm(number) {
    return Math.floor(number / 2.54 - 12 * getFtFromCm(number));
  }

  function convertFtInToCm(ft, inches) {
    // Parse strings to numbers
    const ftNum = parseInt(ft);
    const inchesNum = parseInt(inches);
    // Check if parsing was successful
    if (isNaN(ftNum) || isNaN(inchesNum)) {
      return "";
    }

    const totalInches = 12 * ftNum + inchesNum;
    const heightInCm = totalInches * 2.54;
    const roundedHeightInCm = Math.round(heightInCm);
    return roundedHeightInCm.toString();
  }

  const handleChangeHeightUnit = (event) => {
    let value = event.target.value;
    value = value.replace(/\D/g, ""); // Remove non-numeric characters
    const unit = event.target.value === "cm" ? 0 : 1;
    setExaminationValues((prev) => ({
      ...prev,
      heightUnit: unit,
      height: unit === 0 ? prev.height : "", // Clear height if switching to ft
      heightFt: unit === 1 ? prev.heightFt : "", // Clear heightFt if switching to cm
      heightIn: unit === 1 ? prev.heightIn : "", // Clear heightIn if switching to cm
    }));
  };

  const handleHeightChange = async (e) => {
    const value = e.target.value;
    if (!DECIMAL_THREE_DOT_TWO_REGEX.test(value)) {
      return;
    }
    if (!isNaN(value) && parseFloat(value) > 0 && parseFloat(value) <= 300) {
      setError(null);
      const cm = value;
      const ft = getFtFromCm(parseFloat(value, 10));
      const inches = getInchesFromCm(parseFloat(value, 10));

      //console.log("ft", ft, "cm", cm, "inches", inches);
      setExaminationValues((prev) => ({
        ...prev,
        height: cm,
        heightFt: ft,
        heightIn: inches,
      }));
      examinationValuesRef.current = {
        ...examinationValuesRef.current,
        height: cm,
        heightFt: ft,
        heightIn: inches,
      };
      await calculateBMI();
    } else {
      setError("(Range: 0 - 300)");
      setExaminationValues((prev) => ({
        ...prev,
        height: "",
        heightFt: "",
        heightIn: "",
      }));
      examinationValuesRef.current = {
        ...examinationValuesRef.current,
        height: "",
        heightFt: "",
        heightIn: "",
      };
    }
  };

  const handleHeightFtChange = (event) => {
    const value = event.target.value;
    if (value === "" || (!isNaN(value) && value >= 1 && value <= 10)) {
      setErrorFt(null);
      setExaminationValues((prev) => ({
        ...prev,
        heightFt: value,
        height: convertFtInToCm(value, examinationValues.heightIn || 0),
      }));
      examinationValuesRef.current = {
        ...examinationValuesRef.current,
        heightFt: value,
        height: convertFtInToCm(value, examinationValues.heightIn || 0),
      };
      calculateBMI();
    } else {
      setErrorFt("(Range: 1 - 10)");
    }
  };

  const handleHeightInChange = (event) => {
    const value = event.target.value;
    if (value === "" || (!isNaN(value) && value >= 0 && value <= 11)) {
      setErrorIn(null);
      setExaminationValues((prev) => ({
        ...prev,
        heightIn: value,
        height: convertFtInToCm(examinationValues.heightFt || 0, value),
      }));
      examinationValuesRef.current = {
        ...examinationValuesRef.current,
        heightIn: value,
        height: convertFtInToCm(examinationValues.heightFt || 0, value),
      };
      calculateBMI();
    } else {
      setErrorIn("(Range: 0 - 11)");
    }
  };

  const handleWaistChange = (event) => {
    const value = event.target.value;
    if (!DECIMAL_THREE_DOT_TWO_REGEX.test(value)) {
      return;
    }
    if (isNaN(value) || value < 0 || value > 300) {
      setErrorWaist("Range 0 - 300");
    } else {
      setErrorWaist(null);
    }
    setExaminationValues((prev) => ({
      ...prev,
      waist: value,
    }));
  };

  const handleWeightChange = (event) => {
    let inputValue = event.target.value;
    if (!DECIMAL_THREE_DOT_TWO_REGEX.test(inputValue)) {
      return;
    }
    if (isNaN(inputValue) || inputValue < 0 || inputValue > 300) {
      setErrorWeight("Range: 0 - 300");
    } else {
      setErrorWeight(null);
    }
    setExaminationValues((prev) => ({
      ...prev,
      weight: inputValue,
    }));
    examinationValuesRef.current = {
      ...examinationValuesRef.current,
      weight: inputValue,
    };
    calculateBMI();
  };

  const calculateBMI = async () => {
    //console.log("im coming ");
    const height =
      examinationValuesRef.current.height || examinationValues.height;

    const weight =
      examinationValuesRef.current.weight || examinationValues.weight;

    //console.log("examination values", height, weight);

    let result;

    if (height !== "" && weight !== "") {
      const heightInMeter = parseFloat(height) / 100;
      const heightSquare = heightInMeter * heightInMeter;
      result = heightInMeter !== 0 ? parseFloat(weight) / heightSquare : 0;

      // Round the BMI value to one decimal place
      result = parseFloat(result.toFixed(1));
      //console.log("result", result);
    } else {
      result = 0;
    }

    // Update state with the calculated BMI value
    setExaminationValues((prev) => ({
      ...prev,
      bmi: result,
    }));
  };

  function getBmiCategory(bmi) {
    // console.log("bmo in category", bmi);
    if (bmi < 18.6) {
      return "(Underweight)";
    } else if (bmi >= 18.6 && bmi <= 25) {
      return "(Healthy)";
    } else if (bmi > 25 && bmi < 30) {
      return "(Overweight)";
    } else if (bmi >= 30) {
      return "(Obese)";
    } else {
      return "";
    }
  }

  // function to determine BMI background color
  function getBmiBackgroundColor(bmi) {
    // console.log("bmo in getBmiBackgroundColor", bmi);
    if (bmi < 18.6) {
      return "rgba(255, 215, 0, 0.1)";
    } else if (bmi >= 18.6 && bmi <= 25) {
      return "rgba(0, 128, 0, 0.1)";
    } else if (bmi > 25 && bmi < 30) {
      return "rgba(255, 165, 0, 0.1)";
    } else if (bmi >= 30) {
      return "rgba(255, 0, 0, 0.1)";
    } else {
      return "rgba(0, 0, 0, 0.1)";
    }
  }

  // Blood sugar readings
  const [creatinine, setCreatinine] = useState("");
  const [gfr, setGfr] = useState("");
  const [fbsError, setFbsError] = useState("");
  const [ppbsError, setPpbsError] = useState("");
  const [rbsError, setRbsError] = useState("");
  const [hbaError, setHbaError] = useState("");
  const [serumError, setSerumError] = useState("");

  // For bloodsugar readings
  useEffect(() => {
    BloodSugarError();
  }, [examinationValues]);

  const BloodSugarError = () => {
    const { fbs, ppbs, rbs, hba1c } = examinationValues;

    // Convert examination values to numbers for comparison
    const fbsValue = parseFloat(fbs);
    const ppbsValue = parseFloat(ppbs);
    const rbsValue = parseFloat(rbs);
    const hba1cValue = parseFloat(hba1c);

    // Check and set error messages based on conditions
    if (!isNaN(fbsValue) && (fbsValue < 0 || fbsValue > 700)) {
      setFbsError("Range(0-700)");
    } else {
      setFbsError("");
    }

    if (!isNaN(ppbsValue) && (ppbsValue < 0 || ppbsValue > 700)) {
      setPpbsError("Range(0-700)");
    } else {
      setPpbsError("");
    }

    if (!isNaN(rbsValue) && (rbsValue < 0 || rbsValue > 700)) {
      setRbsError("Range(0-700)");
    } else {
      setRbsError("");
    }

    if (!isNaN(hba1cValue) && (hba1cValue < 0 || hba1cValue > 20)) {
      setHbaError("Range(0-20)");
    } else {
      setHbaError("");
    }
  };

  // BLOOD SUGAR CREATININE CALCULATION

  const handleCreatinineChange = (event) => {
    const value = event.target.value;

    if (value === "") {
      setExaminationValues((prev) => ({
        ...prev,
        creatinine: "",
        gfr: "",
      }));
      setSerumError("");
      return;
    }

    if (!DECIMAL_ONE_DOT_TWO_REGEX.test(value)) {
      return;
    }
    setExaminationValues((prev) => ({ ...prev, creatinine: value }));
    calculateBloodSugar(value);
  };

  const handleGfrChange = (event) => {
    const { value } = event.target;
    if (DECIMAL_THREE_DOT_TWO_REGEX.test(event.target.value)) {
      setExaminationValues((prev) => ({ ...prev, gfr: value }));
    }
    // setGfr(value);
  };

  const calculateBloodSugar = (creatinineValue, gender) => {
    let egfr;
    const creatinineFloat = parseFloat(creatinineValue);

    if (
      isNaN(creatinineFloat) ||
      creatinineFloat < 0.01 ||
      creatinineFloat > 8
    ) {
      setSerumError("Range(0.01-8)");
      setGfr("");
    } else {
      setSerumError("");
      if (patientDetails.GENDER === "Female") {
        if (creatinineFloat <= 0.7) {
          egfr = Math.round(
            142 *
            Math.pow(creatinineFloat / 0.7, -0.241) *
            Math.pow(0.9938, patientDetails.age) *
            1.012
          );
        } else {
          egfr = Math.round(
            144 *
            Math.pow(creatinineFloat / 0.7, -1.2) *
            Math.pow(0.9938, patientDetails.age) *
            1.012
          );
        }
      } else if (patientDetails.GENDER === "Male") {
        if (creatinineFloat <= 0.9) {
          egfr = Math.round(
            142 *
            Math.pow(creatinineFloat / 0.9, -0.302) *
            Math.pow(0.9938, patientDetails.age)
          );
        } else {
          egfr = Math.round(
            142 *
            Math.pow(creatinineFloat / 0.9, -1.2) *
            Math.pow(0.9938, patientDetails.age)
          );
        }
      }
      setGfr(egfr.toString());
      setExaminationValues((prev) => ({ ...prev, gfr: egfr.toString() }));
    }
  };

  // lav values error
  const [ldlError, setLdlError] = useState("");
  const [cholestrolError, setCholestrolError] = useState("");

  const handleLdlChange = (event) => {
    const value = event.target.value;
    if (DECIMAL_THREE_DOT_TWO_REGEX.test(value)) {
      if (value <= 50 || value >= 500) {
        setLdlError("Range(50 - 500)");
      } else {
        setLdlError("");
      }
      setExaminationValues((prev) => ({ ...prev, ldl: value }));
    }
  };

  const handleCholestrolChange = (event) => {
    const value = event.target.value;
    if (DECIMAL_THREE_DOT_TWO_REGEX.test(value)) {
      if (value <= 50 || value >= 500) {
        setCholestrolError("Range(50 - 500)");
      } else {
        setCholestrolError("");
      }
      setExaminationValues((prev) => ({ ...prev, cholestrol: value }));
    }
  };

  function replacer(key, value) {
    try {
      if (key.trim() == "") return value;

      if (key.trim() === "drugHistory") {
        let modifiedJsonString = value.replace(/"(\d+\.\d+)"/g, "$1"); //(/\\"(\d+\.\d+)\\"/g, "$1");
        //console.log("===drug=====", modifiedJsonString);
        return modifiedJsonString;
      }

      if (key.trim() === "examinationHistory") {
        //console.log("===111111111110=====", value);
        let modifiedJsonString = value.replace(/"(\d+(\.\d+)?)"/g, "$1");
        //console.log("===examiantion=====", modifiedJsonString);
        return modifiedJsonString;
      }

      if (value && value !== null && typeof value === "object") {
        //console.log("value inside if", value);
        return value.replace(/"/g, '\\"'); // Replace " with \"  }   return value; }
      }
      return value;
    } catch (err) {
      console.log(
        "======key===",
        key,
        "===========value==",
        typeof value,
        "=======",
        value
      );
      // console.log(err);
      if (Array.isArray(value) && value.length === 0) return value;
      else if (
        key.trim() === "tbHistory" ||
        key.trim() === "osteoArthritisHistory"
      ) {
        let jsonString = JSON.stringify(value).replace(/"/g, '\\"');
        //console.log(jsonString, "1#####");
        jsonString = `\"${jsonString}\"`;
        //console.log(jsonString, "2#####");
        return jsonString;
      } else {
        return JSON.stringify(value);
      }
    }
  }

  function ensureDecimalZero(value) {
    //console.log("Type of value:", typeof value);

    // Check if the value is a number or a string representing a number
    if (!isNaN(value)) {
      return parseFloat(value).toFixed(1);
    } else {
      return value;
    }
  }

  function ensureDecimalZerobmi(value) {
    if (!isNaN(value)) {
      return parseFloat(parseFloat(value).toFixed(1));
    } else {
      return value;
    }
  }

  const saveRecord = async () => {
    try {
      //console.log("vist========", visitData);
      if (visitData) {
        const specificFields = {
          // keeping empty "" instead of null [chnaging null => ""]
          sbp: examinationValues.sbp === "" ? null : examinationValues.sbp,
          dbp: examinationValues.dbp === "" ? null : examinationValues.dbp,
          heartRate:
            examinationValues.heartRate === ""
              ? null
              : examinationValues.heartRate,
          sbp2: examinationValues.sbp2 === "" ? null : examinationValues.sbp2,
          dbp2: examinationValues.dbp2 === "" ? null : examinationValues.dbp2,
          sbp3: examinationValues.sbp3 === "" ? null : examinationValues.sbp3,
          dbp3: examinationValues.dbp3 === "" ? null : examinationValues.dbp3,
          averageSBP:
            ensureDecimalZero(examinationValues.averageSBP) === "NaN"
              ? 0.0
              : ensureDecimalZero(examinationValues.averageSBP),
          averageDBP:
            ensureDecimalZero(examinationValues.averageDBP) === "NaN"
              ? 0.0
              : ensureDecimalZero(examinationValues.averageDBP),
          fbs: examinationValues.fbs === "" ? null : examinationValues.fbs,
          ppbs: examinationValues.ppbs === "" ? null : examinationValues.ppbs,
          rbs: examinationValues.rbs === "" ? null : examinationValues.rbs,
          hba1c:
            ensureDecimalZero(examinationValues.hba1c) === "" ||
              ensureDecimalZero(examinationValues.hba1c) === "NaN"
              ? null
              : ensureDecimalZero(examinationValues.hba1c),
          // waist: examinationValues.waist,
          waist: examinationValues.waist === "" ? null : examinationValues.waist,
          height: examinationValues.height === "" ? null : examinationValues.height,
          weight: examinationValues.weight === "" ? null : examinationValues.weight,
          bmi: ensureDecimalZerobmi(examinationValues.bmi),
          ldl: examinationValues.ldl === "" ? null : examinationValues.ldl,
          creatinine:
            examinationValues.creatinine === ""
              ? null
              : examinationValues.creatinine,
          cholestrol: examinationValues.cholestrol,
          isEdema: examinationValues.isEdema,
          isBreathlessness: examinationValues.isBreathlessness,
          isChestPain: examinationValues.isChestPain,
          isDeficit: examinationValues.isDeficit,
          isAlteredConciousness: examinationValues.isAlteredConciousness,
          isFundus: examinationValues.isFundus,
          isGeneralizedSeizures: examinationValues.isGeneralizedSeizures,
          gfr: examinationValues.gfr === "" ? null : examinationValues.gfr,
        };
        console.log(specificFields, "specificFields..... ..specificFields");
        // console.log(
        //   "===examiantion===",
        //   examinationValues,
        //   "===specofied file===",
        //   specificFields
        // );

        visitData.examinationHistory = JSON.stringify(specificFields);

        //console.log("---latest visitdata==", visitData);

        let VisitData = JSON.stringify(visitData, replacer);

        //console.log("stringifiedVisitData---", VisitData);

        sessionStorage.setItem("visitData", VisitData);
        setIsLoading(true);

        const response = await axios.post(
          `${Config.base_url}PATIENT_VISIT_DATA`,
          {
            USER_ID: UserID,
            VISIT_ID: visitData?.visitId?.toString(),
            VISIT_DATA: VisitData,
            STATUS: 2,
            PRESCRIPTION_DATA: null,
            CREATED_BY: visitData?.nurseId?.toString(),
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "x-caller": encrypted,
            },
          }
        );
        //console.log("coming to repsoe", response);
        if (response.status === 201) {
          setIsLoading(false);
          return true;
        } else {
          console.error("Error: Data not saved");
          return false;
        }
      } else {
        //console.log("cmg to else");
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  const handleNextClick1 = async () => {
    const recordSaved = await saveRecord();
    setIsLoading(true);
    if (recordSaved) {
      if (examinationValues.weight) {
        setIsLoading(false);
        toggleSection4();
      } else {
        setIsLoading(false);
        setError("required");
      }
    } else {
      //console.log("err");
    }
  };

  const handleNextClick = async () => {

    if (
      examinationValues.hba1c &&
      (!examinationValues.fbs || examinationValues.fbs === "")
    ) {
      setFbsError("FBS is required when HbA1c is entered");
      return;
    } else {
      setFbsError("");
    }

    if (filteredRecord.length > 0 && filteredRecord[0].STATUS === 6) {
      // toggleSection4();
    } else if (examinationValues.weight) {
      setIsLoading(true);
      try {
        const recordSaved = await saveRecord();
        if (recordSaved) {
          setIsLoading(false);

          // toggleSection4();
          toast.success("Data saved successfully");

          const patientId = await sessionStorage.getItem("PATIENT_ID");


          const visitDate = new Date(VisitHistory[0].VISIT_DATE);
          const todayDate = new Date();

          // Convert both to UTC date-only strings for proper comparison
          const visitDateUTC = visitDate.toISOString().split('T')[0];
          const todayDateUTC = todayDate.toISOString().split('T')[0];


          const visit = (visitDateUTC <= todayDateUTC)
            // (new Date(VisitHistory[0].VISIT_DATE) < new Date() ||
            //   isSameDay(new Date(VisitHistory[0].VISIT_DATE), new Date()))
            ? VisitHistory[0]
            : VisitHistory[1];


          sessionStorage.setItem("visitData", JSON.stringify(visit.VISIT_DATA));
          sessionStorage.setItem("VISIT_ID", visit.VISIT_ID);

          if (patientId !== null) {
            setTimeout(() => {
              navigate("/patients/reviewprescription");
            }, 5000); // 2000ms = 2 seconds delay
          }


          // };
        } else {
          setIsLoading(false);
          //console.log("Error saving record");
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error saving record:", error);
      }
    } else {
      setError("Required");
    }
  };

  return (
    <>
      <div className="AssessmentExamination">
        {/* Blood pressure reading */}
        <div
          style={{
            display: activeSection === "Examination" ? "block" : "none",
            backgroundColor: "white",
            borderRadius: "1.75rem",
            marginTop: "2%",
          }}
        >
          <p
            style={{
              fontWeight: "400",
              background: "#f4f4f4",
              padding: "0.7rem 1rem",
              borderRadius: "20px 20px 0px 0px",
              width: "100%",
            }}
          >
            Blood Pressure Reading
          </p>
          <div
            style={{
              padding: "0.7rem 2rem",
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
              backgroundColor: "#fffee3",
              borderRadius: "0.7rem",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                SBP (mm/Hg)
              </p>
              <input
                disabled={isRecordClosed}
                type="number"
                style={{
                  border: "none",
                  outline: "none",
                  borderBottom: "1px solid gray",
                  background: "transparent",
                }}
                name="sbp"
                value={examinationValues.sbp}
                onChange={handleChange}
              />
              {/* Error message for SBP */}
              {examinationValues.errorMessage &&
                examinationValues.errorMessageField === "sbp" && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "13px",
                      // paddingTop: "10px",
                    }}
                  >
                    {examinationValues.errorMessage}
                  </span>
                )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                DBP (mm/Hg)
              </p>
              <input
                disabled={isRecordClosed}
                type="number"
                style={{
                  border: "none",
                  outline: "none",
                  borderBottom: "1px solid gray",
                  background: "transparent",
                }}
                name="dbp"
                value={examinationValues.dbp}
                onChange={handleChange}
              />
              {/* Error message for DBP */}
              {examinationValues.errorMessage &&
                examinationValues.errorMessageField === "dbp" && (
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {examinationValues.errorMessage}
                  </span>
                )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                Heart Rate
              </p>
              <input
                disabled={isRecordClosed}
                type="number"
                maxLength="3"
                style={{
                  border: "none",
                  outline: "none",
                  borderBottom: "1px solid gray",
                  background: "transparent",
                }}
                name="heartRate"
                value={examinationValues.heartRate}
                onChange={handleChange}
              />
              {examinationValues.errorMessage &&
                examinationValues.errorMessageField === "heartRate" && (
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {examinationValues.errorMessage}
                  </span>
                )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "0.7rem",
                background: "#ebe3ff",
                alignItems: "center",
                justifyContent: "center",
                padding: "0.5rem 1rem",
              }}
            >
              <p style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>
                Average BP&nbsp;
                {/* {console.log("avgSBP******",examinationValues.averageSBP)} */}
                {(Number(examinationValues.averageSBP || 0) || 0).toFixed(1)}/
                {(Number(examinationValues.averageDBP || 0) || 0).toFixed(1)}

              </p>
            </div>
          </div>

          {((examinationValuesRef.current.sbp || examinationValues.sbp || 0) >=
            140 ||
            (examinationValuesRef.current.dbp || examinationValues.dbp || 0) >=
            90) && (
              <div
                style={{
                  padding: "0.7rem 2rem",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1rem",
                  backgroundColor: "#fffee3",
                  borderRadius: "0.7rem",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                    SBP2 (mm/Hg)
                  </p>
                  <input
                    disabled={isRecordClosed}
                    type="number"
                    maxLength="3"
                    style={{
                      border: "none",
                      outline: "none",
                      borderBottom: "1px solid gray",
                      background: "transparent",
                    }}
                    name="sbp2"
                    value={examinationValues.sbp2 || ""}
                    onChange={handleChange}
                  />
                  {examinationValues.errorMessage &&
                    examinationValues.errorMessageField === "sbp2" && (
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {examinationValues.errorMessage}
                      </span>
                    )}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                    DBP2 (mm/Hg)
                  </p>
                  <input
                    disabled={isRecordClosed}
                    type="number"
                    maxLength="3"
                    style={{
                      border: "none",
                      outline: "none",
                      borderBottom: "1px solid gray",
                      background: "transparent",
                    }}
                    name="dbp2"
                    value={examinationValues.dbp2 || ""}
                    onChange={handleChange}
                  />
                  {examinationValues.errorMessage &&
                    examinationValues.errorMessageField === "dbp2" && (
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {examinationValues.errorMessage}
                      </span>
                    )}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                    SBP3 (mm/Hg)
                  </p>
                  <input
                    disabled={isRecordClosed}
                    type="number"
                    maxLength="3"
                    style={{
                      border: "none",
                      outline: "none",
                      borderBottom: "1px solid gray",
                      background: "transparent",
                    }}
                    name="sbp3"
                    value={examinationValues.sbp3 || ""}
                    onChange={handleChange}
                  />
                  {examinationValues.errorMessage &&
                    examinationValues.errorMessageField === "sbp3" && (
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {examinationValues.errorMessage}
                      </span>
                    )}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                    DBP3 (mm/Hg)
                  </p>
                  <input
                    disabled={isRecordClosed}
                    type="number"
                    maxLength="3"
                    style={{
                      border: "none",
                      outline: "none",
                      borderBottom: "1px solid gray",
                      background: "transparent",
                    }}
                    name="dbp3"
                    value={examinationValues.dbp3 || ""}
                    onChange={handleChange}
                  />
                  {examinationValues.errorMessage &&
                    examinationValues.errorMessageField === "dbp3" && (
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {examinationValues.errorMessage}
                      </span>
                    )}
                </div>
              </div>
            )}
        </div>

        {/* questions container */}
        {(examinationValues.averageSBP >= 180 ||
          examinationValues.averageDBP >= 110) && (
            <div
              style={{
                display: activeSection === "Examination" ? "block" : "none",
                backgroundColor: "white",
                borderRadius: "1.75rem",
              }}
            >
              <p
                style={{
                  fontWeight: "400",
                  background: "#f4f4f4",
                  padding: "0.7rem 1rem",
                  borderRadius: "20px 20px 0px 0px",
                  width: "100%",
                }}
              >
                Please check if the patient has these emergency Indications:
              </p>

              <div
                style={{
                  padding: "0.7rem 2rem",
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "0.75rem",
                }}
              >
                <QuestionsAnswers
                  question="Significant Edema"
                  backendData={examinationValues && examinationValues.isEdema}
                  questionState="isEdema"
                  setExaminationValues={setExaminationValues}
                  onClickButton={(option, questionState) =>
                    handleOptionSelection(option, questionState)
                  }
                  historyData={examinationValues["isEdema"]}
                  isRequired={false}
                />
                <QuestionsAnswers
                  question="Breathlessness"
                  backendData={
                    examinationValues && examinationValues.isBreathlessness
                  }
                  questionState="isBreathlessness"
                  setExaminationValues={setExaminationValues}
                  onClickButton={(option, questionState) =>
                    handleOptionSelection(option, questionState)
                  }
                  historyData={examinationValues["isBreathlessness"]}
                  isRequired={false}
                />
                <QuestionsAnswers
                  question="Agina(Chest Pain)"
                  backendData={examinationValues && examinationValues.isChestPain}
                  questionState="isChestPain"
                  setExaminationValues={setExaminationValues}
                  onClickButton={(option, questionState) =>
                    handleOptionSelection(option, questionState)
                  }
                  historyData={examinationValues["isChestPain"]}
                  isRequired={false}
                />
                <QuestionsAnswers
                  question="Deficit (weakness in one or more limbs) or decreased urinary output"
                  backendData={examinationValues && examinationValues.isDeficit}
                  questionState="isDeficit"
                  setExaminationValues={setExaminationValues}
                  onClickButton={(option, questionState) =>
                    handleOptionSelection(option, questionState)
                  }
                  historyData={examinationValues["isDeficit"]}
                  isRequired={false}
                />
                <QuestionsAnswers
                  question="Altered Conciousness"
                  backendData={
                    examinationValues && examinationValues.isAlteredConciousness
                  }
                  questionState="isAlteredConciousness"
                  setExaminationValues={setExaminationValues}
                  onClickButton={(option, questionState) =>
                    handleOptionSelection(option, questionState)
                  }
                  historyData={examinationValues["isAlteredConciousness"]}
                  isRequired={false}
                />
                <QuestionsAnswers
                  question="Fundus if feasible-hemorrhages,exudates,papilledema"
                  backendData={examinationValues && examinationValues.isFundus}
                  questionState="isFundus"
                  setExaminationValues={setExaminationValues}
                  onClickButton={(option, questionState) =>
                    handleOptionSelection(option, questionState)
                  }
                  historyData={examinationValues["isFundus"]}
                  isRequired={false}
                />
                <QuestionsAnswers
                  question="Generalized seizures"
                  backendData={
                    examinationValues && examinationValues.isGeneralizedSeizures
                  }
                  questionState="isGeneralizedSeizures"
                  setExaminationValues={setExaminationValues}
                  onClickButton={(option, questionState) =>
                    handleOptionSelection(option, questionState)
                  }
                  historyData={examinationValues["isGeneralizedSeizures"]}
                  isRequired={false}
                />
              </div>
            </div>
          )}
        {/* sugar readings */}
        {/* working' */}
        <div
          style={{
            display: activeSection === "Examination" ? "block" : "none",
            backgroundColor: "white",
            borderRadius: "1.75rem",
          }}
        >
          <p
            style={{
              fontWeight: "400",
              background: "#f4f4f4",
              padding: "0.7rem 1rem",
              borderRadius: "20px 20px 0px 0px",
              width: "100%",
            }}
          >
            Blood Sugar Readings
          </p>
          <div
            style={{
              padding: "0.7rem 2rem",
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
              backgroundColor: "#fffee3",
              borderRadius: "0.7rem",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                FBS (mg/dL)
              </p>
              <input
                disabled={isRecordClosed}
                type="number"
                style={{
                  border: "none",
                  outline: "none",
                  borderBottom: "1px solid gray",
                  background: "transparent",
                }}
                value={examinationValues.fbs}
                onChange={(event) => {
                  if (DECIMAL_THREE_DOT_TWO_REGEX.test(event.target.value)) {
                    setExaminationValues({
                      ...examinationValues,
                      fbs: event.target.value,
                    });
                  }
                  BloodSugarError(); // Call BloodSugarError function
                }}
              // name="fbs"
              />
              {fbsError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {fbsError}
                </p>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                PPBS (mg/dL)
              </p>
              <input
                disabled={isRecordClosed}
                type="number"
                style={{
                  border: "none",
                  outline: "none",
                  borderBottom: "1px solid gray",
                  background: "transparent",
                }}
                value={examinationValues.ppbs}
                onChange={(event) => {
                  if (DECIMAL_THREE_DOT_TWO_REGEX.test(event.target.value)) {
                    setExaminationValues({
                      ...examinationValues,
                      ppbs: event.target.value,
                    });
                  }
                  BloodSugarError(); // Call BloodSugarError function
                }}
              />
              {ppbsError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {ppbsError}
                </p>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                RBS (mg/dL)
              </p>
              <input
                disabled={isRecordClosed}
                type="number"
                style={{
                  border: "none",
                  outline: "none",
                  borderBottom: "1px solid gray",
                  background: "transparent",
                }}
                value={examinationValues.rbs}
                onChange={(event) => {
                  if (DECIMAL_THREE_DOT_TWO_REGEX.test(event.target.value)) {
                    setExaminationValues({
                      ...examinationValues,
                      rbs: event.target.value,
                    });
                  }
                  BloodSugarError(); // Call BloodSugarError function
                }}
              />
              {rbsError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {rbsError}
                </p>
              )}
            </div>
          </div>

          <div
            style={{
              padding: "0.7rem 2rem",
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
              backgroundColor: "#fffee3",
              borderRadius: "0.7rem",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                HbA1c (%)
              </p>
              <input
                disabled={isRecordClosed}
                type="number"
                style={{
                  border: "none",
                  outline: "none",
                  borderBottom: "1px solid gray",
                  background: "transparent",
                }}
                value={
                  isNaN(examinationValues.hba1c) ? "" : examinationValues.hba1c
                }
                onChange={(event) => {
                  if (DECIMAL_TWO_DOT_TWO_REGEX.test(event.target.value)) {
                    setExaminationValues({
                      ...examinationValues,
                      hba1c: event.target.value,
                    });
                  }
                  BloodSugarError();
                }}
              />
              {hbaError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {hbaError}
                </p>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                Serum Creatinine
              </p>
              <input
                disabled={isRecordClosed}
                type="number"
                style={{
                  border: "none",
                  outline: "none",
                  borderBottom: "1px solid gray",
                  background: "transparent",
                }}
                value={
                  isNaN(examinationValues.creatinine)
                    ? ""
                    : examinationValues.creatinine
                }
                onChange={handleCreatinineChange}
              />
              {serumError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {serumError}
                </p>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                GFR
              </p>
              <input
                disabled={isRecordClosed}
                type="number"
                // maxLength="3"
                style={{
                  border: "none",
                  outline: "none",
                  borderBottom: "1px solid gray",
                  background: "transparent",
                }}
                value={examinationValues.gfr} // Render gfr value from examinationValues
                onChange={handleGfrChange}
              // readOnly
              />
            </div>
          </div>
        </div>

        {/* measurements */}

        <div
          style={{
            display: activeSection === "Examination" ? "block" : "none",
            backgroundColor: "white",
            borderRadius: "1.75rem",
          }}
        >
          <p
            style={{
              fontWeight: "400",
              background: "#f4f4f4",
              padding: "0.7rem 1rem",
              borderRadius: "20px 20px 0px 0px",
              width: "100%",
              width: "100%",
            }}
          >
            Measurements
          </p>
          <div
            style={{
              padding: "0.7rem 2rem",
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              backgroundColor: "#fffee3",
              borderRadius: "0.7rem",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                Height
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  disabled={isRecordClosed}
                  type="radio"
                  style={{ marginRight: "0.5rem" }}
                  value="cm"
                  checked={examinationValues.heightUnit === 0}
                  onChange={handleChangeHeightUnit}
                />
                <label style={{ marginRight: "1rem" }}>CM</label>
                <input
                  disabled={isRecordClosed}
                  type="radio"
                  style={{ marginRight: "0.5rem" }}
                  value="ft"
                  checked={examinationValues.heightUnit === 1}
                  onChange={handleChangeHeightUnit}
                />
                <label style={{ marginRight: "1rem" }}>FT</label>
                {examinationValues.heightUnit === 0 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <label>cm</label>
                        <input
                          disabled={isRecordClosed}
                          className="ExaminationCM"
                          type="number"
                          style={{
                            border: "none",
                            outline: "none",
                            borderBottom: "1px solid gray",
                            background: "transparent",
                          }}
                          value={examinationValues.height || ""}
                          onChange={handleHeightChange}
                        />
                      </div>
                      {/* <div>
                      {error && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {error}
                        </p>
                      )}
                    </div> */}
                    </div>
                  </>
                ) : (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "0.455rem",
                      }}
                    >
                      <label>Ft</label>
                      <input
                        disabled={isRecordClosed}
                        type="number"
                        style={{
                          border: "none",
                          outline: "none",
                          borderBottom: "1px solid gray",
                          background: "transparent",
                          marginRight: "0.5rem",
                        }}
                        placeholder="ft"
                        value={
                          examinationValues.heightFt === "0"
                            ? ""
                            : examinationValues.heightFt
                        }
                        onChange={handleHeightFtChange}
                      />
                      {errorFt && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errorFt}
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "0.455rem",
                      }}
                    >
                      <label>In</label>
                      <input
                        disabled={isRecordClosed}
                        type="number"
                        style={{
                          border: "none",
                          outline: "none",
                          borderBottom: "1px solid gray",
                          background: "transparent",
                        }}
                        placeholder="inches"
                        value={
                          examinationValues.heightIn === "0"
                            ? ""
                            : examinationValues.heightIn
                        }
                        onChange={handleHeightInChange}
                      />
                      {/* {console.log(
                        examinationValues,
                        "examinationValues...........examinationValues....."
                      )} */}
                      {errorIn && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errorIn}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                padding: "0.7rem 2rem",
                display: "flex",
                gap: "1rem",
                backgroundColor: "#fffee3",
                borderRadius: "0.7rem",
                width: "100%",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                  Waist (cm)
                </p>
                <input
                  disabled={isRecordClosed}
                  type="number"
                  style={{
                    border: "none",
                    outline: "none",
                    borderBottom: "1px solid gray",
                    background: "transparent",
                  }}
                  value={examinationValues.waist}
                  onChange={handleWaistChange}
                />
                {errorWaist && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errorWaist}
                  </p>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                  Weight (kg)
                </p>
                <input
                  disabled={isRecordClosed}
                  type="number"
                  style={{
                    border: "none",
                    outline: "none",
                    borderBottom: "1px solid gray",
                    background: "transparent",
                  }}
                  value={examinationValues.weight}
                  onChange={handleWeightChange}
                />
                {error && (
                  <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
                )}
                {errorWeight && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errorWeight}
                  </p>
                )}
              </div>
              <div
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  backgroundColor: getBmiBackgroundColor(examinationValues.bmi),
                  width: "20%",
                  textAlign: "center",

                }}
              >
                <p>BMI (Kg/m²)</p>
                <p>
                  {isNaN(examinationValues?.bmi)
                    ? ""
                    : examinationValues?.bmi?.toFixed(2)}
                </p>
                {/* <p>
                  {examinationValues?.bmi !== "NaN" ||
                    (null && examinationValues?.bmi.toFixed(2))}
                </p> */}
                <p>{getBmiCategory(examinationValues.bmi)}</p>
              </div>
            </div>
          </div>
        </div>

        {/* lab values */}

        <div
          style={{
            display: activeSection === "Examination" ? "block" : "none",
            backgroundColor: "white",
            borderRadius: "1.75rem",
          }}
        >
          <p
            style={{
              fontWeight: "400",
              background: "#f4f4f4",
              padding: "0.7rem 1rem",
              borderRadius: "20px 20px 0px 0px",
              width: "100%",
            }}
          >
            Lab Values
          </p>
          <div
            style={{
              padding: "0.7rem 2rem",
              display: "flex",
              // justifyContent: "space-between",
              gap: "1rem",
              backgroundColor: "#fffee3",
              borderRadius: "0.7rem",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                LDL (mg/dL)
              </p>
              <input
                disabled={isRecordClosed}
                type="number"
                style={{
                  border: "none",
                  outline: "none",
                  borderBottom: "1px solid gray",
                  background: "transparent",
                }}
                value={examinationValues.ldl}
                onChange={handleLdlChange}
              />
              {ldlError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {ldlError}
                </p>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="examinationText" style={{ lineHeight: "2.2rem" }}>
                Total Cholesterol
              </p>
              <input
                disabled={isRecordClosed}
                type="number"
                // maxLength="3"
                name="cholestral"
                style={{
                  border: "none",
                  outline: "none",
                  borderBottom: "1px solid gray",
                  background: "transparent",
                }}
                value={examinationValues.cholestrol}
                onChange={handleCholestrolChange}
              />
              {cholestrolError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {cholestrolError}
                </p>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px"
            // justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: activeSection === "Examination" ? "Flex" : "none",
              justifyContent: "flex-start",
              // width: "100%",
            }}
            // onClick={saveRecord}
            onClick={toggleSection2}
          >
            <button
              style={{
                background: "#5d4a87",
                color: "white",
                border: "none",
                padding: "0.8rem 2rem ",
                borderRadius: "8px",
                height: "40px",
              }}
            >
              Back
            </button>
          </div>
          {filteredRecord.length > 0 && filteredRecord[0].STATUS !== 6 && (
            <div
              style={{
                display: activeSection === "Examination" ? "Flex" : "none",
                justifyContent: "flex-end",
                width: "100%",
              }}
            // onClick={toggleSection4}
            >
              <button
                style={{
                  background: "#5d4a87",
                  color: "white",
                  border: "none",
                  padding: "0.8rem 2rem ",
                  borderRadius: "8px",
                  height: "40px",
                }}
                onClick={handleNextClick}
              >
                Save
                {isLoading && <CircularProgress size={24} color="inherit" />}
              </button>
            </div>
          )}
        </div>
        {/* <ToastContainer autoClose={10} /> */}
      </div>
    </>
  );
};

export default ExaminationTab;
