import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import Navbar from "../Navbar";
import { json, useNavigate } from "react-router-dom";
import "./ManageABHA.css";
import card from "../../../images/card.png";
import qrcode from "../../../images/barcode.png";
import OtpInput from "react-otp-input";
import { Buffer } from "buffer";
import Config from "../../../Config/config";
import { publicEncrypt, constants } from "crypto-browserify";
import Axios from "axios";
import JSEncrypt from "jsencrypt";
import axios from "axios";
import TopNavBar from "../TopNavBar";
import ImageFromByteArray from "./convertInToImage";
import ImageComponent from "./convertInToImage";

const ManageABHA = () => {
  const [screen, setScreen] = useState("manageABHA");
  const [showLoading, setShowLoading] = useState(false);
  const [variables, setVariables] = useState({
    otp: "",
    showAlert: false,
    alertTitle: "",
    alertMessage: "",
  });
  const [showLoadingQRCode, setShowLoadingQRCode] = useState(false);
  const [qrMessage, setQRMessage] = useState(""); // Message state
  const [pngCard, setPNGCard] = useState();
  const [profileData, setProfileData] = useState();
  const [qrCode, setQrCode] = useState();
  const [type, setType] = useState();
  const [qrCodeData, setQRCodeData] = useState("");
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const selectedPatientDetails = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );
  console.log("selectedpatientsss", selectedPatientDetails);
  const patientabhanumber = selectedPatientDetails.ABHA_NUMBER;

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : sessionStorage.getItem("token");

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  // useEffect(() => {
  //   getPublickey();
  // });
  const sendotp = (otp) => {
    setVariables((prevState) => ({
      ...prevState,
      otp: otp,
    }));
  };
  const Encryption = async (publicKey) => {
    // var publicKey = sessionStorage.getItem("ABDMPublicKey") ?? '';
    var padding = constants.RSA_PKCS1_OAEP_PADDING;
    var data = Buffer.from("ABDMPublicKey");
    var encryptedData = publicEncrypt(
      { key: publicKey, padding: padding },
      data
    ).toString("base64");
    sessionStorage.setItem("encryptedpk", encryptedData);
    return encryptedData;
  };

  // Encryption of otp using JSEncrypt
  const otpencrytion = async (otp) => {
    var data = otp;
    // var key = Encryption(sessionStorage.getItem("ABDMPublicKey"));
    var key = sessionStorage.getItem("ABDMPublicKey");
    var jsencrypt = new JSEncrypt();
    jsencrypt.setPublicKey(key);
    var encrypt = jsencrypt.encrypt(data);
    //console.log("encrypted otp :", encrypt);
    return encrypt;
  };

  const ShowAlert = ({ title, message, onClose }) => {
    return (
      <div className="popup">
        <div className="popup-inner">
          <p
            style={{ fontSize: "24px", fontWeight: "600", fontFamily: "bold" }}
          >
            {title}
          </p>
          <p>{message}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2rem",
              padding: "4%",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="ContinueBtn"
              onClick={onClose}
              style={{
                background: "#52447d",
                width: "20%",
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleClose = () => {
    navigate("/patients");
  };

  const handleChange = (otp) => {
    // if (otp.length < 6) {
    //   inputRef.current.focus();
    // }
    setVariables((prevState) => ({
      ...prevState,
      otp: otp,
    }));
  };

  const handleAlertClose = () => {
    setVariables((prevState) => ({
      ...prevState,
      showAlert: false,
    }));
  };

  const getPublickey = async () => {
    Axios.get(`${Config.abhaBaseUrl}publicKey/${UserID}`, {
      headers: {
        authorization: `Bearer ${token}`,
        "x-caller": await encrypted,
      },
    })
      .then((response) => {
        // console.log("getPublickey response :", response);
        if (response.status == 200) {
          // console.log("PUBLICKEY :", response.data);
          sessionStorage.setItem("ABDMPublicKey", response.data);
          return response;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSessionToken = async (buttonType) => {
    try {
      if (buttonType === "abhaCard") {
        setShowLoading(true);
        setType("abhaCard");
      } else if (buttonType === "qrCode") {
        setShowLoadingQRCode(true);
        setType("qrCode");
      }
      await getPublickey();
      const payload = {
        mobile: selectedPatientDetails.MOBILE,
        USER_ID: UserID,
      };
      //console.log(payload, "getSessionTokenForMobile,,,,");

      const response = await Axios.post(
        `${Config.abhaBaseUrl}getSessionTokenForMobile`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            response.data.details.length > 0
              ? response.data.details[0].message
              : response.data.message,
        }));
      } else if (response.status === 200) {
        sessionStorage.setItem("transactionId", response.data.txnId);
        sessionStorage.setItem("accessToken", response.data.accessToken);
        setScreen("verifyOTP");
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
      // setShowLoading(false);
      buttonType === "abhaCard"
        ? setShowLoading(false)
        : setShowLoadingQRCode(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
      // setShowLoading(false);
      buttonType === "abhaCard"
        ? setShowLoading(false)
        : setShowLoadingQRCode(false);
    }
  };

  const getabhacard = async (buttonType) => {
    try {
      if (buttonType === "abhaCard") {
        setShowLoading(true);
        setType("abhaCard");
      } else if (buttonType === "qrCode") {
        setShowLoadingQRCode(true);
        setType("qrCode");
      }
      const payload = {
        mobile: selectedPatientDetails.MOBILE,
        USER_ID: UserID,
      };
      //console.log(payload, "getSessionTokenForMobile,,,,");

      const response = await Axios.post(
        `${Config.abhaBaseUrl}getSessionTokenForMobile`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            response.data.details.length > 0
              ? response.data.details[0].message
              : response.data.message,
        }));
      } else if (response.status === 200) {
        sessionStorage.setItem("transactionId", response.data.txnId);
        sessionStorage.setItem("accessToken", response.data.accessToken);
        setScreen("verifyOTP");
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
      // setShowLoading(false);
      buttonType === "abhaCard"
        ? setShowLoading(false)
        : setShowLoadingQRCode(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
      // setShowLoading(false);
      buttonType === "abhaCard"
        ? setShowLoading(false)
        : setShowLoadingQRCode(false);
    }
  };

  const validateOTP = async () => {
    try {
      setShowLoading(true);
      let encryptedOTP = await otpencrytion(variables.otp);
      var accessToken = (await sessionStorage.getItem("accessToken")) ?? "";
      var transactionId = (await sessionStorage.getItem("transactionId")) ?? "";

      const otpDataBody = {
        otp: encryptedOTP,
        txnId: transactionId,
        accessToken: accessToken,
        USER_ID: UserID,
      };

      const otpResponse = await Axios.post(
        `${Config.abhaBaseUrl}validateOTP`,
        otpDataBody
      );
      // console.log(
      //   "otp",
      //   otpResponse.data.mobileLinkedHid.length > 0,
      //   "status",
      //   otpResponse.status,
      //   "response",
      //   otpResponse
      // );

      if (otpResponse.data && otpResponse.data.mobileLinkedHid.length > 0) {
        //console.log("cmng");
        sessionStorage.setItem("txnId", otpResponse.data.txnId);
        // sessionStorage.setItem("token", otpResponse.data.token);
        sessionStorage.setItem("loginResponse", otpResponse.data);
        sessionStorage.setItem(
          "mobileLinkedHid",
          JSON.stringify(otpResponse.data.mobileLinkedHid)
        );
        if (type === "abhaCard") {
          await getProfileData(0, otpResponse.data);
        } else {
          await getQRCode(0, otpResponse.data);
        }
      } else if (otpResponse.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: otpResponse.data.code,
          alertMessage:
            otpResponse.data.details.length > 0
              ? otpResponse.data.details[0].message
              : otpResponse.data.message,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime",
        }));
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "Invalid OTP!!!",
          alertMessage: "Please enter a valid OTP.",
        }));
      }
      setShowLoading(false);
    }
  };

  const getPNGCard = async (Token) => {
    var accessToken = (await sessionStorage.getItem("accessToken")) ?? "";
    var token = Token ? Token : sessionStorage.getItem("loginData") ?? "";

    const payload = {
      accessToken: accessToken,
      token: Token,
      USER_ID: UserID,
    };
    await Axios.post(`${Config.abhaBaseUrl}getPNGCard`, payload, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
        "x-caller": encrypted,
      },
      responseType: "arraybuffer",
    })
      .then(async (response) => {
        // console.log(Response)
        if (response.status === 200) {
          //console.log("response...................", response);
          let base64ImageString = Buffer.from(response.data, "utf8").toString(
            "base64"
          );
          //console.log("response...................", base64ImageString);
          setPNGCard(base64ImageString);

          setScreen("abhaCard");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // get abha card from uploads backend

  const getuploadsabhacard = async () => {
    const cleanedToken = token.replace(/"/g, '');
    console.log("token of uploads abha card", cleanedToken);
    try {
      const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
      console.log("id OF USER", PATIENT_ID);
      const payload = {
        userId: UserID,
        id: PATIENT_ID,
      };

      console.log("Payload", payload)
      const url = `${Config.apiURL}/patient/fetchAbhaCard?id=${encodeURIComponent(
        PATIENT_ID
      )}&userId=${encodeURIComponent(UserID)}`;
      const response = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${cleanedToken}`,
          "x-caller": await encrypted,
        },
      });
      if (response.status === 200) {
        console.log("response of abha card", response);
        const abhacard = response.data.data[0].ABHA_CARD;
        if (abhacard == null || abhacard == "") {
          await getSessionToken("abhaCard");
        } else {
          await displayabhacard(abhacard);
        }
        // Handle response data
      }
    }
    catch (err) {
      if (err.response) {
        console.log("Error Response:", err.response.data);
      } else {
        console.log("Request Error:", err.message);
      }
    }
  };

  // for displaying abha card pdf
  // working for opening in another window
  const displayabhacard = async (abhacard) => {
    try {
      if (!abhacard) {
        console.error("Error: abhacard is undefined or null");
        return;
      }

      const response = await Axios.get(`${Config.apiURL}/uploads/${abhacard}`, { responseType: "blob" });

      if (response.status === 200) {
        console.log("++++++++++", response);
        console.log("Response data size:", response.data.size);
        // working
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrlBlob = URL.createObjectURL(pdfBlob);
        window.open(pdfUrlBlob, "_blank");
      }
    } catch (error) {
      console.error("Error fetching ABHA card:", error.response ? error.response.data : error.message);
    }
  };

  const getProfileData = async (profileID, LoginResponse) => {
    //console.log("came to profile");
    const accessToken = (await sessionStorage.getItem("accessToken")) ?? "";
    const loginResponse = LoginResponse; //JSON.parse(sessionStorage.getItem('loginResponse')) ?? '';
    //console.log("login res", loginResponse);

    const jsonData = loginResponse;
    // console.log(
    //   "jspn data",
    //   jsonData,
    //   "healthIdNumber",
    //   jsonData.mobileLinkedHid[profileID].healthIdNumber
    // );

    try {
      setShowLoading(true);
      const payload = {
        healthId: jsonData.mobileLinkedHid[profileID].healthIdNumber ?? "",
        txnId: jsonData.txnId ?? "",
        accessToken: accessToken,
        token: jsonData.token,
        USER_ID: UserID,
      };

      const response = await Axios.post(
        `${Config.abhaBaseUrl}getProfileData`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );
      //console.log("response in profile data", response);
      if (response.status === 200) {
        sessionStorage.setItem("profileData", response.data.profileRes);
        sessionStorage.setItem("loginData", response.data.token);
        setProfileData(response.data.profileRes); //this contians the ABHA info of login person
        await getPNGCard(response.data.token);
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "Server down!!! ",
          alertMessage:
            "Unable to get the profile. Please try after some time......",
        }));
      }
    } catch (err) {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "ABHA Server down!!!",
        alertMessage:
          "The ABHA server is down at this time. Please try after sometime-----------.",
      }));
    }
  };

  //get qr code apis

  const getQRCode = async (profileID, LoginResponse) => {
    //console.log("profile id", profileID, "response in qr", LoginResponse);
    const accessToken = (await sessionStorage.getItem("accessToken")) ?? "";
    const loginResponse = LoginResponse; //JSON.parse(sessionStorage.getItem('loginResponse')) ?? '';
    //console.log("login res", loginResponse);

    const jsonData = loginResponse;


    // try {
    setShowLoading(true);
    const payload = {
      healthId: jsonData.mobileLinkedHid[profileID].healthIdNumber ?? "",
      txnId: jsonData.txnId ?? "",
      accessToken: accessToken,
      token: jsonData.token,
      USER_ID: UserID,
    };

    //   const response = await Axios.post(
    //     `${Config.abhaBaseUrl}getQRCodeImage`,
    //     payload,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         authorization: `Bearer ${token}`,
    //         "x-caller": encrypted,
    //       },
    //       responseType: "arraybuffer",
    //     }
    //   );
    //   console.log("response in get qr code fucntion data", response);
    //   if (response.status === 200) {
    //     // sessionStorage.setItem("profileData", response.data.profileRes);
    //     // sessionStorage.setItem("loginData", response.data.token);
    //     // const buffer = response.data;

    //     let base64ImageString = Buffer.from(response.data, "utf8").toString(
    //       "base64"
    //     );
    //     setQrCode(base64ImageString);

    //     setScreen("generateQR");
    //   } else {
    //     console.log("hello............")
    //     setVariables((prevState) => ({
    //       ...prevState,
    //       showAlert: true,
    //       alertTitle: "Server down!!! ",
    //       alertMessage:
    //         "Unable to get the profile. Please try after some time......",
    //     }));
    //   }
    // } catch (err) {
    //   setVariables((prevState) => ({
    //     ...prevState,
    //     showAlert: true,
    //     alertTitle: "ABHA Server down!!!",
    //     alertMessage:
    //       "The ABHA server is down at this time. Please try after sometime-----------.",
    //   }));
    // }
    await Axios.post(`${Config.abhaBaseUrl}getQRCodeImage`, payload, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
        "x-caller": encrypted,
      }, responseType: "arraybuffer",
    })
      .then(async (response) => {
        // console.log(Response)
        if (response.status === 200) {
          // console.log("response...................", response);
          let base64ImageString = Buffer.from(response.data, "utf8").toString(
            "base64"
          );
          //console.log(base64ImageString,'....h.......')
          setQrCode(base64ImageString);

          setScreen("generateQR");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetQRCode = () => {
    setShowLoadingQRCode(true);
    setQRMessage("QR Code is in progress...");

    setTimeout(() => {
      setShowLoadingQRCode(false);
      setQRMessage("QR Code feature is coming soon!"); // Change this when QR code is ready
    }, 2000); // Simulating a delay
  };

  const LinkAbhaFunc = () => {
    //console.log("user", row);
    sessionStorage.setItem("selectedPatientDetails", selectedPatientDetails);
    //console.log(row.USER_ID, "row.USER_ID");
    sessionStorage.setItem("PATIENT_ID", selectedPatientDetails.USER_ID);
    navigate("/ABHA?action=linkabha");
  };

  // ui

  const AbhaManage = () => {
    return (
      <div className="container">
        <div className="column">
          <h2 className="title">ABHA INTEGRATION SERVICE</h2>
          <h2 className="subtitle">ABHA- Ayushman Bharat Health Account</h2>
          <div className="icon-container">
            <div
              className="icon"
              style={{ cursor: "pointer" }}
              onClick={() => setScreen("manageABHA")}
            >
              <img src={card} alt="ABHA Card" />
              <p>ABHA Card</p>
            </div>
            <div
              className="icon"
              onClick={() => setScreen("manageABHA")}
              style={{ cursor: "pointer" }}
            >
              <img src={qrcode} alt="QR Code" />
              <p>QR Code</p>
            </div>
          </div>
          <div className="text-container">
            <p className="text-ABHA">
              ABHA number is a 14 digit number that will uniquely identify you
              as a participant in India's digital healthcare ecosystem. ABHA
              number will establish a strong and trustable identity for you that
              will be accepted by healthcare providers across the country.
              Seamless sign up for PHR (Personal Health Records) applications
              such as ABDM ABHA application for Health data sharing.
            </p>
          </div>
        </div>
        <div className="card">
          <h2 className="subheading-ayshman">Ayushman Bharat Health Account</h2>
          <div className="button-container">
            <Button
              className="button secondary"
              onClick={
                () => (patientabhanumber != "" || patientabhanumber != null) ?
                  getuploadsabhacard() : LinkAbhaFunc()
              }
            >
              {showLoading && <CircularProgress size={24} />}
              ABHA Card
            </Button>
            <Button
              className="button primary"
              onClick={handleGetQRCode} disabled={showLoadingQRCode}
            >
              {showLoadingQRCode && <CircularProgress size={24} />}
              Get QR Code
            </Button>
            {qrMessage && <p>{qrMessage}</p>}
          </div>
          <div className="approved">
            <h3>Official Partner of NHA</h3>
            <img
              src="https://vajiramandravi.s3.us-east-1.amazonaws.com/media/2023/1/9/18/3/21/about_nha_pg_logo.png"
              alt="NHA Logo"
            />
          </div>
        </div>
      </div>
    );
  };

  // const VerifyOTP = ({sendotp}) => {
  //   const [variablesdata, setVariables] = useState({
  //     otp: "",

  //   });
  //   const handleChangedata = (otp) => {
  //     // sendotp(otp)
  //     setVariables((prevState) => ({
  //       ...prevState,
  //       otp: otp,
  //     }));
  //   };
  //   return (
  //     <div className="container">
  //       <div className="column">
  //         <h2 className="title">ABHA INTEGRATION SERVICE</h2>
  //         <h2 className="subtitle">ABHA- Ayushman Bharat Health Account</h2>
  //         <div className="icon-container">
  //           <div className="icon">
  //             <img
  //               src={card}
  //               alt="ABHA Card"
  //               onClick={() => setScreen("manageABHA")}
  //             />
  //             <p>ABHA Card</p>
  //           </div>
  //           <div className="icon">
  //             <img
  //               src={qrcode}
  //               alt="QR Code"
  //               style={{ cursor: "pointer" }}
  //               onClick={() => setScreen("manageABHA")}
  //             />
  //             <p>QR Code</p>
  //           </div>
  //         </div>
  //         <div className="text-container">
  //           <p className="text-ABHA">
  //             ABHA number is a 14 digit number that will uniquely identify you
  //             as a participant in India's digital healthcare ecosystem. ABHA
  //             number will establish a strong and trustable identity for you that
  //             will be accepted by healthcare providers across the country.
  //             Seamless sign up for PHR (Personal Health Records) applications
  //             such as ABDM ABHA application for Health data sharing.
  //           </p>
  //         </div>
  //       </div>
  //       <div className="card">
  //         <h2 className="subheading-ayshman">Ayushman Bharat Health Account</h2>
  //         <p>
  //           Verify OTP to access your ABHA Account Enter the code sent to{" "}
  //           {selectedPatientDetails.MOBILE}.
  //         </p>
  //         <div className="otp-container">
  //           {/* <OtpInput
  //             value={variables.otp}
  //             onChange={handleChange}
  //             numInputs={6}
  //             renderSeparator={<span>{""}</span>}
  //             renderInput={(props) => <input {...props} type="tel" />}
  //             skipDefaultStyles={true}
  //             inputStyle="otp-input"
  //             inputType="number"
  //           /> */}

  //           {/* <OtpInput
  //             value={variables.otp}
  //             onChange={handleChange}
  //             numInputs={6}
  //             renderSeparator={<span>{""}</span>}
  //             renderInput={(props) => <input {...props} type="tel" />}
  //             skipDefaultStyles={true}
  //             inputStyle="otp-input"
  //             inputType="number"
  //           /> */}

  //           <OtpInput
  //             value={variablesdata.otp}
  //             onChange={handleChangedata}
  //             numInputs={6}
  //             renderSeparator={<span>{""}</span>}
  //             renderInput={(props) => <input {...props} type="tel" />}
  //             skipDefaultStyles={true}
  //             inputStyle="otp-input"
  //             inputType="number"
  //           />
  //         </div>
  //         <div className="button-container">
  //           <Button
  //             className="button primary"
  //             // onClick={() => setScreen("abhaCard")}
  //             onClick={validateOTP}
  //           >
  //             {showLoading && <CircularProgress size={24} />}
  //             Verify OTP
  //           </Button>
  //         </div>
  //         <div className="approved">
  //           <h3>APPROVED BY NHA</h3>
  //           <img
  //             src="https://vajiramandravi.s3.us-east-1.amazonaws.com/media/2023/1/9/18/3/21/about_nha_pg_logo.png"
  //             alt="NHA Logo"
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const GenerateQR = () => {
    return (
      <>
        <div className="qr-code">
          <p className="qrname">ABHA INTEGRATION SERVICE</p>
          <p className="subtitle">ABHA- Ayushman Bharat Health Account</p>
        </div>
        <div className="qr-code-container">
          <div className="topnavinContainer">
            <p className="qr-imagename">QR Code</p>
            <Button className="close-button" onClick={handleClose}>
              Close
            </Button>
          </div>

          <img
            id="pngCardImg"
            src={`data:image/png;base64,${qrCode}`}
            style={{ width: 400, height: 350 }}
          />
          {/* <img
            id="qrCodeImg"
            // src={`data:image/png;base64,${qrCode}`}
            src={`data:image/png;base64,${qrCode}`}
            alt="Decoded Image"
            style={{ margin: "10px", width: "100px", height: "100px" }}
          /> */}
        </div>
      </>
    );
  };

  const AbhaCard = () => {
    return (
      <>
        <div className="qr-code-container">
          <div className="topnavinContainer">
            <p className="qr-imagename">ABHA Card</p>
            <Button className="close-button" onClick={handleClose}>
              Close
            </Button>
          </div>

          <div className="details">
            {" "}
            {/* {console.log(pngCard, "pngCard")} */}
            {/* <img
              id="pngCardImg"
              src={data:image/png;base64,${pngCard}}
              alt="Fetched Image"
              style={{ maxWidth: "100%", height: "auto" }}
            /> */}
            {/* {pngCard && <ImageComponent base64String={pngCard} />} */}
            <img
              id="pngCardImg"
              src={`data:image/png;base64,${pngCard}`}
              style={{
                width: 482,
                height: 308,
                background: "none",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {/* <div className="navbar-container">
        <Navbar />
      </div> */}
      <div className="container">
        {screen === "manageABHA" && <AbhaManage />}
        {screen === "generateQR" && <GenerateQR />}
        {screen === "verifyOTP" && (
          <div className="container">
            <div className="column">
              <h2 className="title">ABHA INTEGRATION SERVICE</h2>
              <h2 className="subtitle">ABHA- Ayushman Bharat Health Account</h2>
              <div className="icon-container">
                <div className="icon">
                  <img
                    src={card}
                    alt="ABHA Card"
                    onClick={() => setScreen("manageABHA")}
                  />
                  <p>ABHA Card</p>
                </div>
                <div className="icon">
                  <img
                    src={qrcode}
                    alt="QR Code"
                    style={{ cursor: "pointer" }}
                    onClick={() => setScreen("manageABHA")}
                  />
                  <p>QR Code</p>
                </div>
              </div>
              <div className="text-container">
                <p className="text-ABHA">
                  ABHA number is a 14 digit number that will uniquely identify
                  you as a participant in India's digital healthcare ecosystem.
                  ABHA number will establish a strong and trustable identity for
                  you that will be accepted by healthcare providers across the
                  country. Seamless sign up for PHR (Personal Health Records)
                  applications such as ABDM ABHA application for Health data
                  sharing.
                </p>
              </div>
            </div>
            <div className="card">
              <h2 className="subheading-ayshman">
                Ayushman Bharat Health Account
              </h2>
              <p>
                Verify OTP to access your ABHA Account Enter the code sent to{" "}
                {selectedPatientDetails.MOBILE}.
              </p>
              <div className="otp-container">
                {/* <OtpInput
              value={variables.otp}
              onChange={handleChange}
              numInputs={6}
              renderSeparator={<span>{""}</span>}
              renderInput={(props) => <input {...props} type="tel" />}
              skipDefaultStyles={true}
              inputStyle="otp-input"
              inputType="number"
            /> */}

                {/* <OtpInput
              value={variables.otp}
              onChange={handleChange}
              numInputs={6}
              renderSeparator={<span>{""}</span>}
              renderInput={(props) => <input {...props} type="tel" />}
              skipDefaultStyles={true}
              inputStyle="otp-input"
              inputType="number"
            /> */}

                <OtpInput
                  value={variables.otp}
                  onChange={handleChange}
                  numInputs={6}
                  renderSeparator={<span>{""}</span>}
                  renderInput={(props) => <input {...props} type="tel" />}
                  skipDefaultStyles={true}
                  inputStyle="otp-input"
                  inputType="number"
                />
              </div>
              <div className="button-container">
                <Button
                  className="button primary"
                  // onClick={() => setScreen("abhaCard")}
                  onClick={validateOTP}
                >
                  {showLoading && <CircularProgress size={24} />}
                  Verify OTP
                </Button>
              </div>
              <div className="approved">
                <h3>APPROVED BY NHA</h3>
                <img
                  src="https://vajiramandravi.s3.us-east-1.amazonaws.com/media/2023/1/9/18/3/21/about_nha_pg_logo.png"
                  alt="NHA Logo"
                />
              </div>
            </div>
          </div>
        )}
        {screen === "abhaCard" && <AbhaCard />}
      </div>
      {variables.showAlert && (
        <ShowAlert
          title={variables.alertTitle}
          message={variables.alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default ManageABHA;
