import React, { useState, useEffect } from "react";
import "./TreatmentPlan.css";
import axios from "axios";
import Config from "../../../Config/config";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

const Diabetes = ({ ...props }) => {
  //console.log(props, " =- =TEMPPPP- =- ");

  const { onFormSubmit } = props;

  const formDatas = {
    drugClass: "",
    drugName: "",
    strength: "",
    frequency: "",
    instruction: "",
    quantity: "",
    reason: "",
    remarks: "",
  };

  const checkedformData = {
    drugClass: "",
    drugName: "",
    strength: "",
    frequency: "",
    instructions: "",
    duration: "",
    reason: "",
  };
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");
  const VISIT_ID = sessionStorage.getItem("VISIT_ID");

  const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
  // //console.log("patieeeeeeeeeee", PATIENT_ID)
  function formatVisitDate(visitDate) {
    // Parse the initial visit date string into a Date object
    const initialDate = new Date(visitDate);

    // Extract the date and time components
    const year = initialDate.getFullYear();
    const month = String(initialDate.getMonth() + 1).padStart(2, "0");
    const day = String(initialDate.getDate()).padStart(2, "0");
    const hours = String(initialDate.getHours()).padStart(2, "0");
    const minutes = String(initialDate.getMinutes()).padStart(2, "0");
    const seconds = String(initialDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(initialDate.getMilliseconds()).padStart(5, "0");

    // Construct the formatted visit date string
    const formattedVisitDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    //console.log("======----formated", formattedVisitDateString);

    return formattedVisitDateString;
  }

  const selectedPatientHistory = sessionStorage.getItem(
    "selectedPatientHistory"
  )
    ? JSON.parse(sessionStorage.getItem("selectedPatientHistory"))
    : [];
  // console.log(
  //   "selectedPatientHistory----------",
  //   selectedPatientHistory.VISIT_DATE
  // );
  const [formData, setFormData] = useState(formDatas);
  const [status, setStatus] = useState(false);
  const [Data, setData] = useState({
    drugList: [],
    antiDiabeticDrugList: [],
    antiHypertensiveDrugList: [],
    // insulinDrugList: [],
    // aspirinDrugList: [],
    // lipidLoweringDrugList: [],
    // isHypertension: "", //medical history data
    // isDiabetes: "",
  }); //medical history data
  const [visitData, setVisitData] = useState();

  const VISITDATA = {
    patientId: selectedPatientHistory.PATIENT_ID,
    mobile: selectedPatientHistory.MOBILE,
    nurseId: selectedPatientHistory.NURSE_USER_ID,
    doctorId: selectedPatientHistory.DOCTOR_USER_ID,
    visitId: selectedPatientHistory.VISIT_ID,
    visitDate: formatVisitDate(selectedPatientHistory.VISIT_DATE),
    medicalHistory: {
      isDiabetes: null,
      isHypertension: null,
      isCKD: null,
      isLiverFailure: null,
      isCOPD: null,
      isAsthma: null,
      isHeartDisease: null,
      isStroke: null,
      isPVD: null,
      isHeartAttack: null,
      isFamilyHypertension: null,
      isFamilyDiabetes: null,
      isFamilyCardioVascular: null,
      isFamilyOtherDisease: null,
      cheifComplaint: [],
      otherChiefComplaint: null,
    },
    drugHistory: {
      isHypertensiveDrugs: null,
      isDiabeticDrugs: null,
      isLipidDrugs: null,
      isAsprin: null,
      isInsulin: null,
      currentHTDrugs: [],
      currentDBDrugs: [],
      currentInsulin: [],
      currentAsprin: [],
      currentLipid: [],
    },
    personalHistory: {
      isPhysicalActivity: null,
      isSmoking: null,
      isDrinking: null,
      drinkingFrequency: null,
      isFamilyHistoryOfMI: null,
      isPolyuria: null,
      isPolydypsia: null,
      isWeightloss: null,
      isOnDialysis: null,
    },
    examinationHistory: {
      sbp: null,
      dbp: null,
      heartRate: null,
      sbp2: null,
      dbp2: null,
      sbp3: null,
      dbp3: null,
      averageSBP: null,
      averageDBP: null,
      fbs: null,
      ppbs: null,
      rbs: null,
      hba1c: null,
      waist: null,
      height: null,
      weight: null,
      bmi: null,
      ldl: null,
      creatinine: null,
      cholestrol: null,
      isEdema: null,
      isBreathlessness: null,
      isChestPain: null,
      isDeficit: null,
      isAlteredConciousness: null,
      isFundus: null,
      isGeneralizedSeizures: null,
      gfr: null,
    },
    htDiagnosis: {
      diagnosis: null,
      control: null,
      recommendation: null,
      notes: null,
      followup: null,
      doctorAcceptance: null,
      doctorNotes: null,
      doctorId: null,
      systemDecision: null,
    },
    dbDiagnosis: {
      diagnosis: null,
      control: null,
      recommendation: null,
      notes: null,
      followup: null,
      doctorAcceptance: null,
      doctorNotes: null,
      doctorId: null,
      systemDecision: null,
    },
    copdDiagnosis: [],
    tbDiagnosis: [],
    copdHistory: {
      isCopd: null,
      isProgressiveDyspnoea: null,
      isChronicCough: null,
      isChronicSputum: null,
      isChestPain: null,
      isFever: null,
      isWeightLoss: null,
      isOrthopnoea: null,
      isParoxysmalNocturnal: null,
      isHaemoptysis: null,
      isSpirometeryTest: null,
      spirometeryValue: null,
      isSmoking: null,
      isBiomassFuel: null,
      isPollution: null,
      mmrcGrade: null,
      isDifficultInBreathing: null,
      isExacerbation: null,
      isAdmitHospitalWithExacerbation: null,
      severeBreathingDifficulty: null,
      exacerbationSymptoms: [],
      severeExacerbationSymptoms: [],
      drugOptions: [],
    },

    tbHistory: {
      edTBType: [],
      presumptiveTBType: [],
      suspectedTBSymptoms: [],
      suspectedComorbidities: [],
      suspectedOtherTBDrugs: [],
      tbPreviousDrugList: [],
      tbCurrentDrugList: [],
      tbAdditionalDrugList: [],
    },
    osteoArthritisHistory: {
      oaAComorbidList: [],
      oaPharmaList: [],
      oaLifestyleList: [],
      oaNonPharmaList: [],
    },
    orderTest: {
      clinicalPathology: [],
      radiology: [],
      bioChemistry: [],
      stoolAnalysis: [],
      serology: [],
      urineAnalysis: [],
      cardiology: [],
    },
  };

  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [medicalHistoryData, setMedicalHistoryData] = useState();
  const [drugHistory, setDrugHistory] = useState(VISITDATA.drugHistory);

  const [drugOptions, setDrugOptions] = useState({
    selectedDrugName: "", // Change initialization from "" to null
    className: "",
    frequencyOptions: [],
    strengthOptions: [],
    selectedStrength: null,
    selectedFrequency: null,
    currentDose: 0,
    strength: 0,
    currentDrugs: [],
  });

  const [filteredRecord, setFilteredRecord] = useState();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#B4B4B4" : provided.borderColor, // Set border color when focused
      boxShadow: state.isFocused ? "0 0 0 1px #B4B4B4" : "none", // Set box shadow when focused
      "&:hover": {
        borderColor: "#B4B4B4", // Maintain border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#D6E5FF"  // Light blue when selected
        : state.isFocused && "White",    // White when not selected or hovered
      color: "black",
      "&:hover": {
        backgroundColor: "#D6E5FF", // Ensure consistent hover behavior
        color: "black",
      },
      boxShadow: "none", // Remove box shadow on focus within options
      boxSizing: "border-box",
      padding: "8px 12px",
      width: "100%",
      margin: "0",
    }),
    menu: (provided) => ({
      ...provided,
      width: 'auto',
      minWidth: '100%', // Ensures the full width is considered
    }),
  };


  useEffect(() => {
    const fetchDataAndSetDrugHistory = async () => {
      try {
        let data = JSON.stringify({
          userId: UserID,
          id: PATIENT_ID,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-caller": encrypted,
          },
          data: data,
        };

        const response = await axios.request(config);
        //console.log("Response from ..:", response, response.data.data);

        const sessionVisitID = parseInt(VISIT_ID, 10);

        const filteredRecords = response.data.data.filter((record) => {
          return record.VISIT_ID === sessionVisitID;
        });
        setFilteredRecord(filteredRecords);
        //console.log("Filtered Records:", filteredRecords, typeof VISIT_ID);

        // if (response.data.data === null || response.data.data.length === 0 || response.data.data[0].VISIT_DATA === null) {
        //   setVisitData(VISITDATA);
        // }
        if (filteredRecords[0].VISIT_DATA === null) {
          //console.log("=in useeffect if=");
          setVisitData(VISITDATA);
        } else {
          let res = await filteredRecords[0].VISIT_DATA;
          //console.log("---res----", res);

          let DrugHistoryData = res ? JSON.parse(res).drugHistory : null;
          let MedicalHistoryData = res ? JSON.parse(res).medicalHistory : null;
          setMedicalHistoryData(MedicalHistoryData);
          //console.log("data===", MedicalHistoryData);

          let parsedRes = !!res ? JSON.parse(res) : null;
          setVisitData(parsedRes);

          if (DrugHistoryData !== null) {
            const parsedDrugData = JSON.parse(DrugHistoryData);
            const parsedMedicalData = JSON.parse(MedicalHistoryData);
            // console.log(
            //   "parseddrugdata",
            //   parsedDrugData,
            //   "parsedMedicalData",
            //   parsedMedicalData.isDiabetes
            // );

            setDrugHistory({
              ...drugHistory,
              isHypertensiveDrugs: parsedDrugData.isHypertensiveDrugs,
              isDiabeticDrugs: parsedDrugData.isDiabeticDrugs,
              currentHTDrugs: modifyDrugArray(parsedDrugData.currentHTDrugs),
              currentDBDrugs: modifyDrugArray(parsedDrugData.currentDBDrugs),
            });

            setData((prevData) => ({
              ...prevData,
              isHypertension: parsedMedicalData?.isHypertension || false,
              isDiabetes: parsedMedicalData?.isDiabetes || false,
            }));
          }
        }
      } catch (err) {
        console.log(err, "err");
      }
    };

    fetchDataAndSetDrugHistory();
  }, [VISIT_ID]);

  const handleDrugTypeChange = (isChecked) => {
    setStatus(isChecked);

    if (isChecked) {
      setOptions(Data.antiDiabeticDrugList);
    } else {
      setOptions([]);
    }
    //console.log("Selected values:", formData);
  };
  //console.log("setOptions", options);

  // Function to modify drug arrays
  const modifyDrugArray = (drugArray) => {
    return drugArray.map((drug) => {
      if (Number.isInteger(drug.doseCeiling)) {
        drug.doseCeiling = `${drug.doseCeiling}.0`;
      } else if (Number.isFinite(drug.doseCeiling)) {
        drug.doseCeiling = `${drug.doseCeiling}`;
      }

      // if (!/\./.test(drug.doseUnit)) {
      //   drug.doseUnit += ".0";
      // }
      if (drug.doseUnit !== null && !/\./.test(drug.doseUnit)) {
        drug.doseUnit += ".0";
      }

      if (Number.isInteger(drug.currentDose)) {
        drug.currentDose = `${drug.currentDose}.0`;
      } else if (Number.isFinite(drug.currentDose)) {
        drug.currentDose = `${drug.currentDose}`;
      }

      return drug;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const drugList = await getDrugList();
      //console.log("Drug List:", drugList);
      // const convertedItems = drugList.map((element) => ({
      const convertedItems = (drugList || []).map((element) => ({
        id: element.id,
        className: element.className,
        drugName: element.name,
        strengthButtons: [
          (element.doseUnit || 0) / 2 + " " + (element.unit || ""),
          (element.doseUnit || 0) + " " + (element.unit || ""),
          (element.doseUnit || 0) * 1.5 + " " + (element.unit || ""),
          (element.doseUnit || 0) * 2 + " " + (element.unit || ""),
        ],
        frequencyButtons: ["Once", "Twice", "Thrice", "Four"],
        specialInstructionsButtons: ["Before food", "After food"],
        recommendedDose: element.recommendedDose,
        doseCeiling: element.doseCeiling,
        unit: element.unit,
        drugProperties: element,
        diseaseType: element.diseaseType,
      }));

      setData((prevData = {}) => ({
        ...prevData,
        // isHypertension: medicalData?.isHypertension || false,
        // isDiabetes: medicalData?.isDiabetes || false,
        drugList: drugList || [],
        antiDiabeticDrugList: convertedItems.filter(
          (i) => i.diseaseType === "Diabetes" && i.drugName !== "Aspirin"
        ),
        antiHypertensiveDrugList: convertedItems.filter(
          (i) =>
            i.diseaseType === "Hypertension" &&
            i.drugName !== "Aspirin" &&
            i.className !== "Statin"
        ),
      }));
    };

    fetchData();
  }, []);

  useEffect(() => {
    getCurrentDose();
    // console.log(
    //   "drugOptions.selectedFrequency:",
    //   drugOptions.selectedFrequency
    // );
  }, [drugOptions.selectedFrequency, drugOptions.selectedStrength, inputValue]);
  const getDrugList = async () => {
    try {
      // const username = "tiatech-tele";
      // const password = "cds$Tia2221";
      // const basicAuth = "Basic " + btoa(`${username}:${password}`);
      const response = await axios.get(
        `${Config.abhaBaseUrl}getDrugsData/${UserID}`, ///${basicAuth}
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      );
      //console.log("hhhhhhhhhhh", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching drug data:", error.message);
      // throw error;
    }
  };
  //console.log("Dataaaa", Data);
  const DropdownIndicator = (props) => {
    return (
      <svg
        width="40px"
        height="30px"
        viewBox="0 0 24 24"
        cursor="pointer"
        fill="#8873C9"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
          fill="#8873C9"
        />
      </svg>
    );
  };
  // const handleDrugChange = (selectedOption) => {
  //   if (selectedOption === null) {
  //     setDrugOptions({
  //       className: "",
  //       frequencyOptions: [],
  //       strengthOptions: [],
  //       drugNames: [],
  //       selectedDrugName: "",
  //     });
  //     return;
  //   }

  //   const selectedClassName = selectedOption.label;

  //   const listOfDrugs = options.filter(
  //     (drug) => drug.className === selectedClassName
  //   );
  //   console.log("listOfDrugs", listOfDrugs);

  //   if (listOfDrugs.length > 0) {
  //     const updatedOptions = {
  //       className: selectedClassName,
  //       drugNames: listOfDrugs,
  //     };

  //     setDrugOptions(updatedOptions);
  //     console.log("updatedOptions", updatedOptions);
  //   } else {
  //     console.log("No drugs found for the selected class.");
  //   }
  // };
  const handleDrugChange = (name) => (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
    if (selectedOption === null) {
      //console.log("Selected option is null, resetting drug options.");
      setDrugOptions({
        className: "",
        frequencyOptions: [],
        strengthOptions: [],
        drugNames: [],
        selectedDrugName: "",
      });
      return;
    }

    const selectedClassName = selectedOption.value;
    //console.log("Selected class name:", selectedClassName);

    const drugsOfClass = options.filter(
      (drug) => drug.className === selectedClassName
    );
    //console.log("List of drugs for selected class:", drugsOfClass);

    const uniqueDrugNames = [
      ...new Set(drugsOfClass.map((drug) => drug.drugName)),
    ];
    //console.log("Unique drug names:", uniqueDrugNames);

    setDrugOptions({
      className: selectedClassName,
      drugNames: uniqueDrugNames.map((name) => ({ value: name, label: name })),
      selectedDrugName: "",
      frequencyOptions: [],
      strengthOptions: [],
    });
  };

  const handleDrugNameChange = (name) => (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
    if (selectedOption === null) {
      //console.log("Selected drug name is null.");
      return;
    }
    const selectedDrug = options.find(
      (drug) => drug.drugName === selectedOption.value
    );

    if (selectedDrug) {
      const newStrengthOptions = selectedDrug.strengthButtons
        ? selectedDrug.strengthButtons.map((strength) => ({
          value: strength,
          label: strength,
        }))
        : [];

      const newFrequencyOptions = selectedDrug.frequencyButtons
        ? Object.values(selectedDrug.frequencyButtons).map((frequency) => ({
          value: frequency,
          label: frequency,
        }))
        : [];
      const newInstructionOptions = selectedDrug.specialInstructionsButtons
        ? Object.values(selectedDrug.specialInstructionsButtons).map(
          (frequency) => ({
            value: frequency,
            label: frequency,
          })
        )
        : [];

      setDrugOptions((prevOptions) => ({
        ...prevOptions,
        selectedDrugName: selectedOption.value,
        frequencyOptions: newFrequencyOptions,
        strengthOptions: newStrengthOptions,
        instructionsOptions: newInstructionOptions,
      }));
    } else {
      //console.log("Selected drug is not found.");
    }
  };

  const frequencyMapping = {
    Once: 1,
    Twice: 2,
    Thrice: 3,
    Four: 4,
  };
  function getCurrentDose() {
    if (drugOptions.selectedStrength && drugOptions.selectedFrequency) {
      const strengthMatch = drugOptions.selectedStrength.match(/\d+(\.\d+)?/);

      if (strengthMatch) {
        const strengthValue =
          drugOptions.selectedStrength !== "other"
            ? parseFloat(strengthMatch[0])
            : "";

        //console.log("strengthValue", strengthValue);
        const frequencyMultiplier =
          frequencyMapping[drugOptions.selectedFrequency] || 1;
        //console.log("frequencyMultiplier", frequencyMultiplier);

        if (!isNaN(strengthValue) && !isNaN(frequencyMultiplier)) {
          let result = strengthValue * frequencyMultiplier;

          // Check if inputValue is a valid number and add it to the result
          const insulinValue = parseFloat(inputValue);
          if (!isNaN(insulinValue)) {
            result += insulinValue;
          }

          //console.log("Final result:", result);
          setDrugOptions({ ...drugOptions, currentDose: result });
        } else {
          //console.log("Invalid strength or frequency value");
        }
      }
    } else {
      //console.log("Either strength or frequency is null");
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.drugName === "" || formData.className === "" || formData.strength === ""
      || formData.frequency === "" || formData.instruction === "" || formData.quantity === "") {
      toast.error("Please enter the required fields");
      return;
    }
    const selectedDrugInfo = {
      drugClass: drugOptions.className,
      drugName: drugOptions.selectedDrugName,
      strength: drugOptions.selectedStrength,
      frequency: drugOptions.selectedFrequency,
      instruction: drugOptions.selectedInstruction,
      // duration: formData.duration,
    };

    //console.log("Selected drugInfo:", selectedDrugInfo);

    //durgadd session
    var existingList = sessionStorage.getItem("addingDrugList");
    var drugList = existingList ? JSON.parse(existingList) : [];
    var uniqueIdentifier = formData.drugName; // Replace with the appropriate property
    var isDuplicate =
      drugList.some(
        (item) => item.className === uniqueIdentifier
      ); // Modify 'id' accordingly

    console.log(existingList, "Item added to the list:", formData, drugList);


    if (!isDuplicate) {
      console.log(existingList, "Item added to the list:", formData, drugList);
      drugList.push(formData);
      sessionStorage.setItem("addingDrugList", JSON.stringify(drugList));
    } else {
      console.log(existingList, "Duplicate item not added:", formData, drugList);
    }

    // var existingList = sessionStorage.getItem("addingDrugList");
    // var drugList = existingList ? JSON.parse(existingList) : [];
    // var uniqueIdentifier = formDatas.id;
    // var isDuplicate = drugList.some(item => item.id === uniqueIdentifier && item.drugName===formData.drugName); // Modify 'id' accordingly
    // if (!isDuplicate) {
    //   drugList.push(formDatas);
    //   sessionStorage.setItem("addingDrugList", JSON.stringify(drugList));
    //   console.log("Item added to the list:", formDatas);
    // } else {
    //   console.log("Duplicate item not added:", formDatas);
    // }
    //
    //console.log("HypertensionFOrmData", formData);

    // onFormSubmit(formData, selectedDrugInfo);

    setFormData(formDatas);
    toast.success("Drug added  Successfully!")
  };

  const handleStrengthChange = (name) => (selectedDrugName) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedDrugName ? selectedDrugName.value : "",
    }));
    //console.log("selectedOption", selectedDrugName);
    setDrugOptions({
      ...drugOptions,
      selectedStrength: selectedDrugName ? selectedDrugName.value : null,
    });
  };
  const handleFrequencyChange = (name) => (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
    //console.log("selectedoption", selectedOption);
    setDrugOptions({
      ...drugOptions,
      selectedFrequency: selectedOption ? selectedOption.value : null,
    });
  };
  const handleInstructionChange = (name) => (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
    //console.log("selectedoption", selectedOption);
    setDrugOptions({
      ...drugOptions,
      selectedInstruction: selectedOption ? selectedOption.value : null,
    });
  };

  return (
    <>
      <div className="Custom-plan">
        <p>Custom plan</p>
        <label htmlFor="drugSelection">
          <input
            type="checkbox"
            id="drugSelection"
            checked={status}
            onChange={(e) => handleDrugTypeChange(e.target.checked)}
          />{" "}
          {""} Select from list of drugs
        </label>
      </div>

      <div className="Hypertension-section">
        <form className="Hypertension-form" onSubmit={handleSubmit}>
          {status ? (
            <>
              <div className="personalInfoContainer">
                <div className="nameandclass">
                  <div className="checkdrugname">
                    <Select
                      id="stateDropdowns"
                      placeholder="Drug Class"
                      isSearchable
                      isClearable
                      options={options.reduce((acc, drug) => {
                        const existingClass = acc.find(
                          (option) => option.label === drug.className
                        );
                        if (!existingClass) {
                          acc.push({
                            value: drug.className,
                            label: drug.className,
                          });
                        }
                        return acc;
                      }, [])}
                      components={DropdownIndicator}
                      onChange={handleDrugChange("className")}
                      value={formData.className ? { value: formData.className, label: formData.className } : null}
                      styles={customStyles}
                    />
                  </div>

                  <div className="checkdrugname">
                    <Select
                      id="stateDropdowns"
                      placeholder="Drug Name"
                      isSearchable
                      isClearable
                      options={drugOptions.drugNames}
                      components={DropdownIndicator}
                      onChange={handleDrugNameChange("drugName")}
                      value={formData.drugName ? { value: formData.drugName, label: formData.drugName } : null}
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className="strengthandfrequncy">
                  <div className="checkstrength">
                    <Select
                      id="stateDropdowns"
                      placeholder="Strength"
                      isSearchable
                      options={drugOptions.strengthOptions}
                      components={DropdownIndicator}
                      onChange={handleStrengthChange("strength")}
                      value={formData.strength ? { value: formData.strength, label: formData.strength } : null}
                      styles={customStyles}
                    />
                  </div>

                  <div className="checkstrength">
                    <Select
                      id="stateDropdowns"
                      placeholder="Frequency"
                      isSearchable
                      styles={customStyles}
                      components={DropdownIndicator}
                      options={drugOptions.frequencyOptions}
                      value={formData.frequency ? { value: formData.frequency, label: formData.frequency } : null}
                      onChange={handleFrequencyChange("frequency")}
                    />
                  </div>
                </div>
                <div className="Instruction">
                  <div className="instructionanddays">
                    <Select
                      id="stateDropdowns"
                      placeholder="Instructions"
                      isSearchable
                      styles={customStyles}
                      components={DropdownIndicator}
                      options={drugOptions.instructionsOptions}
                      onChange={handleInstructionChange("instruction")}
                      value={formData.instruction ? { value: formData.instruction, label: formData.instruction } : null}
                    />
                  </div>
                  <div className="days">
                    <input
                      style={{ backgroundColor: "white", height: "64px" }}
                      type="text"
                      name="quantity"
                      value={formData.quantity}
                      onChange={handleChange}
                      placeholder="Duration in Days"
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="TreatmentplanInputBox">
                <input
                  type="text"
                  name="drugName"
                  value={formData.drugName}
                  onChange={handleChange}
                  placeholder="Drug Name*"
                  required
                />
                <input
                  type="number"
                  name="strength"
                  value={formData.strength}
                  onChange={handleChange}
                  placeholder="Strength*"
                  required
                  className="TreatmentplanInputBoxnumbers"
                />
              </div>

              <div className="frequncyinstructions">
                <select
                  className="Treatmentplandropdown"
                  name="frequency"
                  value={formData.frequency}
                  onChange={handleChange}
                >
                  <option value="">Select Frequency</option>
                  <option value="once">Once</option>
                  <option value="twice">Twice</option>
                  <option value="thrice">Thrice</option>
                  <option value="four">Four</option>
                </select>

                <select
                  className="Treatmentplandropdown"
                  name="instruction"
                  value={formData.instruction}
                  onChange={handleChange}
                >
                  <option value="">Select Instructions*</option>
                  <option value="beforefood">Before Food</option>
                  <option value="afterfood">After Food</option>
                </select>
              </div>
              <div className="TreatmentplanInputBox">
                <input
                  type="number"
                  name="quantity"
                  value={formData.quantity}
                  onChange={handleChange}
                  placeholder="Duration (in days)"
                  className="TreatmentplanInputBoxnumbers"
                />

                <input
                  type="text"
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                  placeholder="Reason"
                />
              </div>
              <div>
                <input
                  type="text"
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                  placeholder="Remarks"
                />
              </div>
            </>
          )}
          <button className="add" type="submit">
            ADD
          </button>
          <ToastContainer />
        </form>
      </div>
    </>
  );
};

export default Diabetes;
