import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import "../pages/AppointmentsPatientList.css"

const Calendar = ({ onDateChange }) => {
  const [value, setValue] = React.useState(new Date());
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        defaultValue={dayjs(value)}
        orientation="portrait"
        openTo="day"
        // value={value}
        onChange={(newValue) => {
          onDateChange(newValue.$d);
          //console.log(newValue.$d, 'newValue');
        }}
        textField={(params) => {
          <TextField {...params} />;
        }}
      />
    </LocalizationProvider>
  );
};

export default Calendar;
