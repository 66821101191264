import React from "react";
import { useState, useEffect } from "react";
import config from "../Config/config";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import axios from 'axios';
import downArrow from "../images/downArrow.svg";
import { Button, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";


const FacilityInfoDetails = () => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({
    MEDICINE: [],
  });
  const [selectedOption, setSelectedOption] = useState("");

  // const [modalOpen, setModalOpen] = useState({ dentistry: false, physiotherapy: false });
  const [variables, setVariables] = useState({
    bedswithoxy: "",
    bedswithoutoxy: "",
    bedswithvent: "",
    bedswithoutvent: "",
    hdubedvent: "",
    hduwithoutvent: "",
    totalbeds: "",
    totalvent: "",
  });

  const [specialitiesMap, setSpecialitiesMap] = useState({});

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));

  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  // const handleOpen = (type) => {
  //   setModalOpen((prev) => ({ ...prev, [type]: true }));
  // };

  // const handleCancel = (type) => {
  //   setModalOpen((prev) => ({ ...prev, [type]: false }));
  // };

  // const handleOk = (type) => {
  //   setModalOpen((prev) => ({ ...prev, [type]: false }));
  // };

  // const renderServiceModal = (serviceType, open) => (
  //   <Dialog open={open} style={{ width: "100%", alignItems: "center" }}>
  //     <DialogTitle className="multisymptoms">Select {serviceType}</DialogTitle>
  //     <DialogContent>
  //       <p>Select the type of {serviceType.toLowerCase()} you'd like to add.</p>
  //       {/* You can add dynamic content here */}
  //     </DialogContent>
  //     <DialogActions>
  //       <Button onClick={() => handleCancel(serviceType.toLowerCase())}>Cancel</Button>
  //       <Button onClick={() => handleOk(serviceType.toLowerCase())}>OK</Button>
  //     </DialogActions>
  //   </Dialog>
  // );


  useEffect(() => {
    fetchAllSpecialities();
  }, []);

  const toggleOptions = (option) => {
    setSelectedOption(option);
  };

  const handleInputChange = (e, field) => {
    // const { value } = e.target;

    // // Allow only numbers (empty string allows backspace deletion)
    // if (/^\d*$/.test(value)) {
    //   setvariables({
    //     ...variables,
    //     [ctrlName]: value,  // Dynamically update the correct field
    //   });
    // }
    const { value } = e.target;
    setVariables((prevVariables) => {
      const newVariables = { ...prevVariables, [field]: value };

      // Update total beds with beds that include oxygen, without oxygen, and ventilators
      if (field === "bedswithoxy" || field === "bedswithoutoxy" ||
        field === "bedswithvent" || field === "bedswithoutvent" ||
        field === "hdubedvent" || field === "hduwithoutvent") {
        const bedsWithOxy = parseInt(newVariables.bedswithoxy || 0, 10);
        const bedsWithoutOxy = parseInt(newVariables.bedswithoutoxy || 0, 10);
        const bedsWithVent = parseInt(newVariables.bedswithvent || 0, 10);
        const bedsWithoutVent = parseInt(newVariables.bedswithoutvent || 0, 10);
        const hduWithVent = parseInt(newVariables.hdubedvent || 0, 10);
        const hduWithoutVent = parseInt(newVariables.hduwithoutvent || 0, 10);

        // Calculate total beds including oxygen and ventilators
        newVariables.totalbeds = bedsWithOxy + bedsWithoutOxy + bedsWithVent + bedsWithoutVent + hduWithVent + hduWithoutVent;
        sessionStorage.setItem("totalbeds", newVariables.totalbeds);
        sessionStorage.setItem("bedsWithOxy", newVariables.bedswithoxy);
        sessionStorage.setItem("hduWithoutVent", newVariables.bedswithvent);
        sessionStorage.setItem("bedsWithVent", newVariables.bedswithoutvent);
        sessionStorage.setItem("bedsWithoutVent", newVariables.hdubedvent);
        sessionStorage.setItem("hduWithVent", newVariables.hduwithoutvent);
        sessionStorage.setItem("bedsWithoutOxy", newVariables.bedswithoutoxy);
      }

      // Update total ventilators
      if (field === "bedswithvent" || field === "bedswithoutvent" ||
        field === "hdubedvent" || field === "hduwithoutvent") {
        const bedsWithVent = parseInt(newVariables.bedswithvent || 0, 10);
        const hduWithVent = parseInt(newVariables.hdubedvent || 0, 10);
        newVariables.totalvent = bedsWithVent + hduWithVent; // Only ventilators from ICU and HDU beds
        sessionStorage.setItem("totalvent", newVariables.totalvent);
        sessionStorage.setItem("bedsWithVent", newVariables.bedswithvent);
        sessionStorage.setItem("hduWithVent", newVariables.hdubedvent);
      }

      return newVariables;
    });
  };

  const fetchAllSpecialities = async () => {
    const selectedMedicineCodes = JSON.parse(sessionStorage.getItem("selectedMedicineCodes") || "[]");
    console.log("selected codes of all", selectedMedicineCodes)

    for (const code of selectedMedicineCodes) {
      await hfrspecialities(code);
    }

    // setIsModalOpen(true);
  };

  // API CALL FOR Hfr specialities
  const hfrspecialities = async (selectedmedicinecode) => {
    console.log("came to hfr specialities");
    // const selectedmedicinecode = JSON.parse(sessionStorage.getItem("selectedMedicineCodes") || "[]")[0] || "";
    console.log("selectedmedicinecodes", selectedmedicinecode);
    try {
      setLoading(true);
      const dataBody = {
        "systemOfMedicineCode": selectedmedicinecode,
      };
      console.log("data body", dataBody);
      const response = await axios.post(
        `${config.abhaBaseUrl}v3/hfrSpecialities`,
        dataBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            "x-caller": await encrypted,
            "x-client-token": config.token,
          },
        }
      );

      if (response.status === 422) {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setLoading(false);
        // setShowLoading(false)
      } else if (response.status === 200) {
        console.log("HFR specialities response success", response);
        // setSpecialities((prev) => [...prev, ...response.data.data.data]);
        const key = selectedmedicinecode.charAt(0);
        setSpecialitiesMap((prev) => ({
          ...prev,
          [key]: response.data.data.data, // Use state setter to trigger a re-render
        }));
        setLoading(false);
        // setSpecialities(response.data.data.data);
      } else {
        // setShowLoading(false)
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
        setLoading(false);
      }
    } catch (err) {
      if (err.response) {
        console.log("Error Response:", err.response.data);
      } else {
        console.log("Request Error:", err.message);
      }
      setLoading(false);
    }
  };

  // const handleDropdownClick = () => {
  //   setIsModalOpen(!isModalOpen); // Open the modal
  // };
  const handleDropdownClick = (key) => {
    setOpenDropdown(key);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleOptionSelect = (type, item) => {
    setSelectedOptions((prev) => {
      const updatedSelections = { ...prev };

      if (updatedSelections[type]) {
        const index = updatedSelections[type].indexOf(item.code);

        if (index === -1) {
          updatedSelections[type].push(item.code);
        } else {
          updatedSelections[type].splice(index, 1);
        }
      } else {
        updatedSelections[type] = [item.code];
      }

      // sessionStorage.setItem("selectedservice", JSON.stringify(updatedSelections));
      sessionStorage.setItem(
        "selectedservice",
        JSON.stringify(updatedSelections[type])
      );
      console.log("getrting the item", sessionStorage.getItem("selectedservice"));

      return updatedSelections;
    });
  };

  const handleOpenDropdown = (key) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [key]: true,
    }));
  };

  // Function to close dropdown
  const handleCloseDropdown = () => {
    setOpenDropdown(null);
  };

  return (
    <div className="facilityFormContainer">
      <div className="addp-patientsHeading">
        <h2>Facility Information Details</h2>
      </div>
      <div style={{ padding: "2%" }}>
        <>
          <div
            className="leftContainer"
            style={{ width: "50%", gap: "1rem", justifyContent: "start" }}
          >
            <h4 style={{ textAlign: "start", marginTop: "10px" }}>
              Service/Specialisation
            </h4>
            {/* for specialities */}
            {/* <>
              <div>
                <div className="facilityCheckbox" onClick={handleDropdownClick}>
                  <p>Select Service/Specialisation</p>
                  <img
                    src={downArrow}
                    style={{ cursor: "pointer", width: "15px", height: "15px" }}
                  />
                </div>
              </div>
              <Dialog
                open={isModalOpen}
                style={{ width: "100%", alignItems: "center" }}
              >
                <DialogTitle className="multisymptoms">
                  Select Service/Specialisation
                </DialogTitle>
                <DialogContent className="medicinecheckbox">
                  {specialities.length > 0 ? (
                    specialities.map((item, index) => (
                      <FormControlLabel
                        key={item.code} // Use item code as a unique identifier
                        control={
                          <Checkbox
                            onChange={() => handleOptionSelect("MEDICINE", item)}
                            value={item.value}
                            checked={selectedOptions.MEDICINE?.includes(item.code)}
                          />
                        }
                        label={item.value} // Display item value as label
                      />
                    ))
                  ) : (
                    <div className="spinningLoader">
                      <CircularProgress size={48} color="inherit" />
                    </div>
                  )
                  }
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleOk}>Cancel</Button>
                  <Button onClick={handleOk}>OK</Button>
                </DialogActions>
              </Dialog>
            </> */}

            {/* <div style={{ padding: "2%" }}> */}
            <div style={{ padding: "2%", gap: "1rem" }}>
              <>
                {Object.keys(specialitiesMap).map((key) => (
                  console.log("specialitiesMap keys:", Object.keys(specialitiesMap)),
                  <div key={key} style={{ marginBottom: "10px" }}>
                    <div className="facilityCheckbox" onClick={() => handleDropdownClick(key)}>
                      <p>
                        {key === "D" && "Dental Specialities"}
                        {key === "P" && "Physiotherapy Specialities"}
                        {key === "A" && "Ayurveda Specialities"}
                        {key === "U" && "Unani Specialities"}
                        {key === "M" && "Modern Medicine(Allopathy) Specialities"}
                        {key === "SR" && "Sowa-Rigpa Specialities"}
                        {key === "S" && "Siddha Specialities"}
                        {key === "H" && "Homeopathy Specialities"}
                      </p>
                      <img
                        src={downArrow}
                        alt="Arrow"
                        style={{ cursor: "pointer", width: "15px", height: "15px" }}
                      />
                    </div>

                    <Dialog
                      open={openDropdown === key}
                      onClose={handleCloseDropdown}
                      style={{ zIndex: 9999 }}
                    >
                      <DialogTitle>
                        {key === "D" && "Dental Specialities"}
                        {key === "P" && "Physiotherapy Specialities"}
                        {key === "A" && "Ayurveda Specialities"}
                        {key === "U" && "Unani Specialities"}
                        {key === "M" && "Modern Medicine(Allopathy) Specialities"}
                        {key === "SR" && "Sowa-Rigpa Specialities"}
                        {key === "S" && "Siddha Specialities"}
                        {key === "H" && "Homeopathy Specialities"}
                      </DialogTitle>

                      <DialogContent className="medicinecheckbox">
                        {loading ? (
                          <div className="spinningLoader">
                            <CircularProgress size={48} color="inherit" />
                          </div>
                        ) : specialitiesMap[key]?.length > 0 ? (
                          specialitiesMap[key].map((item) => (
                            <FormControlLabel
                              key={item.code}
                              control={
                                <Checkbox
                                  onChange={() => handleOptionSelect("MEDICINE", item)}
                                  value={item.value}
                                  checked={selectedOptions.MEDICINE?.includes(item.code)}
                                />
                              }
                              label={item.value}
                            />
                          ))
                        ) : (
                          <p>No data available</p>
                        )}
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={handleCloseDropdown}>Cancel</Button>
                        <Button onClick={handleCloseDropdown}>OK</Button>
                      </DialogActions>
                    </Dialog>

                    {selectedOptions[key]?.length > 0 && (
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "5px",
                        }}
                      >
                        {specialitiesMap[key]
                          ?.filter((item) => selectedOptions[key].includes(item.code))
                          .map((item) => (
                            <div
                              key={item.code}
                              style={{
                                background: "#E3EBFF",
                                padding: "7px 10px",
                                borderRadius: "7px",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {item.value}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                ))}
              </>
            </div>

            {/* </div> */}

            <h4 style={{ textAlign: "start" }}>
              Medical Infrastructure
            </h4>
            {/* Oxygen beds */}
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                gap: "43px",
                marginLeft: "20px"
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ margin: 0, fontSize: "14px", marginBottom: "5px" }}>No of IPD Beds with Oxygen</p>
                <input
                  style={{
                    display: "block",
                    width: "110%",
                  }}
                  className="abhanoInfoInput"
                  type="number"
                  max={4}
                  placeholder="No of IPD Beds with Oxygen"
                  value={variables.bedswithoxy}
                  onChange={(e) => handleInputChange(e, "bedswithoxy")}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ margin: 0, fontSize: "14px", marginBottom: "5px" }}>No of IPD Beds without Oxygen</p>
                <input
                  style={{
                    display: "block",
                    width: "110%",
                  }}
                  className="abhanoInfoInput"
                  type="number"
                  max={4}
                  placeholder="No of IPD Beds without Oxygen"
                  value={variables.bedswithoutoxy}
                  onChange={(e) => handleInputChange(e, "bedswithoutoxy")}
                />
              </div>
            </div>

            {/* ICU ventilator beds */}
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                gap: "40px",
                marginLeft: "20px"
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ margin: 0, fontSize: "14px", marginBottom: "5px" }}>No of ICU Beds with Ventilators</p>

                <input
                  style={{
                    display: "block",
                    width: "108%",
                  }}
                  className="abhanoInfoInput"
                  type="number"
                  max={4}
                  placeholder="No of ICU Beds with Ventilators"
                  value={variables.bedswithvent}
                  onChange={(e) => handleInputChange(e, "bedswithvent")}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ margin: 0, fontSize: "14px", marginBottom: "5px" }}>No of ICU Beds without Ventilators</p>

                <input
                  style={{
                    display: "block",
                    width: "98%",
                  }}
                  className="abhanoInfoInput"
                  type="number"
                  max={4}
                  placeholder="No of ICU Beds without Ventilators"
                  value={variables.bedswithoutvent}
                  onChange={(e) => handleInputChange(e, "bedswithoutvent")}
                />
              </div>
            </div>
            {/* HDU beds */}
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                gap: "35px",
                marginLeft: "20px"
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ margin: 0, fontSize: "14px", marginBottom: "5px" }}>No of HDU Beds with Ventilators</p>

                <input
                  style={{
                    display: "block",
                    width: "105%",
                  }}
                  className="abhanoInfoInput"
                  type="number"
                  max={4}
                  placeholder="No of HDU Beds with Ventilators"
                  value={variables.hdubedvent}
                  onChange={(e) => handleInputChange(e, "hdubedvent")}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ margin: 0, fontSize: "14px", marginBottom: "5px" }}>No of HDU Beds without Ventilators</p>

                <input
                  style={{
                    display: "block",
                    width: "95%",
                  }}
                  className="abhanoInfoInput"
                  type="number"
                  max={4}
                  placeholder="No of HDU Beds without Ventilators"
                  value={variables.hduwithoutvent}
                  onChange={(e) => handleInputChange(e, "hduwithoutvent")}
                />
              </div>
            </div>
            {/* Total beds */}
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                gap: "50px",
                marginLeft: "20px"
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ margin: 0, fontSize: "14px", marginBottom: "5px" }}>Total No of Beds</p>

                <input
                  style={{
                    display: "block",
                    width: "112%",
                  }}
                  className="abhanoInfoInput"
                  type="number"
                  max={4}
                  placeholder="Total No of Beds"
                  value={variables.totalbeds}
                  onChange={(e) => handleInputChange(e, "totalbeds")}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ margin: 0, fontSize: "14px", marginBottom: "5px" }}>Total No of Ventilators</p>
                <input
                  style={{
                    display: "block",
                    width: "110%",
                  }}
                  className="abhanoInfoInput"
                  type="number"
                  max={4}
                  placeholder="Total No of Ventilators"
                  value={variables.totalvent}
                  onChange={(e) => handleInputChange(e, "totalvent")}
                />
              </div>
            </div>
            <h4 style={{ textAlign: "start", marginTop: "20px" }}>
              Does this facility use a Hospital Management Information
              System (HMIS)/ Electronic Medical Record (EMR) System?
            </h4>
            <div className="yes-no-buttons-section" style={{ marginBottom: "10px", marginLeft: "20px" }}>
              <p
                style={{
                  width: "97px",
                  height: "32px",
                  borderRadius: "25px",
                  border:
                    selectedOption === "Yes"
                      ? "1px solid #52447d"
                      : "1px solid #52447d",
                  background: selectedOption === "Yes" ? "#52447d" : "#FFF",
                  color: selectedOption === "Yes" ? "white" : "#52447d",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "16px",

                }}
                onClick={() => toggleOptions("Yes")}
              >
                Yes
              </p>
              <p
                style={{
                  width: "97px",
                  height: "32px",
                  borderRadius: "25px",
                  border:
                    selectedOption === "No"
                      ? "1px solid #52447d"
                      : "1px solid #52447d",
                  background: selectedOption === "No" ? "#52447d" : "#FFF",
                  color: selectedOption === "No" ? "white" : "#52447d",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
                onClick={() => toggleOptions("No")}
              >
                No
              </p>
            </div>
          </div>
          {/* Dentistry Section */}
          {/* <div className="facilityCheckbox" onClick={() => handleOpen("dentistry")}>
            <p>Dentistry</p>
            <img
              src={downArrow}
              style={{ cursor: "pointer", width: "15px", height: "15px" }}
              alt="dropdown"
            />
          </div>
          {renderServiceModal("Dentistry", modalOpen.dentistry)} */}

          {/* Physiotherapy Section */}
          {/* <div className="facilityCheckbox" onClick={() => handleOpen("physiotherapy")}>
            <p>Physiotherapy</p>
            <img
              src={downArrow}
              style={{ cursor: "pointer", width: "15px", height: "15px" }}
              alt="dropdown"
            />
          </div>
          {renderServiceModal("Physiotherapy", modalOpen.physiotherapy)} */}
        </>
      </div>
    </div >
  );
};

export default FacilityInfoDetails;
