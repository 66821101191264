import React from "react";
import { useEffect, useState } from "react";
import TestProfile from "../images/testVisitsProfile.svg";
import AddDocument from "../images/addDocument.png";
import Graphs from "../images/graph.svg";
import ConsolidatedReport from "../images/consolidatedReport.svg";
import pdfSvgRepo from "../images/pdf-svgrepo-com.svg";
import Emergency from "../images/emergency.svg";
import InitialAssesments from "../images/initialAssesment.svg";
import ReviewAssessments from "../images/reviewsnassessments.svg";
import Arrow from "../images/arrow.svg";
import Edit1 from "../images/edit1.svg";
import "./Patients.css";
import { MDBIcon } from "mdbreact";
import icon from "../images/icon.png";
import threeDots from "../images/threeDots.png";
import TestVisits from "../components/modules/TestVisits";
import TestVisit from "../../src/pages/TestVisit";
import backgroundCircle from "../images/backgroundCircle.png";
import search from "../images/search.svg";
import searchAlt from "../images/search-alt.png";
import Navbar from "../components/modules/Navbar";
import leftArrow from "../images/leftArrow.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import Config from "../Config/config";
import Axios from "axios";
import Lottie from "lottie-react";
import profileLottie from "../images/mph_registration.json";
import InitialAssessmentLottie from "../images/mph_ia_icon.json";
import ReviewLottie from "../images/mph_summary.json";
import PrescriptionLottie from "../images/mph_files_1.json";
import { useLocation } from "react-router-dom";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
// import TestVisit from "./TestVisit";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBTableRow,
  MDBTableCell,
} from "mdb-react-ui-kit";

import TableComponent from "../components/Tablecomponent";
import TopNavBar from "../components/modules/TopNavBar";
import { color } from "echarts";
import { green } from "@mui/material/colors";

function PatientsInformation({ setvisiblecontent }) {
  const [patients, setPatients] = useState([]);
  const [visitHistory, setVisitHistory] = useState([]);
  var [selectedPatientHistory, setSelectedPatientHistory] = useState();
  const navigate = useNavigate();

  const PATIENT_ID = sessionStorage.getItem("PATIENT_ID")
    ? JSON.parse(sessionStorage.getItem("PATIENT_ID"))
    : null;
  //console.log("patient_id-----", PATIENT_ID);

  const [showAlert, setshowAlert] = useState(false);
  const [variables, setVariables] = useState({
    aimHealthyUsers: [],
    aimSearch: "",
    searchQuery: "",
    selectedPatient: JSON.parse(
      sessionStorage.getItem("selectedPatientDetails")
    ),
    showPopup: false,
  });


  const [siglePatient, setSiglePatient] = useState(false);
  const [profileArrow, setprofileArrow] = useState(false);
  const [profileAbhaArrow, setprofileAbhaArrow] = useState(false);
  const [DocDetailsArrow, setDocDetailsArrow] = useState(false);
  const [VisitDetailsArrow, setVisitDetailsArrow] = useState(true);
  const [openDialog, setOpenDialog] = useState({
    checkIn: false,
    schedule: false,
    closedRecord: false,
  });

  const [linkedPatientName, setLinkedPatientName] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  // const[visiblecontent,setvisiblecontent]=useState(true)
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  //console.log("id", UserID);

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const futureDateAppointment = localStorage.getItem("visit_date"); //selected date

  const location = useLocation();

  const initialAss = location.pathname.includes("/testvisits/");
  const reviewprescription = location.pathname.includes("/reviewprescription");
  const queryParams = new URLSearchParams(location.search);
  const action = queryParams.get("action");

  //patient details
  // useEffect(() => {
  //   let config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     // url: `https://mpowerqa.clinally.com/patient/allpatients?userid=${UserID}`,
  //     url: `${Config.apiURL}${Config.routes.getPatientsAPI}?userid=${UserID}`,
  //     headers: {
  //       authorization: "Basic bXBvd2VydXNlcjpDbCFuQTExeTEyMyFAIw==",
  //       "content-type": "application/json",
  //     },
  //   };

  //   Axios.request(config)
  //     .then((response) => {
  //       const sortedData = response.data.data.sort((a, b) => {
  //         return new Date(b.CREATED_DATE) - new Date(a.CREATED_DATE);
  //       });
  //       setPatients(sortedData);

  //       if (sortedData.length > 0) {
  //         setVariables({
  //           ...variables,
  //           selectedPatient: response.data.data[0],
  //         });
  //         sessionStorage.setItem(
  //           "MOBILE",
  //           JSON.stringify(response.data.data[0].MOBILE)
  //         );
  //         sessionStorage.setItem(
  //           "SelectedPatient",
  //           JSON.stringify(response.data.data[0])
  //         );
  //         sessionStorage.setItem(
  //           "PATIENT_ID",
  //           JSON.stringify(response.data.data[0].USER_ID)
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  const currentPath = window.location.pathname;
  //console.log(currentPath, "currentPath....................");
  useEffect(() => {
    const fetchData = async () => {
      const UserID = JSON.parse(sessionStorage.getItem("UserID"));
      const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
      let data = JSON.stringify({
        userId: UserID,
        id: PATIENT_ID,
      });

      // console.log("data in visits", data);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: "https://mpowerqa.clinally.com/patient/patientvisits",
        url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-caller": encrypted,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // if (response.data.data[0].status === 2) {
          setVisitHistory(response.data.data);
          console.log("response----------------", response.data.data);
          // }
          if (response.data.data.length > 0) {
            if (
              new Date(response.data.data[0]?.VISIT_DATE) < new Date() ||
              isSameDay(new Date(response.data.data[0]?.VISIT_DATE), new Date())
            ) {
              sessionStorage.setItem(
                "selectedPatientHistory",
                JSON.stringify(response.data.data[0])
              );
            } else if (
              new Date(response.data.data[1]?.VISIT_DATE) < new Date() ||
              isSameDay(new Date(response.data.data[1]?.VISIT_DATE), new Date())
            ) {
              sessionStorage.setItem(
                "selectedPatientHistory",
                JSON.stringify(response.data.data[1])
              );
            } else {
              sessionStorage.setItem(
                "selectedPatientHistory",
                JSON.stringify(response.data.data[0])
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
    // handleVisitHistory(0, visitHistory[0])
  }, [selectedRow]);
  useEffect(() => {
    if (visitHistory.length > 0) {
      if (
        new Date(visitHistory[0]?.VISIT_DATE) < new Date() ||
        isSameDay(new Date(visitHistory[0]?.VISIT_DATE), new Date())
      ) {
        handleVisitHistory(0, visitHistory[0]);
      } else if (
        new Date(visitHistory[1]?.VISIT_DATE) < new Date() ||
        isSameDay(new Date(visitHistory[1]?.VISIT_DATE), new Date())
      ) {
        handleVisitHistory(1, visitHistory[1]);
      } else {
        setvisiblecontent(false);
      }
    }
  }, [visitHistory]);
  // useEffect(() => {
  //   if(selectedPatientHistory !== null || selectedPatientHistory !== undefined){
  //     setSelectedPatientHistory(sessionStorage.getItem("selectedPatientHistory"));

  //   const patient = sessionStorage.getItem("selectedPatientHistory") ? JSON.parse(
  //     sessionStorage.getItem("selectedPatientHistory")
  //   ) : null;
  //   const MedicalHistory =
  //     patient?.VISIT_DATA && patient.VISIT_DATA
  //       ? JSON.parse(patient.VISIT_DATA).medicalHistory
  //       : [];
  //   sessionStorage.setItem("MedicalHistory", JSON.stringify(MedicalHistory));
  //   }

  // })

  //aim Healthy api
  const handleAimHealthySearchResults = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      // url: `https://mpowerqa.clinally.com/user/PATIENTDETAILS/${variables.aimSearch}`,
      url: `${Config.base_url}PATIENTDETAILS/${variables.aimSearch}`,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
        "x-caller": encrypted,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setVariables({
          ...variables,
          aimHealthyUsers: response.data.recordsets,
          showPopup: response.data.recordsets.length > 0,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  console.log("variables.selectedpatient", variables.selectedPatient);

  const handleSearch = debounce((value) => {
    setVariables({
      ...variables,
      searchQuery: value,
    });
    // setSearchQuery(value);
  }, 300);

  const handleAimHealthySearch = (value) => {
    setVariables((prevState) => ({
      ...prevState,
      aimSearch: value,
    }));
  };

  const handleClosePopup = () => {
    // setVariables({
    //   ...variables,
    //   showPopup: false,
    // });
    if (variables.aimSearch === "") {
      setVariables((prevState) => ({
        ...prevState,
        showPopup: false,
      }));
    }
  };

  const handlePatientClick = (index, patientData) => {
    //console.log("patientData", patientData, "parients");
    setVariables({
      ...variables,
      // selectedPatient: patients[index],
      selectedPatient: patientData,
    });
    // sessionStorage.setItem(
    //   "PATIENT_ID",
    //   // JSON.stringify(patients.flat()[index].USER_ID)
    //   JSON.stringify(patientData.USER_ID)
    // );
    // sessionStorage.setItem(
    //   "selectedPatientDetails",
    //   JSON.stringify(patientData)
    // );
    sessionStorage.removeItem("VISIT_ID");
    sessionStorage.removeItem("htResult");
    sessionStorage.removeItem("dbResult");
    // sessionStorage.removeItem('visitData');
  };

  const handleVisitHistory = (index, visit) => {
    setSelectedPatientHistory(visitHistory.flat()[index]);
    //console.log("==index", index, "_++-====v", visit);

    const visitData = visitHistory[index].VISIT_DATA;
    sessionStorage.setItem("VISIT_ID", visit.VISIT_ID);
    sessionStorage.setItem("visitData", JSON.stringify(visitData));

    sessionStorage.setItem(
      "selectedPatientHistory",
      JSON.stringify(visitHistory.flat()[index])
    );
    const patient = JSON.parse(
      sessionStorage.getItem("selectedPatientHistory")
    );

    navigate(
      `/patients/testvisits/${PATIENT_ID || variables.selectedPatient.USER_ID
      }?source=patients`
    );
  };

  const handleVisitHistoryOnClick = (index, visit) => {
    setSelectedPatientHistory(visitHistory.flat()[index]);
    //console.log("==index", index, "_++-====v", visit);

    const visitData = visitHistory[index].VISIT_DATA;
    sessionStorage.setItem("VISIT_ID", visit.VISIT_ID);
    sessionStorage.setItem("visitData", JSON.stringify(visitData));

    sessionStorage.setItem(
      "selectedPatientHistory",
      JSON.stringify(visitHistory.flat()[index])
    );
    const patient = JSON.parse(
      sessionStorage.getItem("selectedPatientHistory")
    );
    //console.log("patient =====", patient);
    if (visit.STATUS === 6) {
      setOpenDialog({ ...openDialog, closedRecord: true });
    } else {
      navigate(
        `/patients/testvisits/${PATIENT_ID || variables.selectedPatient.USER_ID
        }?source=patients`
      );
    }
  };

  const getFormattedDate = (date) => {
    if (date && date !== null && date !== "") {
      const dt = new Date(date);
      const options = { year: "numeric", month: "short", day: "numeric" };
      return dt.toLocaleDateString("en-US", options);
    } else {
      return "";
    }
  };

  const handleOpenDialog = (type) => {
    setOpenDialog({ ...openDialog, [type]: true });
  };

  const handleAddVisit = async () => {
    // console.log("futureDateAppointment", futureDateAppointment);
    if (futureDateAppointment !== null) {
      // console.log("coming");
      handleOpenDialog("schedule");
    } else {
      handleOpenDialog("checkIn");
    }
  };

  const handleNewVisit = async () => {
    const MOBILE = JSON.parse(sessionStorage.getItem("MOBILE"));
    // const selectedDateParam = localStorage.getItem("visit_date"); //selected date
    // console.log("visit_date", selectedDateParam);

    // const inputDate = new Date(futureDateAppointment);
    // const selectedDate = inputDate.toISOString().slice(0, 19).replace("T", " ");

    const inputDate = futureDateAppointment
      ? new Date(futureDateAppointment)
      : new Date();
    const selectedDate = inputDate.toISOString().slice(0, 19).replace("T", " ");
    const visitDate = futureDateAppointment
      ? selectedDate
      : new Date().toISOString().slice(0, 19).replace("T", " ");

    const status = selectedDate == null ? "1" : "0";
    let data = JSON.stringify({
      doctorUserId: UserID,
      id: PATIENT_ID,
      mobile: MOBILE,
      nurseUserId: UserID,
      status: status,
      visitDate: visitDate,
    });

    // console.log("data", data);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.apiURL}${Config.routes.addPatientVisitRecordAPI}`,
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-caller": encrypted,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.status === 200 || response.status === 201) {
          setOpenDialog({ checkIn: false, schedule: false });
          navigate("/appointments");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseAlert = () => {
    setOpenDialog({ checkIn: false, schedule: false });
  };

  function formatDate(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    // Check if the date is in the future
    if (inputDate > currentDate) {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return new Intl.DateTimeFormat("en-US", options).format(inputDate);
    }

    // Calculate the number of months ago
    const monthsAgo = Math.floor(
      (currentDate - inputDate) / (1000 * 60 * 60 * 24 * 30)
    );

    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      inputDate
    );

    return `${formattedDate} `;
  }

  const linkPatientToHospital = async (user, index) => {
    try {
      const paylaod = {
        userId: user.USER_ID || "",
        patientId: user.PATIENT_ID || "",
        mobile: user.MOBILE || "",
        nurseId: UserID,
      };
      // console.log("payload", paylaod);
      const response = await axios.post(
        `${Config.base_url}linkpatienttohospital`,
        paylaod,
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      );

      if (response.status === 201) {
        setLinkedPatientName(`${user.FIRST_NAME} ${user.LAST_NAME}`);
        setPatients((prevPatients) => [...prevPatients, user]);
        setVariables({ ...variables, showPopup: false });
        setshowAlert(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  function calculateAge(birthDateStr) {
    const birthDate = new Date(birthDateStr);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Check if the birthday has occurred this year yet
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }
  const handleOk = () => {
    setshowAlert(false);
  };

  const handleClose = () => {
    setshowAlert(false);
  };

  const handleNavigate = (id) => {
    const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
    navigate(`/patients/add-patients?patientId=${PATIENT_ID}`);
  };

  const handleNaviagteToPrescription = (visit) => {
    // console.log("visit", JSON.stringify(visit.VISIT_DATA));
    sessionStorage.setItem("visitData", JSON.stringify(visit.VISIT_DATA));
    sessionStorage.setItem("VISIT_ID", visit.VISIT_ID);
    const patientId = sessionStorage.getItem("PATIENT_ID");

    //console.log("---------", patientId);
    if (patientId !== null) {
      navigate("/patients/reviewprescription");
    }
  };

  const handleNaviagteToGraphs = (visit) => {
    const patientId = sessionStorage.getItem("PATIENT_ID");
    //console.log("---------", patientId);
    if (patientId !== null) {
      navigate("/patients/graph");
    }
  };

  const statusString = [
    "Add details",
    "CheckedIn",
    "Screening",
    "Examination",
    "Analysis",
    "Prescription",
    "Closed",
    "Followup",
  ];

  // const formatVisitDate = (dateString) => {
  //   const date = new Date(dateString);
  //   return date.toLocaleDateString("en-US", {
  //     year: "numeric",
  //     month: "short",
  //     day: "2-digit",
  //   });
  // };

  const formatVisitDate = (dateString) => {
    const date = new Date(dateString);
    console.log("Raw Date:", date);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    console.log("Formatted Date:", formattedDate);
    return formattedDate;
  };

  const handleNaviagteToDocument = (visit) => {
    const patientId = sessionStorage.getItem("PATIENT_ID");
    if (patientId !== null) {
      navigate("/patients/document");
    }
  };

  // const handleNavigateAbha = (index, user) => {
  //   console.log("user", user);
  //   sessionStorage.setItem("selectedPatientDetails", JSON.stringify(user));
  //   sessionStorage.setItem("PATIENT_ID", user.USER_ID);
  //   navigate("/ABHA?action=addPatient");
  // };

  // const handleNavigateManageAbha = (index, user) => {
  //   sessionStorage.setItem("selectedPatientDetails", JSON.stringify(user));
  //   sessionStorage.setItem("PATIENT_ID", user.USER_ID);
  //   navigate("/manageabha");
  // };

  const handleNaviagteToConsolidatedReport = (visit) => {
    sessionStorage.setItem("visitData", visit.VISIT_DATA);
    navigate("/patients/ConsolidatedReport");
  };
  const isSameDay = (date1, date2) =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

  const handleNavigatetoVisits = () => {
    setOpenDialog({ ...openDialog, closedRecord: false });
  };
  const getConsentsList = (AbhaAdress) => {
    //console.log("entering inot getConsentsList");
    navigate("/patients/manageabhaconsents");
  };
  const getABHASubscription = async (AbhaAdress) => {
    navigate("/patients/manageabhasubscription");
  };
  //NEW PATIENT FUNCTIONS
  const patientsProps = patients.map((patient) => {
    // console.log(patient, "Patients data");
    const date = new Date(patient.LAST_VISIT_DATE);

    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, " ");

    const abhaNumber = patient.ABHA_NUMBER
      ? patient.ABHA_NUMBER.replace(/-/g, "")
      : "";

    return {
      profile: ``,
      name: `${patient.FIRST_NAME} ${patient.LAST_NAME}`,
      gender: `${patient.GENDER}`,
      age: `${patient.age}`,
      mobile: `${patient.MOBILE}`,
      lastVisited: formattedDate,
      ABHAID: abhaNumber,
      userdetails: patient,
    };
  });

  function isFirstDateGreater(firstDate, secondDate) {
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);

    // Check if the two dates are the same calendar date (ignoring time)
    const isSameDate = date1.toDateString() === date2.toDateString();

    if (isSameDate) {
      // If the dates are the same, compare the times
      if (date1 > date2) {
        return false; // First date's time is greater
      } else {
        return true; // First date's time is not greater
      }
    } else {
      return true; // Dates are not the same, return true
    }
  }

  const filteredData = patientsProps.filter((user) => {
    const fullName = `${user.name}`.toLowerCase();
    const searchQueryLower = variables.searchQuery.toLowerCase();

    return fullName.includes(searchQueryLower);
  });

  const columns = [
    { label: "Name", field: "name" },
    {
      label: "Gender",
      field: "gender",
    },
    { label: "Age", field: "age" },
    { label: "Mobile Number", field: "mobile" },
    {
      label: "Last Visited",
      field: "lastVisited",
    },
    { label: "ABHA ID", field: "ABHAID" },
  ];

  // const onRowClick = (row) => {
  //   console.log("Row clicked:", row.userdetails);
  //   handlePatientClick("index", row.userdetails);
  //   setSiglePatient(true);
  //   setSelectedRow(row);
  //   sessionStorage.setItem(
  //     "PATIENT_ID",
  //     JSON.stringify(row.userdetails.USER_ID)
  //   );
  //   sessionStorage.setItem(
  //     "selectedPatientDetails",
  //     JSON.stringify(row.userdetails)
  //   );
  // };

  // const LinkAbhaFunc = (row) => {
  //   console.log("user", row);
  //   sessionStorage.setItem("selectedPatientDetails", JSON.stringify(row));
  //   sessionStorage.setItem("PATIENT_ID", row.USER_ID);
  //   navigate("/ABHA?action=addPatient");
  // };

  // const MangeAbhaFunc = (row) => {
  //   console.log("row", row);
  //   sessionStorage.setItem("selectedPatientDetails", JSON.stringify(row));
  //   sessionStorage.setItem("PATIENT_ID", row.USER_ID);
  //   navigate("/manageabha");
  // };

  //Example data of visitdata
  // const Visitdata = [
  //   {
  //     visitedDate: "02, May 2024",
  //     visitedID: "12345",
  //     review: false,
  //   },
  // ];

  // const backTOpatientpage = () => {
  //   setSiglePatient(false);
  // };

  return (
    <div className="patientsDetilaContainer">
      <div className="imagepatientDetailouter">
        <div className="imagepatientDetail">
          <div className="Circlearoundnameicon">
            <div className="nameContainer">
              <p
                className="shortName"
                style={{ height: "4rem", width: "4rem" }}
              >
                {variables.selectedPatient.FIRST_NAME
                  ? variables.selectedPatient.FIRST_NAME.charAt(0)
                  : ""}
                {variables.selectedPatient.LAST_NAME
                  ? variables.selectedPatient.LAST_NAME.charAt(0)
                  : ""}
              </p>
            </div>
          </div>
          <div className="profileImageTabDetailsContainer">
            {" "}
            <div className="profileImageTabDetails">
              <span className="patientName">
                {variables.selectedPatient.FIRST_NAME
                  ? variables.selectedPatient.FIRST_NAME
                  : "No  FIRST NAME"}
              </span>
            </div>
            <div className="profileImageTabDetailsId">
              Patient ID :{" "}
              <span className="patientId">
                {variables.selectedPatient.PATIENT_ID
                  ? variables.selectedPatient.PATIENT_ID
                  : "No  PATIENT ID"}
              </span>
            </div>
            {/* <button
            onClick={() => handleNavigate(variables.selectedPatient.PATIENT_ID)}
          >
            Edit
          </button> */}
          </div>
        </div>
        <div style={{ margin: "2%" }}>
          <img
            className="profileediting"
            src={Edit1}
            alt="edit1"
            onClick={() => handleNavigate(variables.selectedPatient.PATIENT_ID)}
            data-field="firstName"
          />
        </div>
      </div>
      {/* <hr /> */}

      <div
        className="personalDetailDataMainContainer"
        onClick={() => setprofileArrow(!profileArrow)}
      >
        <p>Personal Details</p>
        {!profileArrow ? <FaAngleUp /> : <FaAngleDown />}
      </div>
      {profileArrow && (
        <div className="personalDetailDataMainContainerInner">
          <div className="inner1">
            <div className="lastChecked">
              Age{" "}
              <span className="lastChecked">
                {variables.selectedPatient.DATE_OF_BIRTH
                  ? calculateAge(variables.selectedPatient.DATE_OF_BIRTH)
                  : "No Data"}
                Yrs
              </span>
            </div>
            <div className="lastChecked">
              Gender{" "}
              <span className="lastChecked">
                {variables.selectedPatient.GENDER
                  ? variables.selectedPatient.GENDER
                  : "No Data"}
              </span>
            </div>
          </div>
          <div className="lastChecked">
            Mobile Number
            <span className="lastChecked">
              +91{" "}
              {variables.selectedPatient.MOBILE
                ? variables.selectedPatient.MOBILE
                : "No Data"}
            </span>
          </div>
          <div className="lastChecked">
            Last Visited{" "}
            <span className="patientName">
              {" "}
              {variables.selectedPatient.LAST_VISIT_DATE
                ? formatVisitDate(variables.selectedPatient.LAST_VISIT_DATE)
                : "No visits"}
            </span>
          </div>
        </div>
      )}
      {/* {variables.selectedPatient.ABHA_NUMBER && (
        <> */}
      <div
        className="personalDetailDataMainContainer"
        onClick={() => setprofileAbhaArrow(!profileAbhaArrow)}
      >
        <p>ABHA Details</p>
        {!profileAbhaArrow ? <FaAngleUp /> : <FaAngleDown />}
      </div>
      {/* &&variables.selectedPatient.ABHA_NUMBER */}
      {profileAbhaArrow && variables.selectedPatient.ABHA_NUMBER ? (
        <div
          className="personalDetailDataMainContainerInner"
          style={{ paddingBottom: "0px" }}
        >
          <div className="lastChecked">
            ABHA Number{" "}
            <span className="patientName">
              {" "}
              {variables.selectedPatient.ABHA_NUMBER
                ? variables.selectedPatient.ABHA_NUMBER
                : "No Number"}
            </span>
          </div>
          <div className="lastChecked" style={{ marginTop: "10px" }}>
            ABHA Address{" "}
            <span className="patientName">
              {" "}
              {variables.selectedPatient.ABHA_ADDRESS
                ? variables.selectedPatient.ABHA_ADDRESS
                : "No Address"}
            </span>
          </div>

          {/* <hr /> */}
          {PATIENT_ID !== null ? (
            <>
              <div
                className="patientsListContainer"
                style={{ paddingBottom: "0px" }}
              >
                {variables?.selectedPatient &&
                  !(
                    variables?.selectedPatient?.ABHA_NUMBER === null ||
                    variables?.selectedPatient?.ABHA_NUMBER === ""
                  ) && (
                    <div className="M3functionality">
                      <div
                        className="row"
                        style={{ paddingLeft: "20px" }}
                      // style={{
                      //   justifyContent: "space-between",
                      //   margin: "0.5rem 0",
                      //   padding: "0.75rem 1rem",
                      //   background: "#EBE3FF",
                      // }}
                      >
                        <p>ABHA Consent</p>
                        <button
                          className="AddvisitBtn"
                          onClick={() =>
                            getConsentsList(
                              variables.selectedPatient.ABHA_ADDRESS
                            )
                          }
                        >
                          Consent
                        </button>
                      </div>
                      <div
                        className="row"
                        style={{ paddingLeft: "20px" }}
                      // style={{
                      //   justifyContent: "space-between",
                      //   margin: "0.5rem 0",
                      //   padding: "0.75rem 1rem",
                      //   background: "#EBE3FF",
                      // }}
                      >
                        <p>ABHA Subscription</p>
                        <button
                          className="AddvisitBtn"
                          onClick={() =>
                            getABHASubscription(
                              variables.selectedPatient.ABHA_ADDRESS
                            )
                          }
                        >
                          Subscription
                        </button>
                      </div>
                    </div>
                  )}
                {/* <div
            className="row"
            style={{
              justifyContent: "space-between",
              margin: "0.5rem 0",
              padding: "0.75rem 1rem",
              background: "#EBE3FF",
            }}
          >
            <p>Visit History</p>
            <button
              className="AddvisitBtn"
              onClick={handleAddVisit}
            >
              Add New Visit
            </button>
          </div> */}
                {/* <div
  className="row"
  style={{
    backgroundColor: "#FFF5E2",
    padding: "2rem 0.75rem 0.75rem 0.75rem",
    borderRadius: "10px",
    marginTop: "1rem",
    gap: "1rem",
    justifyContent: "center",
  }}
>
  <div
    className="testProfileContainer"
    style={{
      backgroundColor: "white",
      width: "93px",
      height: "72px",
    }}
  >
    <img
      src={InitialAssesments}
      alt=""
      style={{ height: "39px" }}
    />
    <p style={{ fontSize: "0.5rem" }}>Initial Assesments</p>
  </div>
  <div
    className="testProfileContainer"
    style={{
      backgroundColor: "white",
      width: "93px",
      height: "72px",
    }}
  >
    <img
      src={ReviewAssessments}
      alt=""
      style={{ height: "39px" }}
    />
    <p style={{ fontSize: "0.5rem" }}>
      Review & Prescription
    </p>
  </div>
</div> */}
                {/* {visitHistory &&
  visitHistory.map((visit, index) => (
    <div
      key={index}
      className="row"
      style={{
        boxShadow: "0px 4px 5px 0px #00348270",
        borderRadius: "10px",
        marginBottom: "4%",marginTop: "0.8rem",
        padding: "1rem",
      }}
    >
      <div
        className="imgContainer"
        style={{ backgroundColor: "#EBE3FF" }}
      >
        <img src="" alt="" />
      </div>
      <div>
        <p style={{ fontSize: "10px", fontWeight: "600" }}>
          {formatDate(visit.VISIT_DATE)}{" "}
        </p>
        <p style={{ fontSize: "10px" }}>
          Visit Reference ID: {visit.VISIT_ID}
        </p>
      </div>
      <div
        key={visit.VISIT_ID}
        className="columnData"
        style={{
          background: "#EBE3FF",
          borderRadius: "6px",
          padding: "0.5rem 0.75rem",
          cursor: "pointer",
        }}
        onClick={() => handleVisitHistory(index)}
      >
        <p style={{ fontSize: "10px" }}>
          {statusString[visit.STATUS]}
        </p>
        <img src={Arrow} alt="Arrow icon" />
      </div>
    </div>
  ))} */}
                {/* {variables?.selectedPatient &&
                      !(
                        variables?.selectedPatient?.ABHA_NUMBER === null ||
                        variables?.selectedPatient?.ABHA_NUMBER === ""
                      ) && (
                        <>
                          {" "}
                          <hr />
                        </>
                      )} */}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {!variables.selectedPatient.ABHA_NUMBER && profileAbhaArrow === true && (
        <p className="notABHaDetails">The patient is not linked with ABHA.</p>
      )}
      {/* </>
      )} */}
      {/* 
      <div
        className="personalDetailDataMainContainer"
        onClick={() => setDocDetailsArrow(!DocDetailsArrow)}
      >
        <p>Document Details</p>
        {!DocDetailsArrow ? <FaAngleUp /> : <FaAngleDown />}
      </div> */}
      {/* {DocDetailsArrow && ( */}
      <div className="col">
        <div
          className="columnData"
          style={{ cursor: "pointer" }}
          onClick={handleNaviagteToDocument}
        >
          <div className="imgContainer">
            <img src={AddDocument} alt="" />
          </div>
          <p>Add Document</p>
        </div>
        <div
          className="columnData"
          style={{ cursor: "pointer" }}
          onClick={handleNaviagteToGraphs}
        >
          <div className="imgContainer">
            <img src={Graphs} alt="" />
          </div>
          <p>Graphs</p>
        </div>
        <div
          className="columnData"
          style={{ cursor: "pointer" }}
          onClick={handleNaviagteToConsolidatedReport}
        >
          <div className="imgContainer">
            <img src={ConsolidatedReport} alt="" />
          </div>
          <p>Consolidated Report</p>
        </div>
        <div
          className="columnData"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/patients/labtests")}
        >
          <div className="imgContainer">
            <img src={Graphs} alt="" />
          </div>
          <p>Lab Tests</p>
        </div>
        <div
          className="columnData"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/patients/billing")}
        >
          <div className="imgContainer">
            <img src={ConsolidatedReport} alt="" />
          </div>
          <p>Billing</p>
        </div>
      </div>
      {/* )} */}
      <div>
        <div
          className="personalDetailDataMainContainer"
          onClick={() => setVisitDetailsArrow(!VisitDetailsArrow)}
        >
          <p>Visit Details</p>
          {!VisitDetailsArrow ? <FaAngleUp /> : <FaAngleDown />}
        </div>

        {VisitDetailsArrow && (
          <div className="visitHistorytab">
            <div className="M3functionality">
              <div className="row" style={{ paddingLeft: "20px" }}>
                <p>Visit History</p>
                <button className="AddvisitBtn" onClick={handleAddVisit}>
                  Add New Visit
                </button>
              </div>
            </div>

            {visitHistory &&
              visitHistory.map((visit, index) => {
                const visitDate = new Date(visit.VISIT_DATE).toISOString().split('T')[0];
                const todayDate = new Date().toISOString().split('T')[0];
                const visitDateUTC = new Date(visit.VISIT_DATE);
                const visitDate1 = visitDateUTC.toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                  timeZone: "UTC"
                });

                return (
                  <>
                    {console.log(
                      visitHistory,
                      "visitHistoryvisitHistory",
                      visit,
                      index
                    )}
                    {statusString[visit.STATUS] === "Closed" &&
                      visit.STATUS === 7 &&
                      visit.STATUS != null ? (
                      <div
                        key={index}
                        className="row"
                        style={{
                          // boxShadow: "0px 4px 5px 0px #00348270",
                          borderRadius: "10px",
                          marginBottom: "4%",
                          marginTop: "0.8rem",
                          padding: "1rem",
                          justifyContent: "center",
                          background: "#F6F6F6",
                        }}
                      >
                        {/* DOCUMENT */}
                        {statusString[visit.STATUS] &&
                          visit.PRESCRIPTION_FILE !== null && (
                            <div
                              className="testProfileContainer"
                              style={{
                                backgroundColor: "white",
                                width: "50px",
                                height: "30px",
                                cursor: "pointer",
                              }}
                              // onClick={ }
                              onClick={() => {
                                // Logic to handle the click event
                                const url = `${Config.apiURL}/${visit.PRESCRIPTION_FILE || ""
                                  }`;
                                window.open(url, "_blank");
                              }}
                            >
                              <Lottie
                                animationData={PrescriptionLottie}
                                loop={true} // Set to false if you don't want the animation to loop
                                autoplay={true} // Set to false if you want to control the animation manually
                                style={{
                                  height: "50px",
                                  width: "100%",
                                }}
                              />
                            </div>
                          )}

                        <div>
                          <p
                            style={{
                              fontSize: "10px",
                              fontWeight: "600",
                              color: "#094A73",
                            }}
                          >
                            {formatDate(visit.VISIT_DATE)}{" "}
                          </p>
                          <p style={{ fontSize: "10px" }}>
                            {/* Visit Reference ID: {visit.VISIT_ID} */}
                            ID:{" "}
                            <span style={{ color: "#094A73" }}>
                              {visit.VISIT_ID}
                            </span>
                          </p>
                        </div>

                        {/* prescription */}
                        {statusString[visit.STATUS] &&
                          visit.STATUS !== 6 &&
                          visit.STATUS !== 7 && (
                            <div
                              className="testProfileContainer"
                              style={{
                                backgroundColor: "white",
                                width: "50px",
                                height: "30px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleNaviagteToPrescription(visit)
                              }
                            >
                              {/* <img
                                        src={ReviewAssessments}
                                 alt=""
                                      style={{ height: "39px" }}
                                   /> */}
                              <Lottie
                                animationData={ReviewLottie}
                                loop={true} // Set to false if you don't want the animation to loop
                                autoplay={true} // Set to false if you want to control the animation manually
                                style={{
                                  height: "35px",
                                  width: "80%",
                                }}
                              />
                            </div>
                          )}

                        <div
                          key={visit.VISIT_ID}
                          className="columnData"
                          style={{
                            background: "#EBE3FF",
                            borderRadius: "6px",
                            padding: "0.5rem 0.75rem",
                            cursor: "pointer",
                          }}
                          onClick={() => handleVisitHistory(index, visit)}
                        >
                          <p style={{ fontSize: "10px" }}>
                            {" "}
                            {statusString[visit.STATUS]}
                          </p>
                          <img src={Arrow} alt="Arrow icon" />
                        </div>
                      </div>
                    ) : (
                      <>
                        {index === 0 &&
                          statusString[visit.STATUS] !== "Closed" &&
                          (visitDate <= todayDate)
                          // (new Date(visit.VISIT_DATE) < new Date() ||
                          //   isSameDay(new Date(visit.VISIT_DATE), new Date())) 
                          &&
                          visit.STATUS !== 7 &&
                          visit.STATUS != null ? (
                          <>
                            <div className="initialassessmentboxbg">
                              {/*console.log(visit, "visitvisitvisitvisitvisit")*/}
                              <div className="initialassessmentboxbg-row ia-spacebetween">
                                <p
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#094A73",
                                  }}
                                >
                                  {visitDate1}
                                  {/* {formatDate(visit.VISIT_DATE)}{" "} */}
                                </p>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {/* Visit Reference ID:{" "} */}
                                  ID:{" "}
                                  <span style={{ color: "#094A73" }}>
                                    {visit.VISIT_ID}
                                  </span>
                                </p>
                              </div>
                              <div
                                className="row"
                                style={{
                                  // backgroundColor: "#FFF5E2",
                                  //padding: "2rem 0.75rem 0.75rem 0.75rem",
                                  borderRadius: "10px",
                                  marginTop: "1rem",
                                  marginBottom: "1rem",
                                  gap: "1rem",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center", // Centers horizontally
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    className="testProfileContainer"
                                    style={{
                                      backgroundColor: "white",
                                      width: "60px",
                                      height: "60px",
                                      cursor: "pointer",
                                      border: initialAss
                                        ? "2px solid orange"
                                        : "0px solid orange",
                                    }}
                                    onClick={() =>
                                      handleVisitHistory(index, visit)
                                    }
                                  >
                                    <img
                                      src={InitialAssesments}
                                      alt=""
                                      style={{ height: "39px" }}
                                    />
                                    {/* <Lottie
                                      style={{ height: "60px", width: "100%" }}
                                       animationData={InitialAssessmentLottie}
                                         loop={true} // Set to false if you don't want the animation to loop
                                               autoplay={true} // Set to false if you want to control the animation manually
                                       /> */}
                                  </div>
                                  <p
                                    style={{
                                      fontSize: "0.7rem",
                                      textAlign: "center",
                                      paddingTop: "5px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Initial Assessments
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    className="testProfileContainer"
                                    style={{
                                      backgroundColor: "white",
                                      width: "60px",
                                      height: "60px",
                                      cursor: "pointer",
                                      border: reviewprescription
                                        ? "2px solid orange"
                                        : "0px solid orange",
                                    }}
                                    onClick={() =>
                                      handleNaviagteToPrescription(visit)
                                    }
                                  >
                                    <img
                                      src={ReviewAssessments}
                                      alt=""
                                      style={{ height: "39px" }}
                                    />
                                    {/* <Lottie
                                          animationData={ReviewLottie}
                                       loop={true} // Set to false if you don't want the animation to loop
                                         autoplay={true} // Set to false if you want to control the animation manually
                                         style={{ height: "60px", width: "100%" }}
                                   /> */}
                                  </div>
                                  <p
                                    style={{
                                      fontSize: "0.7rem",
                                      textAlign: "center",
                                      paddingTop: "5px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Review & Prescription
                                  </p>
                                </div>

                                {/* <div
                                   className="imgContainer"
                                     // style={{ backgroundColor: "#EBE3FF" }}
                                         >
                                      <img src="" alt="" />
                                            </div> */}
                                {/* DOCUMENT */}

                                {statusString[visit.STATUS] &&
                                  visit.PRESCRIPTION_FILE !== null && (
                                    // <div
                                    //   key={index}
                                    //   className="row"
                                    //   style={{
                                    //     // boxShadow: "0px 4px 5px 0px #00348270",
                                    //     borderRadius: "10px",
                                    //     marginBottom: "4%",
                                    //     marginTop: "0.8rem",
                                    //     padding: "1rem",
                                    //     justifyContent: "center",
                                    //   }}
                                    // >
                                    <div
                                      className="testProfileContainer"
                                      style={{
                                        backgroundColor: "white",
                                        width: "50px",
                                        height: "30px",
                                        cursor: "pointer",
                                      }}
                                      // onClick={ }
                                      onClick={() => {
                                        // Logic to handle the click event
                                        const url = `${Config.apiURL}/${visit.PRESCRIPTION_FILE || ""
                                          }`;
                                        window.open(url, "_blank");
                                      }}
                                    >
                                      <Lottie
                                        animationData={PrescriptionLottie}
                                        loop={true} // Set to false if you don't want the animation to loop
                                        autoplay={true} // Set to false if you want to control the animation manually
                                        style={{
                                          height: "50px",
                                          width: "100%",
                                        }}
                                      />
                                    </div>

                                    // </div>
                                  )}
                              </div>
                              <div>
                                <div
                                  key={visit.VISIT_ID}
                                  // className="columnData"
                                  style={{
                                    background: "#EBE3FF",
                                    borderRadius: "6px",
                                    padding: "0.5rem 0.75rem",
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  onClick={() =>
                                    handleVisitHistory(index, visit)
                                  }
                                >
                                  <p style={{ fontSize: "14px" }}>
                                    {" "}
                                    {/* {statusString[visit.STATUS]} */}
                                    {new Date(visit.VISIT_DATE) > new Date() &&
                                      !isSameDay(
                                        new Date(visit.VISIT_DATE),
                                        new Date()
                                      )
                                      ? "Schedule"
                                      : statusString[visit.STATUS]}
                                  </p>
                                  {new Date(visit.VISIT_DATE) >= new Date() ? (
                                    ""
                                  ) : (
                                    <img src={Arrow} alt="Arrow icon" />
                                  )}
                                  {/* <img src={Arrow} alt="Arrow icon" /> */}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* {console.log(visitHistory[0].STATUS,visitHistory,"visitHistory[0].status",((new Date(visit.VISIT_DATE) < new Date())||(isSameDay(new Date(visit.VISIT_DATE), new Date()))))}
                     {console.log("visitHistory[0].status",((new Date(visit.VISIT_DATE) < new Date())||(isSameDay(new Date(visit.VISIT_DATE), new Date()))) &&
                      (visitHistory[0].STATUS === 6 ||
                        visitHistory[0].STATUS === 7))} */}
                            {index === 1 &&
                              // !(new Date(visit.VISIT_DATE) > new Date()) &&
                              (!(new Date(visit.VISIT_DATE) > new Date()) ||
                                isSameDay(
                                  new Date(visit.VISIT_DATE),
                                  new Date()
                                )) &&
                              (new Date(visitHistory[0].CREATED_DATE) >
                                new Date() ||
                                isSameDay(
                                  new Date(visit.CREATED_DATE),
                                  new Date()
                                )) &&
                              !(
                                visit.VISIT_DATE === visitHistory[0].VISIT_DATE
                              ) ? (
                              <>
                                {console.log(
                                  !(
                                    visit.VISIT_DATE ===
                                    visitHistory[0].VISIT_DATE
                                  ),
                                  visit.VISIT_DATE,
                                  visit.VISIT_DATE ===
                                  visitHistory[0].VISIT_DATE,
                                  "VisitTime.....",
                                  new Date(visit.VISIT_DATE),
                                  "ojoidfio",
                                  new Date(visitHistory[0].VISIT_DATE)
                                )}

                                <div
                                  className="initialassessmentboxbg"
                                  style={{ marginTop: "0.8rem" }}
                                >
                                  <div className="initialassessmentboxbg-row ia-spacebetween">
                                    <p
                                      style={{
                                        fontSize: "10px",
                                        fontWeight: "600",
                                        color: "#094A73",
                                      }}
                                    >
                                      {formatDate(visit.VISIT_DATE)}{" "}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "10px",
                                      }}
                                    >
                                      {/* Visit Reference ID:{" "}
                                {visit.VISIT_ID} */}
                                      ID:{" "}
                                      <span style={{ color: "#094A73" }}>
                                        {visit.VISIT_ID}
                                      </span>
                                    </p>
                                  </div>
                                  <div
                                    className="row"
                                    style={{
                                      // backgroundColor: "#FFF5E2",
                                      // padding: "2rem 0.75rem 0.75rem 0.75rem",
                                      borderRadius: "10px",
                                      marginTop: "1rem",
                                      marginBottom: "1rem",
                                      gap: "1rem",
                                      justifyContent: "center",
                                      border: initialAss
                                        ? "2px solid orange"
                                        : "0px solid orange",
                                    }}
                                  >
                                    <div
                                      className="testProfileContainer"
                                      style={{
                                        backgroundColor: "white",
                                        width: "93px",
                                        height: "83px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleVisitHistory(index, visit)
                                      }
                                    >
                                      <img
                                        src={InitialAssesments}
                                        alt=""
                                        style={{
                                          height: "39px",
                                        }}
                                      />
                                      {/* <Lottie
                                              style={{ height: "60px", width: "100%" }}
                                           animationData={InitialAssessmentLottie}
                                        loop={true} // Set to false if you don't want the animation to loop
                                          autoplay={true} // Set to false if you want to control the animation manually
                                         /> */}

                                      <p
                                        style={{
                                          fontSize: "0.5rem",
                                        }}
                                      >
                                        Initial Assessments
                                      </p>
                                    </div>
                                    <div
                                      className="testProfileContainer"
                                      style={{
                                        backgroundColor: "white",
                                        width: "93px",
                                        height: "83px",
                                        cursor: "pointer",
                                        border: reviewprescription
                                          ? "2px solid orange"
                                          : "0px solid orange",
                                      }}
                                      onClick={() =>
                                        handleNaviagteToPrescription(visit)
                                      }
                                    >
                                      <img
                                        src={ReviewAssessments}
                                        alt=""
                                        style={{
                                          height: "39px",
                                        }}
                                      />
                                      {/* <Lottie
                                                animationData={ReviewLottie}
                        loop={true} // Set to false if you don't want the animation to loop
                        autoplay={true} // Set to false if you want to control the animation manually
                        style={{ height: "60px", width: "100%" }}
                      /> */}
                                      <p
                                        style={{
                                          fontSize: "0.5rem",
                                        }}
                                      >
                                        Review & Prescription
                                      </p>
                                    </div>
                                    {statusString[visit.STATUS] &&
                                      visit.PRESCRIPTION_FILE !== null && (
                                        <div
                                          className="testProfileContainer"
                                          style={{
                                            backgroundColor: "white",
                                            width: "50px",
                                            height: "30px",
                                            cursor: "pointer",
                                          }}
                                          // onClick={ }
                                          onClick={() => {
                                            // Logic to handle the click event
                                            const url = `${Config.apiURL}/${visit.PRESCRIPTION_FILE || ""
                                              }`;
                                            window.open(url, "_blank");
                                          }}
                                        >
                                          <Lottie
                                            animationData={PrescriptionLottie}
                                            loop={true} // Set to false if you don't want the animation to loop
                                            autoplay={true} // Set to false if you want to control the animation manually
                                            style={{
                                              height: "50px",
                                              width: "100%",
                                            }}
                                          />
                                        </div>
                                      )}
                                  </div>
                                  {/* <div
                                    key={index}
                                    className="row"
                                    style={{
                                      // boxShadow: "0px 4px 5px 0px #00348270",
                                      borderRadius: "10px",
                                      marginBottom: "4%",
                                      marginTop: "0.8rem",
                                      padding: "1rem",
                                      justifyContent: "center",
                                    }}
                                  > */}
                                  {/* <div
                                         className="imgContainer"
                                      // style={{ backgroundColor: "#EBE3FF" }}
                                               >
                                       <img src="" alt="" />
                                                 </div> */}
                                  {/* DOCUMENT */}

                                  <div
                                    key={visit.VISIT_ID}
                                    style={{
                                      background: "#EBE3FF",
                                      borderRadius: "6px",
                                      padding: "0.5rem 0.75rem",
                                      cursor: "pointer",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    onClick={() =>
                                      handleVisitHistory(index, visit)
                                    }
                                  >
                                    <p
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {" "}
                                      {statusString[visit.STATUS]}
                                    </p>
                                    <img src={Arrow} alt="Arrow icon" />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  key={index}
                                  className="row"
                                  style={{
                                    borderRadius: "10px",
                                    marginBottom: "4%",
                                    marginTop: "0.8rem",
                                    padding: "1rem",
                                    justifyContent: "center",
                                    background:
                                      new Date(visit.VISIT_DATE) > new Date()
                                        ? "#d0d0d0"
                                        : "#F6F6F6",
                                  }}
                                >
                                  {/* DOCUMENT */}
                                  {statusString[visit.STATUS] &&
                                    visit.PRESCRIPTION_FILE !== null ? (
                                    <div
                                      className="testProfileContainer"
                                      style={{
                                        backgroundColor: "white",
                                        width: "50px",
                                        height: "30px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        // Logic to handle the click event
                                        const url = `${Config.apiURL}/${visit.PRESCRIPTION_FILE || ""
                                          }`;
                                        window.open(url, "_blank");
                                      }}
                                    >
                                      <Lottie
                                        animationData={PrescriptionLottie}
                                        loop={true} // Set to false if you don't want the animation to loop
                                        autoplay={true} // Set to false if you want to control the animation manually
                                        style={{
                                          height: "50px",
                                          width: "100%",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="testProfileContainer"
                                      style={{
                                        backgroundColor: "gray", // Black background when conditions are not met
                                        width: "50px",
                                        height: "30px",
                                        cursor: "default", // No pointer cursor as it's inactive
                                      }}
                                    >
                                      <Lottie
                                        animationData={PrescriptionLottie}
                                        loop={false} // Animation stops
                                        autoplay={false} // Animation does not play automatically
                                        style={{
                                          height: "50px",
                                          width: "100%",
                                        }}
                                      />
                                    </div>
                                  )}

                                  <div>
                                    <p
                                      style={{
                                        fontSize: "10px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {formatDate(visit.VISIT_DATE)}{" "}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "10px",
                                      }}
                                    >
                                      ID: {visit.VISIT_ID}
                                    </p>
                                  </div>

                                  {/* prescription */}
                                  {new Date(visit.VISIT_DATE) >
                                    new Date() ? null : (
                                    <>
                                      {statusString[visit.STATUS] &&
                                        visit.STATUS !== 6 &&
                                        visit.STATUS !== 7 && (
                                          <div
                                            className="testProfileContainer"
                                            style={{
                                              backgroundColor: "white",
                                              width: "50px",
                                              height: "30px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleNaviagteToPrescription(
                                                visit
                                              )
                                            }
                                          >
                                            <Lottie
                                              animationData={ReviewLottie}
                                              loop={true}
                                              autoplay={true}
                                              style={{
                                                height: "35px",
                                                width: "80%",
                                              }}
                                            />
                                          </div>
                                        )}
                                    </>
                                  )}

                                  <div
                                    key={visit.VISIT_ID}
                                    className="columnData"
                                    style={{
                                      background: "#EBE3FF",
                                      borderRadius: "6px",
                                      padding: "0.5rem 0.75rem",
                                      cursor:
                                        new Date(visit.VISIT_DATE) > new Date()
                                          ? "none"
                                          : "pointer",
                                    }}
                                    onClick={
                                      new Date(visit.VISIT_DATE) > new Date()
                                        ? null
                                        : () =>
                                          handleVisitHistoryOnClick(
                                            index,
                                            visit
                                          )
                                    }
                                  >
                                    <p
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {new Date(visit.VISIT_DATE) >
                                        new Date() &&
                                        !isSameDay(
                                          new Date(visit.VISIT_DATE),
                                          new Date()
                                        )
                                        ? "Schedule"
                                        : statusString[visit.STATUS]}
                                    </p>
                                    {new Date(visit.VISIT_DATE) > new Date() ? (
                                      ""
                                    ) : (
                                      <img src={Arrow} alt="Arrow icon" />
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                );
              })}
          </div>
        )}
      </div>
      {/* for visit  */}
      <Dialog open={showAlert} onClose={handleClose}>
        <DialogTitle>Successful</DialogTitle>

        <DialogContent>
          <p>
            The patent {linkedPatientName} has been successfully linked to your
            hospital.
          </p>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      {/* schedule appoointment*/}
      <Dialog open={openDialog.schedule} onClose={handleClose}>
        <DialogTitle>Schedule appointment!</DialogTitle>
        <DialogContent>
          <Typography>
            Would you like to schedule an appointment for the Patient on{" "}
            {getFormattedDate(futureDateAppointment)}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert} color="primary">
            cancel
          </Button>
          <Button onClick={handleNewVisit} color="primary">
            continue
          </Button>
        </DialogActions>
      </Dialog>

      {/* checkin appointment */}
      <Dialog open={openDialog.checkIn} onClose={handleClose} PaperProps={{
        style: { width: "25%" }
      }}>
        <DialogTitle>Patient Check-in!</DialogTitle>
        <DialogContent>
          <p style={{ paddingTop: "20px" }}>Would you like to Check-in the Patient?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert} color="primary">
            cancel
          </Button>
          <Button onClick={handleNewVisit} color="primary">
            continue
          </Button>
        </DialogActions>
      </Dialog>

      {/* for closed record view popup */}

      {openDialog.closedRecord && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "3%",
          }}
        >
          <div>
            <Dialog open={openDialog.closedRecord}>
              <DialogTitle> Alert!</DialogTitle>
              <DialogContent>
                <p>
                  This is a view only record. You will be not be able to edit
                  it. Click 'OK' to view the record. This record has been
                  closed.
                </p>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleNavigatetoVisits}
                  style={{ background: "#9b00d3", color: "white" }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )}

      {action === "addappointment" ? (
        <div className="botbox">
          <div className="noteaddappbox">
            Note: Please select patient and click on Add New Visit
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default PatientsInformation;
