import profile from "../../images/profile.png";
import profileAlt from "../../images/ProfileAlt.png";
import React, { useEffect, useState } from "react";
import "./TopNavBar.css";
import downarrrow from "../../images/Shape.png";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";

import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Config from "../../Config/config";
import { useNavigate } from "react-router-dom";
const TopNavBar = () => {
  const navigate = useNavigate();
  const [details, setdetails] = useState([]);
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");
  const userDetails = sessionStorage.getItem("UserData")
    ? JSON.parse(sessionStorage.getItem("UserData"))[0]
    : "";
  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await Axios.get(
          `${Config.base_url}GETUSERDATA/${UserID}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "x-caller": await encrypted,
            },
          }
        );

        const configuration = await Axios.get(
          `${Config.apiURL}/config/getconfiguration/${UserID}`,
          {
            headers: {
              "content-type": "application/json",
              authorization: `Bearer ${token}`,
              "x-caller": await encrypted,
            },
          }
        );

        if (response.status === 200) {
          const extractData = response.data.recordset[0];
          setdetails(extractData);
          // console.log(extractData, "l,,,,,,,,,,,,.......");
        }

        if (configuration.status === 200) {
          // console.log("coming inside ");
          // console.log("configuration", configuration);
          const configure = configuration.data.data;
          // console.log(configure, "l,,,,,,,,,,,,.......");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    getUserData();
  }, []);


  const handlenavigate = () => {
    navigate("/profile");
  };


  return (
    <div className="topNavBarHeader">
      <div className="topNavBarItems">
        {/* {console.log(userDetails, "userDetails..........................")} */}
        <div className="TopNavbar-doctorName">
          <span className="TopNavbar-hello">Hello Dr.</span>
          {userDetails ? ` ${userDetails.FIRST_NAME}` : ""}
        </div>

        <div className="TopNavbar-iteams" onClick={handlenavigate} style={{ cursor: "pointer" }}>
          {details.USER_PHOTO ? (
            <img
              className="Profile-Image"
              src={`${Config.apiURL}${details.USER_PHOTO}`}
              alt="profile photo"
              crossOrigin="true"
            />
          ) : (
            <img className="Profile-Image" src={profileAlt} alt="profile photo" />
          )}

          {/* <img src={downarrrow} alt="profile" /> */}
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;
