import "./App.css";
import Landing from "./pages/Landing";
import Appointments from "./pages/Appointments";
import ResetPin from "./pages/ResetPin";
import AddPatients from "./pages/AddPatients";
import Patients from "./pages/Patients";
import ResetPassword from "./pages/ResetPassword";
import Profile from "./pages/Profile";
import TestVisit from "./pages/TestVisit";
import Test from "./components/modules/Test";
import Calculator from "./pages/Calculator";
import Article from "./pages/Article";
import ReviewPrescription from "./components/modules/Visits/ReviewPage";
import BloodPressureGraph from "./pages/graph";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ABHAIntegration from "./components/modules/ABHA/Abha_Integration";
import Graphs from "./components/modules/Visits/Graph";
import FollowUps from '../src/pages/Followups';
import Document from './components/modules/Visits/Document';
import ManageABHAConsents from "./components/modules/ABHA/ManageABHAConsents";
import ManageABHASubscription from "./components/modules/ABHA/ManageABHASubscription";
// import Treatment from './pages/Treatment'
import DiagnosisComponent from './pages/DiagnosisComponent'
import Treatment from "./components/modules/Visits/TreatmentPlanMainPage";
import ConsolidatedReport from './components/modules/Visits/ConsolidatedReport'
import LabTests from './components/modules/Visits/LabTests'
import Billing from './components/modules/Visits/Billing'
import Prescription from "./components/modules/Visits/Prescription";
import Demographics from "./pages/Demographics";
import PreviewPrescription from './components/modules/Visits/PreviewPrescription';
import ManageAbha from './components/modules/ABHA/ManageABHA'
import Layout from "./pages/layout";
import AboutUs from "./components/modules/AboutUs";
import HelpPage from "./components/modules/HelpPage";
//testing commits

function App() {
  const router = createBrowserRouter([
    {
      children: [
        {
          path: "/patients",
          // element: <Patients />,
          element: <Layout  ComponentName={<Patients />} type={'Patents'}/>,
        },
        {
          path: "/patients/testvisits/:id",
          // element: <TestVisit />,
          element: <Layout  ComponentName={<TestVisit />}/>,
        },
        {
          path: "/patients/manageabhaconsents",
          element: <Layout  ComponentName={<ManageABHAConsents />}/>,
          // element: <ManageABHAConsents />,
        },
        {
          path: "/patients/manageabhasubscription",
          // element: <ManageABHASubscription />,
          element: <Layout  ComponentName={<ManageABHASubscription />}/>,
        },
        {
          path: "/patients/reviewprescription",
          // element: <ReviewPrescription />,
          element: <Layout  ComponentName={<ReviewPrescription />}/>,
        },
        {
          path: "/patients/graph",
          // element: <Graphs />,
          element: <Layout  ComponentName={<Graphs />}/>,
        },
        {
          path: "/patients/document",
          // element: <Document />,
          element: <Layout  ComponentName={<Document />}/>,
        },
        {
          path: "/appointments",
          // element: <Appointments />,
          element: <Layout  ComponentName={<Appointments />}  type={"Appointments"}/>,
        },
        {
          path: "/patients/add-patients",
          // element: <AddPatients />,
          element: <Layout  ComponentName={<AddPatients />} type={"AddPatients"}/>,
        },
        {
          path: "/patients/diagnosis",
          // element: <DiagnosisComponent />,
          element: <Layout  ComponentName={<DiagnosisComponent />}/>,
        },
        {
          path: "/patients/treatmentplan",
          // element: <Treatment />,
          element: <Layout  ComponentName={<Treatment />}/>,
        },
        {
          path: "/patients/treatmentplan/Prescription",
          // element: <Prescription />,
          element: <Layout  ComponentName={<Prescription />}/>,
        },
        {
          path: "/prescription/Previewprescription",
          // element: <PreviewPrescription />, 
          element: <Layout  ComponentName={<PreviewPrescription />}  />,
        },
        {
          path: "/profile",
          // element: <Profile />,
          element: <Layout  ComponentName={<Profile /> }  type={"profile"}/>,
        },
        {
          path: "/aboutus",
          element: <Layout  ComponentName={<AboutUs />} type={'aboutus'}/>,
        },
        {
          path: "/help",
          element: <Layout  ComponentName={<HelpPage />} type={'help'}/>,
        },
        {
          path: "/calculator",
          // element: <Calculator />,
          element: <Layout  ComponentName={<Calculator />} type={'Calculator'}/>,
        },
        {
          path: "/Article",
          // element: <Article />,
          element: <Layout  ComponentName={<Article />} type={'Article'}/>,
        },
        {
          //not use 
          path: "/graph",
          // element: <BloodPressureGraph />,
          element: <Layout  ComponentName={<BloodPressureGraph />} type={'BloodPressureGraph'}/>,
        },
        {
          path: "/followups",
          // element: <FollowUps />,
          element: <Layout  ComponentName={<FollowUps />} type={'FollowUps'}/>,
        },
        {
          //not use 
          path: "/followups/testvisits/:id",
          element: <TestVisit />,
        },
        {
          //not use 
          path: "/followups/prescription",
          element: <Prescription />,
        },
        {

          //use to component not route 
          path: "/followups/graph",
          element: <Graphs />,
        },
        {
          path: "/patients/ConsolidatedReport",
          // element: <ConsolidatedReport />,
          element: <Layout  ComponentName={<ConsolidatedReport />}/>,
        },
        {
          path: "/patients/labtests",
          // element: <LabTests />,
          element: <Layout  ComponentName={<LabTests />} />,
        },
        {
          path: "/patients/billing",
          // element: <LabTests />,
          element: <Layout  ComponentName={<Billing />} />,
        },

      ],
    },
    {
      path: "/",
      element: <Landing />,
    },
    {
      //working
      path: "/login",
      element: <Landing />,
    },
    {
      //working
      path: "/reset-pin",
      element: <ResetPin />,
    },
    {
      //working
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      //not use 
      path: "/Test",
      element: <Test />,
    },
    {
      path: "/ABHA",
      // element: <ABHAIntegration />,
      element: <Layout  ComponentName={<ABHAIntegration />} type={'ABHAIntegration'}/>,
    },
    {
      path: "/abha/demographics",
      // element: <Demographics />,
      element: <Layout  ComponentName={<Demographics />} type={'Demographics'}/>,
    },
    {
      path: "/manageabha",
      // element: <ManageAbha />,
      element: <Layout  ComponentName={<ManageAbha />} type={'ManageAbha'}/>,
    },

  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
