// import React, { useState } from "react";
// import peopleImg from "../../images/people 1.svg";
// import upArrow from "../../images/Group 66.svg";
// import aiimsLogo from "../../images/aiims_logo.png";
// import ccdcLogo from "../../images/CCDC_WHO Collaborating centre logo.jpg";
// import downArrow from "../../images/Group 67.svg";
// const AboutUs = () => {
//   const [showContent, setShowContent] = useState(false);

//   const toggleContent = () => {
//     setShowContent(!showContent);
//   };

//   return (
//     <div
//       className="profileRightContainer"
//       style={{ display: "flex", gap: "1rem" }}
//     >
//       <div className="leftContainer" style={{ width: "70%" }}>
//         <div className="bottomContainer">
//           <div style={{ height: "70vh", overflow: "scroll" }}>
//             <>
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   gap: "2rem",
//                 }}
//               >
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                     gap: "0.5rem",
//                     padding: "0.7rem 3rem",
//                     borderRadius: "6px",
//                     background: showContent ? "#FFC15B" : "#eeecf5",
//                   }}
//                 >
//                   <div
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       gap: "1rem",
//                     }}
//                   >
//                     <img src={peopleImg} />
//                     <label className="About">About ClinAlly</label>
//                   </div>

//                   {/* <img src={upArrow} /> */}
//                   <img
//                     style={{ cursor: "pointer" }}
//                     src={showContent ? downArrow : upArrow}
//                     alt="Arrow"
//                     onClick={toggleContent}
//                   />
//                 </div>
//                 {!showContent && (
//                   <div
//                     style={{
//                       width: "30%",
//                       height: "45px",
//                       background: "white",
//                     }}
//                     className={"version"}
//                   >
//                     Version - 2.0.0
//                   </div>
//                 )}
//               </div>

//               {showContent && (
//                 <>
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       gap: "1rem",
//                     }}
//                   >
//                     <div
//                       style={{
//                         display: "flex",
//                         flexDirection: "column",
//                         padding: "2%",
//                         gap: "1.5rem",
//                         border: "1px solid #FFC15B",
//                         borderRadius: "6px",
//                       }}
//                       className="AboutText"
//                     >
//                       <p>
//                         {" "}
//                         Health System worldwide is facing of challenges of
//                         chronic diseases and lack of expertise to tackle the
//                         growing burden of morbidity and mortality related to
//                         chronic diseases. Despite recognition of the benefits
//                         associated with early detection and treating chronic
//                         diseases and their risk factors, control rates remain
//                         suboptimal.
//                       </p>
//                       <p>
//                         The researchers from prestigious institutions together
//                         developed several digital technologies and interventions
//                         for standardizing, integrating, and improving quality
//                         and access to chronic disease care in healthcare
//                         facilities. The knowledge and experience from these
//                         research projects have led to the development of the
//                         ClinAlly - a platform - Of the Doctor, By the Doctor,
//                         For the Doctor.
//                       </p>
//                       <ul>
//                         <li style={{ display: "flex", flexDirection: "row" }}>
//                           {/* <h1>.</h1> */}
//                           (i) mPower Health is intended to assist different
//                           cadres of health care providers in detection,
//                           evidence-based management, and follow-up of people
//                           with health conditions.
//                         </li>
//                         <li>
//                           (ii) All protocols and information used/displayed in
//                           mPower Health are derived from national and
//                           international guidelines.
//                         </li>
//                         <li>
//                           (iii) mPower Health is developed in collaboration with
//                           the experts from All India Institute of Medical
//                           Sciences (AIIMS), New Delhi and Centre for Chronic
//                           Disease Control (CCDC), New Delhi.
//                         </li>
//                       </ul>
//                       <div
//                         style={{
//                           display: "flex",
//                           flexDirection: "row",
//                           // justifyContent: "space-between",
//                           gap: "10rem",
//                         }}
//                       >
//                         <div
//                           style={{
//                             display: "flex",
//                             // flexDirection: "column",
//                             gap: "1rem",
//                             alignItems: "center",
//                           }}
//                         >
//                           <img style={{ height: "10vh" }} src={aiimsLogo} />
//                           <label className="About">AIIMS</label>
//                         </div>
//                         <div
//                           style={{
//                             display: "flex",
//                             // flexDirection: "column",
//                             gap: "1rem",
//                             alignItems: "center",
//                           }}
//                         >
//                           <img
//                             style={{ height: "10vh", width: "100%" }}
//                             src={ccdcLogo}
//                           />
//                           <label className="About">CCDC</label>
//                         </div>
//                       </div>
//                       <div>
//                         <p>For more information, please visit:</p>
//                         <p>
//                           <a href="http://www.clinally.com">
//                             http://www.clinally.com
//                           </a>
//                         </p>

//                         <p style={{ fontWeight: "bold", padding: "2% 0 0 0" }}>
//                           Disclaimer:
//                         </p>
//                         <p style={{ color: "gray" }}>
//                           Medical prescriptions must be done through established
//                           clinical practice of the country and the liability is
//                           with the providers of care.
//                         </p>
//                       </div>
//                     </div>

//                     <div
//                       style={{
//                         width: "30%",
//                         height: "45px",
//                         background: "white",
//                       }}
//                       className={"version"}
//                     >
//                       Version - 2.0.0
//                     </div>
//                   </div>
//                 </>
//               )}
//             </>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AboutUs;

import React, { useState } from "react";
import peopleImg from "../../images/people 1.svg";
import upArrow from "../../images/Group 66.svg";
import aiimsLogo from "../../images/aiims_logo.png";
import ccdcLogo from "../../images/CCDC-new-logo-1-1.png";
import downArrow from "../../images/Group 67.svg";
import "../modules/About.css"

const AboutUs = () => {
  const [showContent, setShowContent] = useState(true);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  return (
    <div
      className="aboutProfileRightContainer"
      style={{ display: "flex", gap: "1rem" }}
    >
      <div className="leftContainer" style={{ width: "70%" }}>
        <div className="bottomContainer">
          <div className="Abtscroll" >
            <>
              <div className="abtverspace"  >
                <div className="Abtclinallybtn"
                  style={{
                    background: showContent ? "#FFC15B" : "#eeecf5",
                  }}
                  onClick={toggleContent}
                >
                  <div className="Imgtext">
                    <img src={peopleImg} alt="peopleImg" />
                    <label className="About">About ClinAlly</label>
                  </div>

                  {/* <img src={upArrow} /> */}
                  <img
                    style={{ cursor: "pointer" }}
                    src={showContent ? downArrow : upArrow}
                    alt="Arrow"
                    onClick={toggleContent}
                  />
                </div>
                {!showContent && (
                  <div
                    className={"version"}
                  >
                    Version - 1.0.5
                  </div>
                )}
              </div>

              {showContent && (
                <>
                  <div className="gapbtwconanver">
                    <div
                      style={{}}
                      className="AboutTextinside"
                    >
                      <p>
                        {" "}
                        Health System worldwide is facing of challenges of
                        chronic diseases and lack of expertise to tackle the
                        growing burden of morbidity and mortality related to
                        chronic diseases. Despite recognition of the benefits
                        associated with early detection and treating chronic
                        diseases and their risk factors, control rates remain
                        suboptimal.
                      </p>
                      <p>
                        The researchers from prestigious institutions together
                        developed several digital technologies and interventions
                        for standardizing, integrating, and improving quality
                        and access to chronic disease care in healthcare
                        facilities. The knowledge and experience from these
                        research projects have led to the development of the
                        ClinAlly - a platform - Of the Doctor, By the Doctor,
                        For the Doctor.
                      </p>
                      <ul>
                        <li style={{ display: "flex", flexDirection: "row" }}>
                          {/* <h1>.</h1> */}
                          (i) mPower Health is intended to assist different
                          cadres of health care providers in detection,
                          evidence-based management, and follow-up of people
                          with health conditions.
                        </li>
                        <li>
                          (ii) All protocols and information used/displayed in
                          mPower Health are derived from national and
                          international guidelines.
                        </li>
                        <li>
                          (iii) mPower Health is developed in collaboration with
                          the experts from All India Institute of Medical
                          Sciences (AIIMS), New Delhi and Centre for Chronic
                          Disease Control (CCDC), New Delhi.
                        </li>
                      </ul>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          // justifyContent: "space-between",
                          gap: "10rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            // flexDirection: "column",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          <img style={{ height: "10vh" }} src={aiimsLogo} alt="aiimsLogo" />
                          <label className="About">AIIMS</label>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            // flexDirection: "column",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{ height: "10vh", width: "100%" }}
                            src={ccdcLogo}
                            alt="ccdcLogo"
                          />
                          <label className="About">CCDC</label>
                        </div>
                      </div>
                      <div>
                        <p>For more information, please visit:</p>
                        <p>
                          <a href="http://www.clinally.com">
                            http://www.clinally.com
                          </a>
                        </p>

                        <p style={{ fontWeight: "bold", padding: "2% 0 0 0" }}>
                          Disclaimer:
                        </p>
                        <p style={{ color: "gray" }}>
                          Medical prescriptions must be done through established
                          clinical practice of the country and the liability is
                          with the providers of care.
                        </p>
                      </div>
                    </div>

                    <div
                      className={"version"}
                    >
                      Version - 1.0.5
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
