import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../../images/leftArrow.svg";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
// import "./ConsolidatedReport.css";
import "./Prescription.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import Config from "../../../Config/config";
import axios, { Axios } from "axios";
import { DateTime } from "luxon";
import leftArrow from "../../../images/leftArrow.svg";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
  pdf,
} from "@react-pdf/renderer";
import styles from "./styles";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import "./Prescription.css";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";

import OtpInput from "react-otp-input";
import { Snackbar, CircularProgress, Alert } from "@mui/material";
// function getDate() {
//     const today = new Date();
//     return today;
// }

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

const FollowupCalender = ({ onSelectedDate, value }) => {
  const dateValue = dayjs(value);

  const handleDateChange = (newValue) => {
    onSelectedDate(newValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        orientation="landscape"
        openTo="day"
        value={dateValue}
        onChange={handleDateChange}
        renderInput={() => null}
      />
    </LocalizationProvider>
  );
};

const Prescription = () => {
  const navigate = useNavigate();
  const [updateDate, setUpdateDate] = useState(dayjs());
  const [followUp, setFollowUp] = useState(false);
  const [followUpDateOpen, setFollowUpDateOpen] = useState(false);
  const [closeRecord, setCloseRecord] = useState(false);
  const [visitData, setVisitData] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [ABHAopen, setABHAOpen] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [HospiralDetails, setHospiralDetails] = useState([]);
  //
  const [otpBox, setotpBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [HospitalDetails, setHospitalDetails] = useState("");
  const [getTokenData, setgetTokenData] = useState("");
  const [precsriptionClose, setPrecsriptionClose] = useState(false);
  const [apiData, setApiData] = useState({
    accessToken: "",
    v4: "",
    initReq: "",
  });
  const [variables, setVariables] = useState({
    otp: "",
    showAlert: false,
    alertTitle: "",
    alertMessage: "",
  });
  // const navigate = useNavigate();
  //

  const [drugs, setDrugs] = useState([
    // {
    //     className: "Biguanide",
    //     name: "Metformin",
    //     currentDose: "1000",
    //     frequency: "Once",
    //     instructions: "Before food",
    //     durationDays: "3",
    //     quantity: "5",
    //     unit: 'mg',
    // }
  ]);

  // const handleDelete = (index) => {
  //   console.log(index, "................vff.......");
  //   const newDrugs = [...drugs];
  //   newDrugs.splice(index, 1);
  //   setDrugs(newDrugs);

  //   sessionStorage.setItem("addingDrugList", JSON.stringify(newDrugs));
  //   sessionStorage.setItem("selecteddrug", JSON.stringify(newDrugs));

  // };

  const handleDelete = (index) => {
    console.log("Deleting drug at index:", index);

    const deletedDrug = drugs[index];
    if (!deletedDrug || !deletedDrug.name) {
      console.error("Invalid deleted drug", deletedDrug);
      return;
    }
    console.log("Deleted Drug:", deletedDrug);

    // Remove from drugs array and update state
    const newDrugs = drugs.filter((_, i) => i !== index);
    setDrugs(newDrugs);

    // Retrieve stored lists
    const storedAddingDrugList = JSON.parse(sessionStorage.getItem("addingDrugList")) || [];
    const storedSelectedDrugList = JSON.parse(sessionStorage.getItem("selecteddrug")) || [];

    // Normalize the deleted drug's name and class (case-insensitive, trimmed)
    const deletedName = deletedDrug.name ? deletedDrug.name.trim().toLowerCase() : "";
    const deletedClass = deletedDrug.className ? deletedDrug.className.trim() : "";

    // Remove the item at the specified index directly from the stored list
    storedAddingDrugList.splice(index, 1);  // This removes the item at the given index

    // Filter the selected drug list
    const updatedSelectedDrugList = storedSelectedDrugList.filter((drug) => {
      const currentDrugName = drug.drugName ? drug.drugName.trim().toLowerCase() : "";
      const drugClass = drug.className ? drug.className.trim() : "";

      if (!deletedClass) {
        return currentDrugName !== deletedName;
      }
      return !(currentDrugName === deletedName && drugClass === deletedClass);
    });

    // Update sessionStorage with the new lists
    sessionStorage.setItem("addingDrugList", JSON.stringify(storedAddingDrugList));
    sessionStorage.setItem("selecteddrug", JSON.stringify(updatedSelectedDrugList));

    // Debugging logs
    console.log("Updated Adding Drug List:", storedAddingDrugList);
    console.log("Updated Selected Drug List:", updatedSelectedDrugList);
  };


  // const handleDelete = (index) => {
  //   console.log(index, "................vff.......");

  //   const deletedDrug = drugs[index];

  //   const newDrugs = drugs.filter((_, i) => i !== index);
  //   setDrugs(newDrugs);

  //   const storedAddingDrugList = JSON.parse(sessionStorage.getItem("addingDrugList")) || [];
  //   const storedSelectedDrugList = JSON.parse(sessionStorage.getItem("selecteddrug")) || [];

  //   const updatedAddingDrugList = storedAddingDrugList.filter(
  //     (drug) => !(drug.name === deletedDrug.name && drug.className === deletedDrug.className)
  //   );

  //   const updatedSelectedDrugList = storedSelectedDrugList.filter(
  //     (drug) => !(drug.drugName === deletedDrug.name && drug.className === deletedDrug.className)
  //   );

  //   // Update sessionStorage
  //   sessionStorage.setItem("addingDrugList", JSON.stringify(updatedAddingDrugList));
  //   sessionStorage.setItem("selecteddrug", JSON.stringify(updatedSelectedDrugList));
  // };


  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");
  const patient_id = sessionStorage.getItem("PATIENT_ID");
  const patientData = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );
  // console.log(patientData,"patientDatapatientDatapatientData")
  const userDetails = JSON.parse(sessionStorage.getItem("UserData"))[0];
  //console.log("User------", userDetails);

  const PATIENT_ID = sessionStorage.getItem("PATIENT_ID");
  //console.log("patient_id", PATIENT_ID);

  const MOBILE = JSON.parse(sessionStorage.getItem("MOBILE"));

  const patientDetails = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );
  //console.log("patientDetails", patientDetails.FIRST_NAME);
  console.log("Patients------", patientDetails);

  const VISIT_ID = JSON.parse(sessionStorage.getItem("VISIT_ID"));
  const htResult = JSON.parse(sessionStorage.getItem("htResult"));
  const dbResult = JSON.parse(sessionStorage.getItem("dbResult"));
  const visitdata = JSON.parse(sessionStorage.getItem("visitData"));
  console.log(JSON.parse(visitdata.drugHistory), "visitdata_prescription");
  console.log(htResult, "htResult.hctrtn4bt..");
  console.log(dbResult, "htResult.hctrtn4bt..");
  //console.log("VISIT------", visitdata);
  const hospitalId = patientDetails.HOSPITAL_ID;
  //console.log("hospitalId------", hospitalId);
  const examinationhistory = JSON.parse(visitdata.examinationHistory);
  //console.log("History------", examinationhistory);

  const htdiagnosis = JSON.parse(visitdata.htDiagnosis);
  //console.log("htdiagnosis---", htdiagnosis); 

  const dbdiagnosis = JSON.parse(visitdata.dbDiagnosis);
  //console.log("dbdiagnosis----", dbdiagnosis);

  const selectedPatientHistory = sessionStorage.getItem(
    "selectedPatientHistory"
  )
    ? JSON.parse(sessionStorage.getItem("selectedPatientHistory"))
    : [];
  const drugdetail = sessionStorage.getItem("selectedPrescriptionDrugDetails");
  const drugdetails = JSON.parse(drugdetail);
  //console.log("drugdetails", drugdetails);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  function formatDate1(dateString) {
    const date = new Date(dateString);

    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      timeZone: "UTC", // Ensures it stays in UTC
    });
  }


  const defaultHospital = async () => {
    const UserID = sessionStorage.getItem("RegUserId")
      ? JSON.parse(sessionStorage.getItem("RegUserId"))
      : sessionStorage.getItem("UserID");

    const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");
    var options = {
      method: "GET",
      url: `${Config.base_url}getDefaultHospital/${UserID}`,
      headers: {
        authorization: authentication,
        "Content-Type": "application/json",
        "x-caller": encrypted,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log("default hospital", response);
        // console.log(
        //   "setHospitalList",
        //   response.data.recordset?.[0]
        // );
        setHospiralDetails(response.data.recordset?.[0]);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  function getdurglistForBOth(data) {
    if (!data) {
      return []; // Return an empty array if data is null or undefined
    }

    return Object.keys(data).map((key) => {
      if (data[key]) {
        const result = data[key]?.[0] || {};
        return result;
      }
      return {}; // Return an empty object if data[key] is undefined or null
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      // Extracting drug options and suggested drugs
      const drugOptions = htResult?.drugOptions ?? null;
      const DbdrugOptions = dbResult?.drugOptions ?? null;

      const DbsuggestedDrugs = DbdrugOptions?.[0]?.suggestedDrugs ?? null;
      const suggestedDrugs = drugOptions?.[0]?.suggestedDrugs ?? null;
      console.log(DbdrugOptions, "dbDrugs....");
      console.log(drugOptions, "htDrugs....");
      const hyDrugData = getdurglistForBOth(suggestedDrugs);
      const dbDrugData = getdurglistForBOth(DbsuggestedDrugs);
      console.log(
        hyDrugData,
        dbDrugData,
        "uwiheufhuwufowhefoqiwhoweroprqjrv",
        suggestedDrugs
      );
      // Select the drug from suggested drugs
      const drug = JSON.parse(visitdata.drugHistory)?.currentHTDrugs?.[0];
      console.log(drug, "drug......", suggestedDrugs, DbsuggestedDrugs);
      const Dbdrug = JSON.parse(visitdata.drugHistory)?.currentDBDrugs?.[0];
      console.log(Dbdrug, "drugOptions", drug);
      // Update the state only if the drug is not already present
      if (suggestedDrugs && drug) {
        setDrugs((prevData) => {
          if (!prevData.some((d) => d?.id === drug?.id)) {
            return [...prevData, drug];
          }
          return prevData;
        });
      }

      if (DbsuggestedDrugs && Dbdrug) {
        setDrugs((prevData) => {
          if (!prevData.some((d) => d?.id === Dbdrug?.id)) {
            return [...prevData, Dbdrug];
          }
          return prevData;
        });
      }

      // Retrieve drug details from session storage
      const drugdetail = sessionStorage.getItem(
        "selectedPrescriptionDrugDetails"
      );
      const drugdetails = JSON.parse(drugdetail);
      //console.log("drugdetails", drugdetails);

      const existingList =
        JSON.parse(sessionStorage.getItem("addingDrugList")) || [];
      console.log(existingList, ".............................");

      // Add drugs from the existing list if not duplicates
      setDrugs((prevData) => {
        // Merge new drugs from existing list without duplicates
        const mergedData = existingList.reduce(
          (acc, drug) => {
            if (
              !acc.some(
                (d) => d.name === drug.name && d.className === drug.className
              )
            ) {
              console.log(acc, "details...............", drug);
              acc.push({
                id: drug.id,
                className: drug.className,
                name: drug.drugName,
                currentDose: drug.strength,
                frequency: drug.frequency,
                instructions: drug.instruction,
                durationDays: drug.quantity,
                quantity: "1",
                unit: "",
              });
            }
            return acc;
          },
          [...prevData]
        );
        // const uniqueData = mergedData.filter(
        //   (drug, index, self) =>
        //     index === self.findIndex((d) => d?.className === drug?.className)
        // );
        const uniqueData = mergedData.filter(
          (drug, index, self) =>
            index === self.findIndex(
              (d) => d.className === drug.className && d.name === drug.name
            )
        );
        return uniqueData;
      });

      // const storedSelectedDrug = sessionStorage.getItem("selecteddrug");
      // console.log("storedSelectedDrug", storedSelectedDrug);
      // if (storedSelectedDrug) {
      //   const selectedDrug = JSON.parse(storedSelectedDrug);

      //   setDrugs((prevData) => {
      //     if (
      //       !prevData.some(
      //         (d) => d.name === selectedDrug.drugName && d.className === selectedDrug.className
      //       )
      //     ) {
      //       return [
      //         ...prevData,
      //         {
      //           id: Date.now(), // Temporary unique ID
      //           className: selectedDrug.className,
      //           name: selectedDrug.drugName,
      //           currentDose: selectedDrug.strength,
      //           frequency: selectedDrug.frequency,
      //           instructions: selectedDrug.instruction,
      //           durationDays: selectedDrug.quantity,
      //           quantity: "1",
      //           unit: "",
      //         },
      //       ];
      //     }
      //     return prevData;
      //   });
      // }

      const storedSelectedDrug = sessionStorage.getItem("selecteddrug");
      console.log("storedSelectedDrug", storedSelectedDrug);
      if (storedSelectedDrug) {
        const selectedDrugs = JSON.parse(storedSelectedDrug);

        setDrugs((prevData) => {
          const newDrugs = selectedDrugs.filter(
            (selectedDrug) =>
              !prevData.some(
                (d) =>
                  d.name === selectedDrug.drugName &&
                  d.className === selectedDrug.className
              )
          ).map((selectedDrug) => ({
            id: Date.now(), // Temporary unique ID
            className: selectedDrug.className,
            name: selectedDrug.drugName,
            currentDose: selectedDrug.strength,
            frequency: selectedDrug.frequency,
            instructions: selectedDrug.instruction,
            durationDays: selectedDrug.quantity,
            quantity: "1",
            unit: "",
          }));

          return [...prevData, ...newDrugs];
        });
      }

    };

    fetchData();
    defaultHospital();
  }, []);


  // useEffect(() => {
  //   const fetchData = async () => {
  //     // Extracting drug options and suggested drugs
  //     const drugOptions = htResult?.drugOptions ?? null;
  //     const DbdrugOptions = dbResult?.drugOptions ?? null;

  //     const DbsuggestedDrugs = DbdrugOptions?.[0]?.suggestedDrugs ?? null;
  //     const suggestedDrugs = drugOptions?.[0]?.suggestedDrugs ?? null;
  //     console.log(DbdrugOptions, "dbDrugs....");
  //     console.log(drugOptions, "htDrugs....");
  //     // console.log(drugOptions, "htDrugs...");
  //     // Object.keys(DbdrugOptions[0].suggestedDrugs).map((key) => {
  //     //   console.log(`ckdContra for key ${key}:`);
  //     //   const ckdContra = DbdrugOptions[0].suggestedDrugs[key];
  //     //   console.log(`ckdContra for key ${key}:`, ckdContra);
  //     // });
  //     const hyDrugData = getdurglistForBOth(suggestedDrugs);
  //     const dbDrugData = getdurglistForBOth(DbsuggestedDrugs);
  //     console.log(
  //       hyDrugData,
  //       dbDrugData,
  //       "uwiheufhuwufowhefoqiwhoweroprqjre",
  //       suggestedDrugs
  //     );
  //     // Select the drug from suggested drugs
  //     const drug = JSON.parse(visitdata.drugHistory)?.currentHTDrugs?.[0];
  //     //suggestedDrugs?.["7"]?.[0] ?? {};
  //     console.log(drug, "drug......", suggestedDrugs, DbsuggestedDrugs);
  //     const Dbdrug = JSON.parse(visitdata.drugHistory)?.currentDBDrugs?.[0];
  //     //DbsuggestedDrugs?.["1"]?.[0] ?? {};
  //     console.log(Dbdrug, "drugOptions", drug);
  //     //console.log(drug, "drug");

  //     // Update the state only if the drug is not already present
  //     if (suggestedDrugs && drug) {
  //       setDrugs((prevData) => {
  //         if (!prevData.some((d) => d?.id === drug?.id)) {
  //           return [...prevData, drug];
  //         }
  //         return prevData;
  //       });
  //     }

  //     if (DbsuggestedDrugs && Dbdrug) {
  //       setDrugs((prevData) => {
  //         if (!prevData.some((d) => d?.id === Dbdrug?.id)) {
  //           return [...prevData, Dbdrug];
  //         }
  //         return prevData;
  //       });
  //     }

  //     // Retrieve drug details from session storage
  //     const drugdetail = sessionStorage.getItem(
  //       "selectedPrescriptionDrugDetails"
  //     );
  //     const drugdetails = JSON.parse(drugdetail);
  //     //console.log("drugdetails", drugdetails);

  //     // Retrieve existing list from session storage
  //     const existingList =
  //       JSON.parse(sessionStorage.getItem("addingDrugList")) || [];
  //     console.log(existingList, ".............................");

  //     // Add drugs from the existing list if not duplicates
  //     setDrugs((prevData) => {
  //       // Merge new drugs from existing list without duplicates
  //       const mergedData = existingList.reduce(
  //         (acc, drug) => {
  //           if (
  //             !acc.some(
  //               (d) => d.name === drug.name && d.className === drug.className
  //             )
  //           ) {
  //             console.log(acc, "details...............", drug);
  //             acc.push({
  //               id: drug.id,
  //               className: drug.className,
  //               name: drug.drugName,
  //               currentDose: drug.strength,
  //               frequency: drug.frequency,
  //               instructions: drug.instruction,
  //               durationDays: drug.quantity,
  //               quantity: "1",
  //               unit: "",
  //             });
  //           }
  //           return acc;
  //         },
  //         [...prevData]
  //       );
  //       const uniqueData = mergedData.filter(
  //         (drug, index, self) =>
  //           index === self.findIndex((d) => d?.className === drug?.className)
  //       );
  //       return uniqueData;
  //     });
  //   };

  //   fetchData();
  //   defaultHospital();
  // }, []);


  function formatVisitDate(visitDate) {
    // Parse the initial visit date string into a Date object
    const initialDate = new Date(visitDate);

    // Extract the date and time components
    const year = initialDate.getFullYear();
    const month = String(initialDate.getMonth() + 1).padStart(2, "0");
    const day = String(initialDate.getDate()).padStart(2, "0");
    const hours = String(initialDate.getHours()).padStart(2, "0");
    const minutes = String(initialDate.getMinutes()).padStart(2, "0");
    const seconds = String(initialDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(initialDate.getMilliseconds()).padStart(5, "0");

    // Construct the formatted visit date string
    const formattedVisitDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

    return formattedVisitDateString;
  }

  const VISITDATA = {
    patientId: selectedPatientHistory.PATIENT_ID,
    mobile: selectedPatientHistory.MOBILE,
    nurseId: selectedPatientHistory.NURSE_USER_ID,
    doctorId: selectedPatientHistory.DOCTOR_USER_ID,
    visitId: selectedPatientHistory.VISIT_ID,
    visitDate: formatVisitDate(selectedPatientHistory.VISIT_DATE),
    medicalHistory: {
      isDiabetes: null,
      isHypertension: null,
      isCKD: null,
      isLiverFailure: null,
      isCOPD: null,
      isAsthma: null,
      isHeartDisease: null,
      isStroke: null,
      isPVD: null,
      isHeartAttack: null,
      isFamilyHypertension: null,
      isFamilyDiabetes: null,
      isFamilyCardioVascular: null,
      isFamilyOtherDisease: null,
      cheifComplaint: [],
      otherChiefComplaint: "",
    },
    drugHistory: {
      isHypertensiveDrugs: null,
      isDiabeticDrugs: null,
      isLipidDrugs: null,
      isAsprin: null,
      isInsulin: null,
      currentHTDrugs: [],
      currentDBDrugs: [],
      currentInsulin: [],
      currentAsprin: [],
      currentLipid: [],
    },
    personalHistory: {
      isPhysicalActivity: null,
      isSmoking: null,
      isDrinking: null,
      drinkingFrequency: null,
      isFamilyHistoryOfMI: null,
      isPolyuria: null,
      isPolydypsia: null,
      isWeightloss: null,
      isOnDialysis: null,
    },
    examinationHistory: {
      sbp: null,
      dbp: null,
      heartRate: null,
      sbp2: null,
      dbp2: null,
      sbp3: null,
      dbp3: null,
      averageSBP: null,
      averageDBP: null,
      fbs: null,
      ppbs: null,
      rbs: null,
      hba1c: null,
      waist: null,
      height: null,
      weight: null,
      bmi: null,
      ldl: null,
      creatinine: null,
      cholestrol: null,
      isEdema: null,
      isBreathlessness: null,
      isChestPain: null,
      isDeficit: null,
      isAlteredConciousness: null,
      isFundus: null,
      isGeneralizedSeizures: null,
      gfr: null,
    },
    htDiagnosis: {
      diagnosis: null,
      control: null,
      recommendation: null,
      notes: null,
      followup: null,
      doctorAcceptance: null,
      doctorNotes: null,
      doctorId: null,
      systemDecision: null,
    },
    dbDiagnosis: {
      diagnosis: null,
      control: null,
      recommendation: null,
      notes: null,
      followup: null,
      doctorAcceptance: null,
      doctorNotes: null,
      doctorId: null,
      systemDecision: null,
    },
    copdDiagnosis: [],
    tbDiagnosis: [],
    copdHistory: {
      isCopd: null,
      isProgressiveDyspnoea: null,
      isChronicCough: null,
      isChronicSputum: null,
      isChestPain: null,
      isFever: null,
      isWeightLoss: null,
      isOrthopnoea: null,
      isParoxysmalNocturnal: null,
      isHaemoptysis: null,
      isSpirometeryTest: null,
      spirometeryValue: null,
      isSmoking: null,
      isBiomassFuel: null,
      isPollution: null,
      mmrcGrade: null,
      isDifficultInBreathing: null,
      isExacerbation: null,
      isAdmitHospitalWithExacerbation: null,
      severeBreathingDifficulty: null,
      exacerbationSymptoms: [],
      severeExacerbationSymptoms: [],
      drugOptions: [],
    },

    tbHistory: {
      confirmedTBType: [],
      presumptiveTBType: [],
      suspectedTBSymptoms: [],
      suspectedComorbidities: [],
      suspectedOtherTBDrugs: [],
      tbPreviousDrugList: [],
      tbCurrentDrugList: [],
      tbAdditionalDrugList: [],
    },
    osteoArthritisHistory: {
      oaAComorbidList: [],
      oaPharmaList: [],
      oaLifestyleList: [],
      oaNonPharmaList: [],
    },
    orderTest: {
      clinicalPathology: [],
      radiology: [],
      bioChemistry: [],
      stoolAnalysis: [],
      serology: [],
      urineAnalysis: [],
      cardiology: [],
    },
  };

  useEffect(() => {
    const fetchVisitData = async () => {
      try {
        let data = JSON.stringify({
          userId: UserID,
          id: PATIENT_ID,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-caller": encrypted,
          },
          data: data,
        };

        const response = await axios.request(config);
        //console.log("Response from ..:", response, response.data.data);

        const sessionVisitID = parseInt(VISIT_ID, 10);

        const filteredRecords = response.data.data.filter((record) => {
          return record.VISIT_ID === sessionVisitID;
        });

        //console.log("Filtered Records:", filteredRecords, typeof VISIT_ID);
        if (filteredRecords[0].VISIT_DATA === null) {
          setVisitData(VISITDATA);
          //console.log("VISITDATA", VISITDATA);
        } else {
          let res = await filteredRecords[0].VISIT_DATA;
          //console.log("---res----", res);

          let parsedRes = !!res ? JSON.parse(res) : null;
          setVisitData(parsedRes);
        }
      } catch (err) {
        console.log(err, "err");
      }
    };
    fetchVisitData();
  }, [VISIT_ID]);

  const RecordFollowup = () => {
    const visitDate = updateDate ?? DateTime.now().toString();
    let data = JSON.stringify({
      doctorUserId: UserID,
      id: PATIENT_ID,
      mobile: MOBILE,
      nurseUserId: UserID,
      status: "7",
      visitDate: visitDate,
    });

    let config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: `${Config.apiURL}/patient/addpatientvisit`,
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-caller": encrypted,
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        // navigate("/appointments");
        toast.success("Followups record created successfully");
        // alert("Followups record created successfully");
        setFollowUp(false);

      })
      .catch((error) => {
        if (error.response.status === 403) {
          // console.log(error.response.data.message)
          toast.error(error.response.data.message);
          setFollowUp(false);
        }

        console.log(error);
      });
  };

  function replacer(key, value) {
    try {
      if (key.trim() === "") return value;

      if (key.trim() === "drugHistory") {
        let modifiedJsonString = value.replace(/"(\d+\.\d+)"/g, "$1"); //(/\\"(\d+\.\d+)\\"/g, "$1");
        //console.log("===drug=====", modifiedJsonString);
        return modifiedJsonString;
      }

      if (key.trim() === "examinationHistory") {
        //console.log("===111111111110=====", value);
        let modifiedJsonString = value.replace(/"(\d+(\.\d+)?)"/g, "$1");
        //console.log("===examiantion=====", modifiedJsonString);
        return modifiedJsonString;
      }

      if (value && value !== null && typeof value === "object") {
        // console.log("value inside if", value)
        return value.replace(/"/g, '\\"'); // Replace " with \"  }   return value; }
      }
      return value;
    } catch (err) {
      // console.log("======key===", key, "===========value==", typeof value, "=======", value)
      // console.log(err);
      if (Array.isArray(value) && value.length === 0) return value;
      else if (
        key.trim() === "tbHistory" ||
        key.trim() === "osteoArthritisHistory"
      ) {
        let jsonString = JSON.stringify(value).replace(/"/g, '\\"');
        // console.log(jsonString, '1#####');
        jsonString = `\"${jsonString}\"`;
        // console.log(jsonString, '2#####');
        return jsonString;
      } else return JSON.stringify(value);
    }
  }

  const RecordClosed = async () => {
    let VisitData = JSON.stringify(visitData, replacer);
    // console.log("Stringified VisitData---", VisitData);
    sessionStorage.setItem("visitData", VisitData);

    const response = await axios.post(
      `${Config.base_url}PATIENT_VISIT_DATA`,
      {
        USER_ID: UserID,
        VISIT_ID: visitData?.visitId?.toString(),
        VISIT_DATA: VisitData,
        STATUS: 6,
        PRESCRIPTION_DATA: null,
        CREATED_BY: visitData?.nurseId?.toString(),
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-caller": encrypted,
        },
      }
    );

    //console.log("data post", response);
    if (response.status === 201) {
      navigate("/patients");
      return true;
    } else {
      console.error("Error: Data not saved");
      return false;
    }
  };

  const ShowAlert = ({ title, message, onClose }) => {
    return (
      <div className="popup">
        <div className="popup-inner">
          <p
            style={{ fontSize: "24px", fontWeight: "600", fontFamily: "bold" }}
          >
            {title}
          </p>
          <p>{message}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2rem",
              padding: "4%",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="ContinueBtn"
              onClick={
                () => { onClose(); }
              }
              style={{
                background: "#52447d",
                width: "20%",
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div >
    );
  };

  let followupdate = 0;

  try {
    const htFollowUpWeeks = htResult?.suggestedFollowUpInWeeks ?? null;
    const dbFollowUpWeeks = dbResult?.suggestedFollowUpInWeeks ?? null;

    if (htFollowUpWeeks !== null && dbFollowUpWeeks !== null) {
      followupdate = Math.min(htFollowUpWeeks, dbFollowUpWeeks);
    } else if (htFollowUpWeeks !== null) {
      followupdate = htFollowUpWeeks;
    } else if (dbFollowUpWeeks !== null) {
      followupdate = dbFollowUpWeeks;
    }
  } catch (error) {
    console.error(
      "An error occurred while calculating the suggested follow-up time:",
      error
    );
  }

  //console.log("Suggested follow-up in weeks:", followupdate);

  useEffect(() => {
    const currentDate = new Date();
    const newUpdateDate = dayjs(currentDate).add(followupdate * 7, "day");
    // setInitialUpdateDate(newUpdateDate);
    setUpdateDate(newUpdateDate);
    getlinktoken();
    getpatientdata();
  }, []);

  const linkcontexttoken = sessionStorage.getItem("CARECONTEXT_LINK_TOKEN");
  console.log("carecontextlinktikenn", linkcontexttoken);

  const getpatientdata = async () => {
    try {
      const encryptedValue = await encrypted;

      const response = await axios.get(
        `${Config.base_url}getDemographic/${patientDetails.USER_ID}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            authorization: `Bearer ${token}`,
            "x-caller": encryptedValue,
          },
        }
      );

      console.log("get userdata demographic :", response);
      if (response.status === 200) {
        console.log("getuser data in this:", response.data);
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      toast.error("Server Error!");
    }
  };

  const handleFollowupOpen = (event) => {
    setFollowUp(true);
  };

  const handleFollowupClose = () => {
    setFollowUp(false);
  };

  const handleFollowupDateOpen = () => {
    setFollowUpDateOpen(true);
  };

  const handleFollowupDateClose = () => {
    setFollowUpDateOpen(false);
  };

  const handleClickCloseRecordOpen = (event) => {
    setCloseRecord(true);
  };

  const handleClickCloseRecordClose = () => {
    setCloseRecord(false);
  };

  const handleDateSelect = (date) => {
    setUpdateDate(date);
    setFollowUpDateOpen(true);
  };

  const PDFFile = () => {
    return (
      <Document>
        <Page style={styles.body}>
          <View style={styles.user}>
            <View style={styles.userInfo}>
              <Text style={styles.dctrname}>
                Dr {userDetails.FIRST_NAME} {userDetails.LAST_NAME}
              </Text>
              <Text style={styles.email}>
                Email: {userDetails.EMAIL} Phone: {userDetails.MOBILE}
              </Text>
            </View>
            <View>
              <Image
                style={styles.image}
                source={{ uri: "/static/media/logo.f691f45d4d71e34ff640.png" }}
              />
            </View>
          </View>
          <View style={styles.hr}>
            <hr />
          </View>
          <Text style={styles.presc}>Prescription</Text>
          <View style={styles.container}>
            <Text style={styles.patient}>
              Name: {patientDetails.FIRST_NAME}
              {"\n"}
              Mobile Number: {patientDetails.MOBILE}
              {"\n"}
              Date of Birth: {formatDate(patientDetails.DATE_OF_BIRTH)}
              {"\n"}
              Gender: {patientDetails.GENDER}
            </Text>
            <Text style={styles.patient}>
              Visit Date: {formatDate1(patientDetails.LAST_VISIT_DATE)}
              {"\n"}
              Patient ID: {patientDetails.PATIENT_ID}
              {"\n"}
              Age: {patientDetails.age}
            </Text>
          </View>
          <Text>Vitals</Text>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>BloodPressure(mmHg)</Text>
              <Text style={styles.tableColHeader}>FBS(mg/dl)</Text>
              <Text style={styles.tableColHeader}>RBS(mg/dl)</Text>
              <Text style={styles.tableColHeader}>PPBS(mg/dl)</Text>
              <Text style={styles.tableColHeader}>HbA1c%</Text>
              <Text style={styles.tableColHeader}>weight</Text>
              <Text style={styles.tableColHeader}>BMI(Kg/m²)</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCol}>
                {examinationhistory.sbp || "0"}/{examinationhistory.dbp || "0"}
              </Text>
              <Text style={styles.tableCol}>
                {examinationhistory.fbs || "0"}
              </Text>
              <Text style={styles.tableCol}>
                {examinationhistory.rbs || "0"}
              </Text>
              <Text style={styles.tableCol}>
                {examinationhistory.ppbs || "0"}
              </Text>
              <Text style={styles.tableCol}>
                {examinationhistory.hba1c || "0"}
              </Text>
              <Text style={styles.tableCol}>
                {examinationhistory.weight || "0"}
              </Text>
              <Text style={styles.tableCol}>
                {!isNaN(parseFloat(examinationhistory.bmi))
                  ? parseFloat(examinationhistory.bmi).toFixed(0)
                  : "0"}
              </Text>
            </View>
          </View>

          <Text style={styles.hd}>
            Hypertension Diagnosis - {htdiagnosis.diagnosis ? "yes" : "no"}
          </Text>
          <Text style={styles.advice}>Medication Advice</Text>

          {drugs.length > 0 ? (
            <View style={styles.table2}>
              <View style={styles.tableRow2}>
                <Text style={styles.tableColHeader2}>Medicine Name</Text>
                <Text style={styles.tableColHeader2}>Strength</Text>
                <Text style={styles.tableColHeader2}>Frequency</Text>
                <Text style={styles.tableColHeader2}>Duration (in days)</Text>
                <Text style={styles.tableColHeader2} >Quantity</Text>
                <Text style={styles.tableColHeader2}>Special Instructions</Text>
              </View>
              {drugs &&
                drugs.map((drug, index) => (
                  <View style={styles.tableRow2}>
                    <Text style={styles.tableCol2}>{drug.name}</Text>
                    <Text style={styles.tableCol2}>
                      {drug.currentDose}
                      {drug.unit}
                    </Text>
                    <Text style={styles.tableCol2}>
                      {drug.frequency ? drug.frequency : "Once"}
                    </Text>
                    <Text style={styles.tableCol2}>
                      {drug.durationDays ? drug.durationDays : 0}
                    </Text>
                    <Text style={styles.tableCol2}>
                      {drug.quantity ? drug.quantity : 1}
                    </Text>
                    <Text style={styles.tableCol2}>
                      {drug.instructions ? drug.instructions : "Before food"}
                    </Text>
                  </View>
                ))}
            </View>
          ) : (
            <Text></Text>
          )}
          <View style={styles.hr1}>
            <hr />
          </View>
          {console.log(
            dbdiagnosis.diagnosis,
            "dbdiagnosiiagnosis",
            dbdiagnosis
          )}
          <Text style={styles.hd}>
            Diabetes Diagnosis - {dbdiagnosis.diagnosis ? "yes" : "no"}
          </Text>
          <Text style={styles.advice}>Medication Advice</Text>
          <View style={styles.hr1}>
            <hr />
          </View>
          <Text style={styles.hd}>Other Complaints</Text>
          <Text style={styles.advice}>Remarks</Text>
          <Text style={styles.advice}>Medication</Text>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          ></Text>
        </Page>
      </Document>
    );
  };

  const handleClickPrecsriptionClose = () => {
    setABHAOpen(false);
  };

  const handleClickABHAOpen = () => {
    setABHAOpen(true);
    // toast.success("Data saved successfully");
  };

  const handleButtonClick = () => {
    console.log(drugs, "sdafdxdsgsdggsd....");
    setShowPDF(!showPDF);
    // setButtonsDisabled(!showPDF);
    handleUploadPrescription();
  };

  const buttonStyle = {
    background: "#AF8DFF",
    color: "white",
    marginLeft: "3%",
    borderRadius: "10px",
    width: "10vw",
    height: "5vh",
    border: "none",
    cursor: "pointer",
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    background: "#D3D3D3",
    cursor: "not-allowed",
  };

  const convertFileToBytes = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const bytes = new Uint8Array(arrayBuffer);
        resolve(bytes);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleUploadPrescription = async () => {
    try {
      const blobBuffer = await pdf(<PDFFile />).toBlob();
      const fileBytes = await convertFileToBytes(blobBuffer);
      const data = new FormData();
      const blob = new Blob([fileBytes], { type: "application/pdf" });
      data.append("prescription", blob, `prescription_${VISIT_ID}.pdf`);
      data.append(
        "data",
        JSON.stringify({
          USER_ID: UserID,
          PATIENT_ID: PATIENT_ID,
          VISIT_ID: VISIT_ID,
        })
      );

      const options = {
        method: "POST",
        url: `${Config.base_url}upload_prescription`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "x-caller": await encrypted,
        },
        data,
      };
      const response = await axios(options);
      //console.log(response.data, "data-----");
      if (response.status === 201) {
        // toast.success("File Uploaded Successfully!");
      } else if (response.status === 404) {
        // toast.warning("Sorry", "File is not uploaded ");
      } else {
        toast.error(
          "An unknown error occurred.Please try again after sometime"
        );
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  //link Prescription

  const handleChange = (otp) => {
    setVariables((prevState) => ({
      ...prevState,
      otp: otp,
    }));
  };

  //init call for OTP
  const initModeResponse = async (accessToken, V4) => {
    if (patientData && patientData.ABHA_ADDRESS && patientData.MOBILE) {
      const timestamp = new Date();

      const dataBody = {
        USER_ID: UserID,
        requestId: V4,
        timestamp: format(timestamp, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS"),
        token: accessToken,
        ABHA_ADDRESS: patientData.ABHA_ADDRESS,

        HFR_ADDRESS: HospiralDetails?.HOSPITAL_HFR_ADDRESS,
        // .toLowerCase()
      };
      console.log(dataBody, "....dataBody....");
      await axios
        .post(`${Config.abhaBaseUrl}initModeResponse`, dataBody, {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        })

        .then((response) => {
          if (response.status === 422) {
            // console.log("response:", response);
          } else if (response.status === 200) {
            console.log("initModeResponse");
            toast.success("File Uploaded Successfully!");
            setApiData((prevState) => ({
              ...prevState,
              initReq: V4,
            }));
            getToken(V4);
          }
        })
        .catch(async (error) => {
          console.log(error);
          setIsLoading(false);
          // toast.error("Server Error!");
        });
    } else alert("Please Check Your Details, Some Details is Missing");
  };

  //fetchModes
  const fetchModes = async (accessToken) => {
    console.log(UserID, patientData.USER_ID);
    if (patientData && patientData.ABHA_ADDRESS && patientData.MOBILE) {
      const V4 = uuidv4();
      const timestamp = new Date();
      const dataBody = {
        USER_ID: UserID,
        requestId: V4,
        timestamp: format(timestamp, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS"),
        token: accessToken,
        healthId: patientData.ABHA_ADDRESS,

        ABHA_HPR_ADDRESS: patientData.ABHA_HPR_ADDRESS,
      };
      await axios
        .post(`${Config.abhaBaseUrl}fetchMode`, dataBody, {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        })

        .then((response) => {
          if (response.status === 422) {
            // console.log("response:", response);
          } else if (response.status === 200) {
            // sessionStorage.setItem("accessToken", response.data.accessToken);
            console.log("fetchMode");
            uploadAbhaData(accessToken);
          }
        })
        .catch(async (error) => {
          console.log(error);
          setIsLoading(false);
          toast.error("Server Error!");
        });
    } else alert("Please Check Your Details, Some Details is Missing");
  };

  //sessionToken
  const getSessionTokenAPI = async (notify) => {
    if (patientData.ABHA_ADDRESS.trim() !== "") {
      const dataBody = {
        healthId: patientData.ABHA_ADDRESS,
        USER_ID: UserID,
      };

      await axios
        .post(`${Config.abhaBaseUrl}getSessionToken`, dataBody, {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        })

        .then((response) => {
          sessionStorage.setItem("abhaAddress", dataBody.healthId);
          if (response.status === 422) {
            // console.log("response:", response);
          } else if (response.status === 200) {
            sessionStorage.setItem("accessToken", response.data.accessToken);
            setApiData((prevState) => ({
              ...prevState,
              accessToken: response.data.accessToken,
            }));
            if (notify) {
              abhaLinkContextNotify(response.data.accessToken);
            } else {
              fetchModes(response.data.accessToken);
            }

            console.log("getSessionTokenAPI", response.data.accessToken);
          }
        })
        .catch(async (error) => {
          console.log(error);
          setIsLoading(false);
          toast.error("Server Error!");
        });
    } else {
      // alert("Please enter a valid value");
      alert("Uesr not have ABHA ADDRESS");
    }
  };

  //publickey
  //   const getPublickey = async (notify) => {
  //    await axios.get(`${Config.abhaBaseUrl}publicKey/${UserID}`)
  //       .then((response) => {
  //         console.log("getPublickey response :", response);
  //         if (response.status === 200) {
  //           console.log("PUBLICKEY :", response.data);
  //           sessionStorage.setItem("ABDMPublicKey", response.data);
  //           getSessionTokenAPI(notify);
  //           return response;
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setIsLoading(false);
  //         toast.error("Server Error!");
  //       });
  //   };

  const getPublickey = async (notify) => {
    try {
      const response = await axios.get(
        `${Config.abhaBaseUrl}publicKey/${UserID}`,
      );
      console.log("getPublickey response :", response);
      if (response.status === 200) {
        //console.log("PUBLICKEY :", response.data);
        sessionStorage.setItem("ABDMPublicKey", response.data);
        getSessionTokenAPI(notify);
        return response;
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      toast.error("Server Error!");
    }
  };

  // get call for link

  const getlinktoken = async () => {
    setShowLoading(true);
    try {
      const response = await axios.get(
        `${Config.base_url}getPatientData/${patientDetails.USER_ID}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      console.log("get link response :", response);
      if (response.status === 200) {
        sessionStorage.setItem("CARECONTEXT_LINK_TOKEN", response.data.recordset[0].CARECONTEXT_LINK_TOKEN);
        setShowLoading(false);
      }
    } catch (err) {
      console.error(err);
      setShowLoading(false);
      toast.error("Server Error!");
    }
  };


  //confirm
  const confirmotp = async () => {
    if (patientData && patientData.ABHA_ADDRESS && patientData.MOBILE) {
      const timestamp = new Date();
      const dataBody = {
        USER_ID: UserID,
        requestId: apiData.v4,
        timestamp: format(timestamp, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS"),
        txnId: getTokenData.INIT_TXN_ID,
        token: apiData.accessToken,
        otp: variables.otp,
      };
      await axios
        .post(`${Config.abhaBaseUrl}confirmotp`, dataBody, {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        })

        .then((response) => {
          if (response.status === 422) {
            // console.log("response:", response);
          } else if (response.status === 200) {
            // sessionStorage.setItem("accessToken", response.data.accessToken);
            getToken(apiData.initReq);
            //console.log("response", response);
          }
        })
        .catch(async (error) => {
          console.log(error);
          setIsLoading(false);
          toast.error("Server Error!");
        });
    } else alert("Please Check Your Details, Some Details is Missing");
  };
  // recall get token
  const regetToken = async (V4, Skip) => {
    await getToken(V4, Skip);
  };
  //trdid get token
  const getToken = async (V4, Skip) => {
    try {
      const response = await axios.get(
        `${Config.base_url}GET_TOKEN/${UserID}/${patientData.ABHA_ADDRESS}/${V4}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status === 200) {
        if (!Skip) {
          //console.log(response, "..");
          uploadConfirmData(V4);
        } else {
          //console.log(response, "responseresponseresponse");

          if (response.data.recordset[0].INIT_TXN_ID) {
            // console.log(
            //   response,
            //   response.data.recordset[0].INIT_TXN_ID,
            //   "txnid"
            // );

            if (response.data.recordset[0].ACCESS_TOKEN) {
              setIsLoading(true);
              // console.log(
              //   "ACCESS_TOKEN",
              //   response.data.recordset[0].ACCESS_TOKEN
              // );
              await abhaLinkCareContext(
                response.data.recordset[0].ACCESS_TOKEN
              );
            } else {
              setgetTokenData(response.data.recordset[0]);
              setIsLoading(false);
              setotpBox(true);
            }
          } else {
            //console.log(response, "txnid");
            await new Promise((resolve) => setTimeout(resolve, 5000));
            await regetToken(V4, Skip);
          }
        }
      } else {
        console.error("Failed to fetch :", response.statusText);
      }
    } catch (error) {
      console.error("Error :", error);
      setIsLoading(false);
      toast.error("Server Error!");
    }
  };

  //LINK_ABHA_DATA_QR
  const uploadAbhaData = async (accessToken) => {
    const V4 = uuidv4();
    try {
      const body = {
        USER_ID: UserID,
        ABHA_ID: patientData.ABHA_ADDRESS,
        ABHA_DATA: "",
        INIT_REQUEST_ID: V4,
      };
      const response = await axios.post(
        `${Config.base_url}LINK_ABHA_DATA_QR`,
        body,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      //console.log(response, "uploadAbha");
      if (response.status === 201) {
        console.log(response, "uploadAbhaData");
        await initModeResponse(accessToken, V4);
      } else {
        console.error("Failed to fetch:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      toast.error("Server Error!");
    }
  };
  //LINK_ABHA_CONFIRM_ID
  const uploadConfirmData = async (INIT_REQUEST_ID) => {
    const V4 = uuidv4();
    try {
      const body = {
        USER_ID: UserID,
        ABHA_ID: patientData.ABHA_ADDRESS,
        CONFIRM_REQUEST_ID: V4,
        INIT_REQUEST_ID: INIT_REQUEST_ID,
      };
      const response = await axios.post(
        `${Config.base_url}LINK_ABHA_CONFIRM_ID`,
        body,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status === 201) {
        //console.log(response, "uploadConfirmData");
        setApiData((prevState) => ({
          ...prevState,
          v4: V4,
        }));
        await getToken(INIT_REQUEST_ID, "Skip");
      } else {
        console.error("Failed to fetch:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      toast.error("Server Error!");
    }
  };
  //abhaLinkCareContext
  const abhaLinkCareContext = async (ACCESS_TOKEN) => {
    const timestamp = new Date();
    const V4 = uuidv4();
    //console.log("ACCESS_TOKEN");
    try {
      const body = {
        USER_ID: UserID,
        V4: V4,
        timestamp: format(timestamp, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS"),
        token: apiData.accessToken,
        accessToken: ACCESS_TOKEN,
        patientId: patient_id.toString(),
        patientName: patientData.FIRST_NAME,
        visitId: visitdata.visitId,
      };
      //console.log(body, "abhaLinkCareContext body");
      const response = await axios.post(
        `${Config.abhaBaseUrl}abhaLinkCareContext`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status === 200) {
        //console.log(response, "abhaLinkCareContext");
        getabhaLinkContextNotify();
        // setIsLoading(false);
      } else {
        console.error("Failed to fetch:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      toast.error("Server Error!");
    }
  };


  console.log("patientDetails.DATE_OF_BIRTH", patientDetails.DATE_OF_BIRTH);

  // abha v3 link care context
  const abhav3generateLinkCareContext = async () => {
    console.log("came to generatelinktoken");
    setShowLoading(true);
    try {
      const formattedAbhaNumber = patientDetails.ABHA_NUMBER.replace(/-/g, "");

      const genderMapping = {
        "Male": "M",
        "Female": "F",
        "Other": "O",
        "Diverse": "D",
        "Transgender": "T",
        "Unknown": "U"
      };
      const formattedGender = genderMapping[patientDetails.GENDER] || "U";

      const yearOfBirth = patientDetails.DATE_OF_BIRTH
        ? new Date(patientDetails.DATE_OF_BIRTH).getFullYear().toString()
        : "";

      const body = {
        "abhaNumber": formattedAbhaNumber,
        "abhaAddress": `${patientDetails.ABHA_ADDRESS}`,
        "name": `${patientDetails.FIRST_NAME} ${patientDetails.LAST_NAME}`,
        "gender": formattedGender,
        "yearOfBirth": yearOfBirth
      };

      console.log(body, "abhaLinkCareContext body");
      const response = await axios.post(
        `${Config.abhav3BaseUrl}generateLinkToken`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token,
          },
        }
      );

      console.log("status of response", response.status)

      if (response.status === 200 || response.status === 202) {

        await new Promise(resolve => setTimeout(resolve, 6000));

        await getlinktoken();
        console.log(response, "abhaLinkCareContext");
        await abhav3LinkCareContext();
        setShowLoading(false);
      } else {
        console.error("Failed to fetch:", response.statusText);
        setShowLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response.status == "400") {
        await handleClickPrecsriptionClose();
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.error.code || "Error",
          alertMessage: error.response.data.error.message,
        }
        ));
        setShowLoading(false);
        // toast.error("Server Error!");
      }
    };
  }

  // get link care context
  const abhav3LinkCareContext = async () => {
    setShowLoading(true);
    const linkToken = sessionStorage.getItem("CARECONTEXT_LINK_TOKEN");
    if (!linkToken) {
      console.error("x-link-token is missing or undefined!");
      toast.error("Authentication error: Missing link token.");
      return;
    }
    console.log("linku ", linkToken);
    try {
      const formattedAbhaNumber = patientDetails.ABHA_NUMBER.replace(/-/g, "");
      const yearOfBirth = patientDetails.DATE_OF_BIRTH
        ? new Date(patientDetails.DATE_OF_BIRTH).getFullYear().toString()
        : "";

      const body = {
        "abhaNumber": formattedAbhaNumber,
        "abhaAddress": patientDetails.ABHA_ADDRESS,
        "patientReferenceNumber": patientDetails.ABHA_ADDRESS,
        "patientDisplay": patientDetails.HOSPITAL_NAME || 'clinallyhip',
        "careContextsReferenceNumber": visitData.visitId,
        "careContextsDisplay": `Care Context - ${visitData.visitId}`,
        "hiType": "Prescription"
      };
      console.log(body, "abhaLinkCareContext body");
      const response = await axios.post(
        `${Config.abhav3BaseUrl}linkSingleCareContext`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-token": Config.token,
            "x-hip-id": 'clinallyhip',
            "x-link-token": linkToken,
          },
        }
      );

      console.log("status of abha link single care context", response.status)
      if (!linkToken.includes('.') || linkToken.split('.').length !== 3) {
        console.error("Invalid JWT format:", linkToken);
        toast.error("Invalid authentication token.");
        return;
      }

      if (response.status === 200 || response.status === 202) {
        console.log(response, "abhasingleLinkCareContext");
        toast.success("Link care context has been created successfully!");
        await handleClickPrecsriptionClose();
        setShowLoading(false);
      } else {
        console.error("Failed to fetch:", response.statusText);
        setShowLoading(false);
      }
    } catch (error) {
      if (error.response) {
        console.log("Error Response:", error.response.data);
        console.log("Error Response:", error.response);
        if (error.response.status == "400") {
          await handleClickPrecsriptionClose();
          setVariables((prevState) => ({
            ...prevState,
            showAlert: true,
            alertTitle: "Invalid details",
            alertMessage: "The information you provided does not match the details on record with Aadhaar. Please verify and provide accurate information.",
          }));
        }
        setShowLoading(false);
      } else {
        console.log("Request Error:", error.message);
        setShowLoading(false);
      }
      setShowLoading(false);
    }
  };

  const handleAlertClose = () => {
    setVariables((prevState) => ({
      ...prevState,
      showAlert: false,
    }));
  };


  //abhaLinkContextNotify get public key session token
  const getabhaLinkContextNotify = async () => {
    //console.log("getabhaLinkContextNotify.........");
    await getPublickey("notify");
  };

  // /abhaLinkContextNotify
  const abhaLinkContextNotify = async (ACCESS_TOKEN) => {
    const timestamp = new Date();
    const V4 = uuidv4();

    try {
      const body = {
        USER_ID: UserID,
        requestId: V4,
        timestamp: format(timestamp, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        date: format(timestamp, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS"),
        token: apiData.accessToken,
        accessToken: ACCESS_TOKEN,
        patientId: patient_id.toString(),
        healthId: patientData.ABHA_ADDRESS,
        // hfrAddress: HospitalDetails?.HOSPITAL_HFR_ADDRESS
        //   ? HospitalDetails?.HOSPITAL_HFR_ADDRESS
        //   : patientData.ABHA_HPR_ADDRESS,

        //may hrf id replace..
        hfrAddress: patientData.ABHA_HPR_ADDRESS
          ? patientData.ABHA_HPR_ADDRESS
          : HospiralDetails?.HOSPITAL_HFR_ADDRESS,
        visitId: visitdata.visitId.toString(),
      };
      //console.log(body, "abhaLinkCareContext body");
      const response = await axios.post(
        `${Config.abhaBaseUrl}abhaLinkContextNotify`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status === 201) {
        //console.log(response, "abhaLinkCareContextNotify");
        setPrecsriptionClose(false);
        // setIsLoading(!isLoading);
        setIsLoading(false);

        navigate("/patients");
      } else {
        console.error("Failed to fetch:", response.statusText, response);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      toast.error("Server Error!");
    }
  };

  //   console.log("HospitalDetails*********", HospitalDetails);
  if (HospitalDetails && HospitalDetails.length > 0) {
    // HospitalDetails.forEach((address) => {
    if (
      HospitalDetails.HOSPITAL_HFR_ADDRESS &&
      HospitalDetails.HOSPITAL_HFR_ADDRESS !== null
    ) {
      // console.log(
      //   "HOSPITAL_HFR_ADDRESS:",
      //   HospitalDetails.HOSPITAL_HFR_ADDRESS
      // );
    } else {
      //console.log("HOSPITAL_HFR_ADDRESS is null or undefined in this object.");
    }
    // });
  } else {
    //console.log("HospitalDetails is either null or an empty array.");
  }

  // hemanth = JSON.parse(HospitalDetails);
  // console.log('&&&&&&&',hemanth)

  //   const handleClickABHAOpen = async () => {
  // setIsLoading(!isLoading);
  // await getPublickey();

  // setPrecsriptionClose(true);
  //   };

  //   const handleClickPrecsriptionClose = () => {
  //     setPrecsriptionClose("");
  //   };

  const handleVarifyOTP = () => {
    setotpBox(false);
    //console.log(variables.otp);
    setIsLoading(!isLoading);
    confirmotp();
  };
  const linkPrescription = async () => {
    await handleClickPrecsriptionClose();
    setPrecsriptionClose(false);
    setABHAOpen(false);
    // setIsLoading(!isLoading);
    await abhav3generateLinkCareContext();
    // await getPublickey();
    // toast.success("Data saved Sucessfully");
    // navigate("/patients");
  };
  return (
    <>
      <div className="prescriptionborder">
        {/* <Navbar /> */}
        <div className="patientNamePrescription">
          <div
            className="backButton"
            onClick={() => navigate("/patients/treatmentplan")}
          // onClick={() => {
          //   sessionStorage.removeItem("selecteddrug");
          //   navigate("/patients/treatmentplan");
          // }}
          >
            <img id="image" src={BackArrow} />
          </div>
          <button className="review">
            <h3>
              {patientDetails && patientDetails.FIRST_NAME
                ? patientDetails.FIRST_NAME.toUpperCase()
                : ""}
              'S Prescription
            </h3>
          </button>
          <ToastContainer />
        </div>
        <div className="entireprescriptionpage">
          <div className="prescriptionpageDrugs">
            <div className="previewtext">
              <div className="RecommendationPrescription">
                <p style={{ color: "#52447D" }}>Recommendation</p>
              </div>
              <p style={{ color: "#868686" }}>
                Please click 'Print Preview' button and verify the prescription.
              </p>
            </div>
            <div>
              {drugs &&
                drugs.map((drug, index) => (
                  console.log("drugs", drugs),
                  <div className="drugprescription">
                    <div className="drugnamep">
                      <div style={{
                        display: "flex", flexDirection: "row", marginLeft: "2%", alignItems: "center",
                        gap: "2%"
                      }}>
                        <h5 style={{ color: "#52447D", paddingLeft: "3%" }}>
                          {drug?.name}
                        </h5>
                        <div style={{ marginLeft: "2%" }}>
                          <CheckIcon />
                        </div>
                      </div>
                      <div style={{ marginRight: "2%" }}>
                        <button className="DeleteBtnPr" onClick={() => handleDelete(index)}>Delete</button>
                      </div>
                    </div>

                    <div className="DrugDetailsPre">
                      <p className="DrugNamesPre" >
                        {drug?.className ? drug?.className : ""}
                      </p>
                      <p className="DrugNamesPre">
                        {drug?.currentDose ? drug?.currentDose : ""}
                        {drug?.unit ? drug?.unit : ""}{" "}
                        {drug?.frequency ? drug?.frequency : "Once"} daily
                      </p>
                      <p className="DrugNamesPre">
                        {drug?.instructions ? drug?.instructions : "Before food"}{" "}
                        for{" "}
                        {drug?.durationDays
                          ? drug?.durationDays
                          : drug?.quantity
                            ? drug?.quantity
                            : "0"}{" "}
                        days
                      </p>
                    </div>
                    {/*<div>
                      <IconButton
                        aria-label="delete"
                        style={{ color: "red" }}
                        onClick={() => handleDelete(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>*/}
                  </div>
                ))}
            </div>
            <div className="setfollowuptext">
              <div>
                <p>
                  Set follow up date, preview (print) and close prescription.
                  You can go back and edit prescription before closing.
                </p>
              </div>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div className="PrescriptionBtns" style={{ width: "100%" }}>
              <Button
                // style={{
                //     background: '#AF8DFF',
                //     color: 'white',
                //     marginLeft: '3%',
                //     borderRadius: '10px',
                //     width: '10vw',
                //     height: '5vh',
                //     border: 'none',
                //     cursor: 'pointer'
                // }}
                style={buttonsDisabled ? disabledButtonStyle : buttonStyle}
                onClick={handleFollowupOpen}
                disabled={buttonsDisabled}
              >
                SET FOLLOWUP
              </Button>
              <Dialog open={followUp} onClose={handleFollowupClose}>
                <DialogTitle>Set Followup</DialogTitle>
                <div style={{ textAlign: "center" }}>
                  <DialogContent>
                    <p>Suggested followup based on Analysis is</p>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "180%",
                        margin: "4%",
                      }}
                    >
                      {updateDate.format("DD-MMM-YYYY")}
                    </div>
                    <Button
                      onClick={handleFollowupDateOpen}
                      style={{
                        background: "#9b00d3",
                        color: "white",
                        marginTop: "5%",
                      }}
                    >
                      Change
                    </Button>
                    <Dialog
                      open={followUpDateOpen}
                      onClose={handleFollowupDateClose}
                    >
                      <div style={{ background: "#9b00d3", color: "white" }}>
                        <DialogTitle>SELECT DATE</DialogTitle>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "200%",
                            color: "white",
                          }}
                        >
                          <DialogContent>
                            {updateDate.format("ddd, MMM DD")}
                          </DialogContent>
                        </div>
                      </div>
                      <FollowupCalender
                        onSelectedDate={handleDateSelect}
                        value={updateDate}
                      />
                      <DialogActions>
                        <Button
                          onClick={handleFollowupDateClose}
                          style={{ color: "#9b00d3" }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleFollowupDateClose}
                          style={{ color: "#9b00d3" }}
                        >
                          ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </DialogContent>
                </div>
                <DialogActions>
                  <Button
                    onClick={handleFollowupClose}
                    style={{ background: "#9b00d3", color: "white" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={RecordFollowup}
                    style={{ background: "#9b00d3", color: "white" }}
                  >
                    Continue
                  </Button>
                </DialogActions>
              </Dialog>
              <Button
                style={{
                  background: "#AF8DFF",
                  color: "white",
                  marginLeft: "3%",
                  borderRadius: "10px",
                  width: "10vw",
                  height: "5vh",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handleButtonClick}
              >
                PRINT PREVIEW
              </Button>
              <Button
                // style={{
                //     background: '#AF8DFF',
                //     color: 'white',
                //     marginLeft: '5%',
                //     borderRadius: '10px',
                //     width: '10vw',
                //     height: '5vh',
                //     border: 'none',
                //     cursor: 'pointer'
                // }}
                style={buttonsDisabled ? disabledButtonStyle : buttonStyle}
                onClick={handleClickCloseRecordOpen}
                disabled={buttonsDisabled}
              >
                CLOSE RECORD
              </Button>
              <Dialog open={closeRecord} onClose={handleClickCloseRecordClose}>
                <DialogTitle> Attention !!</DialogTitle>
                <DialogContent>
                  <p>Are you sure want to close this record.</p>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClickCloseRecordClose}
                    style={{ background: "#9b00d3", color: "white" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={RecordClosed}
                    style={{ background: "#9b00d3", color: "white" }}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              {patientDetails.ABHA_ADDRESS && (
                <Button
                  style={buttonsDisabled ? disabledButtonStyle : buttonStyle}
                  onClick={handleClickABHAOpen}
                  disabled={buttonsDisabled}
                >
                  LINK ABHA
                </Button>
              )}
              <Dialog open={ABHAopen} onClose={handleClickPrecsriptionClose}>
                <DialogTitle>Attention !!</DialogTitle>
                {HospiralDetails?.HOSPITAL_HFR_ADDRESS === null ||
                  HospiralDetails?.HOSPITAL_HFR_ADDRESS === "" ? (
                  //  &&(patientDetails.ABHA_HPR_ADDRESS === null ||
                  // patientDetails.ABHA_HPR_ADDRESS === "")
                  <DialogContent style={{ marginTop: "10px" }}>
                    <p>
                      Please link/create Health Facility Registry ID to access
                      the ABHA services for patients.
                    </p>
                  </DialogContent>
                ) : (
                  <DialogContent>
                    {patientDetails.ABHA_ADDRESS !== null &&
                      patientDetails.ABHA_ADDRESS !== "" &&
                      (HospiralDetails?.HOSPITAL_HFR_ADDRESS !== null || HospiralDetails?.HOSPITAL_HFR_ADDRESS !== "") ? (
                      <p style={{ marginTop: "15px" }}>
                        Would you like to link this prescription to the
                        patient's ABHA ID.
                      </p>
                    ) : (
                      <p>
                        There is no ABHA ID linked to this patient. An SMS alert
                        will be sent to the patient's registered mobile number.
                        In the future, please link/create the ABHA ID to our
                        system.
                      </p>
                    )}
                  </DialogContent>
                )}

                <DialogActions>
                  <Button
                    onClick={handleClickPrecsriptionClose}
                  // style={{ background: "#9b00d3", color: "white" }}
                  >
                    Cancel
                  </Button>
                  {
                    (HospiralDetails?.HOSPITAL_HFR_ADDRESS === null || HospiralDetails?.HOSPITAL_HFR_ADDRESS === "")
                      &&
                      (patientDetails.ABHA_HPR_ADDRESS === null ||
                        patientDetails.ABHA_HPR_ADDRESS === "")
                      ?
                      (
                        <Button
                          onClick={
                            () => {
                              // getlinktoken
                              // abhav3generateLinkCareContext
                              // console.log("link pres preview")
                              navigate("/profile?action=hrfid")
                            }
                          }
                        // style={{ background: "#9b00d3", color: "white" }}
                        >
                          {showLoading && (
                            <CircularProgress size={24} color="inherit" />
                          )}
                          Link
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            if (linkcontexttoken === null || linkcontexttoken === "null" || linkcontexttoken.trim() === "") {
                              abhav3generateLinkCareContext();
                            } else {
                              abhav3LinkCareContext();
                            }
                          }}
                        // onClick={() => {
                        //   // navigate("/patients")
                        // }}
                        // style={{ background: "#9b00d3", color: "white" }}
                        >
                          {showLoading && (
                            <CircularProgress size={24} color="inherit" />
                          )}
                          Ok
                        </Button>
                      )}
                </DialogActions>
              </Dialog>
            </div>
            <div style={{ overflow: "scroll", height: "100%" }}>
              {showPDF && (
                <PDFViewer
                  style={{ width: "100%", height: "100vh", marginTop: "3%" }}
                >
                  <PDFFile />
                </PDFViewer>
              )}
            </div>
          </div>
        </div>

        {isLoading ? (
          <div
            style={{
              // position: "absolute",
              top: "0",
              width: "100%",
              height: "100%",
              background: "rgba(51, 46, 46, 0.21)", // Equivalent to #332e2e36
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              overflow: "hidden",
              left: "0",
            }}
          >
            <CircularProgress size={30} color="inherit" />
          </div>
        ) : (
          <>
            <Dialog open={otpBox}>
              <div style={{ padding: "8%" }}>
                <DialogTitle> Enter Password </DialogTitle>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <OtpInput
                    value={variables.otp}
                    onChange={handleChange}
                    numInputs={6}
                    renderSeparator={<span>{""}</span>}
                    renderInput={(props) => <input {...props} type="tel" />}
                    skipDefaultStyles={true}
                    inputStyle="otp-input"
                    inputType="number"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "2rem",
                    padding: "4%",
                    marginTop: "5%",
                  }}
                >
                  <button
                    className="ContinueBtn"
                    style={{
                      Background: "#52447d !important",
                      height: "40px",
                    }}
                    onClick={handleVarifyOTP}
                  >
                    Verify OTP
                  </button>

                  <button
                    className="ContinueBtn"
                    onClick={() => {
                      setotpBox(!otpBox);
                    }}
                    style={{
                      Background: "#52447d !important",
                      height: "40px",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Dialog>
          </>
        )}

        {variables.showAlert && (
          <ShowAlert
            title={variables.alertTitle}
            message={variables.alertMessage}
            onClose={handleAlertClose}
          />
        )}
      </div>
    </>
  );
};

export default Prescription;
